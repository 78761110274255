import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loaderStatus', () => {

    const loader = ref<boolean>(false);
    const darkTheme = ref(useLocalStorage('applicationTheme' ,false));
    const isDeactivated = ref(useLocalStorage('is_deactivated' ,false));
    const isNavCollapsed = ref(useLocalStorage('is_nav_collapsed' ,false));
    const alertMessage = ref<string>('');
    const showDialog = ref<boolean>(false);
    const globalLoader = ref<boolean>(false);
    const istodoEdit = ref<boolean>(false);
    const isMemberRemoved = ref<boolean>(false);
    const awskeys = ref<any>();
    const referLink = ref<any>();
    const changePicture = ref<boolean>(false);
    const profilePercentage = ref<any>();
    const isPlanHeaderVisible = ref<boolean>(false);
    const menuHelpOpen = ref<boolean>(false);
    const downloadMenu = ref<boolean>(false);
    const downloadMenuGetApp = ref<boolean>(false);
    const showYoutubeRecommendation = ref<boolean>(false);
    const showLang = ref<boolean>(false);
    const drawerMenuBox = ref<boolean>(false);
    const dropMenu = ref<boolean>(false);
    const addNewMessage = ref<boolean>(false);
    const isDownloading = ref<boolean>(false);
    const bulkTimesheet = ref<boolean>(false);
    const searchToggle = ref<boolean>(false);
    const searchBoxToggle = ref<boolean>(true);
    const getApps = ref<any>(0)
    const getDashboard = ref<any>(0)
    const getUserTimesheetStatus = ref<any>(0)
    const signUpMessage = ref<any>({
      // message1: 'We have sent a verification link to ', 
      // message2: 'Click on the link to complete the verification process. You might need to check your spam folder.',
      // email:'sigupmai@yopmail.com'
    })
    const memberSignupMessage = ref<any>("")

    const showLoader = (loaderVal: boolean) => {
      loader.value = loaderVal;
    };
    
    const toggleTheme = (theme: boolean) =>{
      darkTheme.value  = theme;
    }

    const setDeactivated = (status:boolean) =>{
      isDeactivated.value = status;
    }
    
    const toggleCollapse = (status:boolean) =>{
      isNavCollapsed.value = status;
    }

    const setAlertMessage = (message:string) =>{
      alertMessage.value = message;
    }

    const setDialogStatus = (status:boolean) =>{
      showDialog.value = status;
    }

    const setGlobalLoader = (status:boolean) =>{
      globalLoader.value = status;
    }

    const setIsEditTodo = (status:boolean) =>{
      istodoEdit.value  = status;
    }

    const setMemberRemoveStatus = (status:boolean) =>{
      isMemberRemoved.value = status;
    }

    const setAWSKeys = (keys:string[]) =>{
      awskeys.value = keys;
    }

    const setReferLinkKeys = (keys:any) =>{
      referLink.value = keys;
    }

    const setChangePicture = (key:boolean) =>{
      changePicture.value = key;
    }

    const setPercentageInfo = (data:any)=>{
      profilePercentage.value = data;
    }

    const setHeaderValue = (status:boolean) =>{
      isPlanHeaderVisible.value = status;
    }

    const setHelpMenuState = (state:boolean) =>{
      menuHelpOpen.value = state
    }
      const setdownloadMenuState = (state:boolean) =>{
      downloadMenu.value = state
    }
    const setdownloadMenuGetAppState = (state:boolean) =>{
      downloadMenuGetApp.value = state
    }
      const setWelcomVideoMenuState = (state:boolean) =>{
      showYoutubeRecommendation.value = state
    }
      const setLangMenuState = (state:boolean) =>{
      showLang.value = state
    }
      const setDrawerMenuState = (state:boolean) =>{
      drawerMenuBox.value = state
    }
      const setUserProfileMenuState = (state:boolean) =>{
      dropMenu.value = state
    }
    const setAddNewMessage = (state:boolean) =>{
      addNewMessage.value = state
    }
    const setisDownloading = (state:boolean) =>{
      isDownloading.value = state
    }
    const setisBulkTimesheet = (state:boolean) =>{
      bulkTimesheet.value = state
    }
    const setSearchToggle = (state:boolean) =>{
      searchToggle.value = state
    }
    const setSearchBox = (state:boolean) =>{
      searchBoxToggle.value = state
    }

    return {
      loader,
      darkTheme,
      isDeactivated,
      isNavCollapsed,
      alertMessage,
      showDialog,
      globalLoader,
      istodoEdit,
      isMemberRemoved,
      changePicture,
      awskeys,
      referLink,
      showLoader,
      menuHelpOpen,
      downloadMenu,
      downloadMenuGetApp,
      showYoutubeRecommendation,
      showLang,
      drawerMenuBox,
      dropMenu,
      addNewMessage,
      isDownloading,
      bulkTimesheet,
      searchToggle,
      searchBoxToggle,
      profilePercentage,
      toggleTheme,
      isPlanHeaderVisible,
      setDeactivated,
      toggleCollapse,
      setAlertMessage,
      setDialogStatus,
      setGlobalLoader,
      setIsEditTodo,
      setMemberRemoveStatus,
      setAWSKeys,
      setChangePicture,
      setPercentageInfo,
      setHeaderValue,
      setHelpMenuState,
      setdownloadMenuState,
      setWelcomVideoMenuState,
      setLangMenuState,
      setDrawerMenuState,
      setUserProfileMenuState,
      setdownloadMenuGetAppState,
      setAddNewMessage,
      setisDownloading,
      setisBulkTimesheet,
      setReferLinkKeys,
      getApps,
      signUpMessage,
      memberSignupMessage,
      getDashboard,
      getUserTimesheetStatus,
      setSearchToggle,
      setSearchBox
    };
  });
  
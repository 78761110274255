{
  "Branch": "Branch",
  "BranchHead": "Branch Head",
  "Unit": "Unit",
  "UnitHead": "Unit Head",
  "managingDirector": "Managing Director",
  "exeDirector": "Executive Director",
  "corpUser": "Corporate User",
  "newUnit": "New Unit",
  "addUnit": "Add Unit",
  "noUnits": "There are no units!",
  "addNewUnit": "Add New Unit",
  "unitName": "Unit Name",
  "unitHead": "Unit Head",
  "enterUnitName": "Enter Unit Name",
  "selectUnitHead": "Select Unit Head",
  "createDiffUnitBranch": "Create different units/branch to streamline your workflow.",
  "editUnit": "Edit Unit",
  "removeUnit": "Remove Unit",
  "unitHeadDet": "Unit head details",
  "ediffBranchUnit": "Edit different units/branch to streamline your workflow.",
  "newBranch": "New Branch",
  "noBranch": "There is no branch",
  "selectUnit": "Select Unit",
  "branchName": "Branch Name",
  "editBranch": "Edit Branch",
  "removeBranch": "Remove Branch",
  "branchWillBeRemoved": "Branch will be removed.Do you still wish to continue?", 
  "addNewBranch": "Add New Branch",
  "no_screenshots_line_1":"Currently, screenshots are not available.",
  "no_screenshots_line_2":"Please check your settings to ensure screenshots are set to capture at a minimum frequency of once per interval.",
  "leave_history_line_1":"Currently, there is no leave history available for the selected user. ",
  "leave_history_line_2":"Please ensure leave requests are submitted and recorded for accurate tracking.",
  "no_attendance_line_1":"Currently, there are no attendance records available.",
  "no_attendance_line_2":"Please ensure attendance is logged to maintain accurate tracking of work hours and presence.",
  "no_attendance_line_3":"Submit a timesheet to update attendance records.",
  "no_timesheet_approval_line_1":"Currently, there are no timesheet approval requests.",
  "no_timesheet_approval_line_2":"Please submit a timesheet for approval to ensure accurate tracking and verification of work hours.",
  "no_timesheet_line_1":"Currently, there are no time logs available.",
  "no_timesheet_line_2":"Please add a new time entry to accurately track your work hours and progress.",
  "no_milestone_yet_line_1":"Looks like you don't have any milestones yet. ",
  "no_milestone_yet_line_2":"Start by creating your first milestone to keep track of your project's progress!",
  "no_task_line_1": "Currently, no tasks are available",
  "no_task_line_2": "Please create a new task to start organizing and tracking your work.",
  "Dashboard": "Dashboard",
  "Departments": "Departments",
  "Subscription": "Subscription",
  "Activity": "Activity",
  "Screenshots": "Screenshots",
  "ExplainThisScreen": "Explain this Screen",
  "workstatus-intro": "Workstatus Intro",
  "Apps": "Apps",
  "URL": "URLs",
  "Timesheets": "Timesheets",
  "View & Edit Timesheets": "View Timesheet",
  "Approvals": "Timesheet Approval",
  "Attendance": "Attendance",
  "Time & Attendance": "Time & Attendance",
  "People": "People",
  "Employees": "Employees",
  "Peoples": "Peoples",
  "Members": "Members",
  "Teams": "Teams",
  "Project Management": "Project Management",
  "Projects": "Projects",
  "Todo": "Tasks",
  "Break": "Break",
  "Clients": "Clients",
  "Workorder": "Work Orders",
  "Location": "Location",
  "Job sites": "Job sites",
  "Shift & Leaves": "Shift & Leaves",
  "Schedules": "Schedules",
  "Time Off": "Time off",
  "Reports": "Reports",
  "Time & Activity": "Time & Activity",
  "Weekly": "Weekly",
  "Dynamic Report": "Dynamic Report",
  "Jobsites": "Jobsites",
  "Apps & Urls": "Apps & Urls",
  "Project and Task": "Project and Task",
  "Attendance Report - Basic": "Attendance - Basic",
  "Attendance Report - Advanced": "Attendance - Advanced",
  "Jobsite": "Jobsite",
  "Time off transaction": "Time off transaction",
  "All Reports": "All Reports",
  "Settings": "Settings",
  "General": "General",
  "Features": "Features",
  "Memberships": "Memberships",
  "Organisation Management": "Organizations",
  "Organizations": "Organizations",
  "AllSettings": "All Settings",
  "Change log": "Change log",
  "Helpdesk": "Helpdesk",
  "Integration": "Integrations",
  "UI Elements": "UI Elements",
  "Forms & Tables": "Forms & Tables",
  "Pages": "Pages",
  "Charts & Maps": "Charts & Maps",
  "Others": "Others",
  "Typography": "Typography",
  "Cards": "Cards",
  "Basic": "Basic",
  "Advance": "Advance",
  "Widgets": "Widgets",
  "Components": "Components",
  "Alert": "Alert",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Button": "Button",
  "Calendar": "Calendar",
  "Image": "Image",
  "Pagination": "Pagination",
  "Progress": "Progress",
  "Tooltip": "Tooltip",
  "CheckLocation": "Check location",
  "Slider": "Slider",
  "Date Time Picker": "Date Time Picker",
  "Select": "Select",
  "Switch": "Switch",
  "Checkbox": "Checkbox",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Rating",
  "File Input": "File Input",
  "Form Layout": "Form Layout",
  "Form Validation": "Form Validation",
  "Charts": "Charts",
  "Apex Chart": "Apex Chart",
  "Chartjs": "Chartjs",
  "Account Settings": "Account Settings",
  "User Profile": "User Profile",
  "FAQ": "FAQ",
  "Pricing": "Pricing",
  "List": "List",
  "Edit": "Edit",
  "Nav Levels": "Nav Levels",
  "Level 2.1": "Level 2.1",
  "Level 2.2": "Level 2.2",
  "Level 3.1": "Level 3.1",
  "Level 3.2": "Level 3.2",
  "Raise Support": "Raise Support",
  "Documentation": "Documentation",
  "Dashboards": "Dashboards",
  "Analytics": "Analytics",
  "Apps & Pages": "Apps & Pages",
  "Email": "Email",
  "Chat": "Chat",
  "Invoice": "Invoice",
  "Preview": "Preview",
  "Add": "Add",
  "User": "User",
  "View": "View",
  "Login v1": "Login v1",
  "Login v2": "Login v2",
  "Login": "Login",
  "Register v1": "Register v1",
  "Register v2": "Register v2",
  "Register": "Register",
  "Forget Password v1": "Forget Password v1",
  "Forget Password v2": "Forget Password v2",
  "Forgot Password v1": "Forgot Password v1",
  "Forgot Password v2": "Forgot Password v2",
  "Forgot Password": "Forgot Password",
  "Reset Password v1": "Reset Password v1",
  "Reset Password v2": "Reset Password v2",
  "Reset Password": "Reset Password",
  "Miscellaneous": "Miscellaneous",
  "Coming Soon": "Coming Soon",
  "Not Authorized": "Not Authorized",
  "Under Maintenance": "Under Maintenance",
  "Error": "Error",
  "Statistics": "Statistics",
  "Actions": "Actions",
  "Access Control": "Access Control",
  "User Interface": "User Interface",
  "CRM": "CRM",
  "eCommerce": "eCommerce",
  "Icons": "Icons",
  "Chip": "Chip",
  "Dialog": "Dialog",
  "Expansion Panel": "Expansion Panel",
  "Combobox": "Combobox",
  "Textfield": "Textfield",
  "Range Slider": "Range Slider",
  "Menu": "Menu",
  "Snackbar": "Snackbar",
  "Tabs": "Tabs",
  "Form Elements": "Form Elements",
  "Form Layouts": "Form Layouts",
  "Authentication": "Authentication",
  "Page Not Found - 404": "Page Not Found - 404",
  "Not Authorized - 401": "Not Authorized - 401",
  "Server Error - 500": "Server Error - 500",
  "2": "2",
  "days": "days",
  "freeTrialMsg": "Trial expires in",
  "expireMsg": "Plan expired",
  "Forms": "Forms",
  "Timeline": "Timeline",
  "Disabled Menu": "Disabled Menu",
  "Help Center": "Help Center",
  "Verify Email": "Verify Email",
  "Verify Email v1": "Verify Email v1",
  "Verify Email v2": "Verify Email v2",
  "Two Steps": "Two Steps",
  "Two Steps v1": "Two Steps v1",
  "Two Steps v2": "Two Steps v2",
  "Organization": "Organization",
  "organization": "Organization",
  "addNewOrg": "Add New Organization",
  "changeLang": "Change language",
  "profile1": "Profile",
  "Timesheet": "Timesheet",
  "messageBoard": "Message Board",
  "Timeoff": "Timeoff",
  "Achievement": "Achievement",
  "Edit Organization": "Edit Organization",
  "Timer Apps": "Timer Apps",
  "Department": "Department",
  "Overview": "Overview",
  "Billing History": "Billing History",
  "logout": "Logout",
  "Project & Task Report": "Project & Task Report",
  "Attendance Report": "Attendance Report",
  "Job Sites": "Job Sites",
  "deletedAcc": "This account has been deleted.",
  "Manualedit": "Manual Time Edit",
  "Profile Picture": "Profile Picture",
  "Primary Information": "Primary Information",
  "About": "About",
  "About my job": "About my job",
  "Interests & Hobbies": "Interests & Hobbies",
  "Personal Information": "Personal Information",
  "Emergency contact": "Emergency contact",
  "Job Details": "Job Details",
  "Banking Information": "Banking Information",
  "Identity Information": "Identity Information",
  "Deployed": "Deployed",
  "Yet to Start": "Yet to Start",
  "In Progress": "In Progress",
  "On Hold": "On Hold",
  "Archived": "Archived",
  "Canceled": "Canceled",
  "Open": "Open",
  "In Discussion": "In Discussion",
  "In Development": "In Development",
  "QA Review": "QA Review",
  "Ready For Test": "Ready For Test",
  "Need Help": "Need Help",
  "Re-Open": "Re-Open",
  "Invalid": "Invalid",
  "Ready for deployment": "Ready for deployment",
  "Enabled": "Enabled",
  "Disabled": "Disabled",
  "NOT LOGGED-IN YET": "NOT LOGGED-IN YET",
  "NOT WORKING": "NOT WORKING",
  "WORKING": "WORKING",
  "$vuetify": {
    "badge": "Badge",
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "previous": "previous",
        "next": "next",
        "currentPage": "currentPage",
        "page": "page"
      }
    },
    "input": {
      "clear": "clear",
      "appendAction": "appendAction",
      "prependAction": "prependAction",
      "counterSize": "counterSize"
    },
    "fileInput": {
      "counterSize": "counterSize"
    },
    "rating": {
      "ariaLabel": {
        "item": "item"
      }
    }
  },
  "validators": {
    "requiredValidator": "This field is required",
    "emailValidator": "The Email field must be a valid email",
    "passwordValidator": "Use 6 or more characters with a mix of letters, numbers & symbols(!,#,$,%,&,*,-,@)",
    "confirmedValidator": "Both passwords must match",
    "betweenValidator": {
      "enterNumberbetween": "Enter number between",
      "and": "and",
      "the": "The"
    },
    "integerValidator": "This field must be an integer",
    "regexValidator": "The Regex field format is invalid"
  },
  "LoginView": {
    "Login": "Login",
    "LoginBtn": "Proceed To Login",
    "RememberMe": "Remember me",
    "ForgotPassword": "Forgot Password",
    "Email": "Email address*",
    "Password": "Password"
  },
  "authForgotPassword": {
    "recoverPassword": "Recover Your Password",
    "sendResetLink": "Send reset link",
    "backToLogin": "Go back to login"
  },
  "authMailConfirm": {
    "resetYourPassword": "Reset your password",
    "confirmationEmail": "A confirmation e-mail has been sent to",
    "checkYourInbox": "Check your inbox and click on the 'reset password' link to change your password"
  },
  "settings": {
    "help&support": "HELP & SUPPORT",
    "knowMore": "Know More",
    "notAddedYet": "Not added yet",
    "general": {
      "projects": {
        "controlDefaultRole": "Control the default role as members will be assigned by default to the selected role.",
        "financeSettingDesc": "Allow your Project Managers to view the project financial data",
        "ProjectSettingDesc": "Allow your Project Managers to view the project setting",
        "individualSetting": "INDIVIDUAL SETTING",
        "members": "Members",
        "search": "Search",
        "membersRoleHelp&support": "Delegate responsibilities within your projects by assigning distinct roles to team members, ensuring clarity and optimized project execution. Know more about how to manage role-based assignments for better project management.",
        "manageProjIdHelp&Support": " Assign unique identifiers to projects, ensuring smooth tracking, simplified categorization, and effective project management.",
        "manageTodoStatusHelp&Support": " Enhance task visibility and progress tracking through Task Status Management. Monitor task stages, promote accountability, and achieve project milestones.",
        "projectMilestoneHelp&Support": "Navigate project progression with milestone management. Define key milestones, track accomplishments, and stay on course for successful project outcomes.",
        "manageTaskIDHelp&Support": "Assign unique identifiers to tasks, ensuring smooth tracking, simplified categorization, and effective task management."
      },
      "EditOrg": {
        "setIpAddress": "Set IP Address",
        "OrgDetails": "Organization Details",
        "selCurrency": "Select Currency",
        "selTimezone": "Select Timezone",
        "Date format": "Date format",
        "Save": "Save",
        "Max file size: 2MB": "Max file size: 2MB",
        "vat/gst": "VAT / GST Number*",
        "vatGstIN": "I want a VAT/GSTIN invoice",
        "companyName": "Company Name",
        "companyAddress": "Company Address*",
        "taxId": "Tax ID",
        "start-week": "Start week on*",
        "selectTimezone": "Select Timezone",
        "vatBusiness": "VAT / GST Business Name*",
        "editOrgHelp&support": "Manage and update your organization's details to reflect its identity, ensuring accurate information and smooth operations. Know more about tailoring your organization's profile for seamless management.",
        "setMemberRateHelp&Support": " Define billing rates, optimize revenue streams, and align your services with client expectations. Know more about configuring billing rates that reflect the value of your services.",
        "memberTimeZoneHead": "MEMBERS TIME ZONE",
        "memberTimeZoneHelp&Support": " Global collaboration by configuring individual time zones, ensuring seamless communication and coordinated efforts across diverse locations. Learn more about adding members to multiple time zones for smooth teamwork.",
        "membersWeekOffHead": "MEMBERS WEEKOFF",
        "membersWeekOffHelp&Support": " Better operational planning by setting customized week-offs for members, promoting well-being and efficient employee scheduling. Know more about providing weekoff for better work-life balance and productivity.",
        "billRate($)": "BILL RATE ($)",
        "billRate(₹)": "BILL RATE (₹)",
        "billRate": "BILL RATE"
      },
      "TimerApp": {
        "ScreenshotTimerHead": "SCREENSHOT TIMER",
        "ScreenshotTimerHelp&Support": " Capture work progress with a screenshot timer. Set timer intervals to 5, 10, or 15 minutes as per your project requirements. Learn more about how to customize a screenshot timer for your specific business needs.",
        "IdleNotesHead": "IDLE NOTES",
        "IdleNotesHelp&Support": "Optimize time tracking with idle notes. Log breaks or distractions to view work patterns for detailed productivity analysis of teams. Learn more about adding time notes to gain insight into work habits and optimize efficiency.",
        "DefineBreaksHead": "DEFINE BREAKS",
        "DefineBreaksHelp&Support": " Maintain a balanced workflow by defining structured breaks. Foster rejuvenating pauses while aligning with your team's operational needs. Know more about defining break intervals to promote well-being and flexible work schedules.",
        "BreakTimeLimitHead": "BREAK TIME LIMIT",
        "BreakTimeLimitHelp&Support": "Set predefined break intervals to ensure optimal productivity and prevent extended breaks for better task completion rates. Explore more about setting break times to maintain a healthy work environment for more efficiency.",
        "TrackingModeHelp&Support": "Choose from ‘Visible’ and ‘Stealth’ modes to monitor time, providing flexibility in tracking and accommodating various work routines. Read more about switching tracking modes to suit your preferences and work environment."
      },
      "Department": {
        "DepartmentHelp&Support": " Set up departments to organize your teams. Define HODs, approve timesheets, and more for a productive operational framework. Learn more about setting up departments for better task allocation, collaboration, and management."
      },
      "Members": {
        "memberIdHelp&Support": "Set up unique Member IDs to enhance user identification. Optimize system navigation with personalized Member IDs.",
        "desigantionHelp&Support": "Create the various job titles or designations and assign it to the employees. It will help yu to manage the resource in hierarichal manner",
        "profileSettingsHelp&Support": "Set the privacy and permissions, If you want the employees to see the profile of other employees"
      },
      "expense": {
        "expanseText": "Define Expense category or Enable/Disable Expense category for all members in organization",
        "addCategories": "Add Category",
        "searchPlaceholder": "search category",
        "categoryName": "Category Name",
        "statusText": "Status",
        "deleteText": "Delete",
        "defineCategory": "DEFINE EXPENSE CATEGORY",
        "DefineExpenseHelp&Support":"Consolidate all project-related expenses in one place so as the organizations can achieve better financial control, ensure transparency, and enhance overall project management efficiency.",
        "saveText": "Save",
        "sureToDelete": "Are you sure you want to delete this category?"
      },
      "memberWeeklyOff": "Members Weekly Off",
      "memberTimezone": "Members Timezone",
      "setBillingRate": "Set Member Rates"
    },
    "feature": {
      "activity": {
        "updateVisiblityStatus": "Status changed successfully.",
        "hideActivity": "Hide activity menu for users (including Screenshot,Apps & URLs)",
        "noData": "There are no members present at this moment!",
        "hideScreenshot": "Stop showing app notifications for taking screenshots",
        "productivity-setting": "Track actual productive hours of members by defining productive and unproductive applications/Urls",
        "Disable": "Disable",
        "Enable": "Enable",
        "DeleteScreenshot": "Allow managers and owners to delete screenshots.",
        "INDIVIDUAL SETTING": "INDIVIDUAL SETTING",
        "MEMBERS": "MEMBERS",
        "controlSSFrequency": "Control the frequency of the screenshots.",
        "SsBlur": "Control whether the desktop app blurs screenshots for security and privacy.",
        "TrackUrl": "Control whether the names of apps used and the URLs visited are tracked",
        "Ok": "Ok",
        "Track Apps & URLs both": "Track Apps & URLs both",
        "Track URLs only": "Track URLs only",
        "Track Apps only": "Track Apps only",
        "OFF": "OFF",
        "Add Unproductive": "Add Unproductive",
        "Add productive": "Add productive",
        "URLs": "URLs",
        "Apps": "Apps",
        "All Teams": "All Teams",
        "Search": "Search",
        "hideActivityMenuHelp&Support": "Workstatus gives team owners an advantage to hide the activity menu for their users. Know more about how to hide the activity menu to reduce distractions for focused task management.",
        "screenshotNotificationHelp&Support": "Stay informed with real-time alerts on Screenshot Notifications. Stay accountable and transparent in your work process. Know more about staying updated on screenshot activities for complete transparency.",
        "trackAppsUrlHelp&Support": "Analyze work patterns with proper insights into apps and website usage to optimize productivity. Learn more about website & app monitoring tools to gain valuable insights for better time management.",
        "blurScreenshotHelp&Support": "Ensure data privacy with Screenshots Blur. Safeguard sensitive information in screenshots, balancing accountability and confidentiality. Know more about the advanced features of screenshot monitoring to maintain the highest privacy standards.",
        "screenshotFrequencyHelp&Support": "Customize screenshot capture frequency to 1x, 2x, 3x in the time intervals of 5, 10, and 15 minutes. Learn more about managing and editing screenshot frequency to match your task dynamics.",
        "deleteScreenshotHelp&Support": "Maintain data control by deleting screenshots. Organize records and ensure a clear overview of captured activities. Know more about managing your screenshot history for accurate record-keeping.",
        "productivitySettingsHelp&Support": "Track actual productive hours of team members by defining productive and non-productive applications/URLs. Explore more about optimizing your productivity tools to align with your preferences."
      },
      "timesheet": {
        "modifyTime": "Allow the team members to modify their time manually.",
        "Yes": "Yes",
        "No": "No",
        "individualSetting": "INDIVIDUAL SETTING",
        "MEMBERS": "MEMBERS",
        "approvalTimesheet": "Allow team members to edit their approval timesheet settings",
        "toggle1": "Doesn't Require Approval",
        "toggle2": "Only Manual Timesheet",
        "toggle3": "Both Manual & Automatic Timesheet",
        "popupApproval": "This setting change will require page to be reloaded so that change can be effective system-wide for all member's timesheet data. Please press OK to continue?",
        "requireReason": "Require team members to provide a reason when editing their time.",
        "faceDetection": "Ask team members to take selfie & do face detection whenever Timer is started OR stopped",
        "save": "save",
        "facePer": "How much % is allowed ?",
        "tooltipMessage": "We'll match the Selfie image with Profile images of user & allow above % entered for matching",
        "manageLimits": "Allow Timesheet after Limit?",
        "togglemsg1": "Stop the timer & show alert",
        "togglemsg2": "Let the timer proceed normally",
        "weeklyTimesheet": "Allow Weekly Timesheet",
        "OK": "OK",
        "modifyTimeHelp&Support": "Promote flexibility by allowing team members to edit their time manually. Know more about the process of modifying time for comprehensive and precise reporting.",
        "approvalTimesheetHelp&Support": " Simplify workflow with Timesheet Approval by allowing team members to edit their approval timesheet settings. Know more about approving timesheets for better accountability and streamlined approval procedures.",
        "requireReasonhelp&Support": " Team members must submit a valid reason while editing their time entries. Learn more about adding reasons for regularization requests for accurate record-keeping.",
        "faceDetectionhelp&Support": "Add security to your attendance tracking to ensure reliable identity verification, streamline access control, and bolster operational integrity. Know more about the selfie verification system for fortifying the attendance management process.",
        "manageLimitsHelp&Support": "Tailor access controls, manage user thresholds, and enhance security protocols for identity verification. Learn more about customizing access to ensure secure and controlled operations.",
        "weeklyTimesheetsHelp&Support": "Download the weekly timesheet report for individuals or teams and send it to a selected member name. Learn more about managing and using online timesheets to optimize payroll processes."
      },
      "featureTabName": {
        "Timesheet": "Timesheet",
        "Schedules": "Schedules",
        "modifyTime": "Modify Time",
        "approvalTimesheet": "Approval Timesheet",
        "requireReason": "Require Reason",
        "faceDetection": "Face Detection",
        "manageLimits": "Manage Limits",
        "weeklyTimesheets": "Weekly Timesheets",
        "shiftAlerts": "Shift Alerts",
        "minimumShiftHours": "Minimum Shift Hours",
        "Activity": "Activity",
        "Timeoff": "Timeoff",
        "Jobsite": "Jobsite",
        "Achievement": "Achievement",
        "Notifications": "Notifications",
        "Features Settings": "Features Settings"
      },
      "sidebar": {
        "hideActivity": "Hide Activity Menu",
        "SsNotification": "Screenshot Notifications",
        "trackApps": "Track Apps & URLs",
        "blurSs": "Screenshots Blur",
        "SsFreq": "Screenshots Frequency",
        "delSs": "Delete Screenshots",
        "productivity": "Productivity Settings"
      },
      "schedule": {
        "receiveAlertNotification": "Control who receive alert about a member",
        "Management": "Management",
        "None": "None",
        "Both": "Both",
        "shiftHours": "Control minimum shift hours about a member.",
        "shiftAlertHelp&Support": " Stay punctual and informed with Shift Alerts. Receive timely notifications about upcoming shifts, ensuring smooth scheduling and improved time management. Know more about how to set schedule alerts to improve punctuality.",
        "shiftHourHelp&Support": "Define minimum shift hours to ensure optimal resource utilization and maintain a balanced workload for task allocation. Know more about setting minimum shift hours to streamline task distribution and enhance operational balance."
      },
      "timeOff": {
        "noMembers": "There are no members present at this moment!",
        "timeOffPolicies": "TIME OFF POLICIES",
        "accrualSchedule": "ACCRUAL SCHEDULE",
        "members": "MEMBERS",
        "policy": "POLICY",
        "View": "View",
        "Edit": "Edit",
        "Archive": "Archive",
        "Remove": "Remove",
        "Unarchive": "Unarchive",
        "addPolicy": "Add New Policy",
        "addPolicyMsg": "You can add the new policy of Time Off for complete organization OR for specific users within organization from here. View policy: View the details of individual policy here.",
        "editPolicyMsg": "You can edit the policy of Time Off for complete organization OR for specific users within organization from here.",
        "generalInfo": "General information",
        "back": "Back",
        "policyName": "Policy name*",
        "memberslabel": "Members",
        "automaticallyAddnewMem": "Automatically add new members to this policy",
        "scheduleOfAccrual": "Schedule of accrual*",
        "maxAccrualAmount": "Maximum accrual amount*",
        "amountAccured": "Amount accrued*",
        "allowNegBal": "Allow negative balance*",
        "reqApproval": "Requires approval*",
        "balanceRolls": "Balance rolls over annually*",
        "paidOrUnpaid": "Paid or unpaid*",
        "viewTimeOff": "View Time Off",
        "viewTimeOffMsg": "View the individual time off request details from here.",
        "basicInfo": "Basic information",
        "policyPaidUnpaid": "Policy paid/unpaid",
        "escalation": "ESCALATION",
        "escalationMsg": "Control escalation timeoff-request.",
        "idleNoteText": "Enable,Disable mark as mandatory and mark as non mandatory to the idle notes",
        "breakText": "Define Break or Enable/Disable Break for all members in organization",
        "breakName": "BREAK TIME",
        "addBreak": "Add Break",
        "status": "STATUS",
        "hours": "Hours",
        "accruedHours": "hour(s) accrued for every",
        "workedHours": "hours worked",
        "save": "Save",
        "ok": "Ok",
        "hoursText": "hours worked",
        "accuredHours": "hour(s) accrued for every",
        "timeOffPoliciesHelp&Support": "Create your leave policies which will help you ti manage the leaves of the employees and they would be able to take timeoff based on the policies you will create here",
        "escalationHelp&Support": " Define escalation paths and procedures to address challenges promptly and maintain smooth operations. Learn more about setting up escalation protocols to ensure timely resolution of issues and promote uninterrupted workflows.",
        "editpolicy": "Edit Policy"
      },
      "jobsite": {
        "jobsiteTabHead": "Restrict timer to job sites",
        "jobsiteTabText": "Only allow members to track their time while at a job site",
        "theadName": "NAME",
        "restrictTimerHelp&Support": "Restrict the employees to run their timer on the assigned jobsite only.",
        "enterExitNotificationHelp&Support": "Stay informed and in control with Enter/Exit Notifications. Receive real-time alerts when team members enter or exit specific locations. Find out how enter and exit notifications can keep you connected and informed."
      },
      "achievement": {
        "efficiency": {
          "achievementTabTitle": "EFFICIENCY PRO",
          "achievementTabText": "This achievement badge is given to members every day their activity meets the activity goal. They can win this badge multiple days in a row to create hot streaks",
          "achievementDefault": "DEFAULT",
          "achievementActivity": "Activity Goal",
          "achievementEfficiencyText": "Reach the goal for activity each day",
          "achievementButton": "Apply To All",
          "achievementTitle": "Efficiency Pro",
          "efficienyProHelp&Support": "Unlock advanced tools and insights to optimize your workflow, streamline tasks, and achieve peak productivity.  Learn more about making your teams pro to propel your work forward."
        },
        "productivity": {
          "productivityTabText": "This achievement badge is given to members every week they meet the weekly tasks goal. They can win this badge multiple weeks in a row to create hot streaks.",
          "productivityChamp": "Productivity Champ",
          "productivityChampCaps": "PRODUCTIVITY CHAMP",
          "week-to-dos": "Weekly tasks goals",
          "productivityText": "Reach the goal for completed tasks each week",
          "weekly-to-dos": "WEEKLY Tasks GOALS",
          "todos": "Tasks",
          "productivityHelp&Support": "Become a Productivity Champ with our feature-rich solution. Boost your output, manage tasks seamlessly, and attain your goals efficiently. Know more about how to become a productivity champ to empower your productivity journey."
        },
        "timeHero": {
          "timeHeroTitle": "TIME HERO",
          "timeHeroTabText": "This achievement badge is given to members every week their hours meet the weekly hours goal. They can win this badge multiple weeks in a row to create hot streaks.",
          "timeHeroText": "Reach the goal for total hours worked each week",
          "timeHeroActivityGoal": "Activity goal",
          "timeHeroActivityWeekly": "WEEKLY HOURS GOAL",
          "timeHero": "Time Hero",
          "timeHeroHelp&Support": "  Transform into a Time Hero with our innovative features. Take control of your time, conquer tasks, and excel in your endeavors with empowered productivity. Know more about achievement settings to become a time hero."
        },
        "WorkSummary": {
          "dailyWorkSummaryText": "Manage who will receive Daily work summary email notifications",
          "weeklyWorkSummaryText": "Manage who will receive Weekly work summary email notifications",
          "dailyWorkSummaryTitle": "Daily Work Summary",
          "weeklyWorkSummaryTitle": "Weekly Work Summary",
          "dailyWorkSummaryHelp&Support": " Receive concise overviews of your daily tasks, accomplishments, and milestones for effective planning and reflection. Learn more about daily work summary to shape your daily successes.",
          "weeklyWorkSummaryHelp&Support": " Receive concise overviews of your weekly tasks, accomplishments, and milestones for effective planning and reflection. Learn more about daily work summary to shape your daily successes."
        }
      }
    },
    "organisation": {
      "orgName": "Organisations",
      "requestOrgBtn": "Request Organization",
      "addOrgBtn": "Add New Organisation",
      "activeOrganisation": "ACTIVE ORGANISATIONS",
      "logo": "LOGO",
      "tableName": "NAME",
      "emailId": "EMAIL ID",
      "teamsize": "TEAM SIZE",
      "industry": "INDUSTRY",
      "managers": "MANAGERS",
      "editOrg": "Edit organisation",
      "archiveOrg": "Archive organisation",
      "urarchiveOrg": "Unarchive organisation",
      "addNewOrgTitle": "Add New Organisations",
      "addOrgText": "You can add new organization from here - this will give you flexibility to manage multiple organizations from single account.",
      "companydetails": "Company Details",
      "companyName": "Company Name*",
      "selectIndustry": "Select Industry*",
      "addTeamSize": "Select Team Size*",
      "goal": "Goal",
      "activetab": "Active",
      "archive": "Archive",
      "orgId": "ORG ID",
      "nodata": "No organisations data"
    }
  },
  "Getting-started": {
    "createOrgCheckOne": "Are you creating a new organization or are you joining an existing company account?"
  },
  "commonConst": {
    "Users": "Users",
    "Off": "Off",
    "On": "On",
    "none": "none",
    "timeZoneNote": "Note: The time shown is as per the timezone of selected user."
  },
  "Project": {
    "active-project": {
      "ACTIVE PROJECTS": "ACTIVE PROJECTS",
      "PROJECT NAME": "PROJECT NAME",
      "MANAGERS": "MANAGERS",
      "USERS": "USERS",
      "CLIENTS": "CLIENTS",
      "TYPE": "TYPE",
      "STATUS": "STATUS",
      "Edit Project": "Edit Project",
      "Archive Project": "Archive Project",
      "New Project": "New Project",
      "Active": "Active",
      "moveProject": "Move Project",
      "deleteProject": "Delete Project",
      "addNewProject": "Add New Project",
      "addText": "Here, you can create projects and assign them to right resources and start tracking.",
      "projectBasicInfo": "Basic Information",
      "Save": "Save",
      "Manager": "Manager",
      "moveOrgText": "Move Project To Organisation",
      "moveTodo": "Move all the Tasks associated with this project to selected organization",
      "orgLabel": "Select organisation for project",
      "cantMoveOrg": "you can't move projects as you are associated with only one organization.",
      "confirmArchive": "Are you sure you want to archive this project?",
      "archiveCondition": "Archiving the project will disable them, and projects & Tasks will be removed from all the platform apps. Do you still wish to continue?",
      "backupAndDelete": "Backup & Delete",
      "Delete": "Delete",
      "deleteCondition": "You won't be able to revert this!",
      "noActivePoject": "There are no projects active!",
      "yes": "Yes",
      "no": "No",
      "totalProject": "TOTAL PROJECTS",
      "yetToStart": "YET TO START",
      "inProgress": "IN PROGRESS",
      "onHold": "ON HOLD",
      "completed": "COMPLETED",
      "archived": "ARCHIVED",
      "cancelled": "CANCELLED",
      "projectName": "PROJECT NAME",
      "client": "CLIENTS",
      "status": "STATUS",
      "progress": "PROGRESS",
      "members": "MEMBERS",
      "viewProject": "View Project",
      "markAsCompleted": "Mark as Complete",
      "deleteMilestone": "Delete Milestone",
      "completedTodo": "Tasks Completed",
      "budgeted": "Budgeted",
      "invested": "Invested",
      "hrs": "Hrs",
      "overbudgeted": "Over Budget",
      "isBillable": "Is Billable",
      "goBackToProject": "Go Back To Projects Listing",
      "move": "Move",
      "chooseGroup": "Choose Task List",
      "setBudget": "Set Budget",
      "nameErr": "Special characters are not accepted.",
      "nameError": "Project name must not exceed limit of 50 characters",
      "projectManager": "Project Manager",
      "startDateErr": "Start date must be a lesser than End date.",
      "descErr": "Description must not exceed 400 characters",
      "addProjDecErr": "Description must not exceed 2000 characters",
      "hoursLimit": "You can enter hours up to 99999999",
      "numberLimit": "Number must be between 10 and 100.",
      "budget%": "% of budget is invested",
      "searchClient": "Search client",
      "projectStatus": "Project Status",
      "selectProjectMember": "Project Members",
      "addTodoGroup": "Add Tasks List",
      "addDesc": "Add Description",
      "addBudget": "Add Budget",
      "setBillRate": "Set Bill Rate"
    },
    "archive-project": {
      "noArchiveProject": "There are no projects archived!",
      "Unarchive Project": "Unarchive Project",
      "Projects": "Projects",
      "ProjectName": "Project Name",
      "Archive": "Archived"
    }
  },
  "dashboard": {
    "team": {
      "newTeam": "Add Team",
      "searchTeam": "Search team",
      "filters": "Filters",
      "project": "Project",
      "selectAProject": "Select a project",
      "apply": "Apply",
      "clear": "Clear",
      "teamName": "Team Name",
      "teamLead": "Team Lead",
      "teammates": "Teammates",
      "projects": "PROJECTS",
      "noTeams": "There are no teams!",
      "editTeamText": "Edit team",
      "removeTeam": "Remove team",
      "newTeam1": "New Team",
      "addTeam": {
        "addNewTeams": "Add New Teams",
        "createTeamDeps": "Create different teams/departments to streamline your workflow.",
        "teamName": "Team name",
        "teammates": "Teammates",
        "teamlead": "Team Lead",
        "selectAll": "select all",
        "none": "none"
      },
      "editTeam": {
        "editTeam": "Edit Team",
        "editTeamDeps": "Edit different teams/departments to streamline your workflow.",
        "generalInfo": "General information",
        "teammatesDet": "Teammate details",
        "teamLeadDet": "Team lead details",
        "projectDet": "Projects details",
        "details": "Details",
        "createdBy": "Created by",
        "permissions": "Permissions:",
        "approveTimesheet": "Approve timesheets",
        "approveTimeoffReq": "Approve time off requests",
        "scheduleShift": "Schedule shifts",
        "continue": "Continue",
        "cancel": "Cancel",
        "save": "Save"
      }
    }
  },
  "todo": {
    "reportedBy": "Created/Reported By",
    "Export": "Export",
    "Filter": "Filter",
    "TO-DOs": "Tasks",
    "todo": "Task",
    "Add To-Dos": "Add Taks",
    "noTodos": "There are no Tasks!",
    "TODO DETAILS": "Task DETAILS",
    "TodoGroupStatus": "Tasks List Status",
    "MEMBERS": "MEMBERS",
    "START DATE": "START DATE",
    "END DATE": "END DATE",
    "PRIORITY": "PRIORITY",
    "ATTACHMENTS": "ATTACHMENTS",
    "TYPE": "TYPE",
    "STATUS": "STATUS",
    "PROGRESS": "PROGRESS",
    "Low": "Low",
    "Medium": "Medium",
    "High": "High",
    "View ToDo": "View Task",
    "Edit ToDo": "Edit Task",
    "Complete ToDo": "Complete Task",
    "Delete ToDo": "Delete Task",
    "noTodo": "There are no Tasks!",
    "selectAProj": "Select a project",
    "or": "OR",
    "searchProjs": "Search Projects",
    "reopen": "Reopen Task",
    "addTodoGroup": "Add Tasks List",
    "showMore": "Show More",
    "showLess": "Show Less",
    "moveToOtherGroup": "Move To Other Task List",
    "moveErr": "you can't move Tasks as you have only one Task List.",
    "moveOtherGroup": "Move Task to other Task List",
    "todoLowercase": "Task",
    "addTodo": {
      "newTodo": "Add New Task",
      "addTodoText": "Here, you can create & assign tasks to your resources and start tracking their progress.",
      "BI": "Basic Information",
      "OD": "Other details",
      "membName*": "Member Name*",
      "Cancel": "Cancel",
      "Continue": "Continue",
      "startDate": "Start Date",
      "End Date": "End Date",
      "inputFileText": "Drag & drop file here",
      "browseFile": "Browse Files",
      "Recurrence": "Recurrence",
      "recurrenceFrequency": "Recurrence Frequency",
      "Billable": "Billable",
      "Save": "Save",
      "todoResult": "Tasks results",
      "addTodo": "Add Task",
      "addTodoSideNavText": "Here, you can create & assign tasks to your resources and start tracking their progress.",
      "backToGroups": "Back to Tasks",
      "groupText": "The name may not be greater than 255 characters.",
      "saveTodo": "Save Task",
      "additionalInfo": "Additional Information",
      "endateErr": "End date must be greater than Start date.",
      "estimatedHours": "Estimated Hours",
      "rangeError": "Please enter the value between 00:00 and 99999:59",
      "progress": "Progress",
      "percRangeError": "Please enter the value between 0 to 100",
      "addTodoGroup": "Tasks List*",
      "Expand": "Expand",
      "Collapse": "Collapse",
      "estimated": "Estimated",
      "rangeTImeErr": "Please enter the value greater than 00.01",
      "save&Add": "Save & add more",
      "todoGroupText": "Tasks List"
    },
    "editTodo": {
      "edit": "Edit Tasks",
      "editText": "Here, you can modify tasks, reassign resources, and monitor progress.",
      "BI": "Basic information",
      "OD": "Other details",
      "addMore": "Add more",
      "recurrenceText": "Create next recurrence after closing the current task.",
      "nameErr": "The name may not be greater than 255 characters.",
      "attachFile": "Attach File",
      "investedHours": "Invested Hours",
      "lastEdited": "Last Edited",
      "createdBy": "Created by & Time",
      "parentTodoLabel": "Parent Task Name",
      "todoName": "Task Name*",
      "selectMembers": "Select Members*",
      "Asignee": "Assignee*",
      "Asignees": "Assignees*",
      "ASSIGNEES": "ASSIGNEE(S)"
    },
    "viewTodo": {
      "view": "View TASK",
      "viewText": "View the details of individual Task here & edit the details quickly (if needed).",
      "details": "Task Details",
      "projectName": "Project Name*",
      "name": "Task Name",
      "memberName": "Member Name",
      "startDate": "Start Date",
      "endDate": "End Date",
      "Priority": "Priority",
      "Low": "Low",
      "Medium": "Medium",
      "High": "High",
      "Status": "Status",
      "Progress": "Progress",
      "Description": "Description",
      "noDescription": "No Description available",
      "Attachments": "Attachments",
      "No Attachment": "No Attachment",
      "Download All": "Download All",
      "frequency": "Recurring frequency*",
      "Daily": "Daily",
      "Weekly": "Weekly",
      "Monthly": "Monthly",
      "Yearly": "Yearly",
      "Once every": "Once every*",
      "End after": "End after*",
      "Edit To-do": "Edit Task",
      "budgetoverview": "BUDGET OVERVIEW",
      "TodoStatus": "TASK STATUS",
      "AllToDoGroups": "All Tasks List",
      "todos-worked": "TASKS WORKED",
      "view-all": "View All Tasks",
      "projectID": "Project ID",
      "client": "Client",
      "members": "Members",
      "createdBy": "Created By",
      "createdTime": "Created time",
      "totalTodo": "TOTAL TASKS",
      "due": "Due",
      "overdue": "Overdue",
      "recurring": "Recurring",
      "completed": "Completed (%)",
      "todoType": "Task Type",
      "selectTodo": "Select Task Type",
      "severity": "Severity",
      "tags": "Tags",
      "nodata": "No Data Found!",
      "loadMore": "Load More",
      "todoType1": "Task Type",
      "tag": "Tag",
      "selectPrioroty": "Select Priority",
      "selectoverdue": "Select overdue",
      "selectDue": "Select due",
      "selectStatus": "Select Status",
      "selectSeverity": "Select severity",
      "selectTags": "Select tags",
      "searchProject": "Search Project",
      "selectProject": "Select Project",
      "addTags": "Add tags",
      "attachment": "Attachment",
      "overview": "Overview",
      "typeHere": "Type here",
      "noDailyLimit": "No daily limit",
      "noWeekLimit": "No week limit"
    }
  },
  "addSingleLineTodo": {
    "placeholder": "Enter Task Name",
    "pressEnterText": "Press Enter To Save",
    "assigneeTooltip": "Assignee(s)",
    "startEndDateTooltip": "Start/End Date",
    "priorityTooltip": "SetPriority",
    "typeTooltip": "To-do Type",
    "taskListTooltip": "Task List",
    "enstimatedHoursPlaceholder": "HH:MM",
    "estimatedHoursTooltip": "Estimated hours",
    "estimatedHoursError": "Estimated hours between 00:00 and 99999:59"
  },
  "workOrder": {
    "Work Orders": "Work Orders",
    "+ Add a Work Order": "+ Add a Work Order",
    "weekly": "weekly",
    "Date Range": "Date Range",
    "noWorkOrders": "There are no Work Orders",
    "WORKORDER": "WORKORDER",
    "ASSIGNEES": "ASSIGNEES",
    "ADDRESS": "ADDRESS",
    "BUDGET": "BUDGET",
    "NEXT JOB": "NEXT JOB",
    "STATUS": "STATUS",
    "UNSCHEDULED": "UNSCHEDULED",
    "MISSED": "MISSED",
    "noUpcomingJob": "No upcoming Jobs",
    "ACTIVE": "ACTIVE",
    "CLOSED": "CLOSED",
    "editOrder": "Edit work order",
    "viewOrder": "View work order",
    "closeOrder": "Mark as closed",
    "Work Order Detail": "Work Order Detail",
    "Edit": "Edit",
    "MarkasClosed": "Mark as Closed",
    "CLIENT DETAILS": "CLIENT DETAILS",
    "Name": "Name",
    "Mobile": "Mobile",
    "Email": "Email",
    "ClientAddress:": "Client Address:",
    "UPCOMING JOBS": "UPCOMING JOBS",
    "Date": "Date",
    "Time": "Time",
    "schedulejob": "schedule a job",
    "Never": "Never",
    "Monthly": "Monthly",
    "Instructions": "Instructions",
    "JOBS": "JOBS",
    "+ Add Job": "+ Add Job",
    "JOB ID": "JOB ID",
    "DATE": "DATE",
    "View Job": "View Job",
    "Delete Job": "Delete Job",
    "noWorkOrder": "There are no Work Orders",
    "wishToContinue": "Do you still wish to continue?",
    "Yes": "Yes",
    "No": "No",
    "Workorder": "Work order",
    "Client Name": "Client Name",
    "Status": "Status",
    "Scheduled": "Scheduled",
    "Address": "Address",
    "Assignees": "Assignees",
    "Details": "Details",
    "Time Worked": "Time Worked",
    "Time Started": "Time Started",
    "Time Stoped": "Time Stopped",
    "MarkasRead": "Mark as Read",
    "createWO": "Create Work Order",
    "jobsiteInfo": "Jobsite Information",
    "budgetDetails": "Budget Detail",
    "ON ARRIVAL": "ON ARRIVAL",
    "ON DEPARTURE": "ON DEPARTURE",
    "includeNonBillable": "Include non billable time",
    "addWO": "Add New Work Order",
    "Radius": "Radius",
    "meters": "meters",
    "Next": "Next",
    "scheduleWork": "Schedule the work",
    "readyToSchedule": "Are you ready to schedule and assign it?",
    "NotNow": "Not Now",
    "Schedule": "Schedule It"
  },
  "timeoff": {
    "timeoffTitle": "Time Off",
    "timeoffPolicyName": "POLICY NAME",
    "timeoffStart": "Start",
    "timeoffEnd": "End",
    "timeoffHours": "Hours",
    "timeoffRequestedOn": "Requested On",
    "timeoffUserReason": "User Reason",
    "timeoffStatus": "Status",
    "timeoffViewReaquest": "View request",
    "timeoffedit": "Edit request",
    "timeoffDenyRequest": "Deny request",
    "timeoffApproveRequest": "Approve request",
    "timeoffDeleteRequest": "Delete request",
    "timeoffescalate": "Escalate request",
    "timeoffUpload": "Upload",
    "timeoffSettings": "Settings",
    "timeoffSubmitted": "Submitted",
    "timeoffApproved": "Approved",
    "timeoffDenied": "Denied",
    "timeoffRequestButton": "Request Time Off",
    "timeoffNoData": "There is no Time-off requests made!",
    "timeoffRequestText": "Your employees can request time off; you can view, accept or reject the time off request from our unified platform.",
    "timeOffBasicText": "Basic Information",
    "timeoffCurrentBalance": "Current Balance",
    "timeoffTotalTime": "Total time Requested",
    "timeoffRemainingBalance": "Remaining Balance",
    "timeoffMember": "Member",
    "timeoffPolicy": "Policy*",
    "timeoffSpan": "Time span*",
    "timeoffAllDay": "All Day",
    "timeoffRequestedTime": "Requested time per day*",
    "timeoffReason": "Reason*",
    "timeoffSave": "Save",
    "timeoffBackButton": "Back",
    "requestTimeoff": "Request Timeoff",
    "chooseFile": "Choose file",
    "noFile": "No file chosen",
    "fileFormat": ">Accepted file formats: .csv, .xls, .xlsx",
    "downloadSpreadsheet": "Download spreadsheet template",
    "Import": "Import",
    "Cancel": "Cancel",
    "submitted": "submitted",
    "provideReason": "Please Provide Reason!",
    "Submit": "Submit",
    "text1": "Selected time policy does not allow negative balance",
    "text2": "Selected policy does not allow negative balances less than 100",
    "hoursValidation": " Please enter requested hours less than",
    "hours": "hours",
    "Approved": "Approved",
    "noResult": "No result found",
    "Upgrade": "Upgrade",
    "File": "File",
    "Denied": "Denied"
  },
  "activityModule": {
    "screenshotsSection": {
      "screenshotActivity": "Screenshot Activity",
      "filters": "Filters",
      "export": "Export",
      "dailtTotal": "Daily Total:",
      "viewTimesheet": "View Timesheet",
      "viewApp": "View Apps",
      "viewUrl": "View URL",
      "totalTimeWorked": "TOTAL TIME WORKED:",
      "noActivity": "No Activity",
      "today": "Today",
      "noScreenshots": "Screenshots are not available!",
      "deleteSelectedScreenshot": "Are you sure you want to delete selected screenshot?",
      "deleteAllSelectedScreenshots": "Are you sure you want to delete all selected screenshot?",
      "filterLabelProject": "Project",
      "filterLabelTime": "Time",
      "filterLabelActivityLevel": "Activity level",
      "apply": "Apply",
      "clear": "Clear",
      "manualActivity": "Manual Activity",
      "ViewImage": "View Image",
      "viewAttendance": "View Attendance"
    },
    "appSection": {
      "apps": "Apps",
      "noApps": "You are not using any apps currently!",
      "appWebsite": "APP/WEBSITE",
      "website": "WEBSITE",
      "project": "PROJECT",
      "timeSpent": "TIME SPENT",
      "session": "SESSION",
      "category": "CATEGORY",
      "viewScreenshot": "View Screenshot"
    },
    "urlSection": {
      "url": "URL",
      "noUrls": "You are not using any URLs currently!"
    }
  },
  "jobsite": {
    "Job Sites": "Job Sites",
    "addjobsite": "Add a Jobsite",
    "siteMembers": "Members On Site Now",
    "totalVisits": "Total visits today",
    "deleteJobsiteCheck": "Are you sure you want to delete the jobsite?",
    "ON ARRIVAL": "ON ARRIVAL",
    "Do not start timer": "Don't start timer",
    "ON DEPARTURE": "ON DEPARTURE",
    "Do not stop timer": "Don't stop timer",
    "Add New User": "Add New User",
    "Track Time to project": "Track Time to project",
    "Automatic Timer Actions": "Automatic Timer Actions",
    "Radius": "Radius",
    "Configure Settings": "Configure Settings",
    "Add Job Location": "Add Job Location",
    "jobsiteText": "Define geofences around the dedicated job sites, and start tracking as employees enter or exit the job sites. Also, track the total time spent by each employee at a particular job site.",
    "addJobsite": "Add New Job Site",
    "EditJobSite": "Edit JobSite",
    "searchLocation": "Search location to add a jobsite",
    "searchJobSites": "Search job sites",
    "sortBy": "Sort By",
    "cancel": "Cancel",
    "continue": "Continue",
    "Loading": "Loading",
    "membOnSite": "Members On Site Now",
    "totalVisit": "Total visits today",
    "report": "View jobsite Reports",
    "Address": "Address*",
    "Cancel": "Cancel",
    "Continue": "Continue",
    "Save": "Save",
    "Group": "Group",
    "searchLocationPlaceholder": "Search location"
  },
  "profile": {
    "profile": "Profile",
    "editProfile": "Edit Your Profile",
    "fullName": "Full Name",
    "countryCode": "Country Code",
    "phoneNumber": "Phone No.",
    "email": "E-mail",
    "Password": "Password",
    "timezone": "Timezone",
    "emailAlert": "Email Verification Pending for",
    "timezoneText": "If you don't find exact timezone in your system, please select nearest city with same GMT code.",
    "weeklyOffset": "Weekly Off",
    "cancel": "Cancel",
    "save": "Save",
    "deactivate": "Deactivate account",
    "delete": "Delete account",
    "changeEmail": "CHANGE EMAIL",
    "currentemail": "Enter current password *",
    "enterNew": "Enter new email",
    "contfirmMail": "Confirm new email",
    "detect": "Detect",
    "changePassword": "Change Password",
    "newPassword": "New password *",
    "confirmPassword": "Confirm password *",
    "currentPassword": "Current Password *",
    "changePicture": "CHANGE PICTURE",
    "addPicture": "Add Picture",
    "addPictureText": "Please ensure that while uploading your profile photo(s) - your face is clearly visible. It is important because your profile photos will be used in Face Recognition if you start time on your Mobile Phone.",
    "bottomImg": "Paste and image, drag and drop files or",
    "clickHere": "click here",
    "toUpload": "to upload",
    "deactivateTxt": "Deactivate",
    "IdLabel": "Enter ID Name",
    "changePwd": "Change Password",
    "deleteText": "Delete Account - After deleting your account you will no longer be allowed to log in to your account and also you would not be able to run your desktop application.",
    "accountErr": "Account number is not valid. It should be 5 to 17 digits long",
    "accountValidation": "It should be 5 to 17 characters long and alphanumeric",
    "uploadTooltip": "Supported file types are .pdf, .jpeg, .png, .jpg, .tiff, .eps Max file size supported is 1 MB",
    "enableSetting": "Please enable the edit option",
    "viewPhoto": "View all photo",
    "selectTodo": "Select Task",
    "invalidFileType": "Invalid file type"
  },
  "people": {
    "bulInvite": {
      "noData": "No data found",
      "dateTime": "DATE & TIME",
      "sheetName": "SHEET NAME",
      "INVITESUMMARY": "INVITE SUMMARY",
      "viewDetails": "View Details",
      "Member Details": "Member Details",
      "Status": "Status",
      "Reason": "Reason",
      "SUCCESS": "SUCCESS",
      "FAILED": "FAILED",
      "MEMBER": "MEMBER",
      "STATUS": "STATUS",
      "REASON": "REASON"
    }
  },
  "schedule": {
    "Schedules": "Schedules",
    "Add Schedule": "Add Schedule",
    "MEMBERS": "MEMBERS",
    "addScheduleText": "Now you can easily create, assign & track employee shifts. Fix the shift discrepancies like employees arriving late for shifts or abandoning shifts.",
    "basicInfo": "Basic information",
    "editSchedule": "Edit Schedule",
    "editScheduleText": "Now you can easily create, assign & track employee shifts. Fix the shift discrepancies like employees arriving late for shifts or abandoning shifts.",
    "deleteAll": "Yes,delete all!",
    "deleteOne": "No, delete only one!",
    "areYouSure": "Are you sure?",
    "deleteSchedule": "Do you want to delete all schedules?",
    "Upgrade": "Upgrade",
    "noSchedule": "No shift schedule for this date!",
    "Weekly": "Weekly",
    "Today": "Today",
    "noResult": "No result found",
    "allMembers": "All Members",
    "allTeams": "All Teams",
    "Teams": "Teams"
  },
  "client": {
    "editClient": {
      "editclient": "Edit Client",
      "editText": "Edit clients and invite them to view and track projects/tasks to bring more transparency to your process.",
      "GI": "General information",
      "CI": "Contact Information",
      "PD": "Project details",
      "BD": "Budget details",
      "Cancel": "Cancel",
      "Continue": "Continue",
      "billableTime": "Include non billable time",
      "Save": "Save",
      "startDate": "Start date",
      "address": "Client's Address",
      "email": "Client email",
      "code": "Country code",
      "phone": "Phone No.",
      "Project": "Project",
      "Type": "Type",
      "Based": "Based on",
      "cost": "Cost",
      "Hours": "Hours",
      "Notify": "Notify at",
      "Resets": "Resets",
      "budgetMode": "Budget Mode",
      "budgetType": "Project Type",
      "projectPerHr": "Project Per Hour Rate"
    },
    "addClient": {
      "addNewClient": "Add New Client",
      "addClientText": "Add clients and invite them to view and track projects/tasks to bring more transparency to your process.",
      "editClient": "Edit Client"
    },
    "activeClient": {
      "activeclient": "ACTIVE CLIENTS",
      "clientDetail": "Client Details",
      "PHONE": "Phone",
      "budget": "CLIENT BUDGET",
      "PROJECTS": "Projects",
      "Total": "Total",
      "Archive client": "Archive client",
      "InvitationStatus": "Invitation Status"
    },
    "archiveClients": {
      "ARCHIVEDCLIENTS": "ARCHIVED CLIENTS",
      "NAME": "NAME",
      "EditClient": "Edit client",
      "restoreClient": "Restore client"
    }
  },
  "timesheetModule": {
    "viewTimesheetSection": {
      "timeType": "Time Type",
      "breakType": "Break Type",
      "viewTimesheet": "View Timesheet",
      "noData": "No data available!",
      "addTime": "Add Time",
      "addTimeLog": "Add Time Log",
      "addWeeklyTimeLog": "Add Weekly Time Log",
      "today": "Today",
      "weekly": "Weekly",
      "monthly": "Monthly",
      "dateRange": "Date Range",
      "note": "The time shown is as per the timezone of selected user.",
      "filters": "Filters",
      "exports": "Exports",
      "switchView": "Switch View",
      "projectDetails": "Project Details",
      "activityTime": "Activity Time",
      "idleTime": "Idle Time",
      "manualTime": "Manual Time",
      "breakTime": "Break Time",
      "effectiveTime": "Effective Time",
      "duration": "Duration",
      "nodataDate": "No data for this date!",
      "time": "Time",
      "allTimetype": "All time type",
      "normal": "Normal",
      "idle": "Idle",
      "manual": "Manual",
      "activityLevel": "Activity level",
      "allActivityLevel": "All activity level",
      "apply": "Apply",
      "clear": "Clear",
      "viewTimesheetOption": "View timesheet",
      "deleteTimesheetOption": "Delete timesheet",
      "viewScreenshot": "View Screenshot",
      "viewApps": "View Apps",
      "viewUrl": "View URL",
      "weeklyTimeline": "Weekly Timeline",
      "date": "Date",
      "timeTracked": "Time tracked",
      "startTime": "Start time",
      "endTime": "End time",
      "effectiveTime": "Effective Time",
      "activityPer": "Activity %",
      "activity": "Activity",
      "timeline": "Timeline",
      "addTimesheet": "Add Timesheet",
      "addTimeDesc": "You can enter manual timesheet from here for any specific date & time.",
      "genInfo": "General Information",
      "memNameLabel": "Member Name",
      "projNameLabel": "Project Name",
      "todoNameLabel": "Task Name",
      "billable": "Billable",
      "reason": "Reason",
      "noteSwitch": "Note",
      "save": "Save",
      "advanceFilter": "Advanced filter",
      "downloadTimesheet": "Download timesheets",
      "PDF": "PDF",
      "CSV": "CSV",
      "PRODUCTIVITY": "Productivity",
      "TIME": "TIME",
      "workingHours": "Working hrs",
      "totalHours": "Total hrs",
      "editTimesheet": "Edit timesheet",
      "deleteTimesheet": "Delete timesheet",
      "screenTime": "Screen Time",
      "mobileTime": "Mobile Time",
      "canDeletetimesheet": "Delete this timesheet?",
      "text1": "Deleting timesheet will delete all Activity data including Screenshot, Apps and URLs",
      "Automatic": "Automatic",
      "Manual": "Manual",
      "Both": "Both",
      "whyareyouadding": "Why are you adding?",
      "workingOn": "What were you working on?"
    },
    "approveTimesheetSection": {
      "timesheetApproval": "Timesheet Approval",
      "manualTimesheet": "Manual Timesheet",
      "automaticTimesheet": "Automatic Timesheet",
      "timesheetType": "Timesheet type",
      "memberName": "MEMBER NAME",
      "date": "Date",
      "startTime": "START TIME",
      "endTime": "END TIME",
      "duration": "DURATION",
      "activity": "ACTIVITY",
      "status": "Status",
      "reason": "REASON",
      "viewreq": "View request",
      "approve": "Approve",
      "deny": "Deny",
      "provideReason": "Please Provide Reason!",
      "typeReason": "Type your reason here",
      "submit": "Submit",
      "viewTimesheet": "View Timesheet",
      "viewTimeDesc": "You can view timesheet details from here along with Reason & Notes submitted (if any).",
      "genInfo": "General Information",
      "memName": "Member Name",
      "datey": "Date",
      "startTimey": "Start Time",
      "endTimey": "End Time",
      "durationy": "Duration",
      "activityy": "Activity",
      "statusy": "Status",
      "approvedBy": "Approved By",
      "doApproval": "Date of approval",
      "toApproval": "Time of approval",
      "reasonForTimesheet": "Reason for timesheet",
      "reasonForApproval": "Reason for approval",
      "Back": "Back",
      "deniedBy": "Denied By",
      "deniedDate": "Date of denied",
      "deniedTime": "Time of denied",
      "denialReason": "Reason for Denial",
      "Notes": "Notes",
      "projectName": "Project Name",
      "todoName": "Task Name",
      "Idle": "Idle",
      "Manual": "Manual",
      "timeSpan": "Time span (IST)",
      "Reason": "Reason",
      "editTimesheet": "Edit Timesheet",
      "timeValidation": "Please enter time in AM/PM Format",
      "startEndTimeValidation": "End time should be greater than start time",
      "textone": "You can enter manual timesheet from here for any specific date & time.",
      "timeFormat": "Please enter time in AM/PM Format",
      "Billable": "Billable",
      "type": "Type",
      "dataUnavailable": "Data is not available!",
      "timesheetUpdated": "Timesheet has been updated successfully."
    },
    "attendanceSection": {
      "attendance": "Attendance",
      "attendanceEntry": "Attendance entry",
      "shiftEntry": "Shift entry",
      "last30days": "Last 30 days",
      "checkIn": "Check In",
      "checkOut": "Check Out",
      "attendanceVisual": "Attendance Visual",
      "totalHrs": "Total Hrs",
      "effectiveHrs": "EFFECTIVE HRS",
      "arrivals": "Arrivals",
      "log": "Log",
      "firstPunchIn": "First Punch In(s) & Last Punch Out(s)",
      "allPunchIn": "All Punch In(s) & Punch Out(s)",
      "advanceFilter": "Advanced filters",
      "Download": "Download",
      "exportPDF": "Export as PDF",
      "exportCSV": "Export as CSV",
      "weeklyOff": "Full day Weekly-off",
      "Missed": "Missed",
      "Abandoned": "Abandoned",
      "Incompleted": "Incompleted",
      "dataNotAvailable": "Data for this date range is not available!",
      "Delayed": "Delayed",
      "onTime": "On time",
      "All": "All",
      "pendingShift": "Pending shift",
      "completedShift": "Completed shift",
      "mapView": "Map View",
      "keyboard": "Keyboard",
      "mouse": "Mouse",
      "attendanceStats": "Attendance Stats",
      "totaldays": "Total days",
      "workingDays": "Total working days",
      "daysPresent": "Days present",
      "daysAbsent": "Days absent",
      "workingHrs": "Avg working hrs",
      "breakHrs": "Avg break hrs",
      "attendancePercentage": "Attendance",
      "totalLeaves": "Total Leaves",
      "paid": "Paid",
      "unpaid": "Unpaid"
    },
    "WeeklyTimeLog": {
      "weeklyTimesheet": "Weekly Timesheet",
      "Weekly": "Weekly",
      "addRow": "ADD ROW",
      "USER": "USER",
      "PROJECT": "PROJECT",
      "TOTAL": "TOTAL",
      "markAsBillable": "Mark as Billable",
      "addTime": "Add Time",
      "timeSpan": "Time span (IST)"
    }
  },
  "members": {
    "invitationTomember": "send invitation to member",
    "bulkInvite": "Bulk Invite",
    "inviteNewMem": "Invite New Members",
    "total": "TOTAL",
    "working": "WORKING",
    "online": "ONLINE",
    "offline": "OFFLINE",
    "deleted": "DELETED",
    "deactivated": "DEACTIVATED",
    "userType": "User Type",
    "selectUserType": "select user type",
    "member": "MEMBER",
    "role": "Role",
    "projects": "Projects",
    "status": "STATUS",
    "payment": "PAYMENT",
    "priority": "Daily Limit/Weekly Limit",
    "editLimit": "Edit Member",
    "disableTracking": "Disable tracking",
    "removeMember": "Remove member",
    "enableTracking": "Enable tracking",
    "searchMember": "Search member",
    "importBulk": "Import Bulk Members",
    "importBulkDesc": "You can upload the xls file with member details below in order to start the bulk invite process.",
    "clickHere": "click here",
    "to": "to",
    "downloadSample": "download sample file and fill members details.",
    "importFiles": "Import file filled with members details",
    "toUpload": "to upload",
    "proceed": "Proceed",
    "inviteANewMem": "Invite A New Member",
    "inviteANewMemDesc": "Easily invite your employees and start tracking their progress; it is that simple!",
    "basicInfo": "Basic information",
    "memEmailId": "Member email id*",
    "selectRole": "Select a role for this member*",
    "visibleMode": "Visible Mode",
    "stealthMode": "Stealth Mode",
    "selectProj": "Select projects for this member",
    "memEmail": "Member Email",
    "editInvite": "Edit invite",
    "cancelInvite": "Cancel invite",
    "resendinvite": "Resend invite",
    "editLimitDesc": "You can define the individual member's Daily & Weekly time limit from here along with hourly rate pay.",
    "memberEditlimit": "Member*",
    "weeklyLimit": "Weekly limit",
    "dailyLimit": "Daily limit",
    "hourlyPayRate": "Hourly Pay Rate",
    "useShift": "Use shift to set daily limits",
    "selectDays": "Select Days",
    "editMemberInvite": "Edit Member Invitation",
    "editMemberInviteDesc": "Easily invite your employees and start tracking their progress; it is that simple!",
    "members": "MEMBERS",
    "membersTab": "Members",
    "invitedTab": "Invited",
    "requestedTab": "Requested",
    "bulkInviteStatusTab": "Bulk Invite Status",
    "platform": "Platform",
    "noMemReq": "There are no members requested!",
    "nomembInvited": "There are no members invited!",
    "selectProjectInvite": "Select projects for this client",
    "clientFlag": "Invite client to access the application",
    "inviteClient": "Client email id*",
    "Member": "Member",
    "inviteCancel": "Are you sure you want to cancel the invitation shared?",
    "statusField": "Status"
  },
  "subscription": {
    "overviewplan": "Overview Plan",
    "billingHistory": "Billing History",
    "planOverview": {
      "currentPlan": "CURRENT PLAN",
      "expdate": "EXPIRY DATE",
      "basePrice": "BASE PRICE",
      "changePlan": "Change Plan",
      "planFeature": "PLAN FEATURES"
    },
    "BillingRate": {
      "paymentHistory": "YOUR PAYMENT HISTORY",
      "exportPDF": "Export as PDF",
      "exportCSV": "Export as CSV",
      "INVOICE": "INVOICE",
      "PLAN": "PLAN",
      "AMOUNT": "AMOUNT",
      "DATE": "DATE",
      "CARD NUMBER": "CARD NUMBER",
      "DESCRIPTION": "DESCRIPTION",
      "STATUS": "STATUS",
      "Email": "Email",
      "Download": "Download",
      "noHistory": "No billing history",
      "invoice": "Email Invoice",
      "Subject": "Subject",
      "Message": "Message",
      "Save": "Save"
    },
    "remaininingText": "Invoices is attached. Please download the attachment. Thank you."
  },
  "integration": {
    "listing": {
      "Integration": "Integrations",
      "Add Integration": "Add Integration",
      "NAME": "NAME",
      "LAST SYNC": "LAST SYNC",
      "STATUS": "STATUS",
      "Disabled": "Disabled",
      "Enabled": "Enabled",
      "Remove": "Remove",
      "chooseIntegration": "Choose an integration",
      "Integrations": "Integrations",
      "integrationText1": "Connect with Your Favorite Apps: Seamless Integration Made Easy",
      "integrationText2": "Workstatus provide one-click integration with your go-to apps. Save time and streamline your operations with Workstatus Integrations.",
      "projectmanagement": "Project management",
      "Zoho": "Zoho",
      "Trello": "Trello",
      "Clickup": "Clickup",
      "Teamwork": "Teamwork",
      "Private Cloud": "Private Cloud",
      "AWS": "AWS",
      "Mail Server": "Mail Server",
      "SMTP": "SMTP"
    },
    "aws": {
      "AWS Integration": "AWS Integration",
      "awsText1": "AWS integration helps to get your data on the AWS S3 Bucket. You can integrate your bucket to store the screenshots, profile pictures, task attachments, and reports on your S3 storage. This ensures the privacy of the data.",
      "label": {
        "accesskey": "AWS ACCESS KEY ID",
        "secretkey": "AWS SECRET ACCESS KEY",
        "region": "AWS REGION",
        "bucket": "AWS BUCKET NAME"
      },
      "setupText1": "How to setup AWS?",
      "setupText2": "AWS Access Key ID:",
      "setupText3": "Your AWS Key",
      "secretKeyAccess": "AWS Secret Access Key:",
      "inputSecretKey": "Please input your secret key.",
      "AWS Region:": "AWS Region:",
      "yourRegion": "Your AWS region",
      "bucketName": "AWS Bucket Name:",
      "yourBucketname": "Your AWS bucket name",
      "setupText4": "Test connection is to check the status of synchronization of your AWS account with the workstatus. If the details are all correct and your connection established successfully, then it will be shown as Enabled in the integration listing."
    },
    "smtp": {
      "SMTP Integration": "SMTP Integration",
      "smtpText1": "SMTP Integration will help you set-up your own email server with Workstatus. Send all the mail notifications through your mail server to all the users across your organization.",
      "label": {
        "MailServer": "Mail Server",
        "MailDriver": "Mail Driver",
        "SMTPMethod": "SMTP Secure Method",
        "Username": "Username",
        "Port": "Port",
        "Name": "Name",
        "Email": "Email",
        "Password": "Password"
      },
      "smtpText2": "How to setup SMTP for different MAIL SERVER?",
      "Gmail": "Gmail",
      "Hotmail": "Hotmail",
      "Yahoo": "Yahoo",
      "OtherServer": "Other Server",
      "Mailserver:": "Mail server:",
      "Yourmailserver": "Your SMTP mail server",
      "TLS/SSL": "TLS/SSL",
      "smtpText3": "587 (TLS)/465(SSL), It may be different for this please approach to your Server provider team.",
      "Username/ Email:": "Username/ Email:",
      "smtpText4": "Please input your server username/email id.",
      "Yourpassword": "Your password",
      "equires authentication:": "enquires authentication:",
      "Yes": "Yes",
      "Note:": "Note:",
      "smtpText5": "In order to check your SMTP connection,input your SMTP details and click on the TEST CONNECTION button. Once connection is successful save the settings and send your Invoice using your mail server.",
      "smtpText6": "If you still face the error, Review your setting and attempt again.",
      "smtpText7": "For further queries, feel free to contact us via email Support or using the Live chat (10.00 AM to 10.00 PM IST).",
      "smtpText8": "Test connection is to check the status of synchronization of your SMTP account with the workstatus. If the details are all correct and your connection established successfully, then it will be shown as Enabled in the integration listing.",
      "smtpText9": "Test Connection Related Text",
      "Submit": "Submit",
      "securemethod": "SMTP Secure Method:"
    },
    "commonVariable": {
      "Save": "Save",
      "testconnection": "Test connection",
      "Reset": "Reset"
    },
    "reauthenticate": "Re Authenticate",
    "integrationKey": "AWS key for integration",
    "secretKey": " Secret key for integration",
    "awsKey": "AWS region for integration",
    "bucketKey": "Bucket name for integration",
    "alertMessage": "Are you sure you want to update all the setting will be removed",
    "serverDetails": "Enter your mail server details",
    "driveDetail": "Enter your mail drive details",
    "secretSmtp": " Enter your secure SMTP method",
    "mailPort": "Enter your mail PORT",
    "userName": "Enter your username",
    "name": " Enter your name",
    "mail": "Enter your email",
    "mailPassword": "Enter your mail password."
  },
  "dashboardAnalytics": {
    "Total activity": "Total activity",
    "Total Worked": "Total Worked",
    "Total Earned": "Total Earned",
    "Project worked": "Project worked",
    "Hours Worked Per Day": "Hours Worked Per Day",
    "Activity graph": "Activity graph",
    "Top Projects": "Top Projects",
    "Online Members": "Online Members",
    "Highest % of Manual & Mobile Time": "Highest % of Manual & Mobile Time",
    "Tracked Most Hours": "Tracked Most Hours",
    "Tracked Least Hours": "Tracked Least Hours",
    "Recent Used Website": "Recent Used Website",
    "Recent Used Application": "Recent Used Application",
    "Haven't Tracked Time": "Haven't Tracked Time",
    "Member Location": "Member Location",
    "hello": "Hello",
    "tabMe": "Me",
    "tabAll": "All",
    "totalActivity": "Total Activity Tracked",
    "totalWorked": "Total Worked Time",
    "totalEarned": "Total Earned",
    "totalProject": "Total Project Worked",
    "topProjects": "TOP PROJECTS",
    "members": "MEMBERS",
    "highestManualMobile": "HIGHEST % OF MANUAL & MOBILE TIME",
    "trackedMostHrs": "Tracked Most Hours",
    "trackedLeastHrs": "Tracked Least Hours",
    "recentUsedApps": "Recent Used Applications",
    "recentUsedSites": "Recent Used Websites",
    "haventTrackedTime": "Haven't Tracked Time Yet",
    "shiftSchedule": "Shift Schedule",
    "timeoff": "Time Off",
    "jobsite": "Job Site",
    "timeline": "Timeline",
    "activityGraph": "Activity Graph",
    "recentScreenshot": "Recent Screenshots",
    "recentProjects": "Recent Projects",
    "recentTimesheet": "Recent Timesheets",
    "recentTodos": "Recent Tasks",
    "noDataFound": "No data found.",
    "startTime": "Start time",
    "endTime": "End Time",
    "noScreenshots": "No Screenshots.",
    "noRecentActivity": "No recent activity.",
    "projectNotAssigned": "Project are not assigned to you.",
    "currentAppsUrls": "Currently Apps & Urls data is not available.",
    "currentTiemsheet": "Currently timesheet data is not available.",
    "todosNotAssigned": "Tasks are not assigned to you.",
    "screenTime": "Screen Time",
    "manualTime": "Manual Time",
    "mobileTime": "Mobile Time",
    "breakTime": "Break Time",
    "overexceed": "Over Exceed of Break Time",
    "rejectTime": "Rejected Time",
    "idleTime": "Idle Time",
    "viewAllSs": "View All Screenshots",
    "viewProj": "View Projects",
    "viewUrl": "View URLs",
    "viewApps": "View Apps",
    "viewTimesheet": "View Timesheets",
    "viewTodo": "View Tasks",
    "viewAllMem": "View all members",
    "viewMore": "View more",
    "viewReport": "View Report",
    "date": "Date",
    "timeTracked": "Time Tracked",
    "totalHrsWorkedPerDay": "TOTAL HOURS WORKED PER DAY",
    "Today": "Today",
    "Weekly": "Weekly",
    "Monthly": "Monthly",
    "dateRange": "Date Range",
    "viewImage": "View Image",
    "appDataNotAvailable": "Currently Apps data is not available!",
    "strtTime": "Start time",
    "stpTime": "Stop time",
    "todoName": " Task name",
    "todoTime": "Task time",
    "Offline": "Offline",
    "Online": "Online",
    "Android": "Android",
    "iOS": "iOS",
    "Windows": "Windows",
    "MacOS": "MacOS",
    "Web": "Web",
    "Linux": "Linux",
    "noTrackhours": "No Tracked hours",
    "searchWidget": "Select Widgets",
    "total": "Total"
  },
  "schedules": {
    "schedules": "Schedules",
    "addSchedule": "Add Schedule",
    "addScheduleDesc": "Now you can easily create, assign & track employee shifts. Fix the shift discrepancies like employees arriving late for shifts or abandoning shifts.",
    "basicInfo": "Basic Information",
    "member": "Member*",
    "title": "Title*",
    "description": "Description",
    "shiftDur": "Shift Duration",
    "minHrs": "Minimum Hours*",
    "recShiftOpt": "Recurring Shift Options*",
    "save": "Save",
    "editSchedule": "Edit Schedule",
    "repeatUntil": "Repeat Until",
    "selectedDays": "Selected Days",
    "delete": "Delete",
    "back": "Back",
    "validation": "Minimum shift duration is",
    "hours": "hours!"
  },
  "helpDesk": {
    "supportHelp": "Support & Help",
    "orgId": "Organization ID: ",
    "orgText": "Share This Organization ID With Our Support Team",
    "supportHrs": "Our support hours:",
    "dayTime": "10:00 AM - 06:00 PM. Monday - Friday",
    "successManager": "Your Customer Success Manager",
    "escalation": "FOR ESCALATIONS PLEASE REACH OUT TO",
    "chatWus": "Chat With Us",
    "visitHelpCenter": "Visit Help Center",
    "gotIt": "Got It"
  },
  "department": {
    "department": "Department",
    "departmentName": "DEPARTMENT NAME",
    "departmentHead": "DEPARTMENT HEAD",
    "team": "TEAM",
    "members": "MEMBERS",
    "adddepartment": "New Department",
    "edit": "Edit Department",
    "remove": "Remove Department",
    "noDepts": "There are no departments!",
    "confirmremove": "Department will be removed.Do you still wish to continue?",
    "yes": "Yes",
    "no": "No",
    "ok": "OK",
    "addNewDept": "Add New Department",
    "createDept": "Create different teams/departments to streamline your workflow.",
    "bsicInf": "Basic information",
    "back": "Back",
    "depNameLabel": "Department Name*",
    "depHeadLabel": "Department Head*",
    "teamLabel": "Team",
    "searchTeamPlaceholder": "Search Team",
    "memLabel": "Members",
    "save": "Save",
    "editDept": "Edit Department",
    "searchDept": "Search Department"
  },
  "reports": {
    "timeAndActivity": "Time & Activity Reports",
    "dataCheck": "Data in reports is available till yesterday. Today's data will not be available.",
    "timeAndActivityDesc": "View team members' total hours worked , active time, idle time.",
    "selectBy": "Select By",
    "selectDept": "Select Department",
    "selectMember": "Select Member",
    "selectTeam": "Select Team",
    "daterange": "Date Range",
    "groupBy": "Group By",
    "project": "Project",
    "todo": "Task",
    "hours": "Hours",
    "activityLevel": "Activity level",
    "generateReport": "Generate Report",
    "note": "Note: The time shown is as per the timezone of selected user.",
    "filters": "Filters",
    "send": "Send",
    "schedule": "Schedule",
    "download": "Download",
    "scheduleRep": "Schedule Report",
    "sendRep": "Send Report",
    "apply": "Apply",
    "clear": "Clear",
    "effectiveTime": "EFFECTIVE TIME",
    "totalHrsWorkedPerDay": "TOTAL HOURS WORKED PER DAY",
    "avgActivity": "AVG ACTIVITY",
    "member": "MEMBER",
    "date": "DATE",
    "projDetails": "PROJECT DETAILS",
    "activity": "ACTIVITY",
    "idle": "IDLE",
    "manual": "MANUAL",
    "break": "BREAK",
    "effective": "EFFECTIVE",
    "time": "TIME",
    "totalHrs": "TOTAL HRS",
    "searchMem": "Search member",
    "searchTeam": "Search team",
    "searchDepartment": "Search department",
    "searchProject": "Search project",
    "searchTodo": "Search Task",
    "departmentTab": "Departments",
    "teamsTab": "Teams",
    "memberTab": "Members",
    "weeklyReports": "Weekly Reports",
    "weeklyReportsdesc": "Average hours & average activity per day & the total amount earned.",
    "dataNotAvailabel": "Data for this date range is not available!",
    "memWorked": "MEMBERS WORKED",
    "avgHrsPerDay": "AVG HOURS PER DAY",
    "earned": "EARNED",
    "memberName": "MEMBER NAME",
    "totalHours": "TOTAL HOURS",
    "avgPercentage": "AVG PERCENTAGE",
    "dailyTotal": "Daily total",
    "customrepo": "Customized Report",
    "basicDet": "Basic Details",
    "selectAll": "Select All",
    "unSelectAll": "Unselect All",
    "name": "Name",
    "email": "Email",
    "teamName": " Team Name",
    "teamLeadName": "Team Lead Name",
    "activityDet": "Activity Details",
    "totalHrss": "Total hours",
    "totalidle": "Total idle time",
    "idleTimeP": "Idle Time %",
    "totalSearchDays": "Total searched days",
    "attendacedet": "Attendance Details",
    "weekoffdays": "Week off days",
    "shiftHrs": "Shift hours",
    "totalWorkDays": "Total working days",
    "totalEffectiveHrs": "Total effective hours",
    "totalBreakTime": "Total break time",
    "breakTimeP": "Break Time (%)",
    "totalAtt": "Total attendance",
    "att": "Attendance %",
    "totalTiemoffReq": "Total number of timeoff request",
    "totalTimeofHrsReq": "Total timeoff of hours requested",
    "projectDetails": "Project Details",
    "NoOfProjWork": "No. of projects worked",
    "noOfTodoWork": "No. of Tasks worked",
    "noOfCompTodo": "No. of completed Tasks",
    "jobsiteDets": "Jobsite Details",
    "jobsiteVisited": "No. of jobsites visited",
    "totalTimeOnAllJs": "Total time on all jobsites",
    "noOfDaysOnAllJS": "No. of days on all jobsites",
    "totalTimeOnJs": "Total time on jobsites(%)",
    "exportCsv": "EXPORT AS CSV",
    "appsNUrl": "Apps & URLs Reports",
    "appUrlDesc": "View apps used & URLs visited by team members.",
    "category": "Category",
    "session": "Session",
    "manualTimeEditRep": "Manual Time Edit Reports",
    "manualTimeEditDesc": "View team members' manually added time, including time span.",
    "action": "Action",
    "changedBy": "Changed By",
    "affectedMem": "AFFECTED MEMBERS",
    "hrsAdded": "HOURS ADDED",
    "hrsDeleted": "HOURS DELETED",
    "timeSpan": "TIME SPAN",
    "timeChange": "TIME CHANGE",
    "status": "Status",
    "timesheetApprReport": "Timesheet Approval Report",
    "timesheetApprDesc": "View team members' timesheets and the status of their requests.",
    "projWorkOrder": "PROJECT/WORK ORDER",
    "startTime": "START TIME",
    "endTime": "END TIME",
    "timesheetStatus": "TIMESHEET STATUS",
    "apprDeniAt": "APPROVED/DENIED AT",
    "apprDeniBy": "APPROVED/DENIED BY",
    "reason": "reason",
    "projTodoRep": "Project/Task Report",
    "projTodoDesc": "View team members working on projects, Tasks assigned, status, start & end date of projects.",
    "projName": "PROJECT NAME",
    "estHrs": "EST HRS",
    "timeSpent": "TIME SPENT",
    "attBasic": "Attendance Basic Report",
    "attBasicDesc": "Analyze your team’s average work hours, break hours, & attendance percentage.",
    "avgWorkHrs": "AVG WORK HRS",
    "avgAtt": "AVG ATTENDANCE",
    "avgBreakHrs": "AVG BREAK HRS",
    "memPresencePerDay": "MEMBER PRESENCE PER DAY",
    "memName": "Member Name",
    "totDays": "TOTAL DAYS",
    "totWorkDays": "TOTAL WORKING DAYS",
    "dayPre": "DAYS PRESENT",
    "dayAbs": "DAYS ABSENT",
    "attendance": "Attendance %",
    "attAdv": "Attendance Advance Report",
    "attAdvDesc": "Along with an overview of the team's attendance, you can also access each member's daily attendance.",
    "jobsiteRep": "Jobsite Reports",
    "jobsiteRepDesc": "View each member’s detailed details, including job site , project name , job creator , and enter & exit time.",
    "jobsiteName": "Jobsite name",
    "jobsite": "JOBSITE",
    "creator": "CREATOR",
    "enter": "ENTER",
    "exit": "EXIT",
    "timeofft": "TimeOff Transaction",
    "tieofftDesc": "View team members' time off transaction details like policy name.",
    "sendReportText": "You can send the report to individual email address. You can define the Subject & Message body of the email.",
    "emailLabel": "Enter email*",
    "fileType": "File Type*",
    "schedueText": "You can schedule the report to be sent to individual email address on specific Date/Time intervals. You can define the Subject & Message body of the email.",
    "enterName": "Enter Name*",
    "frequency": "Frequency*",
    "col": "Column",
    "add": "ADD",
    "changesMade": "Changes made on",
    "bookedOn": "BOOKED ON",
    "noResult": "No results found",
    "downloadCSV": "Download as CSV",
    "scheduledReports": "Scheduled Reports",
    "clickToRemove": "Click here to remove this report from sidebar",
    "clickToAdd": "Click here to add this report to sidebar",
    "timeActivityTxt": "Time & Activity",
    "weeklyTxt": "Weekly",
    "apps&Urls": "Apps & Urls",
    "manualEditTxt": "Manual Time Edit",
    "projodoTxt": "Project/Task",
    "attendanceBasicTxt": "Attendance Basic",
    "attendanceAdvanceTxt": "Attendance Advance",
    "timeoffTransanctionTxt": "TimeOff Transaction",
    "normalBreak": "Normal Break",
    "exceedBreak": "Exceeded Break",
    "TotalHours": " Total Hours",
    "Average": "Average",
    "Productive": "Productive",
    "Unproductive": "Unproductive",
    "TimeChange": "Time Change",
    "SelectActionType": "Select action type",
    "edit": "Edit",
    "addText": "Add",
    "delete": "Delete",
    "Date": "Date",
    "Member": "Member",
    "Open": "Open",
    "Approved": "Approved",
    "Denied": "Denied",
    "TimesheetType": "Timesheet Type",
    "TimeDuration": "Time Duration",
    "ApprovedDenied": "Approved / Denied By",
    "TimeSpent": "Time Spent",
    "AttendancePer": "Attendance (%)",
    "AvgWorkingHours": "Avg Working Hours",
    "AvgBreakHours": "Avg Break Hours",
    "JobsiteName": "Jobsite Name",
    "Creator": "Creator",
    "SelectJosite": "Select Josite"
  },
  "generalSettingMember": {
    "topPara": "Stop Struggling to remember names and Set up Member/Employee unique ID for user identification",
    "default": "DEFAULT",
    "auto": "Automatically",
    "manual": "Manually",
    "preScen": "Prefix Scenario",
    "withPre": "With Prefix",
    "withoutPre": "Without Prefix",
    "setPreId": "Set prefix of ID",
    "memId": "Set member ID",
    "setPreIdErr": "Please enter 3 capital Alphabetical characters",
    "memIdErr": "Please enter the value between 0001 and 99999999",
    "save": "Save",
    "setupMemId": "Setup Member ID"
  },
  "memberProfile": {
    "saveChanges": "Save Changes",
    "editChanges": "Edit Details",
    "cancelChanges": "Cancel Changes",
    "About": "About",
    "job": "What I love about my job",
    "interest": "My interests and hobbies",
    "PI": "Personal Information",
    "email": "Alternate Email",
    "countryCode": "Country Code",
    "AlternatePhNo": "Alternate Phone Number",
    "BG": "Blood Group",
    "maritalStatus": "Marital Status",
    "marriageDate": "Marriage Date",
    "permAddress": "Permanent Address",
    "JobDetails": "Job Details",
    "empId": "Employee Id",
    "workLocation": "Work Location",
    "Department": "Department",
    "Designation": "Designation",
    "joiningDate": "Date of Joining",
    "empType": "Employement Type",
    "identityInfo": "Identity Information",
    "missingInfo": "Please enter missing detail",
    "photoId": "Photo Id",
    "idName": "Id Name",
    "idNumber": "Id Number",
    "filelimitWarning": "File size exceeds the limit of 1MB.",
    "Add New": "Add New",
    "addressProof": "Address Proof",
    "otherproof": "Other Id/Proof",
    "primaryInfo": "Primary Information",
    "Gender": "Gender",
    "phNo": "Phone Number",
    "currentAddress": "Current Address",
    "emergencyDetail": "Emergency Contact Details",
    "ContactName": "Contact name",
    "Relation": "Relation",
    "ContactEmail": "Contact Email",
    "contactNo": "Contact Phone Number",
    "contactAddress": "Contact Address",
    "bankingInfo": "Banking Information",
    "bankNo": "Bank Account Number",
    "FSCode": "IFS Code",
    "BankName": "Bank Name",
    "BranchName": "Branch Name",
    "BankAddress": "Bank Address",
    "DangerZone": "Danger Zone",
    "deleteAccount": "Delete your Account",
    "DeactivateAccount": "Deactivate your Account",
    "ChangeEmail": "Change Email",
    "ChangePassword": "Change Password",
    "checkInbox": "Please check your inbox",
    "mailText": "We have sent you an email to confirm your new email address.",
    "deactivateText1": "This action will deactivate the account. Any new data or actions will not be punched in the appication.",
    "deactivateText2": "This will not cancel your existing susbcription. You can login any time to revisit your old data or activate your account again.",
    "continue": "Do you want to continue?",
    "Credentials": "Credentials",
    "maxFileLimit": "Maximum 2 files are allowed",
    "chooseFile": "Choose a file",
    "CommonHeader": {
      "working": "working",
      "reportingMamager": "Reporting Manager",
      "profilePercentage": "Profile Completed:",
      "Profile": "Profile",
      "App Setting": "App Setting"
    },
    "appSetting": {
      "generalSetting": "General Settings",
      "TimeZone": "Time Zone",
      "timeZone": "Members Time Zone",
      "changedTimeZone": "Change Time Zone",
      "billingHours": "Work Hours & Billing",
      "dailyLimit": "Daily Limit",
      "hrs/wk": "hrs/wk",
      "weeklyLimit": "Weekly Limit",
      "payRate": "Hourly Pay Rate",
      "weeklyOff": "Weekly Off",
      "TimerApp": "Timer App",
      "screenshotTimer": "Screenshot Timer",
      "breakTime": "Break Time",
      "breakLimit": "Break Time Limit",
      "idleNote": "Idle Notes",
      "trackingMode": "Tracking Mode",
      "daysCount": "No. of days",
      "trackingTime": "Tracking Time",
      "featureSetting": "Feature Settings",
      "Timesheet": "Timesheet",
      "editTimesheet": "Edit Timesheet",
      "timesheetApproval": "Approval Timesheet",
      "reasonReqire": "Require Reason",
      "limitTimesheet": "Timesheet After Limit",
      "Activity": "Activity",
      "App&URLsTracking": "App & URLs Tracking",
      "activityMenu": "Activity Menu",
      "ssFequency": "Screenshot Frequency",
      "ssAlert": "Screenshot Alert",
      "SsBlur": "Screenshot Blur",
      "deleteSs": "Delete Screenshot",
      "Jobsite": "Jobsite",
      "restrictTimer": "Restrict Timer to Jobsites",
      "trackJobsiteTime": "Track Time at the Jobsites",
      "defineBreak": "Define Break",
      "Visible": "Visible",
      "Stealth": "Stealth",
      "trackingModeTxt": "Control the tracking mode for your organization or for any individual user",
      "restrictTimerTab": "Restrict Timer To Job Sites",
      "enterExitNotification": "Enter/Exit Notifications",
      "Enabled": "Enabled",
      "Disabled": "Disabled",
      "integrationTxt": "You don't have any integrations done for your organization",
      "Enabled but Not Mandatory": "Enabled but Not Mandatory",
      "Enabled & Mandatory": "Enabled & Mandatory",
      "Individual": "Individual",
      "All": "All"
    },
    "deleteAccountConfirmation": "Are you sure you want to delete your account."
  },
  "memberId": {
    "text1": "Stop Struggling to remember names and Set up Member/Employee unique ID for user identification",
    "default": "DEFAULT:",
    "Automatically": "Automatically",
    "Manually": "Manually",
    "prefix": "Prefix Scenario",
    "withpre": "With Prefix",
    "withoutPre": "Without Prefix",
    "preID": "Set prefix of ID",
    "membId": "Set member ID",
    "text2": "Please enter 3 capital Alphabetical characters",
    "text3": "Please enter the value between 0001 and 99999999",
    "text4": "This setting can not be changed to manual mode ever.Are you sure you want to update this setting?",
    "Prefix": "Prefix",
    "ID": "ID",
    "uniqueID": "Set a unique member ID for your organization members.",
    "setPrefixID": "Set prefix for the member ID. It shoud be 3 Alphabetical characters only.",
    "setMemberId": "Set the ID which will be displayed in front of prefix and the range should be 0001 - 10000000",
    "setMemberIdWithout": "Set the member ID without prefix and the range should be 0001 - 10000000",
    "memberIdErrTxt": "Please enter value between 0001 and 99999999"
  },
  "Designation": {
    "text1": "Define Department for all members in organization",
    "addDesignation": "Add Designation",
    "Designation": "Designation",
    "delDesignation": "Delete Designation",
    "editDesignation": "Edit Designation",
    "assignDelete": "Delete & Assign",
    "removeDesignation": "Delete this Designation?",
    "delDesignationText1": "permanently. Do you still wish to continue?",
    "delDesignationText2": "wil be deleted",
    "updateDesig": "Update Designation",
    "desigName": " Designation Name",
    "Update": "Update",
    "define": "Define the level or position that employees are holding in the organization",
    "seeting": "Designation Setting"
  },
  "memberSetting": {
    "Membsettings": "Member settings",
    "open": "Open",
    "Restricted": "Restricted",
    "profileSetting": "Profile Setting",
    "MembSetting": "Members Setting",
    "text1": "There are no widgets present at this moment!",
    "text2": "Are you sure you want to update this setting?",
    "text3": "Here you can customize settings for each member."
  },
  "time-activity-report": {
    "activity_time": "Activity % Time",
    "idle_time": "Idle 5 Time",
    "effective_time": "Effective time",
    "manual_time": "Manual % Time",
    "break_time": "Break Time",
    "total_hrs": "Total Hrs"
  },
  "youTubeVideo": {
    "welcome": "Welcome to Workstatus",
    "setingUp": "Want help setting up?",
    "scheduleCallwithTeam": "Schedule a call with our customer success team.",
    "scheduleCall": "Schedule a Call",
    "productTour": "View Product tour",
    "skip": "Skip to continue",
    "helpdesk": "helpdesk"
  },
  "wsTimePicker": {
    "Hours": "Hours",
    "Minutes": "Minutes"
  },
  "generalsetting": {
    "officeLocation": "Work Location(s)",
    "text": "Paste an image, drag and drop files or",
    "click": "click here",
    "upload": "to upload",
    "Save": "Save",
    "Attach": "Attach",
    "Cancel": "Cancel",
    "sizelimit": "File size should be less than 50KB",
    "defaultRole": "Members Role for Project(s)",
    "projectID": "Manage Project ID ",
    "todoStatus": " Manage Task Status",
    "ownerToolTip": "You can't change the setting for owner account",
    "manageID": " Manage Task ID",
    "milestone": "Project Milestone",
    "financeSetting": "Finance Setting",
    "projectSetting": "Project Setting",
    "DeliveryHead": "Delivery Head",
    "Manager": "Manager",
    "User": "User",
    "None": "None",
    "orgOwner": "Organization Owner",
    "orgManger": "Organization Manager",
    "noMembers": "There are no members present at this moment!",
    "noProjects": "There are no projects present at this moment!",
    "sureToUpdate": "Are you sure you want to update this setting?",
    "breakLimit": {
      "text1": "Set Break Time Limit for either all or selected member",
      "editBreak": "Edit Break"
    },
    "manageIDText": "Stop struggling to remember names and set up unique project IDs for project identification.",
    "manageIdText1": " Once saved, future modifications not allowed for this setting.",
    "validation": "Please enter a number between 0001 and 99999999",
    "Enable": "Enable",
    "Disable": "Disable",
    "DEFAULT": "DEFAULT:",
    "statusName": "TASK STATUS NAME",
    "editSTatus": " Edit Task Status",
    "statusAlreadyAssigned": "This task status is already assigned to some Tasks, please change the task status of such Tasks before disabling the Task status.",
    "enableDisable": "Stop struggling to remember names and setup unique task IDs for task identification.",
    "WeeklyOff": "Weekly Off",
    "allowMembers": "Allow members to select their working days",
    "settings": "settings",
    "editText1": "If you don't find exact timezone in your system, please select nearest city with same GMT code.",
    "allowmembers": "Allow members to choose timezone",
    "timezonelist": "Above listed timezones are without DST",
    "fileSize": "File size should be less than 2MB",
    "changesAlert": "You are about to change the timezone of your organization, please note following points:",
    "alert1": "This will change the timezone of all the members within your organization.",
    "alert2": "On tracker apps (Desktop & Mobile Apps), new timezone change will only take place once you logout & login again OR you reload Organization data.",
    "updateSetting": " Are you sure you want to proceed ahead - if YES then press OK and SAVE the setting?",
    "noMembersPresent": "There are no members present at this moment!",
    "billRate": "Bill Rate",
    "changeTimezone": "Allow member to change their timezone.",
    "selectCity": "If you don't find exact timezone in your system, please select nearest city with same GMT code.",
    "No": "No",
    "Yes": "Yes",
    "Clear": "Clear",
    "Apply": "Apply",
    "diffTimezone": "Different Timezone",
    "Filters": "Filters",
    "allowWeekOff": "Allow member to change their Week-off.",
    "selectOneDay": "please select atleaset one day as working day",
    "pleaseSelectDay": "Please select day's",
    "Upgrade": "Upgrade",
    "timerApps": "Control which timer apps the team member can use.",
    "OK": "OK",
    "enableMandatory": "Enabled & Mandatory",
    "enable": "Enable But Not Mandatory",
    "Disabled": "Disabled",
    "Unlimited": "Unlimited",
    "Fixed": "Fixed",
    "Start": "Start",
    "time": "time",
    "End": "End",
    "MEMBERS": "MEMBERS",
    "trackingMode": "Tracking Scenario",
    "NoOfDays": "No. of days",
    "manageTodoStatus": "Create customized Task status to manage and categorized tasks more efficiently.",
    "confirmTxt": "The settings which you are trying to update will affect all users thus we would need to reload the page to make it effective system wide. Would you like to proceed?",
    "payRate": "Pay Rate"
  },
  "editLimit": {
    "designation": "Designation of member",
    "hrs/wk": "hrs/wk",
    "hrs/day": "hrs/day",
    "limitValidation": "Daily limit should not be greater than weekly limit",
    "₹/hrs": "₹/hrs"
  },
  "commonVar": {
    "OK": "OK",
    "No": "No",
    "Yes": "Yes",
    "cancel": "Cancel",
    "Save": "Save",
    "searchmember": "Search members",
    "notAutorisedTxt": "You don't have access to view this page.",
    "inConvinienceTxt": "We're sorry for the inconvenience.",
    "showMore": "Show More...",
    "showless": "Show less...",
    "noDataFound": "No data found.",
    "trackerLogoutMsg": "Please stop your tracker then you can logout.",
    "trackerTimeError": "Date/Time change error",
    "trackerTimeErrorDes": "A change in your system Date/Time has been detected. Workstatus Web Tracker will be stopped due to this. Please correct your system Date/Time in order to continue the Workstatus Web Tracker."
  },
  "membersTab": {
    "noMembers": "There are no members in this project!",
    "invite": "Invite New Members",
    "sureToRemove": "Are you sure you want to remove the member?",
    "remove": "Once removed from the project, member will not be able to access your organization data (i.e. Projects, Task's, etc.). Are you sure you want to remove the member?",
    "successMsg": "Member invitation request has been successfully sent.",
    "tooltipData": "In stealth mode, the application will run in the background and user doesn't require to intervene application in any manner. They don't require to start/stop the application. Note: Available only for windows."
  },
  "featureLimit": {
    "proceed": "Let the timer proceed normally",
    "stopAlert": "Stop the timer & show alert",
    "teamsSetting": "This setting works based on teams. Please add Team first to use this setting. Create team from",
    "Team": "Team",
    "PRODUCTIVE": "PRODUCTIVE",
    "UNPRODUCTIVE": "UNPRODUCTIVE",
    "addProductive": "Add Productive",
    "addHere": "Add here"
  },
  "jobsiteNotification": {
    "Yes": "Yes",
    "No": "No"
  },
  "choosePlan": {
    "plan": "Choose the plan that's right for your team",
    "planDependency": "Depending on your unique needs, choose the plan that works for you. Check the list of features below to help you reach the right decision.",
    "Save": "Save",
    "Monthly": "Monthly",
    "Annual": "Annual",
    "feature": "Plan Features",
    "text1": "Find the subscription that makes",
    "text2": "the most sense for you or your team.",
    "customPlan": "Require A Custom Plan For Your Organization",
    "customplanText": "Don't worry! Our software is ready to adapt as per your unique needs; get in touch to customize Workstatus for your business.",
    "requestCustom": "Request a Custom Quote",
    "upgrade": "Upgrade, downgrade or cancel your plan anytime.",
    "stealthMode": "Use Stealth Mode for uninterrupted tracking.",
    "smartDecision": "Make smart business decisions based on AI reports.",
    "regularization": "Compliance with the General Data Protection Regulation (GDPR) law.",
    "increaseProductivity": "Increase productivity, profitability & transparency at workplace."
  },
  "Addpolicy": "Add policy",
  "efficiencyPro": {
    "resetIcon": "Reset Icon",
    "changepicture": "Change picture",
    "changeIcon": "Change Icon",
    "size": "(Max icon size 50kb)",
    "Disable": "Disable",
    "Enable": "Enable",
    "text": "Paste an image, drag and drop files or",
    "click": "click here",
    "upload": "to upload",
    "fileSize": "Max Size: 50 kb, Supported Format- .PNG",
    "Save": "Save",
    "Attach": "Attach",
    "Cancel": "Cancel",
    "sizelimit": "File size should be less than 50KB",
    "efficiency": "EFFICIENCY PRO"
  },
  "searchField": {
    "todo": "Search task status",
    "members": "Search members",
    "projects": "Search projects",
    "selectOption": "Select Options",
    "searchDesignation": "Search Designation",
    "searchMilestone": "Search Milestone"
  },
  "editTodoStatus": {
    "addNewTodoGroup": "Add New Tasks List",
    "edit": "Edit Task Status",
    "text1": "Create a task list for enhanced task management efficiency.",
    "BI": "Basic information",
    "text2": "Label name length must not exceed 20 characters.",
    "SelectColor": "Select Color",
    "Status": "Status",
    "Save": "Save",
    "label": "Label Name*",
    "editTodoGroup": "Edit Task List",
    "todoGroupErr": "Tasks List name length must not exceed limit of 100 characters",
    "tooltip1": "This task list will be visible to both project members & client users.",
    "tooltip2": "This task list will only be visible to project members",
    "flag": "Flag*",
    "todoGroupName": "Tasks List Name*",
    "addTodogroup": "Add New Task List",
    "nodataTodoSelect": "Select project first to load associated members.",
    "noTodo": "No task here - please choose another Tasks List / Project."
  },
  "footer": {
    "policy": "Privacy Policy",
    "service": "Terms of Service",
    "reportBug": "Report a Bug",
    "suggestFeature": "Suggest a Feature",
    "Copyright": "Copyright",
    "rightReserved": "All Rights Reserved"
  },
  "allDropdown": {
    "allMembers": "All Members",
    "allJobsites": "All Jobsites",
    "hoursLevel": "All hours level",
    "AllTeams": "All Teams",
    "AllDepartments": "All Departments",
    "Date": "Date",
    "Member": "Member",
    "allproject": "All Projects",
    "activePolicy": "Active policy",
    "archivePolicy": "Archive Policy",
    "TimeOffPolicies": "Time Off Policies",
    "Escalation": "Escalation",
    "upgrade": "Upgrade",
    "memberTimezoneDropdown": "Member(s) with different time zone"
  },
  "viewTimeOff": {
    "view": "View Time Off request",
    "viewIndividual": "View the individual time off request details from here.",
    "GI": "General Information",
    "Back": "Back",
    "Member": "Member",
    "policyName": "Policy Name",
    "startdate": "Start Date",
    "EndDate": "End Date",
    "Hours": "Hours",
    "request": "Requested on",
    "Status": "Status",
    "submitted": "submitted",
    "Userreason": "User reason",
    "EditRequest": "Edit Request"
  },
  "editTimeOff": {
    "edit": "Edit Request",
    "text": "Your employees can request time off; you can view, accept or reject the time off request from our unified platform.",
    "BI": "Basic Information",
    "currentbalance": "Current Balance",
    "totalTime": "Total time Requested",
    "remaining": "Remaining Balance",
    "negativeBalance": "Selected time policy does not allow negative balance",
    "negativeLimit": "Selected policy does not allow negative balances less than 100",
    "lessHours": "Please enter requested hours less than",
    "hours": "hours"
  },
  "label": {
    "Member": "Member",
    "Policy": "Policy*",
    "timespan": "Time span*",
    "allday": "All day",
    "request": "Requested time per day*",
    "reason": "Reason*",
    "time": "Time*"
  },
  "pageNotFound": {
    "text": "We looked everywhere for this page. Are you sure the website URL is correct? Get in touch with the site owner, or go back home..",
    "backHome": "Go back home",
    "pageNotFound": "oops... 404 page",
    "logoutContinue": "Are you sure you want to logout?",
    "logoutSuccess": "You are logged out successfully."
  },
  "achievement": {
    "activityGoal": "ACTIVITY GOAL",
    "Done": "Done"
  },
  "break": {
    "addBreak": "Add Break",
    "updateBreak": "Update Break",
    "Update": "Update",
    "Save": "Save",
    "Status": "Status",
    "name": "Break Name"
  },
  "remainingText": {
    "selectDays": "Please select days"
  },
  "departmentSetting": {
    "textfeild": "Enable Department to add one more Hierarchy in your organization to streamline your workflow",
    "setting": "Department Setting",
    "Permissions": "Permissions",
    "approve": "'Approve Timesheets:",
    "text": " HOD would be able to approve or reject the timesheet filled by the members/teams of the department.",
    "timeoff": "Time Off Requests:",
    "text1": "HOD would be able to request the time- off for their members/teams of the department.. Also HOD would be able to approve the time off requests raised by the members/teams of the department.",
    "createSchedule": "Creating Schedules:",
    "text3": "HOD would be able to create schedules for the members/teams of the department.",
    "Enabled": "Enabled",
    "Disabled": "Disabled",
    "text4": "With the click of a button, Enable or Disable department setting."
  },
  "profileSetting": {
    "memberId": "Setup Member ID",
    "Designation": "Member Designation",
    "Settings": "Member Profile Visibility"
  },
  "PaginationKeys": {
    "showing": "Showing",
    "to": "to",
    "from": "from",
    "entries": "entries",
    "of": "of"
  },
  "placeholder": {
    "searchMilestoneList": "Search Milestone List",
    "searchmember": "Search member",
    "search": "Search",
    "searchmembers": "Search members",
    "Searchproject": "Search project",
    "searchTeam": "Search Teams",
    "select": "Select",
    "selectClient": "Select client",
    "totalDays": "Total Days",
    "searchMilestone": "Search Milestone",
    "SelectTimeType": "Select time type",
    "SelectBreakType": "Select break type"
  },
  "freeforever": {
    "text1": "please refer the details below:",
    "text2": "We've observed that you are using extra features as compared to what is available in our",
    "Youve": "You've",
    "users, only": "users, only",
    "allowedIn": "user is allowed in",
    "remove": "please remove",
    "text3": "members from your organization. Don't worry - your pro-rated amount will be refunded back to you.",
    "Member(s):": "Member(s):",
    "Remove": "Remove",
    "projects, only": "projects, only",
    "projectAllowed": "projects are allowed in",
    "archieve": "please archive",
    "orgProject": "project from your organization.",
    "Project(s):": "Project(s):",
    "Archive": "Archive",
    "removedText": "Once removed from the project, member will not be able to access your organization data (i.e. Projects, Tasks, etc.). Are you sure you want to remove the member?",
    "addon": "Get Add-ons",
    "user": "user",
    "reviewDetails": "Review your details",
    "Plan Info:": "Plan Info:",
    "billingPlan": "Billing Plan",
    "changePlan": "Change plan",
    "purchasedate": "Purchase date",
    "Personal Info:": "Personal Info:",
    "Name": "Name",
    "Phone": "Phone",
    "Email": "Email",
    "companyName": "Company name",
    "details": "Payment Detail",
    "due": "Amount Due",
    "coupon": "(Have a Coupon?)",
    "User": "User",
    "validation": "Min 1 and maximum 500 users are allowed",
    "Remove user": "Remove user",
    "Discount": "Discount",
    "PromoCode": "Promo Code",
    "Apply": "Apply",
    "Subtotal": "Subtotal",
    "totalDue": " Total Amount Due",
    "proceed": "Proceed & Make Payment",
    "text5": "Still unsure? Experience all our features with a",
    "text6": "day FREE TRIAL. Begin your trial now!",
    "start": "Start my",
    "freeDays": "days free trial",
    "invalidCoupon": "Invalid Coupon Code",
    "woohoo!": "woohoo!",
    "applied": "applied",
    "You save ₹": "You save ₹",
    "promocode": "with this promo code",
    "LetProceed": "Let's Proceed",
    "removeMem": "Remove Members",
    "text7": "Please remove members from your organization.  Don't worry - your pro-rated amount will be refunded back to you.",
    "THANKYOU": "THANK YOU",
    "Continue": "Continue",
    "Back": "Back",
    "error": "Error Processing Payment",
    "text8": "We've received below error while trying to make the payment:",
    "sessionExpired": "This Session has been Expired.",
    "text9": "Please resolve the above problem & retry the payment.",
    "InvoiceErr": "Please select the invoice checkbox to send email",
    "downloadErr": "Please select the invoice checkbox to download",
    "yourInvoiceTxt": "Your invoice"
  },
  "VerticalNav": {
    "downloadApps": "Download Our Apps",
    "chooseAppsTxt": "Choose the app that makes time tracking easiest for you. Work wherever you are with the desktop or mobile versions.",
    "desktopApps": "Desktop Apps",
    "mobileApps": "Mobile Apps",
    "mainMenu": "Main Menu",
    "gettingStartedTxt": "Get started with Workstatus",
    "skipTxt": "Skip this step",
    "addTeamTxt": "Add team members",
    "inviteText": "Invite your team so they can start tracking time. You can set their role, assign them to projects, and even set weekly limits, bill rates, and pay rates.",
    "trackTimeTxt": "Tracking & adding time",
    "setup": "SETUP",
    "application": "Applications",
    "addTimeManually": "Add Time Manually",
    "downloadApp": "Download App",
    "addEditTime": "Add, edit, or remove time from the daily, weekly, or calendar timesheets view. Manual time entries have 0% activity rates."
  },
  "onboardingStatus": {
    "onboardingstatus": "Onboarding Status",
    "name": "Member Name",
    "account": "Created Account",
    "download": "Downloaded App",
    "trackedTime": "Tracked Time",
    "reminder": "Send Reminder",
    "requestedOn": "Requested on",
    "invitedOn": "Invited on",
    "createdOn": "Account created on",
    "downloadedOn": "App Downloaded on",
    "timetracked": "Time Tracked on"
  },
  "milestone": {
    "mileEnableDisable": " Here you can easily enable/disable your project milestone.",
    "enable": "Enable",
    "disable": "Disable",
    "sureToUpdate": "Are you sure you want to update this setting?",
    "update": "Milestone Updated",
    "addMile": "Add Milestone",
    "mileDescription": "Set your milestones and begin tracking: add basic information, details and hit save.",
    "basicInfo": "Basic Information",
    "milestoneName": "Milestone Name",
    "letterValidation": "First three letters must be alphabet",
    "limitValidation": "Milestone name must not exceed limit of 50 characters",
    "projectName": "Project Name",
    "startDate": "Start Date",
    "endDate": "End Date",
    "dateCheck": "End date must be greater than Start date.",
    "flag": "Flag",
    "internal": "Internal:",
    "internalDesc": "Milestones will be visible only to project users if marked as internal.",
    "external": "External:",
    "externalDesc": "Milestones will be visible to both project and client users if marked as external.",
    "budgetedHours": "Budgeted Hours",
    "projectOwner": "Project Owner",
    "milestoneCreated": "Milestone Created Successfully",
    "milestoneDetails": "Milestone Details",
    "Milestones": "Milestones",
    "ViewAllMilestones": "View All Milestones",
    "AddMilestone": "Add Milestone"
  },
  "welcomeTour": {
    "welcomeText": "Welcome to Workstatus!",
    "helpText": "We are here to help you set up your account.",
    "letsStart": "Let's Start"
  },
  "ProductivitySummary": {
    "productivity": "Productivity Summary",
    "duration": "Duration",
    "effectiveTime": "Effective Time",
    "productivityTime": "Productivity Time",
    "idleTime": "Idle Time",
    "manualTime": "Manual Time",
    "activityPercentage": "Activity %",
    "idlePercentage": "Idle %",
    "breakTime": "Break Time"
  },
  "Milestone": {
    "editMilestone": "Edit Milestone",
    "individualMilestone": " View the details of individual milestone here & edit the details quickly (if needed).",
    "details": "Milestone details",
    "backMilestone": "Back to milestone",
    "Cancel": "Cancel",
    "save": "Save Milestone",
    "name": "Milestone Name*",
    "validation": "Milestone name must not exceed limit of 100 characters",
    "project": "Project Name*",
    "Flag": "Flag",
    "visiblity": "Milestones will be visible only to project users if marked as internal.",
    "Internal": "Internal:",
    "External": "External:",
    "externalVisiblity": "Milestones will be visible to both project and client users if marked as external.",
    "startDate": "Start Date",
    "endDate": "End Date",
    "dateValidation": "End date must be greater than Start date.",
    "budget": "Budgeted Hours",
    "budgtvalidation": "Budgeted hours should be less than 10",
    "additionalInfo": "Additional Information",
    "owner": "Milestone Owner",
    "createdby": "Created by & Time",
    "lastEdited": "Last Edited",
    "viewMilestone": "View Milestone",
    "investedhours": "Invested Hours",
    "showOnlyCr": "Show only CR",
    "numberLimit": "Only number between 0 and 100 are accepted."
  },
  "TimeOff": {
    "monthlyPattern": "Monthly Pattern",
    "leaveBalance": "Leave Balance",
    "leaveData": "Leave Data",
    "weeklyPattern": "Weekly Pattern",
    "balance": "Balance",
    "consumed": "Consumed",
    "leaveHistory": "Leave History",
    "leaveType": "Leave Type",
    "leaveDuration": "Leave Duration",
    "leaveNote": "Leave Note",
    "status": "Status",
    "approverNote": "Approver Note"
  },
  "comments": {
    "comment": "COMMENTS",
    "sendTxt": "Send to All",
    "editComment": "Edit Comment",
    "deleteComment": "Delete Comment",
    "quoteComment": "Quote Comment",

    "loadMore": "Load More Comments",
    "confirmComplete": "Are you sure you want to delete this comment?",
    "bulkDelete": {
      "first": "Are you sure to delete the",
      "last": "comments. Deleted comments can not be retrieved"
    },
    "enterTagName": "Enter a tag name",
    "Internal": "Internal",
    "External": "External",
    "alertText": "Something went wrong!"
  },
  "todoGroup": {
    "todogroup": "Tasks Lists",
    "Comments": "Comments",
    "todoGroups": "TASKS LISTS",
    "progress": "PROGRESS",
    "delete": "Delete",
    "sureDelete": "Are you sure you want to delete this Tasks List?",
    "sureMarkAsComplete": "Are you sure you want to mark this Tasks List as complete?",
    "sureMove": "Are you sure you want to move this Tasks List?",
    "markAsComplete": "Mark as complete",
    "move": "Move",
    "chooseMilestone": "Choose Milestone",
    "chooseProject": "Choose Project",
    "Search": "Search",
    "SelectProject": "Select Project",
    "SelectMilestone": "Select Milestone",
    "Move": "Move",
    "Cancel": "Cancel",
    "Open": "Open",
    "TodoGroup": "Tasks List",
    "Todos": "Tasks",
    "TodoCompleted": "Tasks Completed",
    "NoDataFound": "No Data Found",
    "Completed": "Completed",
    "newTodoGroup": "New Tasks List"
  },
  "SearchBarText": {
    "screenWiseHelp": "Screenwise Help",
    "downloadApps": "Download Apps",
    "welcomeToWorkstatus": "Welcome to Workstatus",
    "selectThemesMode": "Select Themes Mode",
    "changeLanguage": "Change Language",
    "quickLinks": "Quick Links"
  },
  "drawersText": {
    "noNotes": "No Notes Available",
    "manualNotes": "Manual Note(s)",
    "trackerNotes": "Tracker Note(s)",
    "idleNotes": "Idle Note(s)",
    "CategoryHeading": "Message Board Categories",
    "CategoryText": "Set up categories for this Message Board below.",
    "addmore": "Add more details",
    "searchparenttodo": "Search Parent Task",
    "searchtodogroup": "Search Tasks List",
    "enterEmailId": "Enter Email id",
    "enterMemberId": "Enter Member id",
    "selectRole": "Select Role",
    "memberId": "Member ID",
    "policy": "Policy*",
    "ViewTimeOff": "View Time Off",
    "requestedOn": "Requested on",
    "EditTimeoff": "Edit Timeoff",
    "EditTimeoffTxt": "Your employees can request time off.",
    "memberName": "Member Name",
    "project": "Project",
    "invite": "Invite",
    "manager": "Manager",
    "enterProjectName": "Enter Project Name",
    "client": "Client",
    "selectClient": "Select Client",
    "description": "Description",
    "typeHere": "Type Here",
    "date": "Date",
    "startTime": "Start time",
    "endTime": "End time",
    "note": "Note",
    "addNewMilestone": "Add Milestone",
    "setYourMilestone": "Set your milestones and begin tracking: add basic information, details and hit save.",
    "milestoneStoneName": "Milestone Name",
    "startDate": "Start Date",
    "endDate": "End Date",
    "flag": "Flag",
    "milestoneOwner": "Milestone Owner",
    "milestoneChangeRequest": "Mark if you want to make this milestone as change request",
    "save": "Save",
    "newTodo": "Add New Tasks List",
    "markingTodo": "Marking task status made fun; simply dedicate a color to each status.",
    "projectName": "Project Name",
    "todoGroupname": "Tasks List Name",
    "relatedMilestone": "Related Milestone",
    "none": "None",
    "internal": "Internal",
    "external": "External",
    "budgetedHours": "Budgeted Hours",
    "AddTime": "Manual Time",
    "AddTodo": "Task",
    "AddTodoGroup": "Tasks List",
    "AddMilestone": "Milestone",
    "AddProject": "Project",
    "AddMember": "New Member",
    "AddTeam": "Team",
    "AddDepartment": "Department",
    "AddClient": "Client",
    "AddJobsite": "Jobsite",
    "AddSchedule": "Schedule",
    "RequestTimeoff": "Request Time off",
    "projectCurr": "Project Currency"
  },
  "drawersFieldPlaceholders": {
    "Manager": "Select Manager",
    "Users": "Select Project Members",
    "Member": "Select Member",
    "projName": "Select Project",
    "SelectTodoName": "Select Task",
    "todoGroupName": "Select Tasks List",
    "parentTodoName": "Select parent Task",
    "todoName": "Enter Task Name",
    "status": "Select status",
    "Description": "Type Here",
    "Tags": "Select or Enter Tag Name",
    "TeamName": "Enter Team Name",
    "TeamMates": "Select Teammates",
    "TeamLead": "Select Team Lead",
    "clientEmail": "Enter Client Email Id",
    "ClientProject": "Select project for this client",
    "Policy": "Select Policy",
    "Hours": "Enter Hours",
    "JobsiteName": "Enter Jobsite Name",
    "JobsiteAddress": "Enter Address",
    "Title": "Enter Title",
    "DeptName": "Enter Department Name",
    "DeptHead": "Select Department Head",
    "SelectTeam": "Select Team",
    "milestoneName": "Enter Milestone name",
    "SelectDate": "Select Date",
    "todoGrp": "Enter Tasks List Name",
    "selectFlag": "Select Flag",
    "selectbudget": "Select Budget",
    "enterbudget": "Enter Budget",
    "selectMilestoneOwner": "Select Milestone Owner"
  },
  "add": {
    "AddTo-do": "Add Task",
    "AddTo-doGroup": "Add Tasks List",
    "AddMilestone": "Add Milestone",
    "reqMilestoneName": "Milestone Name*",
    "specialCharVal": "Special character are not accepted",
    "Internal": "Internal",
    "InternalText": "Milestones will be visible only to project users if marked as internal.",
    "External": "External",
    "ExternalText": "Milestones will be visible to both project and client users if marked as external.",
    "budgetVal": "Budgeted hours should be less than 10 digits",
    "reqMilestoneOwner": "Milestone Owner",
    "SearchProjectMember": "Search project member",
    "milestoneChangeRequest": "Mark if you want to make this milestone as change request",
    "milestoneChangeRequestTooltip": "You can create separate milestones for Change Requests within project to track the specific CR related progress/details.",
    "ProjectUpdatedSuccessfully": "Project updated successfully.",
    "updateStatus": "Are you sure you want to update the status?",
    "StatisticsText": "All USED APPLICATIONS STATISTICS",
    "selectNone": "select none",
    "selectAll": "select all",
    "DataNotAvailable": "Data not available!",
    "GridView": "Grid View",
    "TableView": "Table View",
    "Reject": "Reject",
    "searchTeammates": "Search teammates",
    "SearchTeamleads": "Search teamleads",
    "SearchDepartmentHead": "Search Department head",
    "HardDelete": "Hard delete",
    "SelectProjectFirst": "Select a project first",
    "AttendanceReports": "Attendance Reports"
  },
  "filterText": {
    "leaves": "Leaves",
    "absent": "Absent",
    "allLeaves": "All leaves",
    "leaveType": "Select leave type",
    "shiftEntry": "Select shift entry",
    "allShifts": "All shifts",
    "attendanceEntry": "Select attendance entry",
    "allEntry": "All Entry"
  },
  "descLabel": {
    "projectDesc": "Project Description",
    "todoDesc": "Task Description"
  },
  "filter": {
    "Deleted": "Deleted",
    "Deactivated": "Deactivated",
    "deviceType": "Device Type",
    "Mac": "Mac",
    "AllUsers": "All users",
    "AllDevice": "All device",
    "RoleType": "Role Type",
    "SelectRoleType": "Select role type",
    "All": "All",
    "TrackingCategory": "Tracking Category",
    "TrackingMode": "Tracking Mode",
    "SelectCategory": "Select category",
    "SelectMode": "Select mode",
    "AllTodo": "All Tasks",
    "Alphabetically": "Alphabetically",
    "LastVisited": "Last Visited",
    "CurrentWeek": "Current Week",
    "NextWeek": "Next Week",
    "Rejected": "Rejected",
    "Pending": "Pending",
    "Leavepolicy": "Leave policy",
    "Days": "Days",
    "Yettostart": "Yet to start",
    "InProgress": "In Progress",
    "OnHold": "On Hold",
    "Completed": "Completed",
    "Cancelled": "Cancelled",
    "Archived": "Archived",
    "Selecttype": "Select type",
    "AllDuration": "All Duration",
    "Select": "Select",
    "between": "between",
    "SecondGreaterThanFirstValue": "Second value must be greater than first value",
    "Alltype": "All type"
  },
  "selectField": {
    "SameAsPayRate": "Same as pay rate",
    "SameAsPayRateDesc": "Keep the bill rate same as pay rate of user (Pay rate can be changed from organisation settings)",
    "ProjectMember": "Project Members",
    "projNameDesc": "Enter the project name which you want to keep. The Project name must not contain any special characters in it and project name must be of minimum 3 characters.",
    "managerdesc": "Add member who will be the manager of this project or to whom you want to assign as point of contact for this project.",
    "projMemberDesc": "Enter the members who are going to work on this project or assignment. These project members will be added in the Tasks you will create in this project.",
    "projStatusDesc": "Select the status of project. ",
    "startDateDesc": "Mention the date from when you want to start the project.",
    "endDateDesc": "Mention the date by when you want to complete this project.",
    "projDesc": "Enter the details of this project which you want to show to the managers and project users. This may contain the goal of the project , process , achievables or any other valuable / required information related to the project.",
    "clientDesc": "Please add the client for whom you are doing this project. To select client from here you must need to add the client first.",
    "billableDesc": "Please make this toggle on if you want to make this project as billable. This will help you in raising invoice to the clients for actual billable hours.",
    "typeDesc": "Select the type of budget you want to set for this project.",
    "hoursDesc": "Set the number of hours which you want to set as budget for this project.",
    "costDesc": "Set the cost amount which you want to set as budget for this project.",
    "projPerHrRate": "Set the hour per rate of this project.",
    "BudgetMode": "Select the mode of budget you want to set for this project.",
    "CurrencyType": "select currency type for budget",
    "notifyAtDesc": "Define the remaining budget percentage when you want to get notification.",
    "todoProjName": "Enter the project name in which you want to create the Task.",
    "todogroupDesc": "Select the Task List in which you want to associate this Task.",
    "parentTodoDesc": "Select the Task under which you want to create any child Task.",
    "todoNameDesc": "Set the unique name for the Task which you are creating.",
    "todoDesc": "Enter the details of this Task. which you want to show to the members. This may contain the goal of the Task. , process , achievables or any other valuable / required information related to the Task.",
    "selectMemDesc": "Enter the members who are going to work on this Task. Only members added in above defines project can be added in this Task.",
    "todoRecurrence": "It will help you to set the Task as recurring task. ",
    "statusDesc": "Set the status of Task as per the progress of Task. It can be changed any time by editing a Task.",
    "priorityDesc": "Set the priority of Task.",
    "todoStartDate": "Mention the date from when you want to start the Task.",
    "todoEndDate": "Mention the date by when you want to complete this Task.",
    "todoTypeDesc": "Select the type of Task you want to create, Enhancement are for the New Tasks or any task you are defining to any member and Bugs are for the issues which came up during the tasks done by the members.",
    "todoBillable": "Please make this toggle on if you want to make this project as billable. This will help you in raising invoice to the clients for actual billable hours.",
    "severityDesc": "Set the severity of the bugs as per their criticality. Most critical will be set as Shows Topper and Least Critical will be set as Minor.",
    "tagsDesc": "You can create different Tags for the Task which will help to differentiate the task these will also help you to search them and these can also be used during the commenting on Projects, Milestone & Tasks.",
    "estHrDesc": "Set the number of hours which you want to assign for this Task to the members.",
    "investedHrsDesc": "These are the hours which shows how much time the member has worked on that task. Source of the Invested task will be desktop application , Mobile application or Manual Entry from the Timesheets. ",
    "progressPerc": "Set the progress percentage of Task."
  },
  "invite": {
    "InviteMembers": "Invite",
    "investedHrsDesc": "These are the hours which shows how much time the member has worked on that task. Source of the Invested task will be desktop application , Mobile application or Manual Entry from the Timesheets. ",
    "TrackingMode": "Tracking Mode",
    "SelectCategory": "Select category",
    "SelectMode": "Select mode",
    "AllTodo": "All Tasks",
    "Alphabetically": "Alphabetically",
    "LastVisited": "Last Visited",
    "CurrentWeek": "Current Week",
    "NextWeek": "Next Week",
    "Rejected": "Rejected",
    "Pending": "Pending",
    "Leavepolicy": "Leave policy",
    "Days": "Days",
    "Yettostart": "Yet to start",
    "InProgress": "In Progress",
    "OnHold": "On Hold",
    "Completed": "Completed",
    "Cancelled": "Cancelled",
    "Archived": "Archived",
    "Selecttype": "Select type",
    "AllDuration": "All Duration",
    "Select": "Select",
    "between": "between",
    "SecondGreaterThanFirstValue": "Second value must be greater than first value",
    "Alltype": "All type",
    "eta": "ETA"
  },
  "employeeDirectory": {
    "emplyoee": "Employee",
    "selectedLocation": "Select Location",
    "searchLocation": "Search location",
    "allLocation": "All location",
    "selectDepartment": "Select Department",
    "allDepartment": "All department",
    "employeeDirectory": "Employee Directory",
    "searchEmployee": "Search Employee",
    "punchedInAt: ": "Punched in at: ",
    "notLoggedInYet": "Not Logged in Yet",
    "jobTitle:": "Job Title:",
    "department:": "Department:",
    "phoneNo:": "Phone No:",
    "location:": "Location:"
  },
  "budget": {
    "TotalCost": "Total Cost",
    "TotalHours": "Total Hours",
    "BudgetedCost": "Budgeted Cost",
    "InvestedCost": "Invested Cost",
    "BudgetedHours": "Budgeted Hours",
    "InvestedHours": "Invested Hours",
    "Hours": "Hours",
    "Cost": "Cost",
    "Selectbudget": "Select Budget",
    "Enterbudget": "Enter Budget",
    "startDateSmaller": "Start date is smaller than project start date",
    "startDateGreater": "Start date is greater than end date",
    "endDateGreater": "End date is greater than project end date",
    "endDateSmaller": "End date is smaller than start date",
    "projEndDateValid": "End date is smaller than Project start date",
    "EstimatedCost": "Estimated Cost",
    "overinvested": "Overinvested"
  },
  "memberGraph": {
    "TableView": "Table View",
    "GridView": "Grid View",
    "GraphView": "Graph View",
    "EmployeeDetails": "Employee Details",
    "loadMore": "Load More"
  },
  "projectTimesheet": {
    "groupBy": "Group by:",
    "date": "Date",
    "member": "Member",
    "Todo": "Tasks",
    "TaskDetail": "TASK DETAIL",
    "HrsLog": "HRS LOG",
    "TimePeriod": "TIME PERIOD",
    "Member": "MEMBER",
    "Status": "STATUS",
    "Date": "DATE",
    "addManualTime": "Add Manual Time"
  },
  "officeLocation": {
    "manageLocation": "MANAGE WORK LOCATIONS",
    "locationName": "LOCATION NAME",
    "addNewLocation": "Add New Location",
    "enterLocation": "Enter Location Name",
    "saveLocation": "Save Location",
    "editLocation": "Edit Location",
    "deleteLocation": "Delete Location",
    "noLocation": "There are no locations present at this moment!",
    "memberLocation": "Member Location",
    "reminderTooltip": "Remind to start timer",
    "knowMore": "You can set specific IP addresses as work locations. When the employees log in from these defined IP addresses, the system will automatically detect and display the work locations of the employees."
  },
  "messageBoardDisscussion": {
    "noMsg": "No messages just yet",
    "noMsgText": "Post announcements, pitch ideas, and gather feedback while keeping discussions organized and on-topic.",
    "newMsg": "Add Message",
    "pickCategory": "Pick a category (Optional)",
    "typeTitle": "Type a title...",
    "postMsg": "Post Message",
    "saveDraft": "Save as a draft",
    "postThis": "When would you like to post this?",
    "searchMsg": "Search Message",
    "selectTime": "Select Time",
    "scheduleSave": "Schedule & Save",
    "editMessage": "Edit Message",
    "deleteMessage": "Delete Message",
    "draft": "Draft",
    "privacy": "Privacy",
    "allProjectMember": "All Project Members",
    "SearchProjectMember": "Search project member",
    "allClients": "All Clients",
    "SearchProjectClient": "Search project client",
    "editAddCategory": "Edit/Add Categories",
    "goBack": "Go Back",
    "LastUpdated": "Last Updated"
  },
  "notificationSettings": {
    "ReceiveAllNotifications": "Receive All Notifications",
    "ShowNotifications": "Show Notifications",
    "allNotification": "All Notifications",
    "Notifications": "NOTIFICATIONS",
    "email": "EMAIL",
    "notificationDesc": "Choose from below for notifications you wish to receive by email.",
    "helpnsupport": "Stay updated on what matters most to you by managing notifications. Choose for which event of the modules you'd like to be notified about by an email."
  },
  "onboading": {
    "sendRequest": "Send request"
  },
  "graph": {
    "TotalTasks": "Total Tasks",
    "RecentFirst": "Recent First",
    "RecentLast": "Recent Last",
    "ShareYourCommentHere": "Share your comment here",
    "SearchComments": "Search Comments",
    "CopyMilestoneLink": "Copy Milestone Link",
    "FullScreenview": "Full Screen view",
    "DUEDATE": "DUE DATE",
    "ASSIGNEE(S)": "ASSIGNEE(S)",
    "CreatedDate": "Created Date",
    "TaskID": "Task ID",
    "SelectMilestones": "Select Milestones",
    "AllMilestones": "All Milestones",
    "NoMilestones": "No Milestones",
    "ParentTasks": "Parent Tasks",
    "SearchParentTask": "Search Parent Task",
    "EnterKeyword": "Enter Keyword",
    "Searchtags": "Search tags",
    "SearchTasksLists": "Search Tasks Lists",
    "Savefilterview": "Save filter view",
    "ViewName": "View Name",
    "Maximumlengthshouldbe35characters": "Maximum length should be 35 characters",
    "deletefilter": "Are you sure you want to delete this filter?",
    "Updatefilterview": "Update filter view",
    "maxiLimitfilters": "You have reached maximum limit of saving filters. You can save maximum 6 filters.",
    "deleteTask": "Are you sure you want to delete this task?",
    "CopyTaskLink": "Copy Task Link",
    "TodoID": "To do ID",
    "ParentTodoID": "Parent Task ID",
    "NonBillable": "Non Billable",
    "NonRecurring": "Non Recurring",
    "Enhancement": "Enhancement",
    "Bug": "Bug",
    "LowPriority": "Low Priority",
    "MediumPriority": "Medium Priority",
    "HighPriority": "High Priority",
    "AddSubTask": "Add Sub Task",
    "MoveTask": "Move Task",
    "MoveTo-do": "Move To-do",
    "Title": "Title",
    "MessageType": "Message Type",
    "SelectMessageType": "Select Message Type",
    "CategoryType": "Category Type",
    "SelectCategory": "Select Category",
    "Comment(s)": "Comment(s)",
    "Locations": "Locations",
    "OfficeRemote": "Office/Remote",
    "origin": "origin",
    "UpdateFilter": "Update Filter",
    "DeleteFilter": "Delete Filter",
    "SaveFilters": "Save Filters",
    "ClearFilters": "Clear Filters",
    "TaskStatus": "Task Status",
    "None": "None",
    "ShowStopper": "Show Stopper",
    "Critical": "Critical",
    "Major": "Major",
    "Minor": "Minor",
    "Posted": "Posted",
    "Scheduled": "Scheduled",
    "Draft": "Draft"
  },
  "tracker": {
    "title":"Track your time",
    "description":"Select Project and Task to start tracking",
    "searchPlaceholder": "Search...",
    "noData": "No data available",
    "noTaskData":"No task assigned",
    "noProjectSelected": "No Project Selected",
    "workToday": "Today",
    "projectLabel": "Project",
    "projectPlaceholder": "Select Project",
    "taskLabel": "Task",
    "taskPlaceholder": "Select task",
    "downloadInfo": {
      "start": "To record screenshots, please ",
      "download": "download",
      "last": "and use the desktop app."
    }
  },
  "gantt": {
    "groupBy": "Group By",
    "groupByStatus": "Status",
    "groupByMember": "Member",
    "groupByTaskGroup": "Task Group",
    "sortBy": "Sort By",
    "sortByAlfabetically": "Alfabetically",
    "sortByCreatedDate": "Created Date",
    "sortByStartDate": "Start Date",
    "sortByDueDate": "Due Date",
    "sortByTaskId": "Task Id",
    "timelineView": "Timeline view",
    "listingView": "Listing view",
    "days": "Days",
    "week": "Week",
    "monthly": "Monthly",
    "quarterly": "Quarterly"
  },
  "expense": {
    "moduleName":"Expense",
    "newExpense":"New Expense",
    "listTitle":{
      "SNo":"S.No",
      "ExpenseCategory":"Expense Category",
      "ProjectName":"Project Name",
      "TaskName":"Task Name",
      "TeamMember":"Member",
      "DateOfExpense":"Date of Expense",
      "Amount":"Amount",
      "ExpenseDescription":"Expense Description",
      "Attachments":"Attachments",
      "Status":"Status",
      "Reason":"Reason"
    },
    "drawer":{
      "title":"Add Expense",
      "titleDescription":"Here, you can create expense and assign them to right resources and start tracking.",
      "categoryLabel":"Expense Category",
      "categoryPlaceholder":"Select Expense Category",
      "projectNameLabel":"Project Name",
      "projectNamePlaceholder":"Select Project",
      "taskLabel":"Task Name",
      "taskPlaceholder":"Select Task",
      "memberLabel":"Member Name",
      "memberPlaceholder":"Select Member",
      "expenseDateLabel":"Expense Date",
      "hoursWorkedLabel":"Hours Worked",
      "amountLabel":"Amount",
      "descriptionLabel":"Expense Description",
      "descriptionPlaceholder":"Enter Description",
      "attachFileText":"Attach File",
      "searchPlaceholder":"Search..."
    }
  }
}

import '@/@iconify/icons-bundle'
import App from '@/App.vue'

import CommonSnackbar from '@/layouts/CommonSnackbar.vue';

import backupProfile from "@/assets/images/newtheme/ws_default_profile_placehoder.svg";

import ability from '@/plugins/casl/ability'
import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import axios from 'axios'
import VueGoogleMaps from '@fawmi/vue-google-maps'
// import VCalendar from 'v-calendar';
import { Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import CKEditor from '@ckeditor/ckeditor5-vue';

import { useResponseIntercepter } from "@/plugins/axios"
useResponseIntercepter();

import { vCustumTooltip } from "@/helper/customDirective.ts"
// import {openDB} from "@/stores/local/indexedDB"
// openDB()
// console.log("IDB",openDB());

loadFonts()
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post.Accept = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true

// Set a timeout of 10 seconds
// axios.defaults.timeout = 10000;



if (localStorage.getItem('userData') !== null) {
  const userData = JSON.parse(localStorage.getItem('userData'))

  axios.defaults.headers.common.UserID = userData.id
  axios.defaults.headers.common.OrgID = userData.org_id
  axios.defaults.headers.common.SDToken = userData.sd_token
  axios.defaults.headers.deviceType = "Web App"
  // axios.defaults.headers.common['SDToken'] = userData.sd_token;
}
if (localStorage.getItem('accessToken') !== null) {
  // console.log("maints", localStorage.getItem('accessToken'));
  let accessToken = localStorage.getItem('accessToken');
  
  accessToken = accessToken.slice(1, -1);
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}


import gtmPlugin from './plugins/gtmPlugin';
// Create vue app
const app = createApp(App)
app.use(gtmPlugin);

app.config.globalProperties.$backupProfile = backupProfile;

app.component('common-snackbar', CommonSnackbar);

app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.component('VueDatePicker', VueDatePicker)
// app.component('CKEditor', CKEditor)
// Use plugins
app.directive('wsTooltip', vCustumTooltip);

app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAvzqQOAX_6TJotal4lB4s6nZ0UdAmQAcY',
    autobindAllEvents: true,
    libraries: "places"
  }
})
app.use(CKEditor);



// Mount vue app.
app.mount('#app')
export const avatarColors = [
    { bgColor: "#E5E1FA", fgColor: "#624DE3" }, // Light purple background with dark purple text
    { bgColor: "#FFEAE1", fgColor: "#F29C78" }, // Light peach background with coral text
    { bgColor: "#FFEAF8", fgColor: "#E34D99" }, // Light pink background with deep pink text
    { bgColor: "#E9FAE1", fgColor: "#55B947" }, // Light green background with green text
    { bgColor: "#E9ECF9", fgColor: "#4461D7" }, // Light blue background with dark blue text
    { bgColor: "#FCE1E4", fgColor: "#FF4A5A" }, // Light red background with red text
    { bgColor: "#FDFBDC", fgColor: "#C6C03B" }, // Light yellow background with mustard text
    { bgColor: "#D1E8FF", fgColor: "#0066CC" }, // Light sky blue background with blue text
    { bgColor: "#FFEBE6", fgColor: "#D84315" }, // Light peach background with orange text
    { bgColor: "#D7FFF1", fgColor: "#009688" }, // Light mint green background with teal text
    { bgColor: "#FFF1D6", fgColor: "#FF9800" }, // Light cream background with orange text
    { bgColor: "#FFE4E6", fgColor: "#D32F2F" }, // Light rose background with dark red text
];


export  function getFirstInitial(name:any) {
    if (!name) return '';
    
    return name.trim()[0]?.toUpperCase();
}
export function getFirstTwoInitials(name:any) {
    if (!name) return '';
    
    const nameParts = name.trim().split(' ');
    const initials = nameParts.slice(0, 2).map((part:any) => part[0]?.toUpperCase());
    
    return initials.join('');
}
  
export function getColorIndex(name:any) {
    const initials = getFirstTwoInitials(name);
    return initials.charCodeAt(0) % avatarColors.length;
}
import axios from 'axios';
import { reject } from 'lodash';
const cancelSource = axios.CancelToken.source();
import { useRoute, useRouter } from 'vue-router';

export const logoutService =  () => {
  const router = useRouter();
  
  // Clear local storage and redirect user to login page
  localStorage.clear();
  router.push("/auth/login");

  // Send logout request to server
  axios.get("v1/logout")
    .then((res) => {
      if (res.data.response.code === 200) {
        // Optionally clear local storage again if needed
        localStorage.clear();
      }
    })
    .catch((error) => {
      console.error("Logout failed:", error);
      // Handle error appropriately, e.g., show a message to the user
    })
    .finally(() => {
      // Reload the page to ensure a fresh state
      location.reload();
    });
}


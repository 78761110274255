<script setup lang="ts">
//dependency
import { ref } from 'vue';

//store 
import { useHelpdeskStore } from '@/stores/helpdesk';

//components
import greetIcon from '@/assets/images/newtheme/hi.svg';

//declarations
const helpdeskStatus = useHelpdeskStore();

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// import { useLoaderStore } from "@/stores/loader";
// const loader = useLoaderStore();
import { injectionKeyIsVerticalNavHovered, useLayouts } from '@layouts'

const { isVerticalNavCollapsed: isCollapsed, isLessThanOverlayNavBreakpoint, isVerticalNavMini, isAppRtl } = useLayouts()

import { config } from '@layouts/config'
// console.log("config", config)
</script>

<template>
    <VDialog v-model="helpdeskStatus.openWelcomeTour" width="380" style="backdrop-filter: blur(5px);" >
        <VCard class="rounded-lg modal">
            <div class="d-flex justify-end">
                <v-btn icon color="#fff" variant="plain"  @click="helpdeskStatus.setTourStatus(false)" >
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <VCardText class="text-center">
                <div class="text-center">
                    <img
                        :src="greetIcon"
                        :height="60"
                        aspect-ratio="1/1"
                        class="ml-auto"
                    />
                </div>
                <div class="mt-5 welcome-text">{{ t('welcomeTour.welcomeText') }}</div>
                <p class="help-text mt-2 mb-1">{{ t('welcomeTour.helpText') }}</p>
            </VCardText>
            <VCardActions class="d-flex justify-center mb-3">
                <VBtn
                class="vDialog-btn"
                    variant="flat"
                    color="#f234f"
                    rounded="pill"
                    style="color:#624de3;"
                    @click="() => { helpdeskStatus.setTourStatus(false); helpdeskStatus.setStartTourStatus(true); isCollapsed = false }"
                >
                    {{ t('welcomeTour.letsStart') }}
                </VBtn>
            </VCardActions>
        </VCard>
    </VDialog>
</template>

<!-- <style lang="scss">
@use "../@core/scss/template/libs/shepherd.scss";
</style> -->

<style scoped>
.welcome-text{
    color: #f3f3f3;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.help-text{
    color: #f3f3f3;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}
.modal{
    background: #624de3!important;
}
</style>
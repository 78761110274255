let userData = useLocalStorage('userData', {});
let DB_NAME = `workstatus_${userData.value.org_id}`;
let dbPromise: Promise<IDBDatabase> | null = null;

// Create a BroadcastChannel for communication
const channel = new BroadcastChannel('database_channel');

// Retrieve the current database version from localStorage, default to 1 if not set
let currentVersion = parseInt(localStorage.getItem('db_version') || '10');

export function openDB(): Promise<IDBDatabase> {
  userData = useLocalStorage('userData', {});
  DB_NAME = `workstatus_${userData.value.org_id}`;
  // if (dbPromise) return dbPromise;
  return openDBWithRetry(DB_NAME, 35);
}

function openDBWithRetry(dbName: string, version: number, retries = 5, delay = 1000): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    let attempt = 0;

    function tryOpen() {
      const request = indexedDB.open(dbName, version);

      request.onsuccess = (event: Event) => resolve((event.target as IDBOpenDBRequest).result);
      request.onerror = (event: Event) => reject((event.target as IDBOpenDBRequest).error);
      request.onblocked = () => {
        if (attempt < retries) {
          console.log('Database open blocked. Retrying...');
          attempt++;
          setTimeout(tryOpen, delay);
        } else {
          reject('Failed to open database after multiple attempts');
        }
      };
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const stores = [
          { name: `project_db_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `dashboard_dropdowns_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `dashboard_cards_today${userData.value.id}`, options: { keyPath: "id" } },
          { name: `dashboard_cards_weekly${userData.value.id}`, options: { keyPath: "id" } },
          { name: `dashboard_cards_monthly${userData.value.id}`, options: { keyPath: "id" } },
          { name: `screenshot_memberDropdown_table_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `screenshot_listing_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `attendance__memberDropdown_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `view_timesheet__memberDropdown_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `project_summary_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `milestone_list_table_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `attendance__stats_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `attendance__listing_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `projectcomments_table_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `milestonecomments_table_${userData.value.id}`, options: { keyPath: "milestone_id", autoIncrement: true } },
          { name: `messageboard_comments_table_${userData.value.id}`, options: { keyPath: "messageboard_id", autoIncrement: true } },
          { name: `taskcomments_table_${userData.value.id}`, options: { keyPath: "task_id", autoIncrement: true } },
          { name: `attendance__date_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `timesheet__member_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `timesheet__task_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `timesheet__date_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `timesheet__memberDropdown_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `project_overview_details_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `project_overview_cards_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `project_list_search_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_summary_card_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_table_listing_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_graph_listing_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_grid_listing_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_search_listing_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_profile_details_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_app_setting_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `members_timezoneListing_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `messageBoard_listing_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `view_timesheet__list_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `view_timesheet_timeline_view_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `apps_memberDropdown_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `apps_listing_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `url_memberDropdown_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `url_listing_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `timesheet_approval__table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `timesheet_approval_memberDropdown_table_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `timeoff_table_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `timeoff_leaveHistory_table_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `member_Header_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `member__task_simpleView_table_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `member__task_simpleView_card_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `task_simple_view_table_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `task_saved_filters_table_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `tasklist_table_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `data_key_table_${userData.value.id}`, options: { keyPath: "id", autoIncrement: true } },
          { name: `me_dashboard_cards_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `advanced_view_group_table_${userData.value.id}`, options: { keyPath: "project_id", autoIncrement: true } },
          { name: `member__task_advanceView_project_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `member__task_advanceView_task_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `teams_table_${userData.value.id}`, options: { keyPath: "id" } },
          { name: `client_list_${userData.value.id}`, options: { keyPath: "id" } },

        ];
        stores.forEach(store => {
          if (!db.objectStoreNames.contains(store.name)) {
            db.createObjectStore(store.name, store.options);
            console.log(`Object store ${store.name} created.`);
          }
        });

      };
    }

    tryOpen();
  });
}


export async function closeAndDeleteDatabase(retryCount = 3): Promise<void> {
  try {
    // Inform other tabs to close the database connection
    channel.postMessage('closeDatabase');
    
    // Wait a moment to ensure other tabs have time to close the connection
    await new Promise(resolve => setTimeout(resolve, 3000));

    const databases = await indexedDB.databases();
    const baseDBName = 'workstatus_';
    const targetDatabases = databases.filter(db => db.name && db.name.startsWith(baseDBName));

    if (targetDatabases.length === 0) {
      console.log(`No databases found with prefix '${baseDBName}'.`);
      return;
    }

    await retryWithBackoff(async () => {
      for (const dbInfo of targetDatabases) {
        console.log(`Attempting to delete database: ${dbInfo.name}`);
        await new Promise<void>((resolve, reject) => {
          const request = indexedDB.deleteDatabase(dbInfo.name!);

          request.onsuccess = () => {
            console.log(`Database ${dbInfo.name} deleted successfully.`);
            resolve();
          };

          request.onerror = (event: Event) => {
            const error = (event.target as IDBRequest).error;
            console.error(`Error deleting database ${dbInfo.name}:`, error);
            reject(error);
          };

          request.onblocked = () => {
            console.warn(`Database deletion blocked for ${dbInfo.name}. It may be open in another tab or process.`);
            reject(new Error('Deletion blocked'));
          };
        });
      }
    }, retryCount, 2000);

  } catch (error) {
    console.error('Error during database close and delete operation:', error);
  } finally {
    // Reset dbPromise to ensure new connections are handled correctly
    dbPromise = null;
  }
}

// Retry logic with exponential backoff
async function retryWithBackoff(
  action: () => Promise<void>,
  retries: number,
  initialDelay: number
): Promise<void> {
  let attempt = 0;
  let delay = initialDelay;

  while (attempt < retries) {
    try {
      await action();
      return;
    } catch (error) {
      attempt++;
      if (attempt >= retries) {
        console.error(`Failed to complete action after ${retries} attempts: ${error}`);
        throw new Error(`Action failed after ${retries} attempts: ${error}`);
      }
      console.warn(`Attempt ${attempt} failed. Retrying in ${delay} ms...`);
      await new Promise(resolve => setTimeout(resolve, delay));
      delay *= 2; // Exponential backoff
    }
  }
}

// Listen for the 'closeDatabase' message from other tabs
channel.onmessage = (message) => {
  if (message.data === 'closeDatabase' && dbPromise) {
    dbPromise.then(db => db.close());
  }
};

// Add the message event listener to handle notifications from other tabs
channel.addEventListener('message', async (event) => {
  if (event.data === 'closeDatabase') {
    try {
      const db = await openDB();
      if (db) {
        db.close();
        console.log('Database connection closed due to logout in another tab.');
      }
    } catch (error) {
      console.error('Error closing database:', error);
    }
  }
});

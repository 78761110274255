<script lang="ts" setup>
import axios from "axios";
import { injectionKeyIsVerticalNavHovered, useLayouts } from '@layouts'
import { VerticalNavGroup, VerticalNavLink, VerticalNavSectionTitle } from '@layouts/components'
import { config } from '@layouts/config'
import type { NavGroup, NavLink, NavSectionTitle, VerticalNavItems } from '@layouts/types'
import type { Component } from 'vue'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { VNodeRenderer } from './VNodeRenderer'
import { onMounted, computed } from "vue";
import { emailValidator, requiredValidator } from "@validators";
import { useRoute } from "vue-router";
import { useShepherd } from "vue-shepherd";
import { offset } from "@floating-ui/dom";
import type { I18nLanguage } from "@layouts/types";
import type { Anchor } from "vuetify/lib/components";
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";

//store
import { referEarnServices } from "@/services/referEarnService.ts"
import { userDetailStore } from "@/stores/userdata";
import { useLoaderStore } from "@/stores/loader";
import { useVerticalMenuStore } from "@/stores/verticalmenu";
import { useHelpdeskStore } from "@/stores/helpdesk";
import { documentationObj } from "@types/sidebar";
import { AWSKeys } from '@/helper/common';
import { useRolelistStore } from "@/stores/rolelist";
import iIcon from '@/assets/images/svg/i-Icon.svg'

//components
import referEarnDefault from "@/assets/icons/custom/referEarnDefault.svg";
import referEarnHover from "@/assets/icons/custom/referEarnHover.svg";
import referEarnWhite from "@/assets/icons/custom/referEarnWhite.svg";

import wstLogo from "@/assets/images/icons/themeupdate/workstatusleftnavcollapsenew.svg";
import wsLogo from "@/assets/images/newtheme/workstausleftnavimagenew.svg";
import recordSvg from "@/assets/images/newtheme/record.svg";
import recordHoverSvg from "@/assets/images/newtheme/record_hover.svg";
import downloadsAppSvg from "@/assets/images/newtheme/downloadsapp.svg";
import downloadsAppSvgHover from "@/assets/images/newtheme/downloadsapp_hover.svg";
import notificationSvg from "@/assets/images/newtheme/notification.svg";
import notificationSvgHover from "@/assets/images/newtheme/notification_hover.svg";
import windowsNormal from "@/assets/images/svg/norma_windows.svg";
import macNormal from "@/assets/images/svg/mac_normal.svg";
import androidNormal from "@/assets/images/svg/android_normal.svg";
import windowsActive from "@/assets/images/svg/window_active.svg";
import macActive from "@/assets/images/svg/mac_active.svg";
import androidActive from "@/assets/images/svg/android_active.svg";
import tourSVG from "@/assets/images/newtheme/quick_tour.svg";
import tourIconActive from "@/assets/images/newtheme/quick_tour_hover.svg";
import orgProfile from "@/assets/images/newtheme/ws_default_profile_placehoder.svg";
import warningGif from "@/assets/images/gif/swalwarningtheme.gif";
import thankyouSuccess from "@/assets/images/pages/thankyousuccess.gif";
import addBtn from "@/assets/images/svg/addBtn.svg";
import setupIcon from "@/assets/images/newtheme/setup.svg";
import donesvg from "@images/icons/themeupdate/done.svg";
import addTimesheet from "@/views/timesheets/addtimesheet.vue";
import arrowLeft from "@/assets/images/newtheme/arrow-left.svg";
import wsCollapsedIcon from "@/assets/images/newtheme/workstatusleftnavcollapse.svg";
import dayIcon from "@/assets/images/newtheme/daySVG.svg";
import nightIcon from "@/assets/images/newtheme/nightSVG.svg";
import collapsedSetup from "@/assets/images/newtheme/setupIcon.svg";
import collapsedSetupDark from "@/assets/images/newtheme/collapsedsetupIcon.svg"
import arrowLeftDark from "@/assets/images/icons/darktheme/arrow_left_dark.svg";
import {useTodosListStore} from '@/stores/todosListTree'
import pinSidebarSvg from "@/assets/images/svg/pin_sidebar.svg"
import unpinSidebarSvg from "@/assets/images/svg/unpin_sidebar.svg" 
import pinSidebarLightSvg from "@/assets/images/svg/pin_sidebarlight.svg" 
import pinMenuTilt from "@/assets/images/svg/pin_menu_tilt.svg"
import wstDarkSvg from "@images/icons/themeupdate/workstausleftnavimagenew.svg";
//dark theme components
import recordSvg1 from "@/assets/images/svg/record.svg";
import downloadsAppSvg1 from "@/assets/images/svg/downloadsapp.svg";
import notificationSvg1 from "@/assets/images/svg/notification.svg";
import tourSVG1 from "@/assets/images/svg/quick_tour_dark.svg";
import { useOrganizationStore } from "@/stores/settings/organizationStore"
const organizationStore = useOrganizationStore()
import { sidemenuService } from "@/services/sidemenu"
import linuxNormal from '@/assets/images/SearchIconBar/linuxNormal.svg';
import linuxActive from '@/assets/images/SearchIconBar/linuxActive.svg';
import { useMilestoneSettingStore } from "@/stores/milestonesetting.ts"
import { useDrawer } from "@/composable/drawerComposable";
const { unionArray, selectDrawers } = useDrawer();
const referEarnServicesData = referEarnServices()
const milestoneSetting = useMilestoneSettingStore()
const todosListStore = useTodosListStore()
const isLinuxHovering = ref<boolean>(false);
const sidemenuServices = sidemenuService()
const cygneetOrgId = JSON.parse(import.meta.env.VITE_CYGNEET_CLIENT_ORG);

const isHoveredIcon = ref(false);
interface Props {
  tag?: string | Component
  navItems: VerticalNavItems
  isOverlayNavActive: boolean
  languages: I18nLanguage[];
  location?: Anchor;
  toggleIsOverlayNavActive: (value: boolean) => void
  
}


const props = withDefaults(defineProps<Props>(), {
  tag: 'aside',
})
const { locale } = useI18n({ useScope: "global" });
const languagesList = ref<any>([
  {
    label: "English",
    i18nLang: "en",
  },
  {
    label: "French",
    i18nLang: "fr",
  },
  {
    label: "Arabic",
    i18nLang: "ar",
  },
]);
const { t } = useI18n()
const menu = ref(false);
const userStore = userDetailStore();
const userRoleStore = userStore.userRole;
const loader = useLoaderStore();
const router = useRouter();
const veritcalMenu = useVerticalMenuStore();
const helpdeskStatus = useHelpdeskStore();
const roleListData = useRolelistStore();
const showDownloadDesktopApps = ref<boolean>(false)
let tour: any = null;
const isWindowsHovering = ref<boolean>(false);
const isMacHovering = ref<boolean>(false);
const isAndroidHovering = ref<boolean>(false);
const isIosHovering = ref<boolean>(false);
const showDialogBox = ref<boolean>(false);
const alertMessage = ref<string>("");
const iconAlert = ref<any>();
const showLogoutDialogBox = ref<boolean>(false);
const orgList = ref<any>();
const lastSelectedOrganisation = ref([]);
const recordIcon = ref<any>(loader.darkTheme ? recordSvg1 : recordSvg);
const tourIcon = ref<any>(loader.darkTheme ? tourSVG1 : tourSVG);
  const notificationIcon = ref<any>(
  loader.darkTheme ? notificationSvg1 : notificationSvg
);
const downloadIcon = ref<any>(
  loader.darkTheme ? downloadsAppSvg1 : downloadsAppSvg
);
const currentTab = ref<string>("0");
const userEmail = ref<string>("");
const userRole = ref<any>(1);
const userRoles = [
  { value: 1, name: "User" },
  { value: 2, name: "Organization Manager" },
  { value: 3, name: "Delivery Head" },
];
const closeAddTimesheetStatus = ref<boolean>(false);
const trackerMode = ref<any>("1");
const theme = ref<string>(loader.darkTheme ? "dark" : "light");
const showYoutubeRecommendation = ref<boolean>(false);
const videoData = ref<any>();
const orgID = ref<any>();
const planDetails = ref<any>();
const customPlanDetails = ref<any>();
const profileImg = ref<any>(
  userStore.userDetails.profile ? userStore.userDetails.profile : orgProfile
);
const inviteOngoing = ref<boolean>(false);
const route = useRoute()
const tokenid = route.params.token;
const orgName = ref<string>('')
const refNav = ref()

const { width: windowWidth } = useWindowSize()

const isHovered = useElementHover(refNav)
const navdark =
  "background: linear-gradient(91.31deg,#4535a7 .86%,#3d2f96 .86%,#513fc8 .87%,#4737ac .88%,#3c2e92 .89%,#503ec5 110.07%,rgba(69,53,167,.447917) 110.08%,rgba(69,53,167,.484941) 110.09%)!important";

// const navdark =
//   "background: rgb(50, 52, 82)!important";

provide(injectionKeyIsVerticalNavHovered, isHovered)

const { isVerticalNavCollapsed: isCollapsed, isLessThanOverlayNavBreakpoint, isVerticalNavMini, isAppRtl } = useLayouts()

const hideTitleAndIcon = isVerticalNavMini(windowWidth, isHovered)

// const resolveNavItemComponent = (item: NavLink | NavSectionTitle | NavGroup) => {
//   if ('heading' in item)
//     return VerticalNavSectionTitle
//   if ('children' in item)
//     return VerticalNavGroup

//   return VerticalNavLink
// }

// hover icons
const referEarnIcon = ref(referEarnDefault);
const referEarnDarkIcon = ref(referEarnWhite);

const changereferEarnIconOnHover = (key: string) => {
  if (key === "over") {
    referEarnIcon.value = referEarnHover;
    isHoveredIcon.value = true;
  } else {
    referEarnIcon.value = referEarnDefault;
    isHoveredIcon.value = false;
  }
};

const changereferEarnDarkIconOnHover = (key: string) => {
  if (key === "over") {
    referEarnDarkIcon.value = referEarnHover;
    isHoveredIcon.value = true
  } else {
    referEarnDarkIcon.value = referEarnWhite;
    isHoveredIcon.value = false;
  }
};

const referLinkData = async() => {
    const res = await referEarnServicesData.referLink();
    if(res.data.response.code == 200){
        loader.referLink = res.data.response.data;
    }
}

const referStoreCheck = () => {
  if(loader.referLink == null || loader.referLink == undefined){
    referLinkData();
  }
}

const deepClone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};
const resolveNavItemComponent = (
  item: NavLink | NavSectionTitle | NavGroup
) => {
  item = deepClone(item);
  if ("heading" in item) return VerticalNavSectionTitle;
  if ("children" in item) return VerticalNavGroup;

  return VerticalNavLink;
};

const areCredsValid = computed(() => {
  return (
    emailValidator(userEmail.value) === true &&
    requiredValidator(userEmail.value) === true &&
    inviteOngoing.value == false
  );
});
const logoutHandler = () => {
  showDialogBox.value = true;
  alertMessage.value = t('pageNotFound.logoutContinue');
  iconAlert.value = warningGif;
};
const logout = () => {
  loader.setGlobalLoader(true);
  showDialogBox.value = false;
  localStorage.clear();
  // localStorage.removeItem('userData');
  // localStorage.removeItem('accessToken');
  router.push("/auth/login").then(() => {
    loader.setGlobalLoader(true);
    // showLogoutDialogBox.value = true;
    loader.setAlertMessage(t('pageNotFound.logoutSuccess'));
    loader.setDialogStatus(true);
  });
  axios.get("v1/logout").then((res) => {
    if (res.data.response.code == 200) {
      loader.setGlobalLoader(false);
      // localStorage.clear();
      alertMessage.value = res.data.response.message;
      iconAlert.value = thankyouSuccess;
      // loader.setDialogStatus(true);
      // loader.setAlertMessage(t('pageNotFound.logoutSuccess'));
      // router.push("/auth/login");
      // localStorage.removeItem('userData');
      // localStorage.removeItem('accessToken');
      localStorage.clear();
    }
  });
  location.reload();
};
const redirectToLink = () => {
    const linkToNavigate = 'https://support.workstatus.io/en/article/how-to-set-the-tracking-mode-1wooikq/';
    window.open(linkToNavigate, '_blank');
};

const fetchOrganizationsList = () => {
  const formatMappings = {
    'YYYY-MM-DD': 'YYYY, MMM, D',
    'MM-DD-YYYY': 'MMM, D, YYYY',
  };
  axios
    .get(`/v1/user/organization/list?email=${userStore.userDetails.email}`)
    .then((res) => {
      if (res.data.response.code == 200) {
        orgList.value = res.data.response.data;

        lastSelectedOrganisation.value = orgList.value.filter(
          (item: any) => item.id == userStore.userDetails.org_id
        );
        localStorage.setItem('orgName', (lastSelectedOrganisation.value[0]?.name));
        userStore.setOrgName(lastSelectedOrganisation.value[0]?.name);
        localStorage.setItem(
          "lastSelectedOrganisation",
          JSON.stringify(lastSelectedOrganisation.value[0])
        );
        localStorage.setItem('orgDateFormat', lastSelectedOrganisation.value[0].dateformat);
        const literalDateFormat = formatMappings[lastSelectedOrganisation.value[0].dateformat] || 'D, MMM, YYYY';
        localStorage.setItem('literalOrgDateFormat', literalDateFormat);
        userStore.setOrgDateFormat(lastSelectedOrganisation.value[0].dateformat);
        AWSKeys();
      }
    });
};
const schedule = () => {
  const link = document.createElement("a");
  link.href ="https://calendly.com/workstatus/setup";
  document.body.appendChild(link);
  link.target = "_blank";
  link.click();
  showYoutubeRecommendation.value = false;
};
const checkVideoStatus = () => {
  axios
    .post("/v1/organization/welcomescreen", {
      org_id: userStore.userDetails.org_id,
    })
    .then((res) => {
      if (res.data.response.code == 200) {
        videoData.value = res.data.response.data;
      }
    });
};
const setupMyself = () => {
  axios
    .post("/v1/organization/wcstatuschange", {
      org_id: userStore.userDetails.org_id,
      skip_status: 0,
    })
    .then((res) => {
      if (res.data.response.code == 200) {
        showYoutubeRecommendation.value = false;
      }
    });
};



const setSelectedLang = (lang: any) => {
  let reload = true
  axios
    .post("/v1/language/set", {
      label: lang.label,
      i18nLang: lang.i18nLang,
      user_id: userStore.userDetails.id,
    })
    .then((res) => {
      if (res.data.response.code == 200) {
        localStorage.setItem("currentLang", (lang.i18nLang));
        if (reload) {
          location.reload();
          reload = false;
        }
      } else {
        localStorage.setItem("currentLang", (lang.i18nLang));
      }
    });
};
const fetchGlobalStatus = () => {
  // axios
  //   .post("v1/setting/milestone/globalstatus", {
  //   })
  //   .then((res) => {
  //     if (res.data.response.code == 200) {
  //       milestoneSetting.showMilestoneTab = res.data.response.data?.modify_value
  //     }

  //   })
  //   .catch((err) => {

  //   });
  axios.post('v3/setting/milestone/globalstatus', {}).then(res => {
        
        // console.log("sdsddsdsd",res)
        todosListStore.taskIdStatusVal = res.data.response.data.taskIdGlobalStatus.modify_value

        // showHideProjectId.value = res.data.response.data.projectIdGlobalFetch.disable_status

        // milestoneStatus.value = res.data.response.data?.milestoneGlobalStatus.modify_value;
        milestoneSetting.showMilestoneTab = res.data.response.data?.milestoneGlobalStatus.modify_value;
    }) 
};
const actionDisabledOnexpirePlan = computed(() => {
  if (roleListData.currentPlan?.execution == 1) {
    return true;
  }
  else {
    return false;
  }
})
/*
  ℹ️ Close overlay side when route is changed
  Close overlay vertical nav when link is clicked
*/
const getApps = () => {
  axios.post('/v3/user/download/button', {
    'user_id': userStore.userDetails.id
  }).then((res) => {
    if(res.data.response.code == 200){
      loader.getApps = res.data.response.data.get_apps
      loader.getDashboard = res.data.response.data.get_all
      loader.getUserTimesheetStatus = res.data.response.data.userWithoutTimesheet
    }
  })
}
onBeforeMount(() => {
    let featuresList = veritcalMenu.featuresList
    // show/hide download desktop apps
    showDownloadDesktopApps.value = featuresList.some(feature =>
        (feature.name == 'project_management' || feature.name == 'user_activity_analysis' || feature.name == 'time_oversight' || feature.name == 'attendance_tracking') && feature.status == 1
    );



  organizationStore.apiGetBannerStatus();
  if(roleListData.roleListIsSet == 0){
        roleListData.fetchOrgPlanDetails()
    }
  // fetchOrgPlanDetails();
  fetchGlobalStatus();
  getApps();
  // tour
  tour = useShepherd({
    useModalOverlay: true,
    confirmCancel: false,
    stepsContainer: document.querySelector(".layout-wrapper"),
    modelContainer: document.querySelector(".layout-wrapper"),

    defaultStepOptions: {
      classes: "custom-class-name-1 custom-class-name-2",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      modalOverlayOpeningPadding: 2,
      modalOverlayOpeningRadius: 5,
    },
    keyboardNavigation: false,
  });
  // tour.step
  tour.addSteps([
    {
      id: "first_step_onboarding",
      arrow: true,
      attachTo: {
        element: ".first_step_tour",
        on: "right",
      },
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 10,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          helpdeskStatus.setSetupToggle(true);
          // setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          // }, 0);
        });
      },
      text: getHtmlContentOfSteps(0),
      buttons: [
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Next",
          action: function (this) {
            helpdeskStatus.setInviteMenuStatus(true);
            // setTimeout(() => {
              tour.next();
            // }, 0);
          },
        },
      ],
      classes: "custom-class-name-1 custom-class-name-2 __tour_common_back",
      highlightClass: "highlight",
      scrollTo: false,
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          checkIfStepDetailAvailable();
          helpdeskStatus.setStartTourStatus(false);
        },
      },
      floatingUIOptions: {
        middleware: [offset(42)],
      },
    },
    {
      /**
       * Enter email to Invite your team
       */
      id: "second_step_onboarding",
      attachTo: {
        element: "._invite_your_team_by_email",
        on: "right",
      },
      arrow: true,
      modalOverlayOpeningPadding: 7,
      modalOverlayOpeningRadius: 10,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          helpdeskStatus.setInviteMenuStatus(true);
          // setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          // }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Next",
          action(this) {
            helpdeskStatus.setInviteMenuStatus(true);
            // setTimeout(() => {
              helpdeskStatus.setIsSelectMenuStatus(true);
              tour.next();
            // }, 0);
          },
        },
      ],
      cancelIcon: {
        enabled: true,
      },
      classes: " __tour_common_back",
      highlightClass: "highlight",
      scrollTo: false,
      text: getHtmlContentOfSteps(1),
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          checkIfStepDetailAvailable();
          helpdeskStatus.setStartTourStatus(false);
        },
      },
      floatingUIOptions: {
        middleware: [offset(30)],
      },
    },
    {
      /**
       * Choose your role
       */
      id: "third_step_onboarding",
      attachTo: {
        element: "._select_role_panelclass",
        on: "right",
      },
      arrow: true,
      // modalOverlayOpeningPadding: 7,
      // modalOverlayOpeningRadius: 10,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          helpdeskStatus.setInviteMenuStatus(true);
          helpdeskStatus.setIsSelectMenuStatus(true);
          // setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          // }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button-secondary __prev_btn_onboard",
          text: "Prev",
          action(this) {
            helpdeskStatus.setIsSelectMenuStatus(false);
            tour.back();
          },
        },
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Next",
          action(this) {
            helpdeskStatus.setIsSelectMenuStatus(false);
            helpdeskStatus.setInviteMenuStatus(true);
            // setTimeout(() => {
              tour.next();
            // }, 0);
          },
        },
      ],
      cancelIcon: {
        enabled: true,
      },
      classes: " __tour_common_back",
      highlightClass: "highlight",
      scrollTo: false,
      text: getHtmlContentOfSteps(2),
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          checkIfStepDetailAvailable();
          helpdeskStatus.setStartTourStatus(false);
        },
      },
      floatingUIOptions: {
        middleware: [offset(30)],
      },
    },
    {
      id: "fourth_step_onboarding",
      attachTo: {
        element: "._visible_stealth_selection",
        on: "right",
      },
      arrow: true,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          helpdeskStatus.setInviteMenuStatus(true);
          // setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          // }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button-secondary __prev_btn_onboard",
          text: "Prev",
          action(this) {
            helpdeskStatus.setInviteMenuStatus(true);
            // setTimeout(() => {
              helpdeskStatus.setIsSelectMenuStatus(true);
              tour.back();
            // }, 0);
          },
        },
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Next",
          action(this) {
            console.log("openand colese")
            helpdeskStatus.setInviteMenuStatus(true);
            // setTimeout(() => {
              tour.next();
            // }, 0);
          },
        },
      ],
      cancelIcon: {
        enabled: true,
      },
      classes: " __tour_common_back",
      highlightClass: "highlight",
      scrollTo: false,
      text: getHtmlContentOfSteps(3),
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          checkIfStepDetailAvailable();
          helpdeskStatus.setStartTourStatus(false);
        },
      },
      floatingUIOptions: {
        middleware: [offset(30)],
      },
    },
    {
      /**
       * Send Invite to your team.
       */
      id: "fifth_step_onboarding",
      attachTo: {
        element: ".__fith_save_btn",
        on: "right",
      },
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 10,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          helpdeskStatus.setInviteMenuStatus(true);
          // setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          // }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button-secondary __prev_btn_onboard",
          text: "Prev",
          action(this) {
            helpdeskStatus.setInviteMenuStatus(true);
            // setTimeout(() => {
              tour.back();
            // }, 0);
          },
        },
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Next",
          action(this) {
            console.log("jkrol")
            helpdeskStatus.setInviteMenuStatus(false);
            tour.next();
          },
        },
      ],
      cancelIcon: {
        enabled: true,
      },
      classes: " __tour_common_back step_invite_send_invite",
      highlightClass: "highlight",
      scrollTo: false,
      text: getHtmlContentOfSteps(4),
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          checkIfStepDetailAvailable();
          helpdeskStatus.setStartTourStatus(false);
        },
      },
      floatingUIOptions: {
        middleware: [offset(135)],
      },
    },
    {
      /**
       * track your time
       */
      id: "sixth_step_onboarding",
      attachTo: {
        element: ".__track_time",
        on: "right",
      },
      arrow: true,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 10,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          // setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          // }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button-secondary __prev_btn_onboard",
          text: "Prev",
          action(this) {
            tour.back();
          },
        },
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Next",
          action(this) {
            console.log("jkrol12")
            helpdeskStatus.setTrackTimeMenuStatus(true);
            // setTimeout(() => {
              tour.next();
            // }, 0);
          },
        },
      ],
      cancelIcon: {
        enabled: true,
      },
      classes: " __tour_common_back",
      highlightClass: "highlight",
      scrollTo: false,
      text: getHtmlContentOfSteps(5),
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          checkIfStepDetailAvailable();
          helpdeskStatus.setStartTourStatus(false);
        },
      },
      floatingUIOptions: {
        middleware: [offset(40)],
      },
    },
    {
      /**
       * Get Ws Download popup
       */
      id: "sevent_step_onboarding",
      attachTo: {
        element: ".menu_select",
        on: "right",
      },
      arrow: true,
      // modalOverlayOpeningPadding: 7,
      modalOverlayOpeningRadius: 10,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          helpdeskStatus.setTrackTimeMenuStatus(true);
          // setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          // }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button-secondary __prev_btn_onboard",
          text: "Prev",
          action(this) {
            helpdeskStatus.setTrackTimeMenuStatus(false);
            tour.back();
          },
        },
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Next",
          action(this) {
            helpdeskStatus.setTrackTimeMenuStatus(false);
            tour.next();
          },
        },
      ],
      cancelIcon: {
        enabled: true,
      },
      classes: " __tour_common_back",
      highlightClass: "highlight",
      scrollTo: false,
      text: getHtmlContentOfSteps(6),
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          checkIfStepDetailAvailable();
        },
      },
      floatingUIOptions: {
        middleware: [offset(20)],
      },
    },
    {
      /**
       *Choose your plan
       */
      id: "eight_step_onboarding",
      attachTo: {
        element: ".choose_plan_li",
        on: "right",
      },
      arrow: true,
      modalOverlayOpeningPadding: 7,
      modalOverlayOpeningRadius: 10,
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          let isStepDetailAvailable:
            | string
            | number
            | NodeJS.Timeout
            | undefined;
          let promise = new Promise((__resolve) => {
            // setTimeout(function () {
              window.scrollTo(0, 0);
              __resolve("");
            // }, 0);
          });
          promise.then(() => {
            // setTimeout(() => {
              clearInterval(isStepDetailAvailable);
              resolve();
            // }, 0);
          });
        });
      },
      buttons: [
        {
          classes: "shepherd-button-secondary __prev_btn_onboard",
          text: "Prev",
          action(this) {
            helpdeskStatus.setTrackTimeMenuStatus(true);
            tour.back();
          },
        },
        {
          classes: "shepherd-button-primary __next_btn_onboard",
          text: "Close",
          action(this) {
            tour.complete();
            helpdeskStatus.setStartTourStatus(false);
          },
        },
      ],
      cancelIcon: {
        enabled: true,
      },
      classes: " __tour_common_back choose_plan_div_steps",
      highlightClass: "highlight",
      scrollTo: false,
      text: getHtmlContentOfSteps(7),
      when: {
        show: () => {
          const currentStep = tour.getCurrentStep();
          const currentStepElement = currentStep?.getElement();

          const mainDiv =
            currentStepElement?.querySelector(".shepherd-content");
          let createMainDiv: any = document.createElement("div");
          createMainDiv.style =
            "width: 100%;height: 26px;border-radius: 0 0 8px 8px; background: #cec6fe;display: flex;align-items: center;padding-left: 1em;position: relative;";

          let progressBar: any = document.createElement("div");
          let percetange =
            ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100;
          progressBar.style = `position: absolute;height: 2px;top: 0;background-color: #FFF;width: ${percetange}%;left: 0;`;

          let stepsSpan: any = document.createElement("span");
          stepsSpan.style =
            " font-family: Inter;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;margin-right: 5px;";
          stepsSpan.innerText = "Step";

          let stepsCountSpan: any = document.createElement("span");
          stepsCountSpan.innerText = `${tour.steps.indexOf(currentStep) + 1 > 9
            ? tour.steps.indexOf(currentStep) + 1
            : "0" + (tour.steps.indexOf(currentStep) + 1)
            }/${tour.steps.length}`;
          stepsCountSpan.style =
            "font-family: Inter;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;";

          createMainDiv.appendChild(progressBar);
          createMainDiv.appendChild(stepsSpan);
          createMainDiv.appendChild(stepsCountSpan);
          mainDiv.insertBefore(createMainDiv, mainDiv.nextSibling);
        },
        hide: () => {
          // checkIfStepDetailAvailable();
          helpdeskStatus.setStartTourStatus(false);
        },
      },
      floatingUIOptions: {
        middleware: [offset(40)],
      },
    },
  ]);
});

const checkIfStepDetailAvailable = () => {
  const selectedOrganisation = userStore.lastSelOrganisation
    ? userStore.lastSelOrganisation.id
    : "";
  const data = {
    organization_id: selectedOrganisation
      ? selectedOrganisation
      : userStore.userDetails
        ? userStore.userDetails.org_id
        : "",
  };

  userStore.setUserSetupSteps(roleListData.userSteps);
  if (!userStore.userSteps) {
    helpdeskStatus.setSetupToggle(false);
    tour.complete();
  }
};

function getHtmlContentOfSteps(step: number): HTMLElement {
  if (step === 0) {
    /**
     * Create a Project
     */
    let div = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Let’s invite your team member";
    span.style = `color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;`;
    div.appendChild(span);
    return div;
  }
  if (step === 1) {
    /**
     * Enter email to Invite your team
     */
    let div = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Enter email to add your team member";
    span.style = `color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;`;
    div.appendChild(span);
    return div;
  }
  if (step === 2) {
    /**
     * Choose your role
     */
    let mainDiv = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Choose role of your team member";
    span.style = `color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;`;

    /**
     * User Role Details
     */

    let userRoleDiv: any = document.createElement("div");
    userRoleDiv.style = "margin-bottom:15px;";
    let userText: any = document.createElement("div");
    userText.innerText = "User:";
    userText.style = ` color: #fff;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top:15px;
                    `;
    let userContent: any = document.createElement("div");
    userContent.innerText =
      "Assign this role to your employees who are working on different projects";
    userContent.style = `
                        margin-top:5px;
                        color: #fff;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        opacity: 0.9;`;
    userRoleDiv.appendChild(userText);
    userRoleDiv.appendChild(userContent);

    /**
     * Project Manager Details
     */
    let projectManagerDiv: any = document.createElement("div");
    projectManagerDiv.style = "margin-bottom:15px;";
    let projectManagerText: any = document.createElement("div");
    projectManagerText.innerText = "Delivery Head:";
    projectManagerText.style = ` color: #fff;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-top:15px;
                        `;
    let projectManagerContent: any = document.createElement("div");
    projectManagerContent.innerText =
      "Assign this role to employees managing projects.";
    projectManagerContent.style = `
                            margin-top:5px;
                            color: #fff;
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                            opacity: 0.9;`;
    projectManagerDiv.appendChild(projectManagerText);
    projectManagerDiv.appendChild(projectManagerContent);

    /**
     * Organization Manager Details
     */
    let organizationMangerDiv: any = document.createElement("div");
    organizationMangerDiv.style = "margin-bottom:15px;";
    let organizationMangerText: any = document.createElement("div");
    organizationMangerText.innerText = "Organization Manager:";
    organizationMangerText.style = ` color: #fff;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top:15px;
            `;
    let organiationManagerContent: any = document.createElement("div");
    organiationManagerContent.innerText =
      "This role is reserved for managing the organization.";
    organiationManagerContent.style = `
                margin-top:5px;
                color: #fff;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                opacity: 0.9;`;
    organizationMangerDiv.appendChild(organizationMangerText);
    organizationMangerDiv.appendChild(organiationManagerContent);

    mainDiv.appendChild(span);
    mainDiv.appendChild(userRoleDiv);
    mainDiv.appendChild(projectManagerDiv);
    mainDiv.appendChild(organizationMangerDiv);
    return mainDiv;
  }
  if (step === 3) {
    /**
     * Choose your mode
     */
    let mainDiv = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Select Tracking Type -";
    span.style = `color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;`;

    /**
     * User Role Details
     */

    let visibleModeDiv: any = document.createElement("div");
    visibleModeDiv.style = "margin-bottom:15px;";
    let userText: any = document.createElement("div");
    userText.innerText = "Visible Mode:";
    userText.style = ` color: #fff;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top:15px;
            `;
    let userContent: any = document.createElement("div");
    userContent.innerText =
      "In this setting, the Workstatus app is visible to users. They can start, stop or take a break as per their preferences";
    userContent.style = `
                margin-top:5px;
                color: #fff;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                opacity: 0.9;`;
    visibleModeDiv.appendChild(userText);
    visibleModeDiv.appendChild(userContent);

    mainDiv.appendChild(span);
    mainDiv.appendChild(visibleModeDiv);
    return mainDiv;
  }
  if (step === 4) {
    /**
     * Send Invite to your team.
     */
    let mainDiv = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Send invite to your team member";
    span.style = `color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;`;

    let sendInviteDiv: any = document.createElement("div");
    sendInviteDiv.style = "margin-bottom:15px;";

    let userContent: any = document.createElement("div");
    userContent.innerText =
      "Your team member needs to accept the invite and sign-up using the same.";
    userContent.style = `
                margin-top:5px;
                color: #fff;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                opacity: 0.9;`;
    sendInviteDiv.appendChild(userContent);

    mainDiv.appendChild(span);
    mainDiv.appendChild(sendInviteDiv);
    return mainDiv;
  }
  if (step === 5) {
    /**
     * track your time
     */
    let div = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Let’s track your time";
    span.style = `color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;`;
    div.appendChild(span);
    return div;
  }
  if (step == 6) {
    /**
     * Get Ws Download popup
     */
    let mainDiv = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Get Workstatus for every device";
    span.style = `color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;`;

    let sendInviteDiv: any = document.createElement("div");
    sendInviteDiv.style = "margin-bottom:15px;";

    let userContent: any = document.createElement("div");
    userContent.innerText =
      "Workstatus app is available for different devices, like desktop devices (Windows, MacOS & Linux) and mobile devices (Android & iOS).";
    userContent.style = `
                margin-top:5px;
                color: #fff;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                opacity: 0.9;`;
    sendInviteDiv.appendChild(userContent);

    mainDiv.appendChild(span);
    mainDiv.appendChild(sendInviteDiv);
    return mainDiv;
  }
  if (step === 7) {
    /**
     *Choose your plan
     */
    let div = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Choose your plan";
    span.style = `color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;`;
    div.appendChild(span);
    return div;
  }
  if (step === 8) {
    /**
     *Choose your plan
     */
    let div = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Click here to upgrade your plan";
    span.style = `color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;`;
    div.appendChild(span);
    return div;
  }
  if (step === 9) {
    /**
     *Choose your plan
     */
    let div = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Choose number of users";
    span.style = `color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;`;
    div.appendChild(span);
    return div;
  }
  if (step === 10) {
    /**
     *Choose your plan
     */
    let div = document.createElement("div");
    let span: any = document.createElement("span");
    span.innerText = "Make payment to start.";
    span.style = `color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;`;
    div.appendChild(span);
    return div;
  }
}

const getShepherdCurrentStep = () => {
  return tour.getCurrentStep();
};
watch(
  () => helpdeskStatus.startTour,
  () => {
    if (helpdeskStatus.startTour) {
      tour.start();
    }
  }
);

watch(
  () => loader.darkTheme,
  () => {
    if (loader.darkTheme) {
      recordIcon.value = recordSvg1;
      notificationIcon.value = notificationSvg1;
      downloadIcon.value = downloadsAppSvg1;
      tourIcon.value = tourSVG1;
    } else {
      recordIcon.value = recordSvg;
      notificationIcon.value = notificationSvg;
      downloadIcon.value = downloadsAppSvg;
      tourIcon.value = tourSVG;
    }
    theme.value = loader.darkTheme ? "dark" : "light";
  }
);
const changeOrganization = (orgId: any) => {
  localStorage.removeItem("orgData");
  loader.showLoader(true);
  axios
    .post("/v1/change_organization", {
      new_org_id: orgId,
    })
    .then((res) => {
      if (res.data.response.code == 200) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.response.data.token}`;
        localStorage.setItem(
          "accessToken",
          JSON.stringify(res.data.response.data.token)
        );
        getOrgData(orgId);
        fetchOrganizationsList();
        userStore.userDetails.org_id = orgId;
      }
    });
};
const getOrgData = async (orgId: Number) => {
  // loader.showLoader(true);
  axios.get(`/v1/organization/edit/${orgId}`, {}).then((res) => {
    if (res.data.response.code == 200) {
      // loader.showLoader(false);
      localStorage.setItem("orgData", JSON.stringify(res.data.response.data));
      userStore.setOrganization(res.data.response.data);
      fetchUserRolesPermission(orgId);

        // if user role is not client(6) then show menu from menu api
      if(userRoleStore.role_id != 6){
        fetchSideMenu()
      }
    }
  });
};
const fetchSideMenu = async () => {
  const res = await sidemenuServices.fetchSideMenu()
  let featuresList = res.data.response.data?.settings ? res.data.response.data?.settings : []
  localStorage.setItem("featuresList", JSON.stringify(featuresList))
  veritcalMenu.featuresList = featuresList;
  selectDrawers(featuresList);
  // console.log('selectedDrawer function called from verticalNav.vue')
  let sidemenu = res.data.response.data.menu;
  if(featuresList.some(feature => feature?.name == "project_management" && feature.status == 1)){
    sidemenu.forEach(menu => {
    if (menu.title == "Task Management") {
      menu.title = "Projects";
    }
  });
  }else{
    sidemenu.forEach(menu => {
      if(menu.title == "Task Management" || menu.title == "Project Management"){
        menu.title = "Tasks";
      }
  });
  }

  if(cygneetOrgId == userStore.userDetails.org_id){
          sidemenu.forEach(menu => {
          if (menu.title == "People") {
            menu?.children?.forEach(subMenu => {
              if(subMenu.title == 'Teams'){
                subMenu.title = 'Unit'
              }
              if(subMenu.title == "Departments"){
                subMenu.title = 'Branch'
              }
            })
          }
        });
        }
  for (const menu of sidemenu) {
          // Check if menu_id is "dashboard"
          if (menu.menu_id == "dashboard") {
            // Iterate through children of "dashboard" menu
            for (const child of menu.children) {
              // Check if child also has menu_id "dashboard"
              if (child.menu_id == "dashboard") {
                // Update its url key
                if(userRoleStore.role_id == 5 || userRoleStore.role_id == 2 || userRoleStore.role_id == 3 || res.data.response.data.otherkeys.is_team_lead == 1 || res.data.response.data.otherkeys.is_department_head == 1 || res.data.response.data?.otherkeys.is_pm == 1){
                  child.url = "/dashboard/all";
                }
              }
            }
          }
        }
  localStorage.setItem("verticalMenu", JSON.stringify(sidemenu));
  veritcalMenu.storeMenu(sidemenu);
}
const fetchUserRolesPermission = async (orgId: Number) => {
  axios
    .post("/v1/user/organization/role/list", {
      organization_id: orgId,
    })
    .then((res) => {
      if (res.data.response.code == 200) {
        let userRole = {
          id: res.data.response.data.role.id,
          name: res.data.response.data.role.name,
          slug: res.data.response.data.role.slug,
          role_id: res.data.response.data.role_id,
          status: res.data.response.data.status,
        };
        let sidemenu = res.data.response.data.role.menu;
        for (const menu of sidemenu) {
          // Check if menu_id is "dashboard"
          if (menu.menu_id == "dashboard") {
            // Iterate through children of "dashboard" menu
            for (const child of menu.children) {
              // Check if child also has menu_id "dashboard"
              if (child.menu_id == "dashboard") {
                // Update its url key
                if(userRole.role_id == 5 || userRole.role_id == 2 || userRole.role_id == 3 || res.data.response.data.is_team_lead == 1 || res.data.response.data.is_department_head == 1 || res.data.response.data?.is_project_manager == 1){
                  child.url = "/dashboard/all";
                }
              }
            }
          }
        }
        localStorage.setItem(
          "userRoles",
          JSON.stringify(res.data.response.data.role.permission)
        );
        localStorage.setItem("userRole", JSON.stringify(userRole));
        
        // if user role is client(6) then show menu from role/list api
        if(userRole.role_id == 6){
          let featuresList = res.data.response?.settings ? res.data.response?.settings : []
          localStorage.setItem("featuresList", JSON.stringify(featuresList))
          veritcalMenu.featuresList = featuresList;

          if(featuresList.some(feature => feature?.name == "project_management" && feature.status == 1)){
            sidemenu.forEach(menu => {
            if (menu.title == "Task Management") {
              menu.title = "Projects";
            }
          });
          }else{
            sidemenu.forEach(menu => {
              if(menu.title == "Task Management" || menu.title == "Project Management"){
                menu.title = "Tasks";
              }
          });
          }

          if(cygneetOrgId == userStore.userDetails.org_id){
          sidemenu.forEach(menu => {
          if (menu.title == "People") {
            menu?.children?.forEach(subMenu => {
              if(subMenu.title == 'Teams'){
                subMenu.title = 'Unit'
              }
              if(subMenu.title == "Departments"){
                subMenu.title = 'Branch'
              }
            })
          }
        });
        }
          localStorage.setItem("verticalMenu", JSON.stringify(sidemenu));
          veritcalMenu.storeMenu(sidemenu);
        }

        userStore.setUserPermissions(res.data.response.data.role.permission);
        userStore.setUserOrgRole(userRole);
        localStorage.setItem(
          "setupSteps",
          JSON.stringify(res.data.response.steps)
        );
        userStore.setUserSetupSteps(res.data.response.steps);
        window.location.reload();
      }
    });
};

const fetchOrgPlanDetails = () => {
  orgID.value = userStore.userDetails.org_id;
  axios
    .post("v1/user/organization/role/list", {
      organization_id: orgID.value,
    })
    .then((res) => {
      if (res.data.response.code == 200) {
        localStorage.setItem(
          "currentOrgPlanName",
          res.data.response.data.plan_name
        );
        planDetails.value = res.data.response.data;
        roleListData.planData = res.data.response.data.expiry_alert;
        roleListData.currentPlan = res.data.response.data
        roleListData.roleListIsSet = 1;
        if (res.data.response.data?.payment) {
          customPlanDetails.value = res.data.response.data.payment;

          if (!tokenid) {
            let accountData = localStorage.getItem('accountData')
            let user_role_id = (JSON.parse(accountData)).role_id;
            
            // here, the commented code redirect the owner to payment page after organization creation, if plan selected is from marketing plan.
            // if (roleListData.currentPlan.payment?.plan_id && (user_role_id == 2 || user_role_id == 5)) {
            //   router.push({ path: "/dashboard/payment-section" });
            // }else{
            //   logout()
            // }
          } else {
            console.log("vertical nav else console");
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

watch(() => route.name, () => {
  props.toggleIsOverlayNavActive(false)
})
watch(
  () => userStore.userDetails.profile,
  () => {
    profileImg.value = userStore.userDetails.profile
      ? userStore.userDetails.profile
      : orgProfile;
  }
);
const isVerticalNavScrolled = ref(false)
const updateIsVerticalNavScrolled = (val: boolean) => isVerticalNavScrolled.value = val

const handleNavScroll = (evt: Event) => {
  isVerticalNavScrolled.value = (evt.target as HTMLElement).scrollTop > 0
}
const appDownload = (os: string) => {
  if (os === "windows") {
    const specialOrgIds = ["2950", "3007", "5049"];
    const url = specialOrgIds.includes(userStore.userDetails.org_id)
    ? "https://workstatusstatic.s3.amazonaws.com/workstatus2.5.36.exe"
    : "https://app.workstatus.io/downloads/windows/workstatus.exe";
    // var url = ""
    // if(userStore.userDetails.org_id == "2950" || userStore.userDetails.org_id == "3007"){
    //   url = "https://workstatusstatic.s3.amazonaws.com/workstatus2.5.36.exe";
    // }else{
    //   url = "https://app.workstatus.io/downloads/windows/workstatus.exe";
    // }
    window.open(url, "_blank");
  } else if (os === "mac") {
    const url = "https://app.workstatus.io/downloads/mac/workstatus.dmg";
    window.open(url, "_blank");
  }
  else if (os === "linux") {
    const url = "https://support.workstatus.io/en/article/install-and-sign-in-into-linux-desktop-124d7k7/?bust=1688729166943";
    window.open(url, "_blank");
  }
  else if (os === "android") {
    const url =
      "https://play.google.com/store/apps/details?id=com.vinove.workstatus";
    window.open(url, "_blank");
  } else {
    const url = "https://apps.apple.com/us/app/Workstatus-tm/id1570416295";
    window.open(url, "_blank");
  }
};

const inviteTeamMembers = () => {
  inviteOngoing.value = true;
  if (trackerMode.value == 1 || trackerMode.value == "1") {
    axios
      .post("/v1/member/invite", {
        email: userEmail.value,
        organization_id: userStore.userDetails.org_id,
        projectIds: [],
        role: userRole.value,
      })
      .then((res) => {
        if (res.data.response.code == 200) {
          userEmail.value = "";
          userRole.value = "";
          showLogoutDialogBox.value = true;
          inviteOngoing.value = false;
          iconAlert.value = thankyouSuccess;
          alertMessage.value = res.data.response.message;
        } else {
          if (res.data.response.code == 401) {
            userEmail.value = "";
            userRole.value = "";
            showLogoutDialogBox.value = true;
            iconAlert.value = warningGif;
            alertMessage.value = res.data.response.message;
            inviteOngoing.value = false;
          }
        }
      });
  } else {
    axios
      .post("/v1/user_invitation/stealth_mode", {
        email: userEmail.value,
        organization_id: userStore.userDetails.org_id,
        projectIds: [],
        role: userRole.value,
      })
      .then((res) => {
        if (res.data.response.code == 200) {
          userEmail.value = "";
          userRole.value = {};
          showLogoutDialogBox.value = true;
          iconAlert.value = thankyouSuccess;
          inviteOngoing.value = false;
          alertMessage.value = res.data.response.message;
        } else {
          userEmail.value = "";
          userRole.value = "";
          showLogoutDialogBox.value = true;
          inviteOngoing.value = false;
          iconAlert.value = warningGif;
          alertMessage.value = res.data.response.message;
        }
      });
  }
};

const closeAddTimesheetDialogFn = () => {
  closeAddTimesheetStatus.value = false;
};
const changeTheme = () => {
  theme.value = theme.value == "light" ? "dark" : "light";
  loader.toggleTheme(theme.value == "dark");
  axios
    .post("/v1/user/userTheme", {
      mode: theme.value,
    })
    .then((res) => {
      if (res.data.response.code == 200) {
        localStorage.setItem(
          "applicationTheme",
          JSON.stringify(res.data.response.mode == "dark")
        );
      }
    });
};



// Flag to manage outside clicks
let isClickInside = true;

// Function to handle outside click
const handleClickOutside = () => {
     helpdeskStatus.inviteMenu = false;
    tour.complete();
}

const showTiltIcon = ref<boolean>(false)
</script>

<template>
  <Component v-if="!helpdeskStatus.isSetup "
    :is="props.tag"
    ref="refNav"
    class="layout-vertical-nav"
    style="margin-top: 35px; max-width: 240px;"
    :class="[
      {
        'overlay-nav': isLessThanOverlayNavBreakpoint(windowWidth),
        'hovered': isHovered,
        'visible': isOverlayNavActive,
        'scrolled': isVerticalNavScrolled,
      },
    ]"
    :style="[
      loader.darkTheme ? navdark : { background: 'rgb(250, 252, 255) !important' },
      { marginTop: loader.loader ? '0px' : '35px' }
    ]"
     :id="loader.darkTheme ? 'darkTheme__class_for_vue' : ''"
  >
    <!-- 👉 Header -->
    <div class="nav-header" :style="isAppRtl ? 'padding: 0px 18px 0px 0px;' : 'padding: 12px 0 0px 20px'">
      <slot name="nav-header">
        <RouterLink v-if="!loader.darkTheme" to="/" class="app-logo d-flex align-center gap-x-3 app-title-wrapper" style="margin-right: 0px;
    margin-left: 2px;">
          <div name="vertical-nav-app-title">
            <img v-if="helpdeskStatus.isSetup && !isCollapsed && !hideTitleAndIcon"
              :src="'@images/icons/themeupdate/workstausleftnavimagenew.svg'" style="height: 20px" alt="" />
            <img v-else :src="isCollapsed &&  hideTitleAndIcon? wsCollapsedIcon : wsLogo" :style="isCollapsed &&  hideTitleAndIcon ? 'height:28px' : ''" alt="" />
          </div>
        </RouterLink>

        <RouterLink v-else to="/" class="app-logo d-flex align-center gap-x-3 app-title-wrapper">
         <img :src="isCollapsed &&  hideTitleAndIcon ? wstLogo : wstDarkSvg" alt="" class="mb-1"/>
          <div name="vertical-nav-app-title" v-if="helpdeskStatus.isSetup && !isCollapsed && !hideTitleAndIcon">
            <h1 v-show="helpdeskStatus.isSetup && !isCollapsed && !hideTitleAndIcon" class="app-title font-weight-bold leading-normal text-xl">
              <img src="@images/icons/themeupdate/workstausleftnavimagenew.svg" style="height: 20px" alt="" />
            </h1>
          </div>
          
        </RouterLink>
        <!-- 👉 Vertical nav actions -->
        <!-- Show toggle collapsible in >md and close button in <md -->
        <template v-if="!isLessThanOverlayNavBreakpoint(windowWidth)">
          <!-- this image show when verticalNavUnPinned -->
          <img :src="loader.darkTheme ? unpinSidebarSvg : unpinSidebarSvg" v-show="isCollapsed && !hideTitleAndIcon"
            class="header-action mr-6 mb-1 unpin-sidebar-icon" v-bind="config.icons.verticalNavUnPinned" @click="
              isCollapsed = !isCollapsed;
            loader.toggleCollapse(isCollapsed);
            " alt="" />
            <img 
              :src="showTiltIcon ? pinMenuTilt : (loader.darkTheme ? pinSidebarLightSvg : pinSidebarSvg)"
              v-show="!isCollapsed && !hideTitleAndIcon"
              class="header-action mr-7 mb-1"
              v-bind="config.icons.verticalNavPinned"
              @click="isCollapsed = !isCollapsed;
                      loader.toggleCollapse(isCollapsed);"
              @mouseover="showTiltIcon = true"
              @mouseleave="showTiltIcon = false"
              alt=""
            />
        </template>
        <template v-else>
          <Component
            :is="config.app.iconRenderer || 'div'"
            class="header-action"
            v-bind="config.icons.close"
            @click="toggleIsOverlayNavActive(false)"
            style="    margin-right: 7px;
    margin-bottom: 7px;"
          />
        </template>
      </slot>
    </div>
    <slot name="before-nav-items">
      <div class="vertical-nav-items-shadow" />
    </slot>
    <slot
      name="nav-items"
      :update-is-vertical-nav-scrolled="updateIsVerticalNavScrolled"
    >
      <PerfectScrollbar
        :key="isAppRtl"
        tag="ul"
        class="nav-items"
        :options="{ wheelPropagation: false }"
        @ps-scroll-y="handleNavScroll"
      >
        <Component v-if="!loader.darkTheme" v-for="(item, index) in navItems" :is="resolveNavItemComponent(item)"
          :key="index" :item="item" />
        <Component v-if="loader.darkTheme" v-for="(item, index) in navItems" :is="resolveNavItemComponent(item)"
          :key="index" :item="item" />
      </PerfectScrollbar>
    </slot>

    <!-- refer & earn code -->
    <v-menu location="right" no-click-animation v-if="userStore.userDetails.org_id == 128">
      <template v-slot:activator="{ props }">
        <VDivider  v-if="!loader.darkTheme"  style="width: 100%; height: 1px; position:absolute; bottom:69px;" />
        <div @click="referStoreCheck();" class="referEarnCol cursor-pointer" v-if="!loader.darkTheme" v-bind="props"
          @mouseover="changereferEarnIconOnHover('over')"
          @mouseleave="changereferEarnIconOnHover('leave')"
        >
        <div class="lightCol">
          <div class="referEarnListBox">
            <img class="ml-1" :src="referEarnIcon"/>
            <span class="referEarnColTitle"> Refer & Earn </span>
          </div>
          <v-icon class="mb-1" :style="{ color: isHoveredIcon ? '#00000073' : '#00000073' }">
            mdi-chevron-right
          </v-icon>
        </div>
        </div>
        <VDivider  v-if="loader.darkTheme"  style="background:white; width: 100%; height: 1px; position:absolute; bottom:69px;" />
        <div class="referEarnDarkCol cursor-pointer" v-if="loader.darkTheme" v-bind="props"
        @mouseover="changereferEarnDarkIconOnHover('over')"
        @mouseleave="changereferEarnDarkIconOnHover('leave')"
        >
          <div class="darkCol">
            <div class="referEarnListBox">
              <img class="ml-1" :src="loader.darkTheme ? referEarnDarkIcon : referEarnIcon"/>
              <span :class="loader.darkTheme ? 'referEarnDarkColTitle' : 'referEarnColTitle'"> Refer & Earn </span>
            </div>
            <v-icon  :style="{ color: isHoveredIcon ? 'rgb(97 85 174)' : 'rgb(237 237 237)' }">
              mdi-chevron-right
            </v-icon>
          </div>
        </div>
      </template>
      <v-card
          class="ml-4"
          min-width="175"
        >
        <RouterLink to="/refer-earn">
            <div class="cursor-pointer">
              <div class="d-flex align-center nav_active_class nav_helpvideoTask">
                <span>
                    Refer-Earn
                  </span>
              </div>
            </div>
        </RouterLink>
        <VDivider style="width: 100%; height: 1px" />
        <RouterLink to="/refer-earn/invites">
            <div class="cursor-pointer">
              <div class="d-flex align-center nav_active_class nav_helpvideoTask">
                <span>
                    Invites
                  </span>
              </div>
            </div>
        </RouterLink>
        <VDivider style="width: 100%; height: 1px" />
        <RouterLink to="/refer-earn/invite-mail">
            <div class="cursor-pointer">
              <div class="d-flex align-center nav_active_class nav_helpvideoTask">
                <span>
                  Invite Email
                </span>
              </div>
            </div>
        </RouterLink>
        <VDivider style="width: 100%; height: 1px" />
        <RouterLink to="/refer-earn/referrals">
            <div class="cursor-pointer">
              <div class="d-flex align-center nav_active_class nav_helpvideoTask">
                <span>
                  Referrals
                </span>
              </div>
            </div>
        </RouterLink>
        </v-card>
    </v-menu>
    <!-- refer & earn code end -->
    
  </Component>
<!-- Setup Menu -->
<Component v-if="helpdeskStatus.isSetup &&
    userStore?.userSteps &&
    Object.keys(userStore.userSteps).length
    " :is="props.tag" ref="refNav" class="layout-vertical-nav" :class="[
    {
      'overlay-nav': isLessThanOverlayNavBreakpoint(windowWidth),
      'visible': isOverlayNavActive,
      'scrolled': isVerticalNavScrolled,
      'hovered': isHovered
    },
  ]" :style="loader.darkTheme ? 'background: #05264D !important' : 'background: #624DE3 !important'">
    <!-- 👉 Header -->
    <div class="nav-header">
      <slot name="nav-header">
        <RouterLink v-if="!loader.darkTheme" to="/" class="app-logo d-flex align-center gap-x-3 app-title-wrapper">
          <div name="vertical-nav-app-title">
            <img v-if="helpdeskStatus.isSetup && !isCollapsed && !hideTitleAndIcon"
              :src="wstDarkSvg" style="height: 23px" />
            <img v-else :src="isCollapsed &&  hideTitleAndIcon? wsCollapsedIcon : wstDarkSvg" :style="isCollapsed &&  hideTitleAndIcon ? 'height:23px' : ''" />
          </div>
        </RouterLink>

        <RouterLink v-else to="/" class="app-logo d-flex align-center gap-x-3 app-title-wrapper">
          <img :src="isCollapsed &&  hideTitleAndIcon ? wstLogo : wstDarkSvg"  />
        </RouterLink>


        <template v-if="!isLessThanOverlayNavBreakpoint(windowWidth)">
          <!-- hiding for now -->
        </template>
        <template v-else>
          <Component :is="config.app.iconRenderer || 'div'" class="header-action" v-bind="config.icons.close"
            @click="toggleIsOverlayNavActive(false)" />
        </template>
      </slot>
    </div>
    <slot name="before-nav-items">
      <div class="vertical-nav-items-shadow" />
    </slot>
    <slot name="nav-items" :update-is-vertical-nav-scrolled="updateIsVerticalNavScrolled">
      <PerfectScrollbar :key="isAppRtl" tag="ul" class="nav-items" :options="{ wheelPropagation: false }"
        @ps-scroll-y="handleNavScroll">
        <div :class="loader.darkTheme ? 'main-menu-dark' : 'main-menu'" :style="isCollapsed
          ? 'padding-left: 23px; padding-right: 10px; padding-top: 9px; padding-bottom: 12px;'
          : ''
          " @click="helpdeskStatus.setSetupToggle(false); tour.complete();">
          <span class="mr-2"> &lt; </span> 
          <span v-if="(isCollapsed || isCollapsed == false) && hideTitleAndIcon == false" style="font-size: 13px"> {{ t('VerticalNav.mainMenu') }}</span>
        </div>
        <div v-if="(isCollapsed || isCollapsed == false) && hideTitleAndIcon == false" class="w-100 h-100">
          <div class="setup-div">
            <p class="setup-text">{{ t('VerticalNav.gettingStartedTxt') }}</p>
            <div class="d-flex">
              <v-progress-linear :model-value="userStore.userSteps?.percentange" color="blue" height="10" bg-color=""
                rounded />
              <span class="progress-text">{{ userStore.userSteps?.percentange }}%</span>
            </div>
          </div>
          <div class="align-center pt-4" :class="isAppRtl ? 'mr-5' : 'ml-5'">
            <div class="d-flex align-center cursor-pointer">
              <img class="me-4" :src="donesvg" />
              <div class="basicinformation" style="font-size: 14px">
                {{ userStore.userSteps?.list[0].name }}
              </div>
            </div>
            <div class="mt-1" :class="isAppRtl ? 'vertical-line-arabic mr-3' : ' vertical-line ml-3'
              "></div>
            <div class="d-flex align-center cursor-pointer first_step_tour" id="first_step_onboarding">
              <img v-if="userStore.userSteps?.list[1].value" class="me-4" :src="donesvg" />
              <div v-else class="whitecirclewithn me-4" @click="() => {
                    
                    helpdeskStatus.setInviteMenuStatus(true); helpdeskStatus.setTrackTimeMenuStatus(false);
                  }
                    ">2</div>
                    <!-- Open menu -->
              <v-menu  v-model="helpdeskStatus.inviteMenu" :close-on-content-click="false" location="bottom"
                location-strategy="connected" class="menu_select"
                close-on-click="true"
                :class="isAppRtl ? 'inviteMemberSVGArabic' : 'inviteMemberSVG'" persistent no-click-animation >
                <template v-slot:activator="{ props }">
                  <div class="basicinformation" style="font-size: 14px" @click="() => {
                    
                    helpdeskStatus.setInviteMenuStatus(true); helpdeskStatus.setTrackTimeMenuStatus(false);
                  }
                    ">
                    {{ userStore.userSteps?.list[1].name }}
                  </div>
                </template>
                <v-card max-width="350" min-height="370" style="border-radius: 20px">
                  <p class="skip-text mt-2 mb-0" :class="isAppRtl ? 'ml-2' : 'mr-2'"
                    @click="tour.complete();
                    helpdeskStatus.setInviteMenuStatus(false)">
                    {{ t('VerticalNav.skipTxt') }}
                  </p>
                  <VCardText class="pt-2">
                    <div class="download-apps">{{ t('VerticalNav.addTeamTxt') }}</div>
                    <p class="choose-app" style="font-size: 12px">
                      {{ t('VerticalNav.inviteText') }}
                    </p>
                    <VTextField v-model="userEmail" label="Email Address" variant="underlined"
                      class="mt-3 _invite_your_team_by_email" :rules="[requiredValidator, emailValidator]" />
                    <div class="w-100">
                      <VSelect v-model="userRole" label="Role" variant="underlined" item-title="name" item-value="value"
                        :items="userRoles"  id="third_step_onboarding"
                        class="mt-5 _select_role_panelclass" />
                    </div>
                    <v-radio-group v-model="trackerMode" inline class="w-100 _visible_stealth_selection pt-6"
                      id="fourth_step_onboarding" style="padding: 10px 0px">
                      <v-radio label="Visible" value="1" style="color: #777b93; margin-right: 40px"></v-radio>
                      <v-radio
                        label="Stealth"
                        value="2"
                        style="color: #777b93"
                      ></v-radio>
                      <div class="betaLabel" @click="redirectToLink()">
                        <div class="betaText">BETA</div>
                        <div><img :src="iIcon" />
                            <VTooltip width="250" activator="parent" location="bottom">{{ t('membersTab.tooltipData') }}</VTooltip>
                        </div>
                    </div>
                    </v-radio-group>
                    <div class="text-center">
                      <VBtn rounded :color="!areCredsValid ? 'secondary' : 'primary'" :disabled="!areCredsValid"
                        @click="inviteTeamMembers" id="fifth_step_onboarding" class="__fith_save_btn">{{
                          t('settings.general.EditOrg.Save') }}</VBtn>
                    </div>
                  </VCardText>
                </v-card>
              </v-menu>
            </div>
            <div class="mt-1" :class="isAppRtl ? 'vertical-line-arabic mr-3' : ' vertical-line ml-3'
              "></div>
            <div class="d-flex align-center cursor-pointer __track_time" id="sixth_step_onboarding">
              <img v-if="userStore.userSteps?.list[2].value" class="me-4" :src="donesvg" />
              <div v-else class="whitecirclewithn me-4" @click="() => {
                 
                 helpdeskStatus.setTrackTimeMenuStatus(true); helpdeskStatus.setInviteMenuStatus(false)
               }
                 ">3</div>
              <v-menu  v-model="helpdeskStatus.trackTimeMenu" :close-on-content-click="false" location="bottom"
                location-strategy="connected" :class="isAppRtl ? 'trackTimeSVGArabic' : 'trackTimeSVG'" persistent no-click-animation >
                <template v-slot:activator="{ props }">
                  <div class="basicinformation" style="font-size: 14px" @click="() => {
                 
                    helpdeskStatus.setTrackTimeMenuStatus(true); helpdeskStatus.setInviteMenuStatus(false)
                  }
                    ">
                    {{ userStore.userSteps?.list[2].name }}
                  </div>
                </template>
                <v-card max-width="420" min-height="320" style="border-radius: 20px" class="menu_select"
                  id="sevent_step_onboarding">
                  <p class="skip-text mt-2 mb-0" :class="isAppRtl ? 'ml-2' : 'mr-2'"
                    @click="tour.complete(); helpdeskStatus.setTrackTimeMenuStatus(false)">
                    {{ t('VerticalNav.skipTxt') }}
                  </p>
                  <VCardText class="pt-2">
                    <div class="download-apps">{{ t('') }}</div>
                    <VTabs v-model="currentTab">
                      <VTab> {{ t('VerticalNav.application') }} </VTab>
                      <VTab> {{ t('VerticalNav.addTimeManually') }} </VTab>
                    </VTabs>
                    <VDivider class="mr-2" />
                    <VWindow class="mt-5" v-model="currentTab">
                      <VWindowItem>
                        <div class="relative align-center">
                          <p class="choose-app" style="font-size: 12px">
                            {{ t('VerticalNav.chooseAppsTxt') }}
                          </p>
                          <div class="d-flex justify-space-between">

                            <!-- hide this for field force analysis -->
                            <div v-if="showDownloadDesktopApps">
                              <div class="desktop-apps" style="min-width:179px">{{ t('VerticalNav.desktopApps') }}</div>
                              <div class="d-flex justify-space-around mt-6">
                                <div class="window_icon" @click="appDownload('windows')"
                                  @mouseenter="isWindowsHovering = true" @mouseleave="isWindowsHovering = false">
                                  <div class="image">
                                    <img :src="isWindowsHovering
                                      ? windowsActive
                                      : windowsNormal
                                      " />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.Windows') }}</div>
                                </div>
                                <div class="mac_download_icon" @click="appDownload('mac')"
                                  @mouseenter="isMacHovering = true" @mouseleave="isMacHovering = false">
                                  <div class="image">
                                    <img :src="isMacHovering ? macActive : macNormal
                                      " />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.MacOS') }}</div>
                                </div>

                                <div class="nav_linux_download_icon" @click="appDownload('linux')"
                                @mouseenter="isLinuxHovering = true" @mouseleave="isLinuxHovering = false">
                                <div class="image">

                                  <img :src="isLinuxHovering ? linuxActive : linuxNormal" />
                                </div>
                                <div class="text">
                                  <!-- Linux -->
                                  {{ t('dashboardAnalytics.Linux') }}
                                </div>
                              </div>
                              </div>
                            </div>

                            <div>
                              <div class="mobile-apps">{{ t('VerticalNav.mobileApps') }}</div>
                              <div class="d-flex justify-space-around mt-6">
                                <div class="android_icon" @click="appDownload('android')"
                                  @mouseenter="isAndroidHovering = true" @mouseleave="isAndroidHovering = false">
                                  <div class="image">
                                    <img :src="isAndroidHovering
                                      ? androidActive
                                      : androidNormal
                                      " />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.Android') }}</div>
                                </div>
                                <div class="mac_download_icon" @click="appDownload('ios')"
                                  @mouseenter="isIosHovering = true" @mouseleave="isIosHovering = false">
                                  <div class="image">
                                    <img :src="isIosHovering ? macActive : macNormal
                                      " />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.iOS') }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </VWindowItem>
                      <VWindowItem>
                        <p style="font-size: 12px">
                          {{ t('VerticalNav.addEditTime') }}
                        </p>
                        <div class="text-center">
                          <VBtn rounded @click="closeAddTimesheetStatus = true">
                            {{ t('VerticalNav.addTimeManually') }}
                          </VBtn>
                        </div>
                      </VWindowItem>
                    </VWindow>
                  </VCardText>
                </v-card>
              </v-menu>
            </div>
            <div class="mt-1" :class="isAppRtl ? 'vertical-line-arabic mr-3' : ' vertical-line ml-3'
              "></div>
            <div class="d-flex align-center cursor-pointer choose_plan_li" id="eight_step_onboarding">
              <img v-if="userStore.userSteps?.list[3].value" class="me-4" :src="donesvg" />
              <div v-else class="whitecirclewithn me-4" @click="() => {
            
            helpdeskStatus.setTrackTimeMenuStatus(false);
            helpdeskStatus.setInviteMenuStatus(false);
            router.push('/settings/subscription-overview');
          }
            ">4</div>
              <div class="basicinformation" style="font-size: 14px" @click="() => {
            helpdeskStatus.setTrackTimeMenuStatus(false);
            helpdeskStatus.setInviteMenuStatus(false);
                router.push('/settings/subscription-overview');
              }
                ">
                {{ userStore.userSteps?.list[3].name }}
              </div>
            </div>
          </div>
        </div>
        <!-- else icons -->
        <div v-else class="w-100 h-100">
          <div class="align-center pt-4" :class="isAppRtl ? 'mr-5' : 'ml-5'">
            <div class="d-flex align-center cursor-pointer">
              <img class="me-4" :src="donesvg" />
            </div>
            <div class="mt-1" :class="isAppRtl ? 'vertical-line-arabic mr-3' : ' vertical-line ml-3'
              "></div>
            <div class="d-flex align-center cursor-pointer first_step_tour" id="first_step_onboarding">
              <img v-if="userStore.userSteps?.list[1].value" class="me-4" :src="donesvg" />
              <div v-else class="whitecirclewithn me-4" @click="() => {
                    
                    helpdeskStatus.setInviteMenuStatus(true);
                  }
                    ">2</div>
              <v-menu @click:outside="handleClickOutside" v-model="helpdeskStatus.inviteMenu" :close-on-content-click="false" location="bottom"
                location-strategy="connected" class="menu_select"
                :class="isAppRtl ? 'inviteMemberSVGArabic' : 'inviteMemberCloseSVG'">
                <v-card max-width="350" min-height="370" style="border-radius: 20px">
                  <p class="skip-text mt-2 mb-0" :class="isAppRtl ? 'ml-2' : 'mr-2'"
                    @click="tour.complete(); helpdeskStatus.setInviteMenuStatus(false)">
                    {{ t('VerticalNav.skipTxt') }}
                  </p>
                  <VCardText class="pt-2">
                    <div class="download-apps">{{ t('VerticalNav.addTeamTxt') }}</div>
                    <p class="choose-app" style="font-size: 12px">
                      {{ t('VerticalNav.inviteText') }}
                    </p>
                    <VTextField v-model="userEmail" label="Email Address" variant="underlined"
                      class="mt-3 _invite_your_team_by_email" :rules="[requiredValidator, emailValidator]" />
                    <div class="w-100">
                      <VSelect v-model="userRole" label="Role" variant="underlined" item-title="name" item-value="value"
                        :items="userRoles" :menu="helpdeskStatus.isSelectMenu" id="third_step_onboarding"
                        class="mt-5 _select_role_panelclass" />
                    </div>
                    <v-radio-group v-model="trackerMode" inline class="w-100 _visible_stealth_selection pt-6"
                      id="fourth_step_onboarding" style="padding: 10px 0px">
                      <v-radio label="Visible" value="1" style="color: #777b93; margin-right: 40px"></v-radio>
                      <v-radio
                        label="Stealth"
                        value="2"
                        style="color: #777b93"
                      ></v-radio>
                      <div class="betaLabel" @click="redirectToLink()">
                        <div class="betaText">BETA</div>
                        <div><img :src="iIcon" alt=""/>
                            <VTooltip width="250" activator="parent" location="bottom">{{ t('membersTab.tooltipData') }}</VTooltip>
                        </div>
                    </div>
                    </v-radio-group>
                    <div class="text-center">
                      <VBtn rounded :color="!areCredsValid ? 'secondary' : 'primary'" :disabled="!areCredsValid"
                        @click="inviteTeamMembers" id="fifth_step_onboarding" class="__fith_save_btn">{{
                          t('settings.general.EditOrg.Save') }}</VBtn>
                    </div>
                  </VCardText>
                </v-card>
              </v-menu>
            </div>
            <div class="mt-1" :class="isAppRtl ? 'vertical-line-arabic mr-3' : ' vertical-line ml-3'
              "></div>
            <div class="d-flex align-center cursor-pointer __track_time" id="sixth_step_onboarding">
              <img v-if="userStore.userSteps?.list[2].value" class="me-4" :src="donesvg" alt="" />
              <div v-else class="whitecirclewithn me-4" @click="() => {
                   
                    helpdeskStatus.setTrackTimeMenuStatus(true);
                  }
                    ">3</div>
              <v-menu @click:outside="handleClickOutside" v-model="helpdeskStatus.trackTimeMenu" :close-on-content-click="false" location="bottom"
                location-strategy="connected" :class="isAppRtl ? 'trackTimeSVGArabic' : 'trackTimeCloseSVG'">
                <v-card max-width="356" min-height="370" style="border-radius: 20px" class="menu_select"
                  id="sevent_step_onboarding">
                  <p class="skip-text mt-2 mb-0" :class="isAppRtl ? 'ml-2' : 'mr-2'"
                    @click="tour.complete(); helpdeskStatus.setTrackTimeMenuStatus(false)">
                    {{ t('VerticalNav.skipTxt') }}
                  </p>
                  <VCardText class="pt-2">
                    <div class="download-apps">{{ t('') }}</div>
                    <VTabs v-model="currentTab">
                      <VTab> {{ t('VerticalNav.application') }} </VTab>
                      <VTab> {{ t('VerticalNav.addTimeManually') }} </VTab>
                    </VTabs>
                    <VDivider class="mr-2" />
                    <VWindow class="mt-5" v-model="currentTab">
                      <VWindowItem>
                        <div class="relative align-center">
                          <p class="choose-app" style="font-size: 14px">
                            {{ t('VerticalNav.chooseAppsTxt') }}
                          </p>
                          <div class="d-flex justify-space-between">

                            <!-- hide this for field force analysis -->
                            <div v-if="showDownloadDesktopApps">
                              <div class="desktop-apps">{{ t('VerticalNav.desktopApps') }}</div>
                              <div class="d-flex justify-space-around mt-6">
                                <div class="window_icon" @click="appDownload('windows')"
                                  @mouseenter="isWindowsHovering = true" @mouseleave="isWindowsHovering = false">
                                  <div class="image">
                                    <img :src="isWindowsHovering
                                      ? windowsActive
                                      : windowsNormal
                                      " alt="" />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.Windows') }}</div>
                                </div>
                                <div class="mac_download_icon" @click="appDownload('mac')"
                                  @mouseenter="isMacHovering = true" @mouseleave="isMacHovering = false">
                                  <div class="image">
                                    <img :src="isMacHovering ? macActive : macNormal
                                      " alt="" />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.MacOS') }}</div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <div class="mobile-apps">{{ t('VerticalNav.mobileApps') }}</div>
                              <div class="d-flex justify-space-around mt-6">
                                <div class="android_icon" @click="appDownload('android')"
                                  @mouseenter="isAndroidHovering = true" @mouseleave="isAndroidHovering = false">
                                  <div class="image">
                                    <img :src="isAndroidHovering
                                      ? androidActive
                                      : androidNormal
                                      " alt="" />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.Android') }}</div>
                                </div>
                                <div class="mac_download_icon" @click="appDownload('ios')"
                                  @mouseenter="isIosHovering = true" @mouseleave="isIosHovering = false">
                                  <div class="image">
                                    <img :src="isIosHovering ? macActive : macNormal" alt="" />
                                  </div>
                                  <div class="text">{{ t('dashboardAnalytics.iOS') }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center">
                            <VBtn rounded class="mt-7"> {{ t('VerticalNav.downloadApp') }} </VBtn>
                          </div>
                        </div>
                      </VWindowItem>
                      <VWindowItem>
                        <p style="font-size: 12px">
                          {{ t('VerticalNav.addEditTime') }}
                        </p>
                        <div class="text-center">
                          <VBtn rounded @click="closeAddTimesheetStatus = true">
                            {{ t('VerticalNav.addTimeManually') }}
                          </VBtn>
                        </div>
                      </VWindowItem>
                    </VWindow>
                  </VCardText>
                </v-card>
              </v-menu>
            </div>
            <div class="mt-1" :class="isAppRtl ? 'vertical-line-arabic mr-3' : ' vertical-line ml-3'
              "></div>
            <div class="d-flex align-center cursor-pointer choose_plan_li" id="eight_step_onboarding">
              <img v-if="userStore.userSteps?.list[3].value" class="me-4" :src="donesvg" alt="" />
              <div v-else class="whitecirclewithn me-4" @click="() => {
               
               router.push('/settings/subscription-overview');
             }
               ">4</div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </slot>
  </Component>
  <VDialog v-model="showDialogBox" width="480" height="400">
    <VCard class="rounded-lg">
      <div class="d-flex justify-end">
        <v-btn icon color="black" variant="plain" @click="showDialogBox = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <VCardText class="text-center pt-0">
        <div class="text-center">
          <img :width="100" aspect-ratio="1/1" class="ml-auto bg-icon" :src="iconAlert" alt="" />
        </div>
        <div class="text-h6">{{ alertMessage }}</div>
      </VCardText>
      <VCardActions class="d-flex justify-center my-4">
        <VBtn width="129" height="44" variant="flat" color="primary" rounded="pill" @click="logout" class="btn-class">
          <!-- Yes -->
          {{ t('commonVar.Yes') }}test
        </VBtn>
        <VBtn width="129" height="44" variant="outlined" color="primary" rounded="pill" @click="showDialogBox = false">
          <!-- No -->
          {{ t('commonVar.No') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <VDialog v-model="showLogoutDialogBox" width="480" height="400">
    <VCard class="rounded-lg">
      <div class="d-flex justify-end">
        <v-btn icon color="black" variant="plain" @click="showLogoutDialogBox = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <VCardText class="text-center pt-0">
        <div class="text-center">
          <img :width="100" aspect-ratio="1/1" class="ml-auto bg-icon" :src="iconAlert" alt=""/>
        </div>
        <div class="text-h6">{{ alertMessage }}</div>
      </VCardText>
      <VCardActions class="d-flex justify-center my-4">
        <VBtn width="129" height="44" variant="flat" color="primary" rounded="pill" class="btn-class"
          @click="showLogoutDialogBox = false">{{ t('commonVar.OK') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <addTimesheet v-if="closeAddTimesheetStatus" :status="closeAddTimesheetStatus" @closeAddTimesheet="closeAddTimesheetDialogFn" />
  <VDialog v-model="showYoutubeRecommendation" width="874">
    <VCard class="welcome-screen" style="border-radius: 24px">
      <div>
        <div style="text-align: center; position: relative">
          <div style="
              display: flex;
              margin-top: 30px;
              justify-content: space-between;
              margin-bottom: 20px;
              margin-right: 20px;
              margin-left: 20px;
            " class="welcome-header">
            <div style="text-align: center; width: 100%">
              <h1 style="
                  font-weight: 500 !important;
                  font-size: 25px !important;
                  line-height: 30px;
                  text-align: center;
                  color: #00234b;
                ">
                {{ t('youTubeVideo.welcome') }}
              </h1>
            </div>
            <div style="text-align: end">
              <button mat-icon-button tabindex="-1" style="top: -15px" @click="showYoutubeRecommendation = false">
                <v-icon>mdi-close</v-icon>
              </button>
            </div>
          </div>

          <iframe width="750" height="315"
            src="https://www.youtube.com/embed/y1rUzGA3A2E?rel=0&control=0&autoplay=0&modestbranding=1"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>

        <div style="
            color: #525f7f;
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            opacity: 0.5;
            margin-top: 15px;
            cursor: pointer;
            margin-bottom: 30px;
          "></div>
      </div>
    </VCard>
  </VDialog>
</template>

<style lang="scss">
@use "@configured-variables" as variables;
@use "@layouts/styles/mixins";

// 👉 Vertical Nav
.layout-vertical-nav {
  position: fixed;
  z-index: variables.$layout-vertical-nav-z-index;
  display: flex;
  flex-direction: column;
  block-size: 100%;
  inline-size: variables.$layout-vertical-nav-width;
  inset-block-start: 0;
  inset-inline-start: 0;
  // transition: transform 0.25s ease-in-out, inline-size 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  // will-change: transform, inline-size;

  .nav-header {
    display: flex;
    align-items: center;

    .header-action {
      cursor: pointer;
    }
  }
  .nav-bg {
    background: rgb(250, 252, 255) !important;
  }

  .nav-bg-setup {
    background: linear-gradient(91.31deg,
        #4535a7 0.86%,
        #3d2f96 0.86%,
        #513fc8 0.87%,
        #4737ac 0.88%,
        #3c2e92 0.89%,
        #503ec5 110.07%,
        rgba(69, 53, 167, 0.447917) 110.08%,
        rgba(69, 53, 167, 0.484941) 110.09%) !important;
  }
  .app-title-wrapper {
    margin-inline-end: auto;
  }

  .nav-items {
    block-size: 100%;

    // ℹ️ We no loner needs this overflow styles as perfect scrollbar applies it
    // overflow-x: hidden;

    // // ℹ️ We used `overflow-y` instead of `overflow` to mitigate overflow x. Revert back if any issue found.
    // overflow-y: auto;
  }

  // .nav-item-title {
  //   overflow: hidden;
  //   margin-inline-end: auto;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }
  @media screen and (max-width: 1600px) {
    .nav-item-title {
      overflow: hidden;
      margin-inline-end: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      // font-size: 15px !important;
      font-size: 14px !important;
      // line-height: 18px;
      line-height: 15px;
      align-items: center;
      font-feature-settings: "kern" off;
      color: #00234b;
      margin-top: 0 !important;
      margin-bottom: 1px !important;
      /* margin-bottom: 5px !important; */
    }

    .nav-item-title-dark {
      overflow: hidden;
      margin-inline-end: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      // font-size: 15px !important;
      font-size: 14px !important;
      // line-height: 18px;
      line-height: 15px;
      align-items: center;
      font-feature-settings: "kern" off;
      color: #fff;
      margin-top: 0 !important;
      //margin-bottom: 2px !important;
    }
  }

  @media screen and (min-width: 1601px) and (max-width: 5464px) {
    .nav-item-title {
      overflow: hidden;
      margin-inline-end: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 18px;
      align-items: center;
      font-feature-settings: "kern" off;
      color: #00234b;
      margin-top: 0 !important;
      margin-bottom: 2px !important;
    }

    .nav-item-title-dark {
      overflow: hidden;
      margin-inline-end: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 18px;
      align-items: center;
      font-feature-settings: "kern" off;
      color: #fff;
      margin-top: 0 !important;
      // margin-bottom: 2px !important;
    }
  }

  // 👉 Collapsed
  .layout-vertical-nav-collapsed & {
    &:not(.hovered) {
      inline-size: variables.$layout-vertical-nav-collapsed-width;
    }
  }

  // 👉 Overlay nav
  &.overlay-nav {
    &:not(.visible) {
      transform: translateX(-#{variables.$layout-vertical-nav-width});

      @include mixins.rtl {
        transform: translateX(variables.$layout-vertical-nav-width);
      }
    }
  }
}
.welcome-screen {
  margin-top: -40px;
  margin-right: -15px;
  margin-left: -15px;
  border-radius: 24px;

  .mat-card.mat-elevation-z4 {
    box-shadow: none !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .mat-dialog-content {
    max-width: 1000px;
    min-width: 893px;
    min-height: 405px;
    // margin: 0;
    padding: 0;

    form {
      width: 100%;
      min-width: 550px;

      .mat-form-field {
        width: 100%;
      }

      section.checkbox-section {
        display: block;
        margin-bottom: 15px;
      }
    }
  }
}

button.BlueBtnBorderskip {
  padding: 8px 25px;
  border: 1px solid #624de3;
  color: #4461d7 !important;
  box-shadow: none !important;
  margin-right: 20px !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 2px !important;
  line-height: 0px;
  width: 187px;
  height: 52px;
  /* width: 107px !important; */
}

.logoutBtn {
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 16px !important;
  color: white !important;
  padding: 7px 50px 7px 50px !important;
  background-color: #624de3 !important;
  box-shadow: none !important;
  width: 187px !important;
}

@media screen and (max-width: 420px) {
  .welcome-screen {
    margin-left: 0px !important;
  }

  .videoclass {
    width: 260px !important;
    height: 210px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 820px) {
  .welcome-screen {
    margin-left: 0px !important;
  }

  .videoclass {
    width: 400px !important;
    height: 210px !important;
  }
}

.videoclass {
  width: 850px;
  height: 300px;
}

@media screen and (max-width: 420px) {
  .welcome-screen .mat-dialog-content {
    max-width: 100%;
    min-width: 100%;
    margin: 0 -10px;
  }

  .btnmobile {
    margin: 0px 0px 10px 0px !important;
  }
}

@media screen and (max-width: 599px) {
  .welcome-header {
    margin-top: 20px !important;
    margin-bottom: unset !important;
    margin-right: unset !important;
    margin-left: unset !important;
  }
}

.org-list {
  .v-overlay__content {
    max-height: 391px;
    max-width: 1507px;
    --v-overlay-anchor-origin: right bottom;
    transform-origin: left bottom;
    top: 141px;
    left: 205px;
    min-height: 80px;
  }
}

.profile-list {
  .v-overlay__content {
    max-height: 391px;
    max-width: 1507px;
    --v-overlay-anchor-origin: left bottom;
    transform-origin: right bottom;
    min-height: 80px !important;
  }
}

.org-overlay {
  .v-overlay__content {
    max-height: 374px;
    max-width: 1409px;
    min-width: 116px !important;
    position: fixed;
    transform-origin: right bottom;
    top: 188px;
    left: 12px;
    --v-overlay-anchor-origin: top right;
  }
}

</style>
<style scoped>
.radio-color {
  color: #fff;
}

.download-class-section {
  display: flex;
  margin: 0 20px 21px 25px;
  justify-content: center;
}

.setup-btn {
  padding: 10px 10px 10px 18px;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
  background: #624de3;
  color: #ffffff;
  border-radius: 8px;
}

.main-menu {
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
  background: #00234b;
  color: #ffffff;
  border-radius: 8px;
}

.main-menu-dark {
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
  background: #624DE3;
  color: #ffffff;
  border-radius: 8px;
}

/* .button-wrapper{
  vertical-align: middle;
} */

.borderline {
  /* border-right: 1px solid rgba(235, 234, 234, 0.1); */
  padding-right: 20px;
  color: #a4a6bd;
  padding-top: 0;
}

.borderline-left {
  border-left: 1px solid rgba(235, 234, 234, 0.1);
  padding-left: 20px;
  color: #a4a6bd;
  padding-top: 0;
}

.profilename {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
}

.index-menu {
  z-index: 100;
}

.download-apps {
  font-style: normal;
  font-weight: 533;
  font-size: 14px;
  line-height: 120%;
  color: #00234b;
  font-variation-settings: "slnt" 0;
  margin-bottom: 10px;
}

.choose-app {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #525f7f;
}

.desktop-apps {
  background: #624de31a;
  font-style: normal;
  font-weight: 600;
  width: 135px;
  height: 30px;
  font-size: 12px;
  padding: 8px;
  line-height: 14px;
  color: #3d4375;
}

.window_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.window_icon .image {
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* background-image: url(norma_windows.cd02fd3b80e06263.svg); */
  background-repeat: no-repeat;
}

.window_icon .text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #525f7f;
  margin-top: 6px;
}

.mac_download_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mac_download_icon .image {
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* background-image: url(norma_windows.cd02fd3b80e06263.svg); */
  background-repeat: no-repeat;
}

.mac_download_icon .text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #525f7f;
  margin-top: 6px;
}

.android_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.android_icon .text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #525f7f;
  margin-top: 6px;
}

.mobile-apps {
  background: #624de31a;
  font-style: normal;
  font-weight: 600;
  width: 135px;
  height: 30px;
  font-size: 12px;
  padding: 8px;
  line-height: 14px;
  color: #3d4375;
}

.android_icon .image {
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* background-image: url(android_normal.e294aa86fb3a0e36.svg); */
  background-repeat: no-repeat;
}

.profile-section {
  border-top: 0.5px solid rgba(235, 234, 234, 0.4);
  cursor: pointer;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
}

.profile-div {
  display: grid;
  gap: 4px;
  width: 200px;
  margin-top: 5px;
}

.profile-name {
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  line-height: 18px;
  margin-top: 5px;
  word-break: break-word;
}

.org-name {
  padding-left: 10px;
  color: #525f7f !important;
  font-weight: 400;
  font-size: 10px;
  word-break: break-all;
}

.user {
  /* margin: 12px 0; */
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.add-btn {
  background: #624de3;
  border-radius: 50%;
  padding: 5px;
}

.orgText {
  text-transform: capitalize;
}

.setup-div {
  padding: 20px 30px 10px 30px;
  text-decoration: none;
  cursor: pointer;
  margin: 4px;
}

.setup-text {
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}

.progress-text {
  font-weight: 500;
  line-height: 16.8px;
  color: #ffffff;
  margin-left: 5px;
  font-size: 12px;
}

.skip-text {
  text-align: end;
  padding-right: 5px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  line-height: 15px;
  color: #525f7f;
  opacity: 0.5;
}

.vertical-line-arabic {
  border-right: 1px solid rgb(220, 224, 220);
  height: 38px;
}

.setup-btn-dark {
  padding: 10px 10px 10px 18px;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
  background: #00234b;
  color: #ffffff;
  border-radius: 8px;
}

.betaText { 
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.betaLabel {
    display: flex;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 35px;
    background: #624DE3; 
    height:17px;
    margin-top:10px;
    cursor:pointer;
}

.unpin-sidebar-icon {
  transition: all 0.3s ease;
}

.unpin-sidebar-icon:hover {
  content: url('@/assets/images/svg/pin_sidebar.svg');
}

.referEarnCol{
  /* margin : 0px 14px; */
  padding : 0px 12px;
  /* min-height: 40px; */
  height: 33px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-radius: 5px;
  /* margin-top: 4px;
  margin-bottom: 4px; */
  /* width: 89%; */
  width: 100%;
  position: absolute;
  bottom: 36px;
  background: rgb(250, 252, 255);
}
.referEarnCol:hover{
  /* background: #f3f1fe !important; */
    .referEarnColTitle{
      color: #624de3 !important;
      font-weight: 600 !important;
    }
    .lightCol{
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    white-space: nowrap;
    width: 100%;
    border-radius: 5px;
    padding: 2px 12px;
    background: #f3f1fe;
 
}
}

.referEarnDarkCol{
  padding : 0px 12px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  bottom: 36px;
  background: linear-gradient(91.31deg, rgb(69, 53, 167) 0.86%, rgb(61, 47, 150) 0.86%, rgb(81, 63, 200) 0.87%, rgb(71, 55, 172) 0.88%, rgb(60, 46, 146) 0.89%, rgb(80, 62, 197) 110.07%, rgba(69, 53, 167, 0.447) 110.08%, rgba(69, 53, 167, 0.486) 110.09%) !important;
}

.referEarnDarkCol:hover{
  background : white;
  .referEarnDarkColTitle{
    color: #624de3;
  } 
  .darkCol{
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    white-space: nowrap;
    width: 100%;
    border-radius: 5px;
    padding: 2px 12px;
    background: white;
 
}
}

.referEarnColTitle{
  overflow: hidden;
  margin-inline-end: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 18px;
  align-items: center;
  font-feature-settings: "kern" off;
  color: #00234b;
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}
.referEarnDarkColTitle{
  overflow: hidden;
  margin-inline-end: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 18px;
  align-items: center;
  font-feature-settings: "kern" off;
  color: white;
  margin-top: 0 !important;
}
.referEarnListBox{
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.darkCol{
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  border-radius: 5px;
  padding: 2px 12px;
}
.lightCol{
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  border-radius: 5px;
  padding: 2px 12px;
}

</style>
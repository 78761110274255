import axios from "axios";
import { useSnackbarStore } from "@/stores/snackbarStore";
import { useAuthStore } from "@/stores/authStore";

const router = useRouter();
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

export const useResponseIntercepter = () => {
  axios.interceptors.response.use(
    // response => response,
    response => {
      const snackbarStore = useSnackbarStore();
      const auth = useAuthStore();
      if (
        // response?.data?.response?.code == 401 
        // || 
        response?.data?.response?.code == 1001
        ) {
        // Code 401 for Auth Token expiry, if Token is expired, logout the user and redirect to login page.
        // Also inform the user that, you session has been expired, kindly relogin.
        snackbarStore.setSnackbar('Your session has expired. Please login again.', 300)
        auth.logout();
        router.push('/login'); // Redirect to login page
      }
      return response;
    },
    error => {
      const snackbarStore = useSnackbarStore();
      console.log(error,'-----------error');
      if (error.response) {
        if (error.response.status === 429) {
          snackbarStore.setSnackbar('Rate limit exceeded. Please try again later.',300);
        } else if (error.response.status === 503) {
          snackbarStore.setSnackbar('Service unavailable. Please try again later.',300);
        }
      }
      return Promise.reject(error);
    }
  );
};
export const useRequestIntercepter = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      // Add authorization token or any other custom headers here
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      // Handle request error here
      return Promise.reject(error);
    }
  );
};
export default axiosIns;
import { openDB } from '@/stores/local/indexedDB'
let userData = useLocalStorage('userData', {})
const DB_NAME = `workstatus_${userData.value.org_id}`;
// Define store collection name
let STORE_NAME = `advanced_view_group_table_${userData.value.id}`;
let db: IDBDatabase | null = null;

function ensureSerializable(obj: any): any {
return JSON.parse(JSON.stringify(obj));
}
  
function updateUserData(){
  userData = useLocalStorage('userData', {});
  STORE_NAME = `advanced_view_group_table_${userData.value.id}`;
}

//insert items in table/store
export function addTodoGroupItem(item: any,  sortByData: any, timestampData: any, projectId: any): Promise<void> {
  updateUserData()
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB();
        const transaction = db.transaction([STORE_NAME], "readwrite");
        const store = transaction.objectStore(STORE_NAME);
  
        let dataToStore = {
          project_id: projectId, 
          data: item,
          sortBy: sortByData,
          timestamp: timestampData
        }
        // Ensure the item is serializable
        const serializableItem = ensureSerializable(dataToStore);
  
        const request = store.put(serializableItem);
        request.onsuccess = () => resolve();
        request.onerror = (event: Event) =>
          reject((event.target as IDBRequest).error);
      } catch (error) {
        reject(error);
      }
    });
  }


//get task list 
export function fetchTaskAdvanceViewGroupData(projectId: any): Promise<void> {
  updateUserData()
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDB();
            const transaction = db.transaction([STORE_NAME], "readonly");
            const store = transaction.objectStore(STORE_NAME);
          
            const request = store.get(projectId);
            request.onsuccess = () => {
                const items = request.result
                resolve(items);
              };
        }
        catch (error) {
            reject(error)
        }
    })
}

//complete task
export function completeTaskListDB(projectId: any, taskListId: any): Promise<void> {
    updateUserData();
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB();
        const transaction = db.transaction([STORE_NAME], "readwrite");
        const store = transaction.objectStore(STORE_NAME);
        
        const request = store.get(projectId);
        request.onsuccess = () => {
          const items = request.result;
          if (items && items.data) {
            
            const taskListIndex = items.data.findIndex((taskList: any) => taskList.id == taskListId);
            if (taskListIndex != -1) {
                items.data[taskListIndex].todogrp_status = 1;
                items.data[taskListIndex].todogroup_status = 1;
            }
            
  
            const updateRequest = store.put(items);
            updateRequest.onsuccess = () => {
              resolve();
            };
            updateRequest.onerror = (event) => {
              reject(new Error('Failed to update the store'));
            };
          } else {
            resolve(items); // Resolve even if there are no items to update
          }
        };
        request.onerror = (event) => {
          reject(new Error('Failed to retrieve the store'));
        };
      } catch (error) {
        reject(error);
      }
    });
  }
  
//delete task list
export function deleteTaskListDB(projectId: any, taskListId: any): Promise<void> {
    updateUserData();
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB();
        const transaction = db.transaction([STORE_NAME], "readwrite");
        const store = transaction.objectStore(STORE_NAME);
        
        const request = store.get(projectId);
        request.onsuccess = () => {
          const items = request.result;
          if (items && items.data) {
            // Filter out the todos whose ids are in the todoIds array
            items.data = items.data.filter((taskList: any) => taskList.id != taskListId);
  
            const updateRequest = store.put(items);
            updateRequest.onsuccess = () => {
              resolve();
            };
            updateRequest.onerror = (event) => {
              reject(new Error('Failed to update the store'));
            };
          } else {
            resolve(items); // Resolve even if there are no items to delete
          }
        };
        request.onerror = (event) => {
          reject(new Error('Failed to retrieve the store'));
        };
      } catch (error) {
        reject(error);
      }
    });
  }
  
//complete task
export function editTaskListDB(projectId: any, taskListId: any, taskListObject: any): Promise<void> {
  updateUserData();
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
      
      const request = store.get(projectId);
      request.onsuccess = () => {
        const items = request.result;
        if (items && items.data) {
          
          const taskListIndex = items.data.findIndex((taskList: any) => taskList.id == taskListId);
          if (taskListIndex != -1) {
              items.data[taskListIndex].group_name = taskListObject?.group_name;
              items.data[taskListIndex].milestone_name = taskListObject?.milestone_name;
              items.data[taskListIndex].milestone_status = taskListObject?.milestone_status;
          }
          

          const updateRequest = store.put(items);
          updateRequest.onsuccess = () => {
            resolve();
          };
          updateRequest.onerror = (event) => {
            reject(new Error('Failed to update the store'));
          };
        } else {
          resolve(items); // Resolve even if there are no items to update
        }
      };
      request.onerror = (event) => {
        reject(new Error('Failed to retrieve the store'));
      };
    } catch (error) {
      reject(error);
    }
  });
}


//remove stored filter
export function clearTaskListId(projectId: number): Promise<void> {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();  // Assumes openDB is a function that opens your IndexedDB connection
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      const deleteRequest = store.delete(projectId);
      deleteRequest.onsuccess = () => {
        resolve();
      };
      deleteRequest.onerror = (event) => {
        reject(new Error('Failed to delete the item from the store'));
      };
    } catch (error) {
      reject(error);
    }
  });
}


export const vCustumTooltip = {
  mounted(el, binding) {
    if(!binding.value){
      return false;
    }
    const tooltipEl = document.createElement("div");
    tooltipEl.className = "tooltip-custom-text";
    
    // Set the initial tooltip text
    tooltipEl.innerText = binding.value;

    document.body.appendChild(tooltipEl);

    const showTooltip = () => {
      const rect = el.getBoundingClientRect();
      tooltipEl.style.top = `${rect.top - 30}px`; // Adjust as needed
      tooltipEl.style.left = `${rect.left + rect.width / 2}px`;
      tooltipEl.style.opacity = "1";
      tooltipEl.style.visibility = "visible";
    };

    const hideTooltip = () => {
      tooltipEl.style.opacity = "0";
      tooltipEl.style.visibility = "hidden";
    };

    el.addEventListener("mouseenter", showTooltip);
    el.addEventListener("mouseleave", hideTooltip);

    // Store tooltip element and event listeners
    el._tooltipEl = tooltipEl;
    el._showTooltip = showTooltip;
    el._hideTooltip = hideTooltip;
  },
  updated(el, binding) {
    // Update the tooltip text when binding value changes
    if(!binding.value){
      return false;
    }
    el._tooltipEl.innerText = binding.value;
  },
  beforeUnmount(el, binding) {
    if(!binding.value){
      return false;
    }
    // Cleanup event listeners and remove the tooltip element
    el.removeEventListener("mouseenter", el._showTooltip);
    el.removeEventListener("mouseleave", el._hideTooltip);
    document.body.removeChild(el._tooltipEl);
  },
};


  export const vCustumTooltip2 = {
    mounted(el, binding) {
      const tooltipEl = document.createElement("div");
      tooltipEl.className = "tooltip-custom-text";
      document.body.appendChild(tooltipEl);

      const showTooltip = () => {
        const rect = el.getBoundingClientRect();
        tooltipEl.style.top = `${rect.top - 30}px`; // Adjust as needed
        tooltipEl.style.left = `${rect.left + rect.width / 2}px`;
        tooltipEl.style.opacity = "1";
        tooltipEl.style.visibility = "visible";
      };

      const hideTooltip = () => {
        tooltipEl.style.opacity = "0";
        tooltipEl.style.visibility = "hidden";
      };

      el.addEventListener("mouseenter", showTooltip);
      el.addEventListener("mouseleave", hideTooltip);

      // Store tooltip element and event listeners
      el._tooltipEl = tooltipEl;
      el._showTooltip = showTooltip;
      el._hideTooltip = hideTooltip;
    },
    updated(el, binding) {
      // Update the tooltip text when binding value changes
      el._tooltipEl.innerText = binding.value;
    },
    beforeUnmount(el) {
      // Cleanup event listeners and remove the tooltip element
      el.removeEventListener("mouseenter", el._showTooltip);
      el.removeEventListener("mouseleave", el._hideTooltip);
      document.body.removeChild(el._tooltipEl);
    },
  };
import { openDB } from '@/stores/local/indexedDB'
let userData = useLocalStorage('userData', {})

const DB_NAME = `workstatus_${userData.value.org_id}`;
// Define store collection name
let STORE_NAME = `data_key_table_${userData.value.id}`;

function updateUserData(){
  userData = useLocalStorage('userData', {});
   STORE_NAME = `data_key_table_${userData.value.id}`;
}

let db: IDBDatabase | null = null;


function ensureSerializable(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

// add Data Key
export function addDataKeyItem(item: any): Promise<number> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    const db:any = await openDB();
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);
     // Ensure the item is serializable
     const serializableItem = ensureSerializable(item);
     const request = store.add(serializableItem);
    request.onsuccess = () => resolve(request.result as number);
    request.onerror = (event: Event) =>
      reject((event.target as IDBRequest).error);
  });
}

export function getDataKeyItems(): Promise<any[]> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    const db:any = await openDB();
    const transaction = db.transaction([STORE_NAME], "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAll();
    request.onsuccess = () => resolve(request.result as any[]);
    request.onerror = (event: Event) =>
      reject((event.target as IDBRequest).error);
  });
}

export function deleteDataKeyItem(id: number): Promise<void> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    const db:any = await openDB();
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(id);
    request.onsuccess = () => resolve();
    request.onerror = (event: Event) =>
      reject((event.target as IDBRequest).error);
  });
}

export function addUpdateDataKeyItem(item: any): Promise<void> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    const db:any = await openDB();
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const serializableItem = ensureSerializable(item);
    const request = store.put(serializableItem);
    request.onsuccess = () => resolve();
    request.onerror = (event: Event) =>
      reject((event.target as IDBRequest).error);
  });
}

//clear the store data
export function clearDataKeyDb(): Promise<void> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    try { 
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
      const request = store.clear();

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };

      transaction.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    } catch (error) {
      reject(error);
    }
  });
}

import { defineStore } from "pinia";

export const useHelpdeskStore = defineStore('helpdeskStatus', () => {
    //state
    const helpdeskNavStatus = ref<boolean>(false);
    const openChat = ref<boolean>(false);
    const openWelcomeTour = ref<boolean>(false);
    const startTour = ref<boolean>(false);
    const isSetup = ref<boolean>(false);
    const changePlanActive = ref<boolean>(false);
    const tourActive = ref<boolean>(false);
    const inviteMenu = ref<boolean>(false);
    const isSelectMenu = ref<boolean>(false);
    const trackTimeMenu = ref<boolean>(false);
    const moveToPremium = ref<boolean>(false);
    const showChatIcon = ref<boolean>(true);

    //actions
    const setHelpdeskNavStatus = (status: boolean) => {
        helpdeskNavStatus.value = status;
    };
    const setOpenChatToggle = (toggle: boolean) => {
        openChat.value = toggle;
    };
    const setTourStatus = (status: boolean) => {
        openWelcomeTour.value = status;
    };
    const setStartTourStatus = (status: boolean) => {
        startTour.value = status;
    };
    const setSetupToggle = (status: boolean) => {
        isSetup.value = status;
    };
    const setChangePlanStatus = (status: boolean) => {
        changePlanActive.value = status;
    }
    const setTourActiveStatus = (status: boolean) => {
        tourActive.value = status;
    }
    const setInviteMenuStatus = (status: boolean) => {
        inviteMenu.value = status;
    };
    const setIsSelectMenuStatus = (status: boolean) => {
        isSelectMenu.value = status;
    };
    const setTrackTimeMenuStatus = (status: boolean) => {
        trackTimeMenu.value = status;
    };
    const setMoveToPremiumStatus = (status: boolean) => {
        moveToPremium.value = status;
    };

    const setShowChatIconToggle = (toggle: boolean) => {
        showChatIcon.value = toggle;
    };

    return{
        helpdeskNavStatus,
        openChat,
        openWelcomeTour,
        startTour,
        isSetup,
        changePlanActive,
        tourActive,
        inviteMenu,
        isSelectMenu,
        trackTimeMenu,
        moveToPremium,
        setHelpdeskNavStatus,
        setOpenChatToggle,
        setTourStatus,
        setStartTourStatus,
        setSetupToggle,
        setChangePlanStatus,
        setTourActiveStatus,
        setInviteMenuStatus,
        setIsSelectMenuStatus,
        setTrackTimeMenuStatus,
        setMoveToPremiumStatus,
        showChatIcon,
        setShowChatIconToggle
    }
})
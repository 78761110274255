import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('useSnackbar', () => {

    const snackbar = ref<boolean>(false); // show snackbar, if this variable is set to true
    const snackbar_message = ref<string>(""); // snackbar message.
    const snackbar_status = ref<any>()

    const setSnackbar = (message:string, statusCode: any) => {
        snackbar_message.value = message;
        snackbar_status.value = statusCode;
        snackbar.value = true
    }

    const hideSnackbar = () => {
        snackbar.value = false
    }

    return {
        snackbar,
        snackbar_message,
        setSnackbar,
        snackbar_status,
        hideSnackbar
    };
  });
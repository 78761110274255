import { defineStore } from 'pinia';

export const useDrawerStore = defineStore('drawerState', () => {

    const route = useRoute()
    const router = useRouter()

    //invite new member drawer 
    const openExpenseCreateDrawer = ref<any>(false);
    const expenseCreateSuccess = ref<any>(false);
    const openViewExpense = ref<any>(false);
    
    const addMemberDrawer = ref<any>(false);
    const addTeamDrawer = ref<any>(false);
    const addDepartmentDrawer = ref<any>(false);
    const showComponent = ref(true);
    const addProjectDrawer = ref<any>(false);
    const addScheduleDrawer = ref<any>(false);

    const inviteMemberKey = ref<any>([]);
    const closeDrawer = () => {
        addMemberDrawer.value = false;
        addProjectDrawer.value = false;
    }

    //invite client
    const fromAddProject = ref<any>(false);
    const addClientDrawer = ref<any>(false);
    const showClientComponent = ref(true);
    const inviteClientKey = ref<any>();
    const closeClientDrawer = () => {
        addClientDrawer.value = false;
    }
    // add timesheet
    const addTimeDrawer = ref<any>(false);
    const showOverviewComponent = ref<any>(false);
    // add timeoff
    const addTimeoffDrawer = ref<any>(false)
    
    // add todogroup
    const addTodoGroupDrawer = ref<any>(false);
    const openTodoGroupFromViewMilestone = ref<any>(false);
    //add milestone
    const addMilestoneDrawer = ref<any>(false);
    const showMilestoneComponent = ref(true);
    const addMilestoneKey = ref<any>([]);
    const closeMilestoneDrawer = () => {
        addMilestoneDrawer.value = false;
    }
    const createdMilestoneData = ref<any>()
    const openAddTodo = ref<any>(false);
    const openEditDrawer=ref<any>(false);
    const openEditTodo=ref<any>(false);
    const openEditTodoGroup=ref<any>(false);
    const isEditSchedule=ref<any>(false);
    const openEditMilestone=ref<any>(false);
    const openViewMilestone=ref<any>(false);
    const openEditProject = ref<any>(false);
    const openViewTimesheet = ref<any>(false);
    const openEditTimesheet = ref<any>(false);
    const openEditClient = ref<any>(false);
    const openEditDepartment = ref<any>(false);
    const openEditTeam = ref<any>(false);
    const openEditJobsiteDrawer = ref<any>(false);
    const openViewTimeoff =ref<any>(false);
    const openEditTimeoff =ref<any>(false);
    const addJobsiteDrawer=ref<any>(false);
    const loadJobsiteListFromAddJobsite = ref<boolean>(false)
    const loadMilestonesListFromTodoDrawer = ref<any>(false)
    const loadTeamListFromTeamDrawer = ref<any>(false);
    const loadScheduleListFromDrawer = ref<any>(false);
    const loadDepartmentFromDrawer = ref<any>(false);
    const loadInviteMembersTab = ref<boolean>(false)
    const loadProjectOverviewFromEditProject = ref<boolean>(false)
    const reloadProjectHeader = ref<boolean>(false)
    const loadClientListFromCLientDrawer = ref<boolean>(false)
    const openViewTodoGrpStatus = ref<boolean>(false)
    const currentTodoGroupName = ref<any>('');
    const openCategoryDrawer = ref<any>(false); 
    const openSimpleViewTaskGrpStatus = ref<any>(false); 
    const openProjectStatusSummary = ref<boolean>(false)
    const updateSimpleViewTodo = ref<any>(false)
    const updateSimpleViewTodoFromAddTodoDrawer = ref<any>(false)
    const updateMemberSimpleViewTodo = ref<any>(false)
    const todoIdForSimpleView = ref<any>(false)
    const simpleViewUpdateMembers = ref<any>();
    const simpleViewTodoItem = ref<any>()
    const simpleViewTodoItemFromAddTodoDrawer = ref<any>()
    const membersimpleViewTodoItemFromAddTodoDrawer = ref<any>()
    const addTeamFromDrawer = ref<boolean>(false)
    const addTeamDataFromAddTeamDrawer = ref<any>()

    const editTeamFromDrawer = ref<boolean>(false)
    const editTeamDataFromAddTeamDrawer = ref<any>()


    // variables for triggering sync button in clients listing
    const clientListSyncRequest = ref(false)
    const triggerClientListSyncfromAddClientDrawer = ref(false);
    const triggerClientListSyncfromEditClientDrawer = ref(false);

    const setTodoItem = (value: any) => {
        
        simpleViewTodoItem.value = value
        
    }
    const setTodoItemFromAddTodoDrawer = (value: any) => {
        
        simpleViewTodoItemFromAddTodoDrawer.value = value
        
    }
    const setMemberTaskItemFromAddTodoDrawer = (value: any) => {
        
        membersimpleViewTodoItemFromAddTodoDrawer.value = value
        
    }

    const setAddTeamDataFromAddTeamDrawer = (value: any) => {
        
        addTeamDataFromAddTeamDrawer.value = value
        
    }

    const setEditTeamDataFromAddTeamDrawer = (value: any) => {
        
        editTeamDataFromAddTeamDrawer.value = value
        
    }
    //    -------------------------------
// const currentQueryParams = { ...route.query };
      
// const appendQueryParam = (paramId:any) => {
//     const currentQueryParams = { ...route.query };
//     currentQueryParams.id = paramId; // Add or update the query parameter
//     // console.log(currentQueryParams)
//     // currentQueryParams.id = paramId; // Add or update the query parameter
//     router.replace({ query: currentQueryParams }); // Replace the current route with the updated query parameters
//     openEditDrawer.value=true;
// };

// const appendQueryParamProject = () => {
//     const currentQueryParams = { ...route.query };
//     currentQueryParams.id = '454'; // Add or update the query parameter
//     router.replace({ query: currentQueryParams }); // Replace the current route with the updated query parameters
// };

// const removeQueryParam = () => {
//     const currentQueryParams = { ...route.query };
//     delete currentQueryParams.id; // Remove the 'page' query parameter
//     router.replace({ query: currentQueryParams }); // Replace the current route with the updated query parameters
//     openEditDrawer.value=false;
//   };

    return{
        //invite new member drawer
        addMemberDrawer,
        showComponent,
        addProjectDrawer,
        addScheduleDrawer,
        addTeamDrawer,
        addDepartmentDrawer,
        closeDrawer,
        inviteMemberKey,

        addClientDrawer,
        showClientComponent,
        inviteClientKey,
        closeClientDrawer,

        addMilestoneDrawer,
        showMilestoneComponent,
        addMilestoneKey,
        closeMilestoneDrawer,
        createdMilestoneData,
        openAddTodo,
        openEditDrawer,
        openEditTodo,
        openEditTodoGroup,
        isEditSchedule,
        openEditMilestone,
        openViewMilestone,
        openEditProject,
        openViewTimesheet,
        openEditTimesheet,
        openEditClient,
        openEditDepartment,
        openEditTeam,
        openEditJobsiteDrawer,
        openViewTimeoff,
        openEditTimeoff,
        fromAddProject,
        loadMilestonesListFromTodoDrawer,
        loadInviteMembersTab,
        addTimeoffDrawer  ,
        addTimeDrawer,
        addTodoGroupDrawer,
        openTodoGroupFromViewMilestone,
        loadProjectOverviewFromEditProject,
        reloadProjectHeader,
        addJobsiteDrawer,
        loadClientListFromCLientDrawer,
        loadJobsiteListFromAddJobsite,
        loadTeamListFromTeamDrawer,
        loadScheduleListFromDrawer,
        loadDepartmentFromDrawer,
        openViewTodoGrpStatus,
        currentTodoGroupName,
        openCategoryDrawer,
        openSimpleViewTaskGrpStatus,
        showOverviewComponent,
        openProjectStatusSummary,
        openExpenseCreateDrawer,
        expenseCreateSuccess,
        openViewExpense,
        updateSimpleViewTodo,
        updateMemberSimpleViewTodo,
        todoIdForSimpleView,
        simpleViewUpdateMembers,
        setTodoItem,
        simpleViewTodoItem,
        updateSimpleViewTodoFromAddTodoDrawer,
        simpleViewTodoItemFromAddTodoDrawer,
        setTodoItemFromAddTodoDrawer,
        setMemberTaskItemFromAddTodoDrawer,
        membersimpleViewTodoItemFromAddTodoDrawer,
        setAddTeamDataFromAddTeamDrawer,
        addTeamFromDrawer,
        addTeamDataFromAddTeamDrawer,
        setEditTeamDataFromAddTeamDrawer,
        editTeamFromDrawer,
        editTeamDataFromAddTeamDrawer,
        clientListSyncRequest,
        triggerClientListSyncfromAddClientDrawer,
        triggerClientListSyncfromEditClientDrawer
    }
})
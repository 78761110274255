import { defineStore } from "pinia";
import axios from "axios";

export const useOrganizationStore = defineStore('organizationStore', ()=>{
   // Define states
   const error = ref<any>();
   const rolelist = ref<any>();
   const showBanner = ref<boolean>(false);
   
   // Define Getters
   const showBannerStatus = computed(() => showBanner.value)

   // Define actions
   const apiGetOrganizationRoleList = async()=> {
      try {
      
         const org: string | null = localStorage.getItem('orgData')
         const orgJSON = JSON.parse(org ?? '')
         const response = await axios.post('/v1/user/organization/role/list', {
            organization_id: orgJSON.id
         })
         const data = response.data; // Use response.data to access the API response
         if (data.response.code == "200") {
            rolelist.value = data.response.data
         } else {
            alert('Please refresh the page.');
         }
       } catch (error) {
         console.warn(error)
       }
   }

   const apiGetBannerStatus = async()=>{
      // try {
      //    const response = await axios.get('/show_holi_banner')
      //    const data = response.data; // Use response.data to access the API response
      //    // console.log("apiGetBannerStatus",data.status);
      //    if(data.status == "active"){
      //       showBanner.value = true;
      //    }else{
            showBanner.value = false;
      //    }
      //  } catch (error) {
      //    console.warn(error)
      //  }
   }

   return {
      error,
      rolelist,
      showBannerStatus,
      showBanner,
      apiGetBannerStatus,
      apiGetOrganizationRoleList
    };
});
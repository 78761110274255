import { defineStore } from "pinia";
import axios from "axios";
import { initialAbility } from "@/plugins/casl/ability";
import { useAppAbility } from "@/plugins/casl/useAppAbility";
import { useRouter } from "vue-router";
import { useSnackbarStore } from "@/stores/snackbarStore";
import { closeAndDeleteDatabase } from '@/stores/local/indexedDB'

// const routervue = useRouter();
const channel = new BroadcastChannel('logout_channel');
export const useAuthStore = defineStore("authStore", () => {
  const snackbarStore = useSnackbarStore();
  const router = useRouter();
  const ability = useAppAbility();
  let logoutHit = 0;
  

  const logout = async () => {
    // Clear local storage
    localStorage.removeItem("userAbilities");
    localStorage.removeItem("userData");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("verticalMenu");
    localStorage.removeItem("featuresList");
    localStorage.removeItem("timesheetDisplayedTabs");
    localStorage.removeItem("displayedTabs");
    localStorage.removeItem("organizationDisplayedTabs");
    localStorage.removeItem("organizationRemainingTabs");
    localStorage.removeItem("remainingTabs");
    localStorage.removeItem("achievementDisplayedTabs");
    localStorage.removeItem("timesheetRemainingTabs");
    localStorage.removeItem("db_version");
    localStorage.removeItem("userType");

    // localStorage.removeItem("marketingToFreeTrial")
    // Navigate to login page
    router.push("/auth/login");
  
    // Reset abilities
    ability.update(initialAbility);
  
    // Close and delete the database
    
    // Perform logout operation if it hasn't been done yet
    // if (logoutHit === 0) {
      try {
        const res = await axios.get("/v1/logout");
        channel.postMessage('logout_all_tabs');
        snackbarStore.setSnackbar(res.data.response.message, 200);
        await closeAndDeleteDatabase();
        logoutHit = 1;
      } catch (error) {
        console.error('Logout failed', error);
      }
    // }
  };
  
  return {
    logout,
  };
  

});
channel.addEventListener('message', async (event) => {
  if (event.data == 'logout_all_tabs') {
    try {
      window.location.href = '/auth/login';
    } catch (error) {
      console.error('Error closing database:', error);
    }
  }
});
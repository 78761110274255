import axios from 'axios';

const cancelSource = axios.CancelToken.source();

export const sidemenuService =  () => {
    const fetchSideMenu = async() => {
       return axios.get('/v3/user/organization/menu/list')
    }
   
   
    return {
        fetchSideMenu
    };
}

{
  "Branch": "فرع",
  "BranchHead": "رئيس الفرع",
  "Unit": "وحدة",
  "UnitHead": "رئيس الوحدة",
  "managingDirector": "المدير العام",
  "exeDirector": "المدير التنفيذي",
  "corpUser": "مستخدم الشركة",
  "newUnit": "وحدة جديدة",
  "addUnit": "أضف وحدة",
  "noUnits": "لا توجد وحدات",
  "addNewUnit": "إضافة وحدة جديدة",
  "unitName": "اسم الوحدة",
  "unitHead": "رئيس الوحدة",
  "enterUnitName": "أدخل اسم الوحدة",
  "selectUnitHead": "حدد رئيس الوحدة",
  "createDiffUnitBranch": "قم بإنشاء وحدات/فرع مختلف لتبسيط سير عملك.",
  "editUnit": "تحرير الوحدة",
  "removeUnit": "إزالة الوحدة",
  "unitHeadDet": "تفاصيل رئيس الوحدة",
  "ediffBranchUnit": "قم بتحرير وحدات/فرع مختلف لتبسيط سير العمل الخاص بك.",
  "newBranch": "فرع جديد",
  "noBranch": "لا يوجد فرع",
  "selectUnit": "حدد الوحدة",
  "branchName": "اسم الفرع",
  "editBranch": "تحرير فرع",
  "removeBranch": "إزالة الفرع",
  "branchWillBeRemoved": "سيتم إزالة الفرع. هل مازلت ترغب في المتابعة؟", 
  "addNewBranch": "إضافة فرع جديد",
  "leave_history_line_1":"Currently, there is no leave history available for the selected user. ",
  "leave_history_line_2":"Please ensure leave requests are submitted and recorded for accurate tracking.",
  "no_attendance_line_1":"Currently, there are no attendance records available.",
  "no_attendance_line_2":"Please ensure attendance is logged to maintain accurate tracking of work hours and presence.",
  "no_attendance_line_3":"Submit a timesheet to update attendance records.",
  "no_timesheet_approval_line_1":"Currently, there are no timesheet approval requests.",
  "no_timesheet_approval_line_2":"Please submit a timesheet for approval to ensure accurate tracking and verification of work hours.",
  "no_timesheet_line_1":"Currently, there are no time logs available.",
  "no_timesheet_line_2":"Please add a new time entry to accurately track your work hours and progress.",
  "no_milestone_yet_line_1":"Looks like you don't have any milestones yet.",
  "no_milestone_yet_line_2":"Start by creating your first milestone to keep track of your project's progress!",
  "Dashboard": "لوحة القيادة",
  "Departments": "الأقسام",
  "Subscription": "الاشتراك",
  "Activity": "نشاط",
  "Screenshots": "لقطات",
  "ExplainThisScreen": "شرح هذه الشاشة",
  "VisitHelpCenter": "زيارة مركز المساعدة",
  "workstatus-intro" : "Workstatus مقدمة",
  "Apps": "تطبيقات",
  "URL": "URLs",
  "Timesheets": "الدوام",
  "View & Edit Timesheets": "عرض وتحرير الجداول الزمنية",
  "Approvals": "الموافقات",
  "Attendance": "حضور",
  "Time & Attendance": "الوقت والحضور",
  "People": "الأشخاص",
  "Employees": "الموظفون",
  "Peoples": "الشعوب",
  "Members": "أعضاء",
  "Teams": "فرق",
  "Project Management": "ادارة مشروع",
  "Projects": "مشروع",
  "Todo": "لكى يفعل",
  "Break": "استراحة",
  "Clients": "العملاء",
  "Workorder": "طلب العمل",
  "Location": "موقع",
  "Job sites": "مواقع العمل",
  "Shift & Leaves": "التحول والأوراق",
  "Schedules": "جداول",
  "Time Off": "انتهى الوقت",
  "Reports": "التقارير",
  "Time & Activity": "الوقت والنشاط",
  "Weekly": "أسبوعي",
  "Dynamic Report": "تقرير ديناميكي",
  "Jobsites" : "مواقع العمل",
  "Apps & Urls": "تطبيقات و عناوين المواقع",
  "Project and Task": "المشروع والمهمة",
  "Attendance Report - Basic": "تقرير الحضور - أساسي",
  "Attendance Report - Advanced": "تقرير الحضور - متقدم",
  "Jobsite": "موقع عمل",
  "Time off transaction": "إجازة الصفقة",
  "All Reports": "كافة التقارير",
  "Settings": "إعدادات",
  "General": "عام",
  "Features": "سمات",
  "Memberships": "العضويات",
  "Organisation Management": "إدارة المنظمة",
  "Organizations": "إدارة المنظمة",
  "AllSettings": "جميع الإعدادات",
  "Change log": "سجل التغيير",
  "Helpdesk": "مكتب المساعدة",
  "Integration": "اندماج",
  "UI Elements": "عناصر واجهة المستخدم",
  "Forms & Tables": "النماذج والجداول",
  "Pages": "الصفحات",
  "Charts & Maps": "الرسوم البيانية والخرائط",
  "Others": "آحرون",
  "Typography": "الطباعة",
  "Cards": "البطاقات",
  "Basic": "أساسي",
  "Advance": "يتقدم",
  "Widgets": "الحاجيات",
  "Actions": "أجراءات",
  "Components": "عناصر",
  "Alert": "انذار",
  "Avatar": "الصورة الرمزية",
  "Badge": "شارة",
  "Button": "زر",
  "Calendar": "تقويم",
  "Image": "صورة",
  "Pagination": "ترقيم الصفحات",
  "Progress": "تقدم",
  "Tooltip": "تلميح",
  "CheckLocation": "تحقق من الموقع",
  "Slider": "المنزلق",
  "Date Time Picker": "منتقي التاريخ والوقت",
  "Select": "يختار",
  "Switch": "يُحوّل",
  "Checkbox": "خانة اختيار",
  "Radio": "مذياع",
  "Textarea": "تيكستاريا",
  "Rating": "تقييم",
  "File Input": "إدخال الملف",
  "Form Layout": "تخطيط النموذج",
  "Form Validation": "التحقق من صحة النموذج",
  "Charts": "الرسوم البيانية",
  "Apex Chart": "مخطط أبيكس",
  "Chartjs": "تشارتجس",
  "Account Settings": "إعدادت الحساب",
  "User Profile": "ملف تعريفي للمستخدم",
  "FAQ": "التعليمات",
  "Pricing": "التسعير",
  "List": "قائمة",
  "Edit": "يحرر",
  "Nav Levels": "مستويات التنقل",
  "Level 2.1": "المستوى 2.1",
  "Level 2.2": "مستوى 2.2",
  "Level 3.1": "المستوى 3.1",
  "Level 3.2": "المستوى 3.2",
  "Raise Support": "رفع الدعم",
  "Documentation": "توثيق",
  "Dashboards": "لوحات القيادة",
  "Apps & Pages": "التطبيقات والصفحات",
  "Email": "البريد الإلكتروني",
  "Chat": "دردشة",
  "Invoice": "فاتورة",
  "Preview": "معاينة",
  "Add": "يضيف",
  "User": "المستعمل",
  "View": "رأي",
  "Login v1": "تسجيل الدخول v1",
  "Login v2": "تسجيل الدخول v2",
  "Login": "تسجيل الدخول",
  "Register v1": "تسجيل v1",
  "Register v2": "تسجيل v2",
  "Register": "تسجيل",
  "Forget Password v1": "نسيت كلمة المرور v1",
  "Forget Password v2": "نسيت كلمة المرور v2",
  "Forgot Password v1": "نسيت كلمة المرور v1",
  "Forgot Password v2": "نسيت كلمة المرور v2",
  "Forgot Password": "نسيت كلمة المرور",
  "Reset Password v1": "إعادة تعيين كلمة المرور v1",
  "Reset Password v2": "إعادة تعيين كلمة المرور v2",
  "Reset Password": "إعادة تعيين كلمة المرور",
  "Miscellaneous": "متفرقات",
  "Coming Soon": "قريبا",
  "Not Authorized": "غير مخول",
  "Under Maintenance": "تحت الصيانة",
  "Error": "خطأ",
  "Statistics": "إحصائيات",
  "Analytics": "تحليلات",
  "Access Control": "صلاحية التحكم صلاحية الدخول",
  "User Interface": "واجهة المستخدم",
  "CRM": "سي آر إم",
  "Icons": "أيقونات",
  "Chip": "رقاقة",
  "Dialog": "حوار",
  "Expansion Panel": "لوحة التوسع",
  "Combobox": "صندوق التحرير",
  "Textfield": "مجال التحرير مكان كتابة النص",
  "Range Slider": "نطاق المنزلق",
  "Menu": "قائمة الطعام",
  "Snackbar": "مطعم الوجبات الخفيفة",
  "Tabs": "نوافذ التبويب",
  "Form Elements": "عناصر النموذج",
  "Form Layouts": "تخطيطات النموذج",
  "Authentication": "المصادقة",
  "Page Not Found - 404": "الصفحة غير موجودة - 404",
  "Not Authorized - 401": "غير مصرح - 401",
  "Server Error - 500": "خطأ في الخادم - 500",
  "2": "2",
  "days":"أيام",
  "freeTrialMsg":"تنتهي الفترة التجريبية في",
  "expireMsg": "انتهت الفترة التجريبية",
  "Forms": "نماذج",
  "Timeline": "الجدول الزمني",
  "Disabled Menu": "قائمة المعوقين",
  "Help Center": "مركز المساعدة",
  "Verify Email": "التحقق من البريد الإلكتروني",
  "Verify Email v1": "تحقق من البريد الإلكتروني v1",
  "Verify Email v2": "تحقق من البريد الإلكتروني v2",
  "Two Steps": "خطوتين",
  "Two Steps v1": "خطوتين v1.0",
  "Two Steps v2": "خطوتين v2.0",
  "eCommerce": "التجارة الإلكترونية",
  "Organization": "المؤسسة",
  "organization": "المؤسسة",
  "addNewOrg": "إضافة مؤسسة جديدة",
  "changeLang": "تغيير اللغة",
  "profile1": "الملف الشخصي",
  "logout": "تسجيل الخروج",
  "Timesheet": "ورقة العمل الزمنية",
  "messageBoard" : "لوحة الرسائل",
  "Timeoff": "وقت الراحة",
  "Achievement": "إنجاز",
  "Edit Organization": "تحرير المنظمة",
  "Timer Apps": "تطبيقات المؤقت",
  "Department": "القسم",
  "Overview": "نظرة عامة",
  "Billing History": "سجل الفوترة",
  "Project & Task Report": "تقرير المشروع والمهمة",
  "Attendance Report": "تقرير الحضور",
  "Job Sites": "مواقع العمل",
  "Time off": "إجازة",
  "deletedAcc": "تم حذف هذا الحساب.",
  "Manualedit": "تحرير الوقت اليدوي",
  "Profile Picture": "صورة الملف الشخصي",
  "Primary Information": "المعلومات الأساسية",
  "About": "حول",
  "About my job": "حول عملي",
  "Interests & Hobbies": "الاهتمامات والهوايات",
  "Personal Information": "المعلومات الشخصية",
  "Emergency contact": "جهة الاتصال في حالات الطوارئ",
  "Job Details": "تفاصيل العمل",
  "Banking Information": "معلومات البنكية",
  "Identity Information": "معلومات الهوية",
  "Deployed":"تم النشر",
  "Yet to Start":"لم يبدأ بعد",
  "In Progress":"قيد التنفيذ",
  "On Hold":"معلق",
  "Archived":"مؤرشف",
  "Canceled":"ملغى",
  "Open":"مفتوح",
  "In Discussion":"قيد المناقشة",
  "In Development":"قيد التطوير",
  "QA Review":"مراجعة الجودة",
  "Ready For Test":"جاهز للاختبار",
  "Need Help":"بحاجة للمساعدة",
  "Re-Open":"إعادة فتح",
  "Invalid":"غير صالح",
  "Ready for deployment":"جاهز للنشر",
  "Enabled": "مفعل",
  "Disabled": "معطل",
  "NOT LOGGED-IN YET": "لم تقم بتسجيل الدخول بعد",
  "NOT WORKING": "لا يعمل",
  "WORKING": "يعمل",
  "$vuetify": {
    "badge": "شارة",
    "pagination": {
      "ariaLabel": {
        "root": "جذر",
        "previous": "السابق",
        "next": "التالي",
        "currentPage": "الصفحه الحاليه",
        "page": "صفحة"
      }
    },
    "input": {
      "clear": "صافي",
      "appendAction": "إلحاق الإجراء",
      "prependAction": "قبل العمل"
    },
    "fileInput": {
      "counterSize": "حجم العداد"
    },
    "rating": {
      "ariaLabel": {
        "item": "العنصر"
      }
    }
  },
  "validators":{
    "requiredValidator": "هذه الخانة مطلوبه",
    "emailValidator": "يجب أن يكون حقل البريد الإلكتروني بريدًا إلكترونيًا صالحًا",
    "passwordValidator": "استخدم 6 أحرف أو أكثر مع مزيج من الأحرف والأرقام والرموز (!،#،$،%،&،*,-,@)",
    "confirmedValidator": "يجب أن تتطابق كلمتا المرور",
    "betweenValidator":{
      "enterNumberbetween": "أدخل رقمًا بين",
      "and": "و",
      "the": "ال"
    },
    "integerValidator": "يجب أن يكون هذا الحقل عددًا صحيحًا",
    "regexValidator": "تنسيق حقل Regex غير صالح"
  },
  "LoginView": {
    "Login": "تسجيل الدخول",
    "LoginBtn": "انتقل إلى تسجيل الدخول",
    "RememberMe": "تذكرنى",
    "ForgotPassword": "هل نسيت كلمة السر؟",
    "Email": "عنوان البريد الإلكتروني *",
    "Password": "كلمة المرور*"
  },
  "authForgotPassword": {
    "recoverPassword": "استعادة كلمة المرور الخاصة بك",
    "sendResetLink": "أرسل رابط إعادة التعيين",
    "backToLogin": "العودة لتسجيل الدخول"
  },
  "authMailConfirm":{
    "resetYourPassword": "اعد ضبط كلمه السر",
    "confirmationEmail": "تم إرسال بريد إلكتروني للتأكيد إلى",
    "checkYourInbox": "تحقق من صندوق الوارد الخاص بك وانقر على رابط إعادة تعيين كلمة المرور لتغيير كلمة المرور الخاصة بك"
  },
  "settings": {
    "help&support": "المساعدة والدعم",
    "knowMore": "اعرف أكثر",
    "notAddedYet" : "لم يتم إضافته بعد",
    "general": {
      "projects":{
        "controlDefaultRole": "التحكم في الدور الافتراضي حيث سيتم تعيين الأعضاء بشكل افتراضي إلى الدور المحدد.",
        "financeSettingDesc": "السماح لمديري المشروع بعرض البيانات المالية للمشروع.",
        "ProjectSettingDesc": "السماح لمديري المشروع بعرض إعداد المشروع." ,
        "individualSetting": "الإعداد الفردي",
        "members": "الأعضاء",
        "search": "يبحث",
        "membersRoleHelp&support" : "تفويض المسؤوليات داخل مشاريعك من خلال تعيين أدوار مميزة لأعضاء الفريق، مما يضمن الوضوح وتحسين تنفيذ المشروع. اعرف المزيد حول كيفية إدارة التكليفات المستندة إلى الأدوار لتحسين إدارة المشاريع.",
        "manageProjIdHelp&Support" : "تخصيص معرفات فريدة للمشاريع، مضموناً تتبع سلسًا، تصنيف مبسط، وإدارة مشاريع فعّالة.",
        "manageTodoStatusHelp&Support" : "قم بتعزيز رؤية المهام وتتبع التقدم من خلال إدارة حالة المهام. مراقبة معالم المهمة وتعزيز المساءلة وتحقيق أهداف المشروع.",
        "projectMilestoneHelp&Support" : "توجيه تقدم المشروع من خلال إدارة الأهداف الرئيسية. حدد الأهداف الرئيسية، وتتبع الإنجازات، وابقَ على المسار نحو نتائج مشروع ناجحة.",
        "manageTaskIDHelp&Support"  :  "قم بتعيين معرفات فريدة للمهام، مما يضمن التتبع السلس والتصنيف المبسط وإدارة المهام بشكل فعال."
      },
      "EditOrg": {
        "setIpAddress": "تعيين عنوان IP",
        "OrgDetails": "تفاصيل المنظمة",
        "selCurrency": "اختر العملة*",
        "selTimezone": "اختر المجال الزمني",
        "Date format": "صيغة التاريخ",
        "Save": "يحفظ",
        "Max file size: 2MB": "الحد الأقصى لحجم الملف: 2 ميجا بايت",
        "vat/gst":"رقم ضريبة القيمة المضافة / ضريبة السلع والخدمات *",
        "vatGstIN": "أريد فاتورة ضريبة القيمة المضافة / GSTIN",
        "companyName": "اسم الشركة",
        "companyAddress": "عنوان الشركة",
        "taxId": "الرقم الضريبي",
        "start-week": "يبدأ الأسبوع يوم*",
        "selectTimezone": "اختر المجال الزمني",
        "vatBusiness": "الاسم التجاري لضريبة القيمة المضافة / ضريبة السلع والخدمات *",
        "editOrgHelp&support" : "إدارة وتحديث تفاصيل مؤسستك لتعكس هويتها، مما يضمن الحصول على معلومات دقيقة وعمليات سلسة. تعرف على المزيد حول تصميم ملف تعريف مؤسستك لإدارة سلسة.",
        "setMemberRateHelp&Support" : "قم بتحديد معدلات الفوترة، وتحسين تدفقات الإيرادات، ومواءمة خدماتك مع توقعات العملاء. تعرف على المزيد حول تكوين معدلات الفوترة التي تعكس قيمة خدماتك.",
        "memberTimeZoneHead" : "المنطقة الزمنية للأعضاء",
        "memberTimeZoneHelp&Support" : "تعاون عالمي من خلال تكوين مناطق زمنية فردية، مما يضمن التواصل السلس والجهود المنسقة عبر مواقع متنوعة. تعرف على المزيد حول إضافة أعضاء إلى مناطق زمنية متعددة للعمل الجماعي بسلاسة.",
        "membersWeekOffHead" : "أسبوع الأعضاء",
        "membersWeekOffHelp&Support" : "تخطيط تشغيلي أفضل من خلال تحديد إجازات أسبوعية مخصصة للأعضاء، وتعزيز الرفاهية وجدولة الموظفين بكفاءة. تعرف على المزيد حول توفير عطلة نهاية الأسبوع لتحسين التوازن بين العمل والحياة والإنتاجية.",
        "billRate($)": "سعر الفاتورة ($)",
        "billRate(₹)": "سعر الفاتورة (₹)",
        "billRate": "سعر الفاتورة"
      },
      "TimerApp": {
        "ScreenshotTimerHead": "مؤقت للتقاط لقطة الشاشة",
        "ScreenshotTimerHelp&Support" : "التقاط تقدم العمل باستخدام مؤقت للتقاط الشاشة. قم بتعيين فواصل زمنية للمؤقت بمقدار 5، 10، أو 15 دقيقة وفقًا لمتطلبات مشروعك. تعرَّف أكثر على كيفية تخصيص مؤقت التقاط الشاشة لتلبية احتياجات عملك الخاصة.",
        "IdleNotesHead" : "ملاحظات الخمول",
        "IdleNotesHelp&Support" : "قم بتحسين تتبع الوقت مع ملاحظات الخمول. سجّل فترات الراحة أو التشتيت لعرض أنماط العمل لتحليل إنتاجية الفرق بتفصيل. اعرف المزيد حول إضافة ملاحظات الوقت للحصول على رؤية حول عادات العمل وتحسين الكفاءة.",
        "DefineBreaksHelp&Support" : "حافظ على توازن سير العمل من خلال تحديد فترات استراحة منظمة. زرع فترات الراحة المجددة مع مراعاة احتياجات العمل العملي لفريقك. تعرف على المزيد حول تحديد فواصل الاستراحة لتعزيز الرفاهية والجدول الزمني المرن للعمل.",
        "DefineBreaksHead" : "تعريف الفترات الاستراحة",
        "BreakTimeLimitHelp&Support" : "حدد فواصل استراحة محددة مسبقًا لضمان الإنتاجية الأمثل ومنع تمديد الفترات الاستراحة لتحسين معدلات إكمال المهام. استكشف المزيد حول تحديد أوقات الفترة الاستراحة للحفاظ على بيئة عمل صحية لتحقيق مزيد من الكفاءة.",
        "BreakTimeLimitHead" : "حدود الوقت للفترة الاستراحة",
        "TrackingModeHelp&Support" : "اختر بين وضعي 'مرئي' و 'خفي' لمراقبة الوقت، مما يوفر مرونة في التتبع واستيعاب أوقات العمل المختلفة. اقرأ المزيد حول تبديل أوضاع التتبع لتناسب تفضيلاتك وبيئة العمل."
      },
      "Department": {
        "DepartmentHelp&Support": "قم بإعداد الأقسام لتنظيم فرق العمل. حدد رؤساء الأقسام، ووافق على جداول الأوقات، والمزيد لإطار تشغيلي إنتاجي. تعرف على المزيد حول إعداد الأقسام لتحسين تخصيص المهام والتعاون وإدارة أفضل للمشروعات."
        },
        "Members": {
        "memberIdHelp&Support": "قم بإعداد معرفات الأعضاء الفريدة لتعزيز التعرف على المستخدمين. قم بتحسين تصفح النظام باستخدام معرفات الأعضاء الشخصية.",
        "desigantionHelp&Support": "أنشئ مختلف الألقاب الوظيفية أو التعيينات وقم بتعيينها للموظفين. سيساعدك ذلك في إدارة الموارد بطريقة هرمية.",
        "profileSettingsHelp&Support": "قم بتعيين الخصوصية والأذونات. إذا كنت ترغب في أن يرى الموظفون ملف تعريف موظفين آخرين."
        },
        "expense": {
          "expanseText": "تحديد فئة المصاريف أو تفعيل/تعطيل فئة المصاريف لجميع الأعضاء في المنظمة",
          "addCategories": "إضافة فئة",
          "searchPlaceholder": "البحث عن فئة",
          "categoryName": "اسم الفئة",
          "statusText": "الحالة",
          "deleteText": "حذف",
          "defineCategory": "تحديد فئة المصاريف",
          "DefineExpenseHelp&Support": "قم بتجميع جميع النفقات المتعلقة بالمشروع في مكان واحد بحيث يمكن للمنظمات تحقيق تحكم مالي أفضل، وضمان الشفافية، وتعزيز كفاءة إدارة المشروع بشكل عام.",
          "saveText": "حفظ",
          "sureToDelete": "هل أنت متأكد من أنك تريد حذف هذه الفئة؟"
        },
      "memberWeeklyOff": "أيام إجازة أسبوعية للأعضاء",
      "memberTimezone": "المنطقة الزمنية للأعضاء",
      "setBillingRate": "تعيين أسعار الأعضاء"
    },
    "feature": {
      "activity": {
        "updateVisiblityStatus": "تم تغيير الوضع بنجاح.",
        "hideActivity": "إخفاء قائمة النشاط للمستخدمين (بما في ذلك لقطة الشاشة والتطبيقات وعناوين URL)",
        "noData": "لا يوجد أعضاء حاضرين في هذه اللحظة!",
        "hideScreenshot" : "توقف عن إظهار إخطارات التطبيق لأخذ لقطات الشاشة",
        "productivity-setting": "تتبع ساعات الإنتاج الفعلية للأعضاء عن طريق تحديد تطبيقات/عناوين URL منتجة وغير منتجة",
        "Disable" : "إبطال",
        "Enable": "يُمكَِن",
        "DeleteScreenshot": "اسمح للمديرين والمالكين بحذف لقطات الشاشة.",
        "INDIVIDUAL SETTING": "الإعداد الفردي",
        "MEMBERS": "أعضاء",
        "controlSSFrequency": "التحكم في وتيرة لقطات الشاشة.",
        "SsBlur": ".تحكم في ما إذا كان تطبيق سطح المكتب يطمس لقطات الشاشة للأمان والخصوصية",
        "TrackUrl": "التحكم في ما إذا كان يتم تتبع أسماء التطبيقات المستخدمة وعناوين URL التي تمت زيارتها",
        "Ok": "نعم",
        "Track Apps & URLs both": "تتبع التطبيقات وعناوين URL على حد سواء",
        "Track URLs only": "تتبع عناوين URL فقط",
        "Track Apps only": "تتبع التطبيقات فقط",
        "OFF": "عن",
        "Add Unproductive": "أضف غير منتج",
        "Add productive": "أضف الإنتاجية",
        "URLs": "عناوين URL",
        "Apps": "تطبيقات",
        "All Teams": "كل الفرق",
        "Search": "يبحث",
        "hideActivityMenuHelp&Support" : "تتيح Workstatus لأصحاب الفرق القدرة على إخفاء قائمة النشاط لمستخدميهم. تعرف المزيد حول كيفية إخفاء قائمة النشاط لتقليل التشتيت وتحسين إدارة المهام المركزة.",
"screenshotNotificationHelp&Support" : "ابق على اطلاع دائم مع التنبيهات الفورية حول إشعارات اللقطات. حافظ على المسؤولية والشفافية في عملك. تعرف المزيد حول كيفية البقاء على اطلاع دائم حول الأنشطة التي تظهر في اللقطات لتحقيق شفافية كاملة.",
"trackAppsUrlHelp&Support" : "قم بتحليل أنماط العمل مع رؤى صحيحة حول استخدام التطبيقات والمواقع لتحسين الإنتاجية. تعرف المزيد حول أدوات رصد مواقع الويب والتطبيقات للحصول على رؤى قيمة لتحسين إدارة الوقت.",
"blurScreenshotHelp&Support" : "تأكد من خصوصية البيانات مع خاصية تحريف اللقطات. حافظ على سرية المعلومات الحساسة في اللقطات، محققاً توازناً بين المساءلة والسرية. تعرف المزيد حول المزايا المتقدمة لمراقبة اللقطات للحفاظ على أعلى معايير الخصوصية.",
"screenshotFrequencyHelp&Support" : "قم بتخصيص تكرار التقاط اللقطات إلى 1x، 2x، 3x في فترات زمنية قدرها 5، 10، و 15 دقيقة. تعرف المزيد حول إدارة وتحرير تكرار التقاط اللقطات لمطابقة ديناميات مهمتك.",
"deleteScreenshotHelp&Support" : "حافظ على السيطرة على البيانات من خلال حذف اللقطات. نظم السجلات وتأكد من وجود نظرة واضحة على الأنشطة التي تم التقاطها. تعرف المزيد حول إدارة تاريخ لقطاتك للحفاظ على سجل دقيق.",
"productivitySettingsHelp&Support" : "تتبع الساعات الإنتاجية الفعلية لأعضاء الفريق عن طريق تعريف التطبيقات/المواقع الإنتاجية وغير الإنتاجية. استكشف المزيد حول كيفية تحسين أدوات الإنتاجية لتتناسب مع تفضيلاتك."
      },
      "timesheet":{
        "modifyTime":"السماح لأعضاء الفريق بتعديل وقتهم يدويًا.",
        "Yes":"نعم",
        "No":"لا",
        "individualSetting":"الإعداد الفردي",
        "MEMBERS":"أعضاء",
        "approvalTimesheet":"السماح لأعضاء الفريق بتحرير إعدادات الجدول الزمني لموافقة",
        "toggle1":"لا يتطلب موافقة",
        "toggle2":"الجدول الزمني اليدوي فقط",
        "toggle3":"كل من الجدول الزمني اليدوي والأوتوماتيكي",
        "popupApproval":"سيتطلب تغيير هذا الإعداد أن يتم إعادة تحميل الصفحة بحيث يمكن أن يكون التغيير فعالًا على مستوى النظام لجميع بيانات الجدول الزمني للأعضاء.الرجاء الضغط على موافق للمتابعة؟",
        "requireReason":"مطالبة أعضاء الفريق بتوفير سبب عند تحرير وقتهم.",
        "faceDetection":"اطلب من أعضاء الفريق أن يأخذوا Selfie و Do Clansion Condect",
        "save":"يحفظ",
        "facePer":"كم ٪ مسموح؟",
        "tooltipMessage":"سنطابق صورة selfie مع صور ملف تعريف للمستخدم والسماح أعلاه ٪ بالإدخال للمطابقة",
        "manageLimits":"السماح بالزمن الجامي بعد الحد؟",
        "togglemsg1":"أوقف الموقت وإظهار التنبيه",
        "togglemsg2":"دع المؤقت يمتلك بشكل طبيعي",
        "weeklyTimesheet":"السماح بتوقيت الجدول الزمني الأسبوعي",
        "OK":"نعم",
        "modifyTimeHelp&Support": "تعزيز المرونة عن طريق السماح لأعضاء الفريق بتحرير أوقاتهم يدوياً. تعرَّف أكثر على عملية تعديل الوقت لتحقيق تقارير شاملة ودقيقة.",
        "approvalTimesheetHelp&Support" : "بسهولة تامة عملية العمل مع موافقة الجداول الزمنية عن طريق السماح لأعضاء الفريق بتعديل إعدادات موافقة الجدول الزمني الخاص بهم. اعرف المزيد حول الموافقة على الجداول الزمنية لضمان المساءلة وإجراءات الموافقة الميسرة.",
"requireReasonhelp&Support" : "يجب على أعضاء الفريق تقديم سبب صحيح أثناء تعديل إدخالات وقتهم. تعرف على المزيد حول إضافة الأسباب لطلبات التنظيم للحفاظ على سجل دقيق.",
"faceDetectionhelp&Support" : "أضف أمانًا إلى تتبع الحضور الخاص بك لضمان التحقق الموثوق من الهوية، وتيسير التحكم في الوصول، وتعزيز نزاهة العمليات. تعرف المزيد حول نظام التحقق من الهوية بالصورة الشخصية لتعزيز عملية إدارة الحضور.",
"manageLimitsHelp&Support" : "ضبط عناصر التحكم في الوصول، وإدارة حدود المستخدمين، وتعزيز بروتوكولات الأمان للتحقق من الهوية. تعرف المزيد حول تخصيص الوصول لضمان عمليات آمنة ومسيطرة.",
"weeklyTimesheetsHelp&Support" : "قم بتنزيل تقرير الجداول الزمنية الأسبوعي للأفراد أو الفرق وأرسله إلى اسم عضو محدد. تعرف المزيد حول إدارة واستخدام الجداول الزمنية عبر الإنترنت لتحسين عمليات المرتبات."
        
      },
      "featureTabName":{
        "Timesheet":"ورقة التوقيت",
        "Schedules":"جداول",
        "modifyTime":"تعديل الوقت",
        "approvalTimesheet":"موافقة",
        "requireReason":"تتطلب سبب",
        "faceDetection":"الكشف عن الوجه",
        "manageLimits":"إدارة الحدود",
        "weeklyTimesheets":"الجداول الزمنية الأسبوعية",
        "shiftAlerts":"تنبيهات التحول",
        "minimumShiftHours":"ساعات التحول الحد الأدنى",
        "Activity": "نشاط",
        "Timeoff": "انتهى الوقت",
        "Jobsite": "موقع عمل",
        "Achievement": "إنجاز",
        "Notifications": "إشعارات",
        "Features Settings": "إعدادات الميزات"
        
      },
      "sidebar": {
        "hideActivity": "إخفاء قائمة النشاط",
        "SsNotification": "إخطارات لقطة الشاشة",
        "trackApps": "تتبع التطبيقات وعناوين URL",
        "blurSs": "لقطات طمس",
        "SsFreq": "تكرار لقطات الشاشة",
        "delSs": "حذف لقطات الشاشة",
        "productivity": "إعدادات الإنتاجية"
      },
      "schedule": {
        "receiveAlertNotification": "التحكم في من يتلقى تنبيهًا عن أحد الأعضاء",
        "Management": "الإدارة",
        "None": "لا أحد",
        "Both": "كلاهما",
        "shiftHours": "التحكم في الحد الأدنى من ساعات العمل حول العضو.",
        "shiftAlertHelp&Support" : "ابق على الوقت والعلم باستمرار مع تنبيهات الورديات. تلقى إشعارات في الوقت المناسب حول الورديات القادمة، مما يضمن جدولة سلسة وتحسين إدارة الوقت. تعرف المزيد حول كيفية ضبط تنبيهات الجدول لتحسين الانتظام.",
"shiftHourHelp&Support" : "حدد الحد الأدنى لساعات الورديات لضمان استخدام الموارد بشكل أمثل والحفاظ على توازن العبء العمل لتوزيع المهام. تعرف المزيد حول ضبط الحد الأدنى لساعات الورديات لتبسيط توزيع المهام وتعزيز التوازن التشغيلي."
      },
      "timeOff": {
        "noMembers": "لا يوجد أعضاء حاضرين في هذه اللحظة!",
        "timeOffPolicies": "سياسات الإجازات",
        "accrualSchedule": "الجدول المكتسب",
        "members": "أعضاء",
        "policy": "سياسة",
        "View": "منظر",
        "Edit": "يحرر",
        "Archive": "أرشيف",
        "Remove": "يزيل",
        "Unarchive": "أخرج من الأرشيف",
        "addPolicy": "أضف سياسة جديدة",
        "addPolicyMsg": "يمكنك إضافة السياسة الجديدة لـ Time Off للمؤسسة الكاملة أو لمستخدمين محددين داخل المؤسسة من هنا. عرض السياسة: اعرض تفاصيل السياسة الفردية هنا.",
        "editPolicyMsg": "يمكنك تعديل سياسة Time Off للمؤسسة الكاملة أو لمستخدمين محددين داخل المؤسسة من هنا.",
        "generalInfo": "معلومات عامة",
        "back": "خلف",
        "policyName": "اسم السياسة*",
        "memberslabel": "أعضاء",
        "automaticallyAddnewMem": "إضافة أعضاء جدد إلى هذه السياسة تلقائيًا",
        "scheduleOfAccrual": "جدول الاستحقاق *",
        "maxAccrualAmount": "الحد الأقصى لمبلغ الاستحقاق",
        "amountAccured": "المبلغ المستحق",
        "allowNegBal": "السماح بالرصيد السلبي *",
        "reqApproval": "يتطلب الموافقة *",
        "balanceRolls": "يتجدد الرصيد على أساس سنوي *",
        "paidOrUnpaid": "مدفوعة أو غير مدفوعة *",
        "viewTimeOff": "مشاهدة ملف Time Off",
        "viewTimeOffMsg": "اعرض تفاصيل طلب الإجازة الفردية من هنا.",
        "basicInfo": "معلومات اساسية",
        "policyPaidUnpaid": "الوثيقة مدفوعة / غير مدفوعة",
        "escalation": "التصعيد",
        "escalationMsg": "التحكم في طلب إجازة تصعيد التصعيد.",
        "idleNoteText": "تمكين ، تعطيل وضع علامة إلزامية ووضع علامة على أنها غير إلزامية للملاحظات الخاملة",
        "breakText": "تعريف الفاصل أو التمكين ، تعطيل الفاصل لجميع الأعضاء في المؤسسة",
        "breakName":"فترة إستراحة",
        "addBreak": "إضافة فاصل",
        "status": "حالة",
        "hours": "ساعات",
        "accruedHours": "ساعة مكتسبة لكل",
        "workedHours": "ساعات عمل",
        "save": "حفظ",
        "ok": "موافق",
        "hoursText": "ساعات العمل",
        "accuredHours": "الساعات المتراكمة لكل",
        "timeOffPoliciesHelp&Support" : "قم بإنشاء سياسات الإجازات الخاصة بك التي ستساعدك في إدارة إجازات الموظفين، وستتمكنون من أخذ إجازات بناءً على السياسات التي ستقومون بإنشائها هنا.",
        "escalationHelp&Support" : "حدد مسارات التصعيد والإجراءات لمعالجة التحديات بسرعة والحفاظ على سير العمل بسلاسة. تعرف المزيد حول إعداد بروتوكولات التصعيد لضمان حل المشكلات في الوقت المناسب وتعزيز سير العمل بدون انقطاع.",
        "editpolicy":"تحرير السياسة"
      },
      "jobsite":{
        "jobsiteTabHead": "قصر الموقت على مواقع العمل",
        "jobsiteTabText": "اسمح للأعضاء فقط بتتبع وقتهم أثناء تواجدهم في موقع العمل",
        "theadName": "اسم",
        "restrictTimerHelp&Support" : "قم بتقييد الموظفين لتشغيل مؤقتهم فقط في موقع العمل المخصص.",
"enterExitNotificationHelp&Support" : "ابقى على علم وفي السيطرة مع إشعارات الدخول/الخروج. احصل على تنبيهات فورية عندما يدخل أو يخرج أعضاء الفريق من مواقع معينة. اكتشف كيف يمكن لإشعارات الدخول والخروج أن تبقيك متصلاً وعلى علم."
      },
      "achievement":{
        "efficiency":{
          "achievementTabTitle": "الكفاءة المهنية",
          "achievementTabText": "تُمنح شارة الإنجاز هذه للأعضاء كل يوم يلبي نشاطهم هدف النشاط. يمكنهم الفوز بهذه الشارة عدة أيام متتالية لإنشاء خطوط ساخنة",
          "achievementDefault": "تقصير",
          "achievementActivity": "هدف النشاط",
          "achievementEfficiencyText": "الوصول إلى هدف النشاط كل يوم",
          "achievementButton": "تطبيق على الكل",
          "achievementTitle": "الكفاءة Pro",
          "efficienyProHelp&Support" : "فتح الأدوات المتقدمة والرؤى لتحسين سير العمل الخاص بك، وتسهيل المهام، وتحقيق أقصى إنتاجية. تعرف المزيد حول كيفية جعل فرقك محترفة لدفع عملك إلى الأمام."
        },
        "productivity":{
          "productivityTabText": "تُمنح شارة الإنجاز هذه للأعضاء كل أسبوع يحققون فيه هدف المهام الأسبوعي. يمكنهم الفوز بهذه الشارة لعدة أسابيع متتالية لإنشاء خطوط ساخنة.",
          "productivityChamp": "بطل الإنتاجية",
          "productivityChampCaps": "بطل الإنتاجية",
          "week-to-dos": "أهداف المهام الأسبوعية",
          "productivityText":"حقق هدف المهام المكتملة كل أسبوع",
          "weekly-to-dos": "أهداف TO-DOS الأسبوعية",
          "todos": "المهام",
          "productivityHelp&Support" : "كن بطلًا في الإنتاجية مع حلاً يتميز بالميزات. زيّن إنتاجك، وأدر المهام بسهولة، وحقق أهدافك بكفاءة. تعرف المزيد حول كيفية أن تصبح بطلاً في الإنتاجية لتمكين رحلتك في تحقيق الإنتاجية."
        },
        "timeHero": {
          "timeHeroTitle": "تايم هيرو",
          "timeHeroTabText": "تُمنح شارة الإنجاز هذه للأعضاء كل أسبوع ، حيث تحقق ساعاتهم هدف الساعات الأسبوعي. يمكنهم الفوز بهذه الشارة لعدة أسابيع متتالية لإنشاء خطوط ساخنة",
          "timeHeroText" : "الوصول إلى الهدف لإجمالي ساعات العمل كل أسبوع",
          "timeHeroActivityGoal": "هدف النشاطl",
          "timeHeroActivityWeekly": "هدف الساعات الأسبوعية",
          "timeHero": "بطل الوقت",
          "timeHeroHelp&Support" : "تحول إلى بطل الوقت مع ميزاتنا المبتكرة. تولّى السيطرة على وقتك، وتغلب على المهام، وتفوق في مساعيك مع إنتاجية ممكنة. تعرف المزيد حول إعدادات التحقيق لتصبح بطلاً للوقت."
        },
        "WorkSummary": {
          "dailyWorkSummaryText": "إدارة من سيتلقى إشعارات ملخص العمل اليومية عبر البريد الإلكتروني",
          "weeklyWorkSummaryText": "إدارة من سيتلقى إشعارات ملخص العمل الأسبوعي عبر البريد الإلكتروني",
          "dailyWorkSummaryTitle": "ملخص العمل اليومي",
          "weeklyWorkSummaryTitle": "ملخص العمل الأسبوعي",
          "dailyWorkSummaryHelp&Support" : "احصل على نظرات موجزة حول مهامك اليومية وإنجازاتك والأهداف الرئيسية للتخطيط الفعّال والتأمل. تعرف المزيد حول ملخص العمل اليومي لتحديد نجاحاتك اليومية.",
"weeklyWorkSummaryHelp&Support" : "احصل على نظرات موجزة حول مهامك الأسبوعية وإنجازاتك والأهداف الرئيسية للتخطيط الفعّال والتأمل. تعرف المزيد حول ملخص العمل اليومي لتحديد نجاحاتك اليومية."
        }
      }
    },
    "organisation":{
      "orgName": "المنظمات",
      "requestOrgBtn": "طلب المنظمة",
      "addOrgBtn": "أضف مؤسسة جديدة",
      "activeOrganisation": "المنظمات النشطة",
      "logo": "شعار",
      "tableName": "اسم",
      "emailId": "عنوان الايميل",
      "teamsize": "حجم الفريق",
      "industry": "صناعة",
      "managers": "مدراء",
      "editOrg": "تحرير المنظمة",
      "archiveOrg": "تنظيم الأرشيف",
      "urarchiveOrg": "منظمة غير مؤرشفة",
      "addNewOrgTitle": "أضف منظمات جديدة",
      "addOrgText": "يمكنك إضافة مؤسسة جديدة من هنا - سيمنحك ذلك المرونة لإدارة مؤسسات متعددة من حساب واحد.",
      "companydetails": "تفاصيل الشركة",
      "companyName":"اسم الشركة*",
      "selectIndustry": "حدد الصناعة *",
      "addTeamSize": "حدد حجم الفريق *",
      "goal": "هدف",
      "activetab": "نشيط",
      "archive": "أرشيف",
      "orgId": "معرف المؤسسة",
      "nodata": "لا توجد بيانات المنظمات"
    }
  },
  "Getting-started": {
    "createOrgCheckOne": "هل تقوم بإنشاء مؤسسة جديدة أم أنك تنضم إلى حساب شركة موجود؟"
  },
  "commonConst": {
    "Users": "المستخدمون",
    "Off": "عن",
    "On": "على",
    "none": "لا أحد",
    "timeZoneNote": "ملاحظة: الوقت المعروض حسب المنطقة الزمنية للمستخدم المختار."
  },
  "Project": {
    "active-project": {
        "ACTIVE PROJECTS": "المشاريع النشطة",
        "PROJECT NAME": "اسم المشروع",
        "MANAGERS": "المديرين",
        "USERS": "المستخدمين",
        "CLIENTS": "العملاء",
        "TYPE": "النوع",
        "STATUS": "الحالة",
        "Edit Project": "تحرير المشروع",
        "Archive Project": "أرشفة المشروع",
        "New Project": "مشروع جديد",
        "Active": "نشط",
        "moveProject": "نقل المشروع",
        "deleteProject": "حذف المشروع",
        "addNewProject": "إضافة مشروع جديد",
        "addText": "هنا، يمكنك إنشاء مشاريع وتعيينها للموارد الصحيحة وبدء التتبع.",
        "projectBasicInfo": "معلومات أساسية",
        "Save": "حفظ",
        "Manager": "المدير",
        "moveOrgText": "نقل المشروع إلى المؤسسة",
        "moveTodo": "نقل جميع المهام المتعلقة بهذا المشروع إلى المؤسسة المحددة",
        "orgLabel": "حدد المؤسسة للمشروع",
        "cantMoveOrg": "لا يمكنك نقل المشاريع لأنك مرتبط بمؤسسة واحدة فقط.",
        "confirmArchive": "هل أنت متأكد أنك تريد أرشفة هذا المشروع؟",
        "archiveCondition": "سيؤدي أرشفة المشروع إلى تعطيله، وسيتم إزالة المشاريع والمهام من جميع تطبيقات المنصة. هل ترغب في المتابعة؟",
        "backupAndDelete": "نسخ احتياطي وحذف",
        "Delete": "حذف",
        "deleteCondition": "لن تتمكن من التراجع عن هذا!",
        "noActivePoject": "لا توجد مشاريع نشطة!",
        "yes": "نعم",
        "no": "لا",
        "totalProject": "إجمالي المشاريع",
        "yetToStart": "لم يبدأ بعد",
        "inProgress": "قيد التقدم",
        "onHold": "معلق",
        "completed": "مكتمل",
        "archived": "مؤرشف",
        "cancelled": "ملغاة",
        "projectName": "اسم المشروع",
        "client": "العملاء",
        "status": "الحالة",
        "progress": "التقدم",
        "members": "الأعضاء",
        "viewProject": "عرض المشروع",
        "markAsCompleted": "وضع علامة كمكتمل",
        "deleteMilestone": "حذف البلوغ",
        "completedTodo": "المهام المكتملة",
        "budgeted": "الميزانية",
        "invested": "الاستثمار",
        "hrs": "ساعات",
        "overbudgeted": "أكثر من الميزانية",
        "isBillable": "قابلة للفوترة",
        "goBackToProject": "العودة إلى المشروع",
        "move": "نقل",
        "chooseGroup": "اختيار مجموعة",
        "setBudget": "تحديد الميزانية",
        "nameErr": "لا يتم قبول الأحرف الخاصة",
        "nameError": "لا يجب أن يتجاوز اسم المشروع حد 50 حرفًا",
        "projectManager": "مدير المشروع",
        "startDateErr": "يجب أن تكون تاريخ البداية قبل تاريخ الانتهاء.",
        "descErr": "الوصف لا يجب أن يتجاوز 400 حرف.",
        "addProjDecErr": "يجب ألا تتجاوز الوصف 2000 حرف",
        "hoursLimit": "يمكنك إدخال ساعات تصل إلى 99999999",
        "numberLimit": "يجب أن يكون الرقم بين 10 و 100.",
        "budget%": "تم استثمار % من الميزانية",
        "searchClient": "البحث عن العميل",
        "projectStatus": "حالة المشروع",
        "selectProjectMember": " أعضاء المشروع",
        "addTodoGroup": "إضافة مجموعة مهام",
        "addDesc": "إضافة وصف",
        "addBudget": "إضافة ميزانية",
        "setBillRate" : "تحديد معدل الفاتورة"
    },
    "archive-project": {
        "noArchiveProject": "لا توجد مشاريع مؤرشفة!",
        "Unarchive Project": "إلغاء أرشفة المشروع",
        "Projects": "مشاريع",
        "ProjectName": "اسم المشروع",
        "Archive": "مؤرشف"
    }
  },
  "dashboard": {
    "team": {
      "newTeam": "إضافة فريق",
      "searchTeam": "فريق البحث",
      "filters": "المرشحات",
      "project": "مشروع",
      "selectAProject": "اختر مشروعًا",
      "apply": "يتقدم",
      "clear": "واضح",
      "teamName": "اسم الفريق",
      "teamLead": "قائد الفريق",
      "teammates": "فرق",
      "projects": "المشاريع",
      "noTeams": "لا توجد فرق!",
      "editTeamText": "تحرير الفريق",
      "removeTeam": "إزالة الفريق",
      "newTeam1": "فريق جديد",
      "addTeam": {
        "addNewTeams": "إضافة فرق جديدة",
        "createTeamDeps": "قم بإنشاء فرق / أقسام مختلفة لتبسيط سير عملك.",
        "teamName": "اسم الفريق",
        "teammates": "الزملاء",
        "teamlead": "قائد الفريق",
        "selectAll": "اختر الكل",
        "none": "لا أحد"
      },
      "editTeam": {
        "editTeam": "تحرير الفريق",
        "editTeamDeps": "قم بتحرير فرق / أقسام مختلفة لتبسيط سير عملك.",
        "generalInfo": "معلومات عامة",
        "teammatesDet": "تفاصيل أعضاء الفريق",
        "teamLeadDet": "تفاصيل قائد الفريق",
        "projectDet": "تفاصيل المشاريع",
        "details": "تفاصيل",
        "createdBy": "انشأ من قبل",
        "permissions": "أذونات:",
        "approveTimesheet": "الموافقة على الجداول الزمنية",
        "approveTimeoffReq": "الموافقة على طلبات الإجازات",
        "scheduleShift": "جدولة الورديات",
        "continue": "يكمل",
        "cancel": "يلغي",
        "save": "يحفظ"
      }
    }
  },
  "todo": {
    "reportedBy":"تم الإنشاء/الإبلاغ بواسطة",
    "Export": "يصدّر",
    "Filter": "منقي",
    "TO-DOs": "المهام",
    "todo": "للقيام به",
    "Add To-Dos": "إضافة المهام",
    "noTodos": "لا توجد مهام!",
    "TODO DETAILS": "تفاصيل المهام",
    "TodoGroupStatus":"حالة مجموعة تودو",
    "MEMBERS": "أعضاء",
    "START DATE": "تاريخ البدء",
    "END DATE": "تاريخ الانتهاء",
    "PRIORITY": "أولوية",
    "ATTACHMENTS": "المرفقات",
    "TYPE": "يكتب",
    "STATUS": "حالة",
    "PROGRESS": "تقدم",
    "Low": "قليل",
    "Medium": "واسطة",
    "High": "عالي",
    "View ToDo": "مشاهدة ملف ToDo",
    "Edit ToDo": "تحرير المهام",
    "Complete ToDo": "أكمل ToDo",
    "Delete ToDo": "حذف ToDo",
    "noTodo": "لا يوجد كل شيء!",
    "selectAProj": "اختر مشروعًا",
    "or": "أو",
    "searchProjs": "مشاريع البحث",
    "reopen": "ليو بن والتربة",
    "addTodoGroup": "إضافة مجموعة المهام",
    "showMore": "عرض المزيد",
    "showLess": "عرض أقل",
    "moveToOtherGroup": "نقل إلى مجموعة أخرى",
    "moveErr": "لا يمكنك نقل المهام لأن لديك فقط مجموعة مهام واحدة.",
    "moveOtherGroup": "نقل المهمة إلى مجموعة أخرى",
    "todoLowercase": "مهمة",
    "addTodo": {
      "newTodo": "إضافة تودو جديد",
      "addTodoText": "هنا ، يمكنك إنشاء المهام وتعيينها لمواردك والبدء في تتبع تقدمها.",
      "BI": "معلومات اساسية",
      "OD": "تفاصيل أخرى",
      "membName*": "اسم عضو*",
      "Cancel": "يلغي",
      "Continue": "يكمل",
      "startDate": "تاريخ البدء",
      "End Date": "تاريخ الانتهاء",
      "inputFileText": "سحب وإسقاط الملف هنا",
      "browseFile": "تصفح ملفات",
      "Recurrence": "تكرار",
      "Billable": "قابل للفوترة",
      "Save": "يحفظ",
      "recurrenceFrequency": "تكرار التكرار",
      "todoResult": "نتائج المهام التي يجب القيام بها",
      "addTodo": "إضافة مهمة",
      "addTodoSideNavText": "هنا، يمكنك إنشاء وتعيين المهام لمواردك وبدء تتبع تقدمها.",
      "backToGroups": "العودة إلى تودوس",
      "groupText": "قد لا يزيد الاسم عن 255 حرفًا.",
      "saveTodo": "حفظ المهمة",
      "additionalInfo": "معلومات إضافية",
      "endateErr": "يجب أن تكون تاريخ الانتهاء أكبر من تاريخ البداية.",
      "estimatedHours": "الساعات المتوقعة",
      "rangeError": "يرجى إدخال قيمة بين 00:00 و 99999:59",
      "progress": "التقدم",
      "percRangeError": "يرجى إدخال القيمة بين 0 و 100",
      "addTodoGroup": "مجموعة المهام*",
      "Expand": "توسيع",
      "Collapse": "طي",
      "estimated": "مقدر",
      "rangeTImeErr": "الرجاء إدخال قيمة أكبر من 00.01",
      "save&Add":"حفظ وإضافة المزيد",
      "todoGroupText": "مجموعة المهام"
    },
    "editTodo": {
      "edit": "تحرير Todo",
      "editText": "هنا ، يمكنك إنشاء المهام وتعيينها لمواردك والبدء في تتبع تقدمها.",
      "BI": "معلومات اساسية",
      "OD": "تفاصيل أخرى",
      "addMore": "أضف المزيد",
      "recurrenceText": "قم بإنشاء التكرار التالي بعد إغلاق المهمة الحالية.",
      "nameErr": "قد لا يزيد الاسم عن 255 حرفًا.",
      "attachFile": "إرفاق ملف",
      "investedHours": "الساعات المستثمرة",
      "lastEdited": "آخر تعديل",
      "createdBy": "تم الإنشاء بواسطة والوقت",
      "parentTodoLabel": "اسم المهمة الأم",
      "todoName": "اسم المهمة*",
      "selectMembers": "تحديد الأعضاء*",
      "Asignee" : "المُخَصَّص",
      "Asignees" : "المُخَصَّصون",
      "ASSIGNEES" : "المكلف (المتنازل لهم)"
    },
    "viewTodo": {
      "view": "مشاهدة ملف TO-DO",
      "viewText": "عرض تفاصيل المهام الفردية هنا وتعديل التفاصيل بسرعة (إذا لزم الأمر).",
      "details": "تفاصيل المهام",
      "projectName": "*اسم المشروع",
      "name": "اسم المهام",
      "memberName": "اسم عضو",
      "startDate": "تاريخ البدء",
      "endDate": "تاريخ الانتهاء",
      "Priority": "أولوية",
      "Low": "قليل",
      "Medium": "واسطة",
      "High": "عالي",
      "Status": "حالة",
      "Progress": "تقدم",
      "Description": "وصف",
      "noDescription": "لا يوجد وصف متاح",
      "Attachments": "المرفقات",
      "No Attachment": "لا يوجد مرفق",
      "Download All": "تحميل الكل",
      "frequency": "التردد المتكرر*",
      "Daily": "يوميًا",
      "Weekly": "أسبوعي",
      "Monthly": "شهريا",
      "Yearly": "سنوي",
      "Once every": "مرة كل*",
      "End after": "تنتهي بعد*",
      "Edit To-do": "تحرير المهمة",
      "budgetoverview": "نظرة عامة على الميزانية",
      "TodoStatus" : "حالة المهمة",
      "AllToDoGroups" : "كل مجموعات المهام",
      "todos-worked": "المهام التي تم العمل عليها",
      "view-all": "عرض جميع المهام",
      "projectID": "معرف المشروع",
      "client": "العميل",
      "members": "الأعضاء",
      "createdBy": "تم الإنشاء بواسطة",
      "createdTime": "وقت الإنشاء",
      "totalTodo": "مجموع المهام",
      "due": "تاريخ الاستحقاق",
      "overdue": "تأخر",
      "recurring": "متكرر",
      "completed": "مكتمل(%)",
      "todoType": "نوع المهمة",
      "selectTodo": "تحديد نوع المهمة",
      "severity": "حدة",
      "tags": "وسوم",
      "nodata": "لاتوجد بيانات!",
      "loadMore": "تحميل المزيد",
      "todoType1": "نوع المهمة",
      "tag": "وسم",
      "selectPrioroty": "تحديد الأولوية",
      "selectoverdue": "تحديد المهام المتأخرة",
      "selectDue": "تحديد المهام المستحقة",
      "selectStatus": "تحديد الحالة",
      "selectSeverity": "تحديد الخطورة",
      "selectTags": "تحديد الوسوم",
      "searchProject": "البحث في المشروع",
      "selectProject": "تحديد المشروع",
      "addTags": "إضافة الوسوم",
      "attachment": "مرفق",
      "overview": "نظرة عامة",
      "typeHere": "اكتب هنا",
      "noDailyLimit": "لا يوجد حد يومي",
      "noWeekLimit": "لا يوجد حد أسبوعي"
    }
  },
  "addSingleLineTodo":{
    "placeholder":"أدخل اسم المهمة",
    "pressEnterText":"اضغط على Enter للحفظ",
    "assigneeTooltip":"المكلف (المكلفون)",
    "startEndDateTooltip":"تاريخ البدء/الانتهاء",
    "priorityTooltip":"يضع أولويات",
    "taskListTooltip":"قائمة المهام",
    "typeTooltip":"نوع المهام",
    "enstimatedHoursPlaceholder":"سمو: مم",
    "estimatedHoursTooltip":"الساعات المقدرة",
    "estimatedHoursError":"الساعات المقدرة بين 00:00 و99999:59"
  },
  "workOrder": {
    "Work Orders": "طلبات العمل",
    "+ Add a Work Order": "+ أضف طلب عمل",
    "weekly": "أسبوعي",
    "Date Range": "نطاق الموعد",
    "noWorkOrders": "لا توجد أوامر عمل",
    "WORKORDER": "عامل",
    "ASSIGNEES": "المندوبون",
    "ADDRESS": "عنوان",
    "BUDGET": "ميزانية",
    "NEXT JOB": "الوظيفة التالية",
    "STATUS": "حالة",
    "UNSCHEDULED": "غير مقيد",
    "MISSED": "مٌفتَقد",
    "noUpcomingJob": "لا توجد وظائف قادمة",
    "ACTIVE": "نشيط",
    "CLOSED": "مغلق",
    "editOrder": "تحرير أمر العمل",
    "viewOrder": "عرض أمر العمل",
    "closeOrder": "وضع علامة مغلق",
    "Work Order Detail": "تفاصيل أمر العمل",
    "Edit": "يحرر",
    "MarkasClosed": "وضع علامة مغلق",
    "CLIENT DETAILS": "تفاصيل العميل",
    "Name": "اسم",
    "Mobile": "متحرك",
    "Email": "بريد إلكتروني",
    "ClientAddress:": "عنوان العميل:",
    "UPCOMING JOBS": "الوظائف القادمة",
    "Date": "تاريخ",
    "Time": "وقت",
    "schedulejob": "جدولة وظيفة",
    "Never": "أبداً",
    "Monthly": "شهريا",
    "Instructions": "تعليمات",
    "JOBS": "وظائف",
    "+ Add Job": "+ أضف الوظيفة",
    "JOB ID": "معرف الوظيفة",
    "DATE": "تاريخ",
    "View Job": "مشاهدة الوظيفة",
    "Delete Job": "حذف الوظيفة",
    "noWorkOrder": "لا توجد أوامر عمل",
    "wishToContinue": "هل مازلت ترغب في الاستمرار؟",
    "Yes": "نعم",
    "No": "لا",
    "Workorder": "أمر العمل",
    "Client Name": "اسم العميل",
    "Status": "حالة",
    "Scheduled": "المقرر",
    "Address": "عنوان",
    "Assignees": "المعينون",
    "Details": "تفاصيل",
    "Time Worked": "يعمل الوقت",
    "Time Started": "بدأ الوقت",
    "Time Stoped": "توقف الوقت",
    "MarkasRead": "ضع إشارة مقروء",
    "createWO": "إنشاء أمر عمل",
    "jobsiteInfo": "معلومات موقع العمل",
    "budgetDetails": "تفاصيل الميزانية",
    "ON ARRIVAL": "عند الوصول",
    "ON DEPARTURE": "على رحيل",
    "includeNonBillable": "قم بتضمين الوقت غير القابل للفوترة",
    "addWO": "إضافة أمر عمل جديد",
    "Radius": "نصف القطر",
    "meters": "أمتار",
    "Next": "التالي",
    "scheduleWork": "جدولة العمل",
    "readyToSchedule": "هل أنت مستعد للجدولة والتنازل عنها؟",
    "NotNow": "ليس الآن",
    "Schedule": "الجدول الزمني لها"
  },
  "timeoff":{
    "timeoffTitle": "انتهى الوقت",
    "timeoffPolicyName": "اسم السياسة",
    "timeoffStart": "يبدأ",
    "timeoffEnd": "نهاية",
    "timeoffHours": "ساعات",
    "timeoffRequestedOn": "طلبت في",
    "timeoffUserReason": "سبب المستخدم",
    "timeoffStatus": "حالة",
    "timeoffViewReaquest":"عرض الطلب",
    "timeoffedit":"طلب التعديل",
    "timeoffDenyRequest":"رفض الطلب",
    "timeoffApproveRequest":"طلب الموافقة",
    "timeoffDeleteRequest":"حذف طلب",
    "timeoffescalate":"طلب تصعيد",
    "timeoffUpload": "رفع",
    "timeoffSettings": "إعدادات",
    "timeoffSubmitted": "مُقَدَّم",
    "timeoffApproved" : "موافقة",
    "timeoffDenied": "رفض",
    "timeoffRequestButton": "طلب إجازة",
    "timeoffNoData": "لا توجد طلبات إجازة!",
    "timeoffRequestText": "يمكن لموظفيك طلب إجازة ؛ يمكنك عرض أو قبول أو رفض طلب الإجازة من منصتنا الموحدة.",
    "timeOffBasicText": "معلومات اساسية",
    "timeoffCurrentBalance": "الرصيد الحالي",
    "timeoffTotalTime": "إجمالي الوقت المطلوب",
    "timeoffRemainingBalance": "الرصيد المتبقي",
    "timeoffMember": "عضو",
    "timeoffPolicy": "سياسة*",
    "timeoffSpan": "الفترة الزمنية*",
    "timeoffAllDay": "طوال اليوم",
    "timeoffRequestedTime": "الوقت المطلوب في اليوم *",
    "timeoffReason": "سبب*",
    "timeoffSave": "يحفظ",
    "timeoffBackButton": "خلف",
    "requestTimeoff": "طلب إجازة",
    "chooseFile": "اختيار ملف",
    "noFile": "لم يتم اختيار أي ملف",
    "fileFormat": "تنسيقات الملفات المقبولة: .csv، .xls، .xlsx",
    "downloadSpreadsheet": "تحميل قالب الجدول الإلكتروني",
    "Import": "استيراد",
    "Cancel": "إلغاء",
    "submitted": "تم التقديم",
    "provideReason": "يرجى تقديم سبب!",
    "Submit": "إرسال",
    "text1": "السياسة الزمنية المحددة لا تسمح برصيد سلبي",
    "text2": "السياسة المحددة لا تسمح بأرصدة سلبية أقل من 100",
    "hoursValidation": "الرجاء إدخال عدد الساعات المطلوبة أقل من",
    "hours": "ساعات",
    "Approved": "تمت الموافقة",
    "noResult": "لم يتم العثور على نتائج",
    "Upgrade": "ترقية",
    "File": "ملف",
    "Denied": "تم الرفض"
  },
  "activityModule": {
    "screenshotsSection": {
      "screenshotActivity": "نشاط لقطة الشاشة",
      "filters": "المرشحات",
      "export": "يصدّر",
      "dailtTotal": ":المجموع اليومي",
      "viewTimesheet": "عرض الجدول الزمني",
      "viewApp": "عرض التطبيقات",
      "viewUrl": "عرض URL",
      "totalTimeWorked": "إجمالي وقت العمل:",
      "noActivity": "لا يوجد نشاط",
      "today": "اليوم",
      "noScreenshots": "لقطات الشاشة غير متوفرة!",
      "deleteSelectedScreenshot": "هل أنت متأكد أنك تريد حذف لقطة الشاشة المحددة؟",
      "deleteAllSelectedScreenshots": "هل أنت متأكد أنك تريد حذف كل لقطة الشاشة المحددة؟",
      "filterLabelProject": "مشروع",
      "filterLabelTime": "وقت",
      "filterLabelActivityLevel": "مستوى النشاط",
      "apply": "يتقدم",
      "clear": "واضح",
      "manualActivity": "نشاط يدوي",
      "ViewImage": "عرض الصورة",
      "viewAttendance": "عرض الحضور"
    },
    "appSection": {
      "apps": "تطبيقات",
      "noApps": "أنت لا تستخدم أي تطبيقات حاليًا!",
      "appWebsite": "التطبيق / الموقع",
      "project": "مشروع",
      "website": "موقع الويب",
      "timeSpent": "قضاء الوقت",
      "session": "حصة",
      "category": "فئة",
      "viewScreenshot": "مشاهدة لقطة الشاشة"
    },
    "urlSection": {
      "url": "URL",
      "noUrls": "أنت لا تستخدم أي عناوين URL حاليًا!"
    }
  },
  "jobsite": {
    "Job Sites": "مواقع العمل",
    "addjobsite": "أضف موقع عمل",
    "siteMembers": "الأعضاء في الموقع الآن",
    "totalVisits": "إجمالي الزيارات اليوم",
    "deleteJobsiteCheck": "هل أنت متأكد أنك تريد حذف موقع العمل؟",
    "ON ARRIVAL": "عند الوصول",
    "Do not start timer": "لا تبدأ الموقت",
    "ON DEPARTURE": "على رحيل",
    "Do not stop timer": "لا توقف الموقت",
    "Add New User": "إضافة مستخدم جديد",
    "Track Time to project": "تتبع الوقت للمشروع",
    "Automatic Timer Actions": "إجراءات الموقت التلقائي",
    "Radius": "نصف القطر",
    "Configure Settings": "تكوين الإعدادات",
    "Add Job Location": "أضف موقع الوظيفة",
    "jobsiteText": "قم بتحديد المناطق الجغرافية حول مواقع العمل المخصصة، وابدأ في التتبع عند دخول الموظفين إلى مواقع العمل أو خروجهم منها. يمكنك أيضًا تتبع إجمالي الوقت الذي يقضيه كل موظف في موقع عمل معين.",
    "addJobsite": "إضافة موقع عمل جديد",
    "EditJobSite": "تحرير موقع العمل",
    "searchLocation": "موقع البحث لإضافة موقع العمل",
    "searchJobSites": "البحث في مواقع العمل",
    "sortBy": "ترتيب حسب",
    "cancel": "يلغي",
    "continue": "يكمل",
    "Loading": "جار التحميل",
    "membOnSite": "الأعضاء في الموقع الآن",
    "totalVisit": "إجمالي الزيارات اليوم",
    "report": "عرض تقارير موقع العمل",
    "Address": "العنوان*",
    "Cancel": "إلغاء",
    "Continue": "متابعة",
    "Save": "حفظ",
    "Group": "مجموعة",
    "searchLocationPlaceholder": "البحث عن الموقع"
  },
  "profile": {
    "profile": "حساب تعريفي",
    "editProfile": "عدل ملفك الشخصي",
    "fullName": "الاسم الكامل",
    "countryCode": "الرقم الدولي",
    "phoneNumber": "رقم الهاتف.",
    "email": "بريد إلكتروني",
    "Password":"كلمة المرور",
    "timezone": "وحدة زمنية",
    "emailAlert": "التحقق من البريد الإلكتروني معلق لـ",
    "timezoneText": "إذا لم تجد منطقة زمنية محددة في نظامك ، فيرجى تحديد أقرب مدينة بنفس رمز GMT.",
    "weeklyOffset": "راحه اسبوعيه",
    "cancel": "يلغي",
    "save": "يحفظ",
    "deactivate": "تعطيل الحساب",
    "delete": "حذف الحساب",
    "changeEmail": "تغيير الايميل",
    "currentemail": "إدخل كلمة السر الحالية *",
    "enterNew": "أدخل بريدًا إلكترونيًا جديدًا",
    "contfirmMail": "تأكيد البريد الإلكتروني الجديد",
    "detect" : "يكشف",
    "changePassword": "تغيير كلمة المرور",
    "newPassword": "كلمة المرور الجديدة *",
    "confirmPassword": "تأكيد كلمة المرور *",
    "currentPassword": "كلمة السر الحالية *",
    "changePicture" : "تغيير الصورة",
    "addPicture" : "إضافة الصورة",
    "addPictureText": "يرجى التأكد من أن وجهك مرئي بوضوح أثناء تحميل صورة (صور) ملفك الشخصي. إنه أمر مهم لأنه سيتم استخدام صور ملفك الشخصي في التعرف على الوجوه إذا بدأت الوقت على هاتفك المحمول.",
    "bottomImg": "لصق وصورة ، سحب وإفلات الملفات أو",
    "clickHere": "انقر هنا",
    "toUpload": "للتحميل",
    "deactivateTxt": "إلغاء التنشيط",
    "IdLabel": "أدخل اسم الهوية",
    "changePwd": "تغيير كلمة المرور",
    "deleteText": "حذف الحساب - بعد حذف حسابك، لن تكون مسموحًا لك بتسجيل الدخول إلى حسابك ولن تتمكن أيضًا من تشغيل تطبيق سطح المكتب الخاص بك.",
    "accountErr": "رقم الحساب غير صالح. يجب أن يكون طوله 16 رقمًا.",
    "accountValidation": "يجب أن يكون طوله 11 حرفًا معرفيًا وألفا رقميًا.",
    "uploadTooltip": "أنواع الملفات المدعومة هي .pdf، .jpeg، .png، .jpg، .tiff، .eps الحد الأقصى لحجم الملف المدعوم هو 1 ميغابايت",
    "enableSetting": "الرجاء تفعيل خيار التعديل",
    "viewPhoto": "عرض جميع الصور",
    "selectTodo": "اختيار مهمة",
    "invalidFileType": "نوع الملف غير صالح"
  },
  "people": {
    "bulInvite": {
      "noData": "لاتوجد بيانات",
      "dateTime": "التاريخ والوقت",
      "sheetName": "اسم الورقة",
      "INVITESUMMARY": "ملخص دعوة",
      "viewDetails": "عرض التفاصيل",
      "Member Details": "تفاصيل العضو",
      "Status": "حالة",
      "Reason": "سبب",
      "SUCCESS": "نجاح",
      "FAILED": "فشل"
    }
  },
  "schedule": {
    "Schedules": "جداول",
    "Add Schedule": "إضافة جدول",
    "MEMBERS": "أعضاء",
    "addScheduleText": "يمكنك الآن إنشاء ورديات الموظفين وتعيينها وتتبعها بسهولة. أصلح تباينات الورديات مثل وصول الموظفين متأخرًا في الورديات أو ترك الورديات.",
    "basicInfo": "معلومات اساسية",
    "editSchedule": "تحرير الجدول",
    "deleteAll": "نعم، احذف الكل!",
    "deleteOne": "لا، احذف فقط واحد!",
    "areYouSure": "هل أنت متأكد؟",
    "deleteSchedule": "هل تريد حذف جميع الجداول؟",
    "Upgrade": "ترقية",
    "noSchedule": "لا يوجد جدول متغير لهذا التاريخ!",
    "Weekly": "أسبوعياً",
    "Today": "اليوم",
    "noResult": "لم يتم العثور على نتائج",
    "allMembers": "جميع الأعضاء",
    "allTeams": "جميع الفرق",
    "Teams": "الفرق"
  },
  "client": {
    "editClient": {
      "editclient": "تحرير العميل",
      "editText": "قم بتحرير العملاء ودعوتهم لعرض وتتبع المشاريع / المهام لتحقيق المزيد من الشفافية في عمليتك.",
      "GI": "معلومات عامة",
      "CI": "معلومات الاتصال",
      "PD": "تفاصيل المشروع",
      "BD": "تفاصيل الميزانية",
      "Cancel": "يلغي",
      "Continue": "يكمل",
      "billableTime": "قم بتضمين الوقت غير القابل للفوترة",
      "Save": "يحفظ",
      "startDate": "تاريخ البدء",
      "address": "عنوان العميل",
      "email": "البريد الإلكتروني للعميل",
      "code": "الرقم الدولي",
      "phone": "رقم الهاتف.",
      "Project": "مشروع",
      "Type": "يكتب",
      "Based": "مرتكز على",
      "cost": "يكلف",
      "Hours": "ساعات",
      "Notify": "يخطر في",
      "Resets": "يعيد",
      "budgetMode": "وضع الميزانية",
      "budgetType": "نوع الميزانية",
      "projectPerHr" : "سعر المشروع لكل"
    },
    "addClient": {
      "addNewClient": "إضافة عميل جديد",
      "addClientText": "أضف عملاء وادعهم لعرض وتتبع المشاريع / المهام لإضفاء مزيد من الشفافية على عمليتك.",
      "editClient": "تحرير العميل"
    },
    "activeClient": {
      "activeclient": "العملاء النشطين",
      "clientDetail": "تفاصيل العميل",
      "PHONE": "هاتف",
      "budget": "ميزانية العميل",
      "PROJECTS": "المشاريع",
      "Total": "المجموع",
      "Archive client": "عميل الأرشيف",
      "InvitationStatus" : "حالة الدعوة"
    },
    "archiveClients": {
      "ARCHIVEDCLIENTS": "العملاء المؤرشفين",
      "NAME": "اسم",
      "EditClient": "تحرير العميل",
      "restoreClient": "استعادة العميل"
    }
  },
  "timesheetModule": {
    "viewTimesheetSection": {
      "timeType": "نوع الوقت",
      "breakType": "نوع الاستراحة",
      "viewTimesheet": "عرض الجدول الزمني",
      "noData": "لا تتوافر بيانات!",
      "addTime": "إضافة وقت",
      "addTimeLog": "أضف سجل الوقت",
      "addWeeklyTimeLog": "أضف سجل الوقت الأسبوعي",
      "today": "اليوم",
      "weekly": "أسبوعي",
      "monthly": "شهريا",
      "dateRange": "نطاق الموعد",
      "note": "ملاحظة: الوقت المعروض حسب المنطقة الزمنية للمستخدم المختار.",
      "filters": "المرشحات  ",
      "exports": "صادرات",
      "switchView": "عرض التبديل",
      "projectDetails": "تفاصيل المشروع",
      "activityTime": "وقت النشاط",
      "idleTime": "وقت الخمول",
      "manualTime": "الوقت اليدوي",
      "breakTime": "فترة إستراحة",
      "effectiveTime": "الوقت الفعلي",
      "duration": "مدة",
      "nodataDate": "لا توجد بيانات لهذا التاريخ!",
      "time": "وقت",
      "allTimetype": "كل نوع الوقت",
      "normal": "طبيعي",
      "idle": "عاطل",
      "manual": "يدوي",
      "activityLevel": "مستوى النشاط",
      "allActivityLevel": "كل مستوى النشاط",
      "apply": "يتقدم",
      "clear": "واضح",
      "viewTimesheetOption": "عرض الجدول الزمني",
      "deleteTimesheetOption": "حذف الجدول الزمني",
      "viewScreenshot": "مشاهدة لقطة الشاشة",
      "viewApps": "عرض التطبيقات",
      "viewUrl": "عرض URL",
      "weeklyTimeline": "الجدول الزمني الأسبوعي",
      "date": "تاريخ",
      "timeTracked": "الوقت المتعقب",
      "startTime": "وقت البدء",
      "endTime": "وقت النهاية",
      "effectiveTime": "الوقت الفعال",
      "activityPer": "النشاط %",
      "activity": "النشاط",
      "timeline": "الجدول الزمني",
      "addTimesheet": "إضافة الجدول الزمني",
      "addTimeDesc": "يمكنك إدخال الجدول الزمني اليدوي من هنا لأي تاريخ ووقت محدد.",
      "genInfo": "معلومات عامة",
      "memNameLabel": "اسم عضو",
      "projNameLabel": "اسم المشروع",
      "todoNameLabel": "اسم المهام",
      "billable": "قابلة للفوترة",
      "reason": "سبب",
      "noteSwitch": "ملحوظة",
      "save": "يحفظ",
      "advanceFilter": "تصفية متقدمة",
      "downloadTimesheet": "تحميل جداول الزمن",
      "PDF": "PDF",
      "CSV": "CSV",
      "PRODUCTIVITY": "الإنتاجية",
      "TIME": "الوقت",
      "workingHours": "ساعات العمل",
      "totalHours": "إجمالي الساعات",
      "editTimesheet": "تحرير جدول الزمن",
      "deleteTimesheet": "حذف جدول الزمن",
      "screenTime": "وقت الشاشة",
      "mobileTime": "وقت الجوال",
      "canDeletetimesheet": "هل ترغب في حذف هذا الجدول الزمني؟",
      "text1": "سيؤدي حذف جدول الزمن إلى حذف جميع بيانات النشاط بما في ذلك لقطات الشاشة والتطبيقات وعناوين URL",
      "Automatic": "تلقائي",
      "Manual": "يدوي",
      "Both": "كليهما",
      "whyareyouadding": "لماذا تقوم بالإضافة؟",
      "workingOn": "على ماذا كنت تعمل؟"
    },
    "approveTimesheetSection": {
      "timesheetApproval": "الموافقات على الدوام",
      "manualTimesheet": "الجدول الزمني اليدوي",
      "automaticTimesheet": "الجدول الزمني التلقائي",
      "timesheetType": "نوع الجدول الزمني",
      "memberName": "اسم عضو",
      "date": "تاريخ",
      "startTime": "وقت البدء",
      "endTime": "وقت النهاية",
      "duration": "مدة",
      "activity": "نشاط",
      "status": "حالة",
      "reason": "سبب",
      "viewreq": "عرض الطلب",
      "approve": "يعتمد",
      "deny": "ينكر",
      "provideReason": "يرجى تقديم السبب!",
      "typeReason": "اكتب السبب الخاص بك",
      "submit": "يُقدِّم",
      "viewTimesheet": "عرض الجدول الزمني",
      "viewTimeDesc": "يمكنك عرض تفاصيل الجدول الزمني من هنا بالإضافة إلى السبب والملاحظات المقدمة (إن وجدت).",
      "genInfo": "معلومات عامة",
      "memName": "اسم عضو",
      "datey": "تاريخ",
      "startTimey": "وقت البدء",
      "endTimey": "وقت النهاية",
      "durationy": "مدة",
      "activityy": "نشاط",
      "statusy": "حالة",
      "approvedBy": "تمت الموافقة عليه من قبل",
      "doApproval": "تاريخ الموافقة",
      "toApproval": "وقت الموافقة",
      "reasonForTimesheet": "سبب الجدول الزمني",
      "reasonForApproval": "سبب الموافقة",
      "Back": "رجوع",
      "deniedBy": "تم الرفض بواسطة",
      "deniedDate": "تاريخ الرفض",
      "deniedTime": "وقت الرفض",
      "denialReason": "سبب الرفض",
      "Notes": "ملاحظات",
      "projectName": "اسم المشروع",
      "todoName": "اسم المهمة",
      "Idle": "خمول",
      "Manual": "يدوي",
      "timeSpan": "فاصل زمني (IST)",
      "Reason": "السبب",
      "editTimesheet": "تحرير الجدول الزمني",
      "timeValidation": "يرجى إدخال الوقت في تنسيق AM/PM",
      "startEndTimeValidation": "يجب أن يكون وقت الانتهاء أكبر من وقت البدء",
      "textone": "يمكنك إدخال الجدول الزمني اليدوي من هنا لتاريخ ووقت محددين.",
      "timeFormat": "يرجى إدخال الوقت في تنسيق AM/PM",
      "Billable": "قابل للفوترة",
      "type": "نوع",
      "dataUnavailable": "البيانات غير متوفرة!",
      "timesheetUpdated": "تم تحديث الجدول الزمني بنجاح."     
    },
    "attendanceSection": {
      "attendance": "حضور",
      "attendanceEntry": "دخول الحضور",
      "shiftEntry": "دخول التحول",
      "last30days": "آخر 30 يومًا",
      "checkIn": "تحقق في",
      "checkOut": "الدفع",
      "attendanceVisual": "الحضور المرئي",
      "totalHrs": "إجمالي الساعات",
      "effectiveHrs": "فعالة HRS",
      "arrivals": "الوصول",
      "log": "سجل",
      "firstPunchIn": "أول لكمة في (ق) وآخر (ق) لكمة",
      "allPunchIn": "كل دخل (م) و (ق) لكمة (ق)",
      "advanceFilter": "تصفية متقدمة",
      "Download": "تحميل",
      "exportPDF": "تصدير كملف PDF",
      "exportCSV": "تصدير كملف CSV",
      "weeklyOff": "يوم كامل للراحة الأسبوعية",
      "Missed": "تم التفويت فيه",
      "Abandoned": "تم التخلي عنه",
      "Incompleted": "غير مكتمل",
      "dataNotAvailable": "البيانات لهذا النطاق الزمني غير متاحة!",
      "Delayed": "متأخر",
      "onTime": "في الوقت المحدد",
      "All": "الكل",
      "pendingShift": "الوردية المعلقة",
      "completedShift": "الوردية المكتملة",
      "mapView": "عرض الخريطة",
      "keyboard": "لوحة المفاتيح",
      "mouse": "فأرة",
      "attendanceStats": "إحصائيات الحضور",
      "totaldays": "الأيام الإجمالية",
      "workingDays": "أيام العمل الإجمالية",
      "daysPresent": "أيام الحضور",
      "daysAbsent": "أيام الغياب",
      "workingHrs": "متوسط ساعات العمل",
      "breakHrs": "متوسط ساعات الاستراحة",
      "attendancePercentage": "الحضور بالنسبة",
      "totalLeaves": "إجمالي الإجازات",
      "paid": "مدفوع",
      "unpaid": "غير مدفوع"
    },
    "WeeklyTimeLog": {
      "weeklyTimesheet": "الجدول الزمني الأسبوعي",
      "Weekly": "أسبوعي",
      "addRow": "إضافة صف",
      "USER": "المستخدم",
      "PROJECT": "المشروع",
      "TOTAL": "الإجمالي",
      "markAsBillable": "وضع علامة كقابل للفوترة",
      "addTime": "إضافة وقت",
      "timeSpan": "فاصل زمني (IST)"
    }
  },
  "members": {
    "invitationTomember":"إرسال دعوة إلى العضو",
    "bulkInvite": "دعوة مجمعة",
    "inviteNewMem": "قم بدعوة أعضاء جدد",
    "total": "المجموع",
    "working": "يعمل",
    "online": "متصل",
    "offline": "غير متصل على الانترنت",
    "deleted": "تم الحذف",
    "deactivated": "معطلة",
    "userType": "نوع المستخدم",
    "selectUserType": "حدد نوع المستخدم",
    "member": "عضو",
    "role": "دور",
    "projects": "المشاريع",
    "status": "حالة",
    "payment": "قسط",
    "priority": "الحد اليومي/الحد الأسبوعي",
    "editLimit": "تحرير الحد",
    "disableTracking": "تعطيل التتبع",
    "removeMember": "إزالة العضو",
    "enableTracking": "تمكين التتبع",
    "searchMember": "عضو البحث",
    "importBulk": "استيراد أعضاء مجمعة",
    "importBulkDesc": "يمكنك تحميل ملف xls مع تفاصيل الأعضاء أدناه لبدء عملية الدعوة المجمعة.",
    "clickHere": "انقر هنا",
    "to": "ل",
    "downloadSample": "تنزيل ملف نموذج وملء تفاصيل الأعضاء.",
    "importFiles": "ملف استيراد مليء ببيانات الأعضاء",
    "toUpload": "للتحميل",
    "proceed": "يتابع",
    "inviteANewMem": "قم بدعوة عضو جديد",
    "inviteANewMemDesc": "قم بدعوة موظفيك بسهولة وابدأ في تتبع تقدمهم ؛ الأمر بهذه البساطة!",
    "basicInfo": "معلومات اساسية",
    "memEmailId": "معرف البريد الإلكتروني للعضو *",
    "selectRole": "حدد دورًا لهذا العضو *",
    "visibleMode": "الوضع المرئي",
    "stealthMode": "وضع التخفي",
    "selectProj": "حدد مشاريع لهذا العضو",
    "memEmail": "البريد الإلكتروني للعضو",
    "editInvite": "تحرير الدعوة",
    "cancelInvite": "إلغاء الدعوة",
    "resendinvite": "إعادة إرسال الدعوة",
    "editLimitDesc": "يمكنك تحديد الحد الزمني اليومي والأسبوعي للعضو الفردي من هنا جنبًا إلى جنب مع معدل الدفع بالساعة.",
    "memberEditlimit": "عضو*",
    "weeklyLimit": "الحد الأسبوعي",
    "dailyLimit": "الحد اليومي",
    "hourlyPayRate": "معدل الأجر بالساعة",
    "useShift": "استخدم التحول لتعيين الحدود اليومية",
    "selectDays": "حدد الأيام",
    "editMemberInvite": "تحرير دعوة العضو",
    "editMemberInviteDesc": "قم بدعوة موظفيك بسهولة وابدأ في تتبع تقدمهم ؛ الأمر بهذه البساطة!",
    "members": "أعضاء",
    "membersTab": "أعضاء",
    "invitedTab": "مدعو",
    "requestedTab": "مطلوب",
    "bulkInviteStatusTab": "حالة الدعوة الجماعية",
    "platform": "منصة",
    "noMemReq": "لا يوجد أعضاء مطلوبين!",
    "nomembInvited": "لا يوجد أعضاء مدعوين!",
    "selectProjectInvite": "حدد المشاريع لهذا العميل",
    "clientFlag": "دعوة العميل للوصول إلى التطبيق",
    "inviteClient": "معرف البريد الإلكتروني للعميل *",
    "Member": "عضو",
    "inviteCancel": "هل أنت متأكد من رغبتك في إلغاء الدعوة المشتركة؟",
    "statusField": "الحالة"
  },
  "subscription": {
    "overviewplan": "نظرة عامة على الخطة",
    "billingHistory": "محفوظات الفواتير",
    "planOverview":{
      "currentPlan": "الخطة الحالية",
      "expdate": "تاريخ الانتهاء",
      "basePrice": "السعر الأساسي",
      "changePlan": "خطة التغيير",
      "planFeature": "ميزات الخطة"
    },
    "BillingRate": {
      "paymentHistory": "تاريخ الدفع الخاص بك",
      "exportPDF": "تصدير كملف PDF",
      "exportCSV": "تصدير كملف CSV",
      "INVOICE": "فاتورة",
      "PLAN": "يخطط",
      "AMOUNT": "كمية",
      "DATE": "تاريخ",
      "CARD NUMBER": "رقم البطاقة",
      "DESCRIPTION": "وصف",
      "STATUS": "حالة",
      "Email": "البريد الإلكتروني",
      "Download": "تحميل",
      "noHistory": "لا توجد تاريخ فواتير",
      "invoice": "إرسال الفاتورة عبر البريد الإلكتروني",
      "Subject": "الموضوع",
      "Message": "الرسالة",
      "Save": "حفظ"
    },
    "remaininingText": "الفواتير مرفقة. يرجى تنزيل المرفق. شكرًا لك."
  },
  "integration": {
    "listing": {
      "Integration": "اندماج",
      "Add Integration": "أضف التكامل",
      "NAME": "اسم",
      "LAST SYNC": "آخر مزامنة",
      "STATUS": "حالة",
      "Disabled": "عاجز",
      "Enabled": "ممكن",
      "Remove": "يزيل",
      "chooseIntegration": "اختر التكامل",
      "Integrations": "تكاملات",
      "integrationText1": "تواصل مع تطبيقاتك المفضلة: أصبح التكامل السلس أمرًا سهلاً",
      "integrationText2": "يوفر Workstatus تكاملاً بنقرة واحدة مع تطبيقات go-to الخاصة بك. وفر الوقت وقم بتبسيط عملياتك باستخدام تكاملات Workstatus.",
      "projectmanagement": "ادارة مشروع",
      "Zoho": "زوهو",
      "Trello": "تريلو",
      "Clickup": "انقر فوق",
      "Teamwork": "العمل بروح الفريق الواحد",
      "Private Cloud": "سحابة خاصة",
      "AWS": "AWS",
      "Mail Server": "خادم البريد",
      "SMTP": "SMTP"
    },
    "aws": {
      "AWS Integration": "تكامل AWS",
      "awsText1": "يساعد تكامل AWS في الحصول على بياناتك في حاوية AWS S3. يمكنك دمج الحاوية الخاصة بك لتخزين لقطات الشاشة وصور الملف الشخصي ومرفقات المهام والتقارير حول تخزين S3. هذا يضمن خصوصية البيانات.",
      "label": {
        "accesskey": "معرّف مفتاح AWS ACCESS",
        "secretkey": "اسم مجموعة مفاتيح الوصول السري لـ AWS BUCAWS",
        "region": "منطقة AWS",
        "bucket": "AWS BUCKET NAME"
      },
      "setupText1": "كيفية إعداد AWS؟",
      "setupText2": "معرّف مفتاح الوصول إلى AWS:",
      "setupText3": "مفتاح AWS الخاص بك",
      "secretKeyAccess": "الرجاء إدخال مفتاحك السري.",
      "inputSecretKey": "الرجاء إدخال مفتاحك السري.",
      "AWS Region:": "منطقة AWS:",
      "yourRegion": "منطقة AWS الخاصة بك",
      "bucketName": "اسم حاوية AWS:",
      "yourBucketname": "اسم حاوية AWS الخاص بك",
      "setupText4": "اختبار الاتصال هو التحقق من حالة مزامنة حساب AWS الخاص بك مع حالة العمل. إذا كانت جميع التفاصيل صحيحة وتم إنشاء اتصالك بنجاح ، فسيتم عرضه على أنه ممكّن في قائمة التكامل."
    },
    "smtp": {
      "SMTP Integration": "تكامل SMTP",
      "smtpText1": "سيساعدك تكامل SMTP في إعداد خادم البريد الإلكتروني الخاص بك باستخدام Workstatus. أرسل جميع إعلامات البريد من خلال خادم البريد الخاص بك إلى جميع المستخدمين عبر مؤسستك.",
      "label": {
        "MailServer": "خادم البريد",
        "MailDriver": "سائق البريد",
        "SMTPMethod": "طريقة SMTP الآمنة",
        "Username": "اسم المستخدم",
        "Port": "ميناء",
        "Name": "اسم",
        "Email": "بريد إلكتروني",
        "Password": "كلمة المرور"
      },
      "smtpText2": "كيفية إعداد SMTP لخادم البريد المختلف؟",
      "Gmail": "بريد جوجل",
      "Hotmail": "هوتميل",
      "Yahoo": "ياهو",
      "OtherServer": "خادم آخر",
      "Mailserver:": "خادم البريد:",
      "Yourmailserver": "خادم البريد SMTP الخاص بك",
      "TLS/SSL": "TLS / SSL",
      "smtpText3": "587 (TLS) / 465 (SSL) ، قد يكون الأمر مختلفًا لهذا ، يرجى التواصل مع فريق مزود الخادم الخاص بك.",
      "Username/ Email:": "اسم المستخدم / البريد الإلكتروني:",
      "smtpText4": "يرجى إدخال اسم مستخدم الخادم الخاص بك / معرف البريد الإلكتروني.",
      "Yourpassword": "كلمة السر خاصتك",
      "equires authentication:": "يتطلب المصادقة:",
      "Yes": "نعم",
      "Note:": "ملحوظة:",
      "smtpText5": "من أجل التحقق من اتصال SMTP الخاص بك ، أدخل تفاصيل SMTP وانقر على زر اختبار الاتصال. بمجرد نجاح الاتصال ، احفظ الإعدادات وأرسل فاتورتك باستخدام خادم البريد الخاص بك.",
      "smtpText6": "إذا كنت لا تزال تواجه الخطأ ، فراجع الإعداد وحاول مرة أخرى.",
      "smtpText7": "لمزيد من الاستفسارات ، لا تتردد في الاتصال بنا عبر دعم البريد الإلكتروني أو استخدام الدردشة المباشرة (من 10 صباحًا إلى 10 مساءً بتوقيت الهند القياسي).",
      "smtpText8": "اختبار الاتصال هو التحقق من حالة مزامنة حساب SMTP الخاص بك مع حالة العمل. إذا كانت جميع التفاصيل صحيحة وتم إنشاء اتصالك بنجاح ، فسيتم عرضه على أنه ممكّن في قائمة التكامل.",
      "smtpText9": "اختبار النص المتعلق بالاتصال",
      "Submit": "يُقدِّم"
    },
    "commonVariable": {
      "Save": "يحفظ",
      "testconnection": "اختبار الاتصال",
      "Reset": "إعادة ضبط"
    },
    "reauthenticate": "إعادة التحقق",
    "integrationKey": "مفتاح AWS للتكامل",
    "secretKey": "المفتاح السري للتكامل",
    "awsKey": "المنطقة الجغرافية لـ AWS للتكامل",
    "bucketKey": "اسم الحاوية للتكامل",
    "alertMessage": "هل أنت متأكد أنك تريد تحديث كل الإعدادات؟ سيتم إزالة جميع الإعدادات.",
    "serverDetails": "أدخل تفاصيل خادم البريد الخاص بك",
    "driveDetail": "أدخل تفاصيل محرك البريد الخاص بك",
    "secretSmtp": "أدخل طريقة SMTP الآمنة الخاصة بك",
    "mailPort": "أدخل منفذ البريد الخاص بك",
    "userName": "أدخل اسم المستخدم الخاص بك",
    "name": "أدخل اسمك",
    "mail": "أدخل بريدك الإلكتروني",
    "mailPassword": "أدخل كلمة مرور بريدك الإلكتروني"
  },
  "dashboardAnalytics": {
    "Total activity" : "النشاط الإجمالي",
    "Total Worked" : "الإجمالي المعمول به",
    "Total Earned": "المجموع المكتسب",
    "Project worked":"عمل المشروع",
    "Hours Worked Per Day":"ساعات العمل في اليوم",
    "Activity graph":"رسم بياني للنشاط",
    "Top Projects":"أفضل المشاريع",
    "Online Members":"الأعضاء المتصلين",
    "Highest % of Manual & Mobile Time":"أعلى نسبة من الوقت اليدوي والمحمول",
    "Tracked Most Hours":"أكثر الساعات تتبعًا",
    "Tracked Least Hours":"أقل الساعات تتبعًا",
    "Recent Used Website":"الموقع الإلكتروني المستخدم حديثًا",
    "Recent Used Application":"التطبيق المستخدم حديثًا",
    "Haven't Tracked Time":"لم يتم تتبع الوقت",
    "Member Location":"موقع العضو",
    "hello": "مرحبًا",
    "tabMe": "أنا",
    "tabAll": "الجميع",
    "totalActivity": "إجمالي النشاط الذي تم تتبعه",
    "totalWorked": "إجمالي وقت العمل",
    "totalEarned": "إجمالي المكتسبة",
    "totalProject": "تم تنفيذ المشروع بالكامل",
    "topProjects": "أفضل المشاريع",
    "members": "أعضاء",
    "highestManualMobile": "أعلى نسبة من الوقت اليدوي والمتنقل",
    "trackedMostHrs": "تتبع معظم الساعات",
    "trackedLeastHrs": "تتبع أقل ساعات",
    "recentUsedApps": "التطبيقات المستخدمة الأخيرة",
    "recentUsedSites": "المواقع المستخدمة مؤخرا",
    "haventTrackedTime": "لم أتتبع الوقت بعد",
    "shiftSchedule": "جدول المناوبة",
    "timeoff": "انتهى الوقت",
    "jobsite": "موقع عمل",
    "timeline": "الجدول الزمني",
    "activityGraph": "الرسم البياني للنشاط",
    "recentScreenshot": "لقطات الشاشة الأخيرة",
    "recentProjects": "مشاريع حديثه",
    "recentTimesheet": "الجداول الزمنية الأخيرة",
    "recentTodos": "المهام الأخيرة",
    "noDataFound": "لاتوجد بيانات.",
    "startTime": "وقت البدء",
    "endTime": "وقت النهاية",
    "noScreenshots": "لا لقطات.",
    "noRecentActivity": "لا آخر الزوار.",
    "projectNotAssigned": "لم يتم تعيين المشروع لك.",
    "currentAppsUrls": "بيانات التطبيقات وعناوين URL غير متاحة حاليًا.",
    "currentTiemsheet": "بيانات الجدول الزمني حاليا غير متوفرة.",
    "todosNotAssigned": "لم يتم تعيين المهام لك.",
    "screenTime": "وقت الشاشة",
    "manualTime": "الوقت اليدوي",
    "mobileTime": "وقت الجوال",
    "breakTime": "فترة إستراحة",
    "overexceed": "تجاوز وقت الاستراحة",
    "rejectTime": "الوقت المرفوض",
    "idleTime": "وقت الخمول",
    "viewAllSs": "عرض جميع لقطات الشاشة",
    "viewProj": "عرض المشاريع",
    "viewUrl": "عرض عناوين URL",
    "viewApps": "عرض التطبيقات",
    "viewTimesheet": "عرض الجداول الزمنية",
    "viewTodo": "عرض المهام",
    "viewAllMem": "مشاهدة كافة الأعضاء",
    "viewMore": "عرض المزيد",
    "viewReport": "عرض التقرير",
    "date": "تاريخ",
    "timeTracked": "الوقت المتتبع",
    "totalHrsWorkedPerDay": "إجمالي ساعات العمل في اليوم",
    "Today": "اليوم",
    "Weekly": "أسبوعياً",
    "Monthly": "شهرياً",
    "dateRange": "نطاق التاريخ",
    "viewImage": "عرض الصورة",
    "appDataNotAvailable": "حالياً لا تتوفر بيانات التطبيق!",
    "strtTime": "وقت البدء",
    "stpTime": "وقت الانتهاء",
    "todoName": "اسم المهمة",
    "todoTime": "وقت المهمة",
    "Offline": "غير متصل",
    "Online": "متصل",
    "Android": "أندرويد",
    "iOS": "آي أو إس",
    "Windows": "ويندوز",
    "MacOS": "ماك أو إس",
    "Web": "ويب",
    "Linux": "لينكس",
    "noTrackhours": "لا توجد ساعات تتبع"  ,
    "searchWidget": "اختيار الحاجيات",
    "total": "المجموع"
  },
  "schedules": {
    "schedules": "جداول",
    "addSchedule": "إضافة الجدول الزمني",
    "addScheduleDesc": "الآن يمكنك بسهولة إنشاء وتعيين وتتبع ورديات الموظفين. قم بإصلاح التناقضات في الورديات مثل وصول الموظفين متأخرًا عن الورديات أو التخلي عن الورديات.",
    "basicInfo": "معلومات اساسية",
    "member": "عضو",
    "title": "عنوان",
    "description": "وصف",
    "shiftDur": "مدة التحول",
    "minHrs": "الحد الأدنى للساعات*",
    "recShiftOpt": "خيارات التحول المتكررة*",
    "save": "يحفظ",
    "editSchedule": "تحرير الجدول الزمني",
    "repeatUntil": "كرر حتى",
    "selectedDays": "أيام مختارة",
    "delete": "يمسح",
    "back": "العودة",
    "validation": "الحد الأدنى لمدة الوردية هو",
    "hours": "ساعة!"
  },
  "helpDesk": {
   "supportHelp": "الدعم والمساعدة",
   "orgId": "معرف المنظمة:",
   "orgText": "شارك معرف المنظمة هذا مع فريق الدعم لدينا",
   "supportHrs": "ساعات الدعم لدينا:",
   "dayTime": "10:00 صباحًا - 06:00 مساءً. الإثنين، الجمعة",
   "successManager": "مدير نجاح العملاء الخاص بك",
   "escalation": "للتصعيد، يرجى التواصل معنا",
   "chatWus": "دردش معنا",
   "visitHelpCenter": "قم بزيارة مركز المساعدة",
    "gotIt" : "حسنًا"
  },
  "department": {
    "department": "القسم",
    "departmentName": "اسم القسم",
    "departmentHead": "رئيس القسم",
    "team": "الفريق",
    "members": "الأعضاء",
    "adddepartment": "قسم جديد",
    "edit": "تعديل القسم",
    "remove": "حذف القسم",
    "noDepts": "لا توجد أقسام!",
    "confirmremove": "سيتم حذف القسم. هل ترغب ما زلت ترغب في المتابعة؟",
    "yes": "نعم",
    "no": "لا",
    "ok": "موافق",
    "addNewDept": "إضافة قسم جديد",
    "createDept": "قم بإنشاء أقسام/فرق مختلفة لتنظيم سير العمل الخاص بك.",
    "bsicInf": "المعلومات الأساسية",
    "back": "رجوع",
    "depNameLabel": "اسم القسم*",
    "depHeadLabel": "رئيس القسم*",
    "teamLabel": "الفريق*",
    "searchTeamPlaceholder": "بحث عن فريق",
    "memLabel": "الأعضاء",
    "save": "حفظ",
    "editDept": "قسم التحرير",
    "searchDept": "البحث في القسم"
  },
  "reports":{
    "timeAndActivity": "تقارير الوقت والنشاط",
    "dataCheck": "البيانات في التقارير متاحة حتى أمس. لن تتوفر البيانات لليوم.",
    "timeAndActivityDesc": "عرض إجمالي ساعات العمل لأعضاء الفريق، الوقت النشط، والوقت الخمول.",
    "selectBy": "اختيار بواسطة",
    "selectDept": "اختيار القسم",
    "selectMember": "اختيار العضو",
    "selectTeam": "اختيار الفريق",
    "daterange": "نطاق التاريخ",
    "groupBy": "تجميع حسب",
    "project": "المشروع",
    "todo": "المهمة",
    "hours": "ساعات",
    "activityLevel": "مستوى النشاط",
    "generateReport": "إنشاء تقرير",
    "note": "ملحوظة: الوقت المعروض حسب توقيت منطقة العضو المحدد.",
    "filters": "مرشحات",
    "send": "إرسال",
    "schedule": "جدولة",
    "download": "تنزيل",
    "scheduleRep": "جدولة التقرير",
    "sendRep": "إرسال التقرير",
    "apply": "تطبيق",
    "clear": "مسح",
    "effectiveTime": "الوقت الفعّال",
    "totalHrsWorkedPerDay": "إجمالي ساعات العمل يوميًا",
    "avgActivity": "متوسط النشاط",
    "member": "العضو",
    "date": "التاريخ",
    "projDetails": "تفاصيل المشروع",
    "activity": "النشاط",
    "idle": "الخمول",
    "manual": "يدوي",
    "break": "استراحة",
    "effective": "فعّال",
    "time": "الوقت",
    "totalHrs": "إجمالي الساعات",
    "searchMem": "البحث عن عضو",
    "searchTeam": "البحث عن فريق",
    "searchDepartment": "البحث عن قسم",
    "searchProject": "البحث عن مشروع",
    "searchTodo": "البحث عن مهمة",
    "departmentTab": "الأقسام",
    "teamsTab": "الفرق",
    "memberTab": "الأعضاء",
    "weeklyReports": "التقارير الاسبوعية",
    "weeklyReportsdesc": "متوسط ​​الساعات ومتوسط ​​النشاط في اليوم وإجمالي المبلغ المكتسب.",
    "dataNotAvailabel": "البيانات لهذا النطاق الزمني غير متوفرة!",
    "memWorked": "الأعضاء الذين عملوا",
    "avgHrsPerDay": "متوسط الساعات في اليوم",
    "earned": "الأرباح",
    "memberName": "اسم العضو",
    "totalHours": "إجمالي الساعات",
    "avgPercentage": "متوسط النسبة",
    "dailyTotal": "المجموع اليومي",
    "customrepo": "تقرير مخصص",
  "basicDet": "تفاصيل أساسية",
  "selectAll": "تحديد الكل",
  "unSelectAll": "إلغاء تحديد الكل",
  "name": "الاسم",
  "email": "البريد الإلكتروني",
  "teamName": "اسم الفريق",
  "teamLeadName": "اسم قائد الفريق",
  "activityDet": "تفاصيل النشاط",
  "totalHrss": "إجمالي الساعات",
  "totalidle": "إجمالي وقت الخمول",
  "idleTimeP": "نسبة وقت الخمول",
  "totalSearchDays": "إجمالي أيام البحث",
  "attendacedet": "تفاصيل الحضور",
  "weekoffdays": "أيام العطل الأسبوعية",
  "shiftHrs": "ساعات الوردية",
  "totalWorkDays": "إجمالي أيام العمل",
  "totalEffectiveHrs": "إجمالي الساعات الفعّالة",
  "totalBreakTime": "إجمالي وقت الاستراحة",
  "breakTimeP": "نسبة وقت الاستراحة",
  "totalAtt": "إجمالي الحضور",
  "att": "نسبة الحضور",
  "totalTiemoffReq": "إجمالي عدد طلبات الإجازة",
  "totalTimeofHrsReq": "إجمالي ساعات الإجازة المطلوبة",
  "projectDetails": "تفاصيل المشروع",
  "NoOfProjWork": "عدد المشاريع التي تم العمل بها",
  "noOfTodoWork": "عدد المهام التي تم العمل بها",
  "noOfCompTodo": "عدد المهام المكتملة",
  "jobsiteDets": "تفاصيل مواقع العمل",
  "jobsiteVisited": "عدد مواقع العمل التي تم زيارتها",
  "totalTimeOnAllJs": "إجمالي الوقت على جميع مواقع العمل",
  "noOfDaysOnAllJS": "عدد الأيام على جميع مواقع العمل",
  "totalTimeOnJs": "إجمالي الوقت على مواقع العمل (%)",
  "exportCsv": "تصدير كملف CSV",
  "appsNUrl": "تقارير التطبيقات والروابط",
  "appUrlDesc": "عرض التطبيقات المستخدمة والروابط التي تم زيارتها من قبل أعضاء الفريق.",
  "category": "الفئة",
  "session": "الجلسة",
  "manualTimeEditRep": "تقارير تعديل الوقت يدويًا",
  "manualTimeEditDesc": "عرض الوقت الذي تمت إضافته يدويًا من قبل أعضاء الفريق، بما في ذلك الفترة الزمنية.",
  "action": "الإجراء",
  "changedBy": "تم التغيير بواسطة",
  "affectedMem": "الأعضاء المتأثرين",
  "hrsAdded": "الساعات المضافة",
  "hrsDeleted": "الساعات المحذوفة",
  "timeSpan": "الفترة الزمنية",
  "timeChange": "تغيير الوقت",
  "status": "الحالة",
  "timesheetApprReport": "تقرير موافقة الجداول الزمنية",
  "timesheetApprDesc": "عرض جداول أوقات أعضاء الفريق وحالة طلباتهم.",
  "projWorkOrder": "المشروع/أمر العمل",
  "startTime": "وقت البدء",
  "endTime": "وقت الانتهاء",
  "timesheetStatus": "حالة الجدول الزمني",
  "apprDeniAt": "تمت الموافقة/الرفض في",
  "apprDeniBy": "تمت الموافقة/الرفض بواسطة",
  "reason": "السبب",
  "projTodoRep": "تقرير المشروع/المهمة",
  "projTodoDesc": "عرض أعضاء الفريق العاملين على المشاريع، المهام المخصصة، الحالة، وتواريخ بداية وانتهاء المشاريع.",
  "projName": "اسم المشروع",
  "estHrs": "الساعات المقدرة",
  "timeSpent": "الوقت المستغرق",
  "attBasic": "تقرير الحضور الأساسي",
  "attBasicDesc": "قم بتحليل متوسط ساعات عمل فريقك، وساعات الاستراحة، ونسبة الحضور.",
  "avgWorkHrs": "متوسط ساعات العمل",
  "avgAtt": "متوسط الحضور",
  "avgBreakHrs": "متوسط ساعات الاستراحة",
  "memPresencePerDay": "حضور العضو في اليوم",
  "memName": "اسم العضو",
  "totDays": "إجمالي الأيام",
  "totWorkDays": "إجمالي أيام العمل",
  "dayPre": "أيام حاضر",
  "dayAbs": "أيام غائب",
  "attendance": "نسبة الحضور",
  "attAdv": "تقرير الحضور المتقدم",
  "attAdvDesc": "بالإضافة إلى نظرة عامة على حضور الفريق، يمكنك أيضًا الوصول إلى حضور كل عضو يوميًا.",
  "jobsiteRep": "تقارير مواقع العمل",
  "jobsiteRepDesc": "عرض تفاصيل كل عضو بالتفصيل، بما في ذلك موقع العمل، اسم المشروع، منشئ الوظيفة، ووقت الدخول والخروج.",
  "jobsiteName": "اسم موقع العمل",
  "jobsite": "موقع العمل",
  "creator": "المنشئ",
  "enter": "الدخول",
  "exit": "الخروج",
  "timeofft": "معاملة الإجازة",
  "tieofftDesc": "عرض تفاصيل معاملات الإجازة لأعضاء الفريق مثل اسم السياسة.",
  "sendReportText": "يمكنك إرسال التقرير إلى عناوين البريد الإلكتروني الفردية. يمكنك تحديد موضوع ونص الرسالة البريدية.",
  "emailLabel": "أدخل البريد الإلكتروني*",
  "fileType": "نوع الملف*",
  "schedueText": "يمكنك جدولة إرسال التقرير إلى عناوين البريد الإلكتروني الفردية في فترات زمنية محددة. يمكنك تحديد موضوع ونص الرسالة البريدية.",
  "enterName": "أدخل الاسم*",
  "frequency": "التكرار*",
  "col": "عمود",
  "add": "إضافة",
  "changesMade": "تم إجراء التغييرات على",
  "bookedOn": "حجز في",
  "noResult": "لم يتم العثور على نتائج",
  "downloadCSV": "التنزيل بتنسيق CSV",
  "scheduledReports": "التقارير المجدولة",
  "clickToRemove": "انقر هنا لإزالة هذا التقرير من الشريط الجانبي",
  "clickToAdd": "انقر هنا لإضافة هذا التقرير إلى الشريط الجانبي",
  "timeActivityTxt": "الوقت والنشاط",
  "weeklyTxt": "أسبوعي",
  "apps&Urls": "التطبيقات والروابط",
  "manualEditTxt": "تحرير الوقت يدويًا",
  "projodoTxt": "المشروع/المهمة",
  "attendanceBasicTxt": "الحضور الأساسي",
  "attendanceAdvanceTxt": "الحضور المتقدم",
  "timeoffTransanctionTxt": "معاملة الاستراحة",
  "normalBreak": "استراحة عادية",
  "exceedBreak": "استراحة تجاوزت الحد",
  "TotalHours": "إجمالي الساعات",
  "Average": "المعدل",
  "Productive": "منتج",
  "Unproductive": "غير منتج",
  "TimeChange": "تغيير الوقت",
  "SelectActionType": "اختر نوع الإجراء",
  "edit": "تحرير",
  "addText": "إضافة",
  "delete": "حذف",
  "Date": "التاريخ",
  "Member": "عضو",
  "Open": "مفتوح",
  "Approved": "موافق عليه",
  "Denied": "مرفوض",
  "TimesheetType": "نوع الجدول الزمني",
  "TimeDuration": "مدة الوقت",
  "ApprovedDenied": "موافق عليه / مرفوض من قبل",
  "TimeSpent": "الوقت المستغرق",
  "AttendancePer": "الحضور (%)",
  "AvgWorkingHours": "متوسط ساعات العمل",
  "AvgBreakHours": "متوسط ساعات الاستراحة",
  "JobsiteName": "اسم موقع العمل",
  "Creator": "المنشئ",
  "SelectJosite":"اختر موقع العمل"
  },
  "generalSettingMember": {
    "TopPara": "وقف عن التعب في تذكر الأسماء وأنشئ هوية فريدة للأعضاء/الموظفين لتحديد هوية المستخدم", 
    "default": "تقصير",
    "auto": "تقصير",
    "manual": "يدويا",
    "preScen": "سيناريو البادئة",
    "withPre": "مع البادئة",
    "withoutPre": "بدون بادئة",
    "setPreId": "تعيين بادئة المعرف",
    "memId": "تعيين معرف العضو",
    "setPreIdErr": "الرجاء إدخال 3 أحرف أبجدية كبيرة", 
    "memIdErr": "الرجاء إدخال القيمة بين 0001 و9999",
    "save": "يحفظ",
    "setupMemId": "معرف عضو الإعداد"
  },
  "memberProfile": {
    "saveChanges": "حفظ التغييرات",
    "editChanges": "تحرير التفاصيل",
    "cancelChanges": "إلغاء التغييرات",
    "About": "عن",
    "job": "ما أحبه في عملي",
    "interest": "اهتماماتي وهواياتي",
    "PI": "معلومات شخصية",
    "email": "الايميل البديل",
    "countryCode": "الرقم الدولي",
    "AlternatePhNo": "رقم هاتف بديل",
    "BG": "فصيلة الدم",
    "maritalStatus": "الحالة الاجتماعية",
    "marriageDate": "تاريخ الزواج",
    "permAddress": "العنوان الثابت",
    "JobDetails": "تفاصيل الوظيفة",
    "empId": "هوية الموظف",
    "workLocation": "مكان العمل",
    "Department": "قسم",
    "Designation": "تعيين",
    "joiningDate": "تاريخ الإنضمام",
    "empType": "نوع التوظيف",
    "identityInfo": "معلومات الهوية",
    "missingInfo": "الرجاء إدخال التفاصيل المفقودة",
    "photoId": "معرف الصورة",
    "idName": "اسم المعرف",
    "idNumber": "رقم الهوية",
    "filelimitWarning": "حجم الملف يتجاوز الحد المسموح به وهو 1 ميجابايت.",
    "Add New": "اضف جديد",
    "addressProof": "إثبات العنوان",
    "otherproof": "معرف/إثبات آخر",
    "primaryInfo": "معلومات أساسية أخرى معرف/إثبات",
    "Gender": "جنس",
    "phNo": "رقم التليفون",
    "currentAddress": "العنوان الحالي",
    "emergencyDetail": "تفاصيل الاتصال في حالات الطوارئ",
    "ContactName": "اسم جهة الاتصال",
    "Relation": "علاقة",
    "ContactEmail": "تواصل بالبريد الاكتروني",
    "contactNo": "الاتصال رقم الهاتف",
    "contactAddress": "عنوان الإتصال",
    "bankingInfo": "المعلومات المصرفية",
    "bankNo": "رقم الحساب المصرفي",
    "FSCode": "رمز آي إف إس",
    "BankName": "اسم البنك",
    "BranchName": "اسم الفرع",
    "BankAddress": "عنوان البنك",
    "DangerZone": "منطقة الخطر",
    "deleteAccount": "حذف حسابك",
    "DeactivateAccount": "الغاء تفعيل حسابك",
    "ChangeEmail": "تغيير الايميل",
    "ChangePassword": "تغيير كلمة المرور",
    "checkInbox": "يرجى التحقق من البريد الوارد الخاص بك",
    "mailText": "لقد أرسلنا إليك بريدًا إلكترونيًا لتأكيد عنوان بريدك الإلكتروني الجديد.",
    "deactivateText1": "سيؤدي هذا الإجراء إلى إلغاء تنشيط الحساب. لن يتم إدخال أي بيانات أو إجراءات جديدة في التطبيق.",
    "deactivateText2": "لن يؤدي هذا إلى إلغاء اشتراكك الحالي. يمكنك تسجيل الدخول في أي وقت لزيارة بياناتك القديمة أو تفعيل حسابك مرة أخرى.",
    "continue": "هل تريد الاستمرار؟",
    "Credentials": "بيانات الاعتماد",
    "maxFileLimit": "يسمح بملفين كحد أقصى",
    "chooseFile": "اختر ملفًا",
    "CommonHeader": {
      "working": "عمل",
      "reportingMamager": "مدير التقارير",
      "profilePercentage": "الملف الشخصي مكتمل:",
      "Profile": "حساب تعريفي",
      "App Setting": "إعداد التطبيق"
    },
    "appSetting": {
      "generalSetting": "الاعدادات العامة",
      "TimeZone": "وحدة زمنية",
      "timeZone": "المنطقة الزمنية للأعضاء",
      "changedTimeZone": "تغيير المنطقة الزمنية",
      "billingHours": "ساعات العمل والفواتير",
      "dailyLimit": "الحد اليومي",
      "hrs/wk": "ساعة/أسبوع",
      "weeklyLimit": "الحد الأسبوعي",
      "payRate": "معدل الأجر بالساعة",
      "weeklyOff": "راحه اسبوعيه",
      "TimerApp": "تطبيق الموقت",
      "screenshotTimer": "مؤقت لقطة الشاشة",
      "breakTime": "فترة إستراحة",
      "breakLimit": "كسر الحد الزمني",
      "idleNote": "ملاحظة خاملة",
      "trackingMode": "وضع التتبع",
      "daysCount": "لا أيام",
      "trackingTime": "تتبع الوقت",
      "featureSetting": "إعدادات الميزة",
      "Timesheet": "ورقة التوقيت",
      "editTimesheet": "تحرير الجدول الزمني",
      "timesheetApproval": "الجدول الزمني للموافقة",
      "reasonReqire": "تتطلب السبب",
      "limitTimesheet": "الجدول الزمني بعد الحد",
      "Activity": "نشاط",
      "App&URLsTracking": "تتبع التطبيقات وعناوين URL",
      "activityMenu": "قائمة النشاط",
      "ssFequency": "تردد لقطة الشاشة",
      "ssAlert": "تنبيه لقطة الشاشة",
      "SsBlur": "لقطة الشاشة طمس",
      "deleteSs": "حذف لقطة الشاشة",
      "Jobsite": "موقع عمل",
      "restrictTimer": "تقييد الموقت على مواقع العمل",
      "trackJobsiteTime": "تتبع الوقت في مواقع العمل",
      "defineBreak": "تعريف الاستراحة",
      "Visible": "مرئي",
      "Stealth": "التخفي",
      "trackingModeTxt": "التحكم في وضع التتبع لمؤسستك أو لأي مستخدم فردي",
      "restrictTimerTab": "تقييد المؤقت بمواقع العمل",
      "enterExitNotification": "إشعارات الدخول/الخروج",
      "Enabled": "ممكّن",
      "Disabled": "عاجز",
      "integrationTxt": "لم تقم بأي عمليات تكامل لمؤسستك",
      "Enabled but Not Mandatory": "ممكّنة ولكنها ليست إلزامية",
      "Enabled & Mandatory": "ممكّنة وإلزامية",
      "Individual": "فردي",
      "All": "الجميع"
    },
    "deleteAccountConfirmation": "هل انت متأكد انك تريد حذف حسابك."
  },
   "memberId": {
    "text1": "توقف عن المعاناة في تذكر الأسماء وقم بإعداد معرف فريد للعضو/الموظف لتحديد هوية المستخدم",
    "default": "تقصير:",
    "Automatically": "تلقائيا",
    "Manually": "يدويا",
    "prefix": "سيناريو البادئة",
    "withpre": "مع البادئة",
    "withoutPre": "بدون بادئة",
    "preID": "تعيين بادئة المعرف",
    "membId": "تعيين معرف العضو",
    "text2": "الرجاء إدخال 3 أحرف أبجدية كبيرة",
    "text3": "الرجاء إدخال قيمة بين 0001 و 1000",
    "text4": "لا يمكن تغيير هذا الإعداد إلى وضع يدوي أبدًا. هل أنت متأكد أنك تريد تحديث هذا الإعداد؟",
    "Prefix": "بادئة",
    "ID": "هوية",
    "uniqueID": "قم بتعيين مُعرِّف فريد لأعضاء منظمتك.",
    "setPrefixID": "قم بتعيين بادئة لهوية العضو. يجب أن تكون 3 أحرف أبجدية فقط.",
    "setMemberId": "قم بتعيين الهوية التي سيتم عرضها أمام البادئة، ويجب أن تكون النطاق من 0001 إلى 10000000.",
    "setMemberIdWithout":"قم بتعيين هوية العضو بدون بادئة، ويجب أن تكون النطاق من 0001 إلى 10000000.",
    "memberIdErrTxt": "الرجاء إدخال قيمة بين 0001 و99999999"
  },
  "Designation": {
    "text1": "تعريف القسم لجميع أعضاء المؤسسة",
    "addDesignation": "إضافة تعيين",
    "Designation": "تعيين",
    "delDesignation" :"حذف التعيين",
    "editDesignation": "تحرير التعيين",
    "assignDelete": "حذف وتعيين",
    "removeDesignation": "حذف هذا التعيين؟",
    "delDesignationText1": "بشكل دائم. هل ترغب متابعة؟",
    "delDesignationText2": "سيتم حذف",
    "updateDesig": "تحديث التعيين",
    "desigName": "اسم التعيين",
    "Update": "تحديث",
    "define": "تعريف المستوى أو المنصب الذي يحتله الموظفون في المؤسسة.",
    "seeting": "إعدادات التصنيف"
  },
  "memberSetting": {
    "Membsettings": "إعدادات الأعضاء",
    "open": "مفتوح",
    "Restricted": "مقيد",
    "profileSetting": "إعدادات الملف الشخصي",
    "MembSetting": "إعدادات الأعضاء",
    "text1": "لا توجد حاجبات حاليًا!",
    "text2": "هل أنت متأكد أنك تريد تحديث هذا الإعداد؟",
    "text3": "هنا يمكنك تخصيص الإعدادات لكل عضو."
  },
  "time-activity-report":{
    "idle_time": "وقت الخمول 5",
    "effective_time": "الوقت الفعّال",
    "manual_time": "وقت العمل اليدوي %",
    "break_time": "وقت الاستراحة",
    "total_hrs": "إجمالي الساعات"
  },
  "youTubeVideo": {
    "welcome": "مرحبًا في Workstatus",
    "setingUp": "هل ترغب في مساعدة في الإعداد؟",
    "scheduleCallwithTeam": "جدولة مكالمة مع فريق نجاح العملاء لدينا.",
    "scheduleCall": "جدولة مكالمة",
    "productTour": "عرض جولة المنتج",
    "skip": "تخطي للمتابعة",
    "helpdesk": "مكتب المساعدة"
  },
  "wsTimePicker": {
    "Hours": "ساعات",
    "Minutes": "دقائق"
  },
  "generalsetting": {
    "officeLocation" : "مكان العمل",
    "text": "قم بلصق صورة، أو سحب وإسقاط الملفات، أو",
    "click": "انقر هنا",
    "upload": "للرفع",
    "Save": "حفظ",
    "Attach": "إرفاق",
    "Cancel": "إلغاء",
    "sizelimit": "يجب أن يكون حجم الملف أقل من 50 كيلوبايت",
    "defaultRole": "الدور الافتراضي للأعضاء",
    "projectID": "إدارة معرف المشروع",
    "todoStatus": "إدارة حالة المهمات الغير منجزة",
    "ownerToolTip" : "لا يمكنك تغيير الإعدادات لحساب المالك",
    "manageID": "إدارة معرف المهمة/المهمة",
    "milestone":"مرحلة المشروع",
    "financeSetting": "إعدادات الشؤون المالية",
    "projectSetting": "إعداد المشروع",
    "DeliveryHead": "رئيس التوصيل",
    "Manager": "مدير",
    "User": "مستخدم",
    "None": "لا شيء",
    "orgOwner": "مالك المنظمة",
    "orgManger": "مدير المنظمة",
    "noMembers": "لا يوجد أعضاء حاليا في هذه اللحظة!",
    "noProjects": "لا توجد مشاريع حاليًا في هذه اللحظة!",
    "sureToUpdate": "هل أنت متأكد أنك تريد تحديث هذا الإعداد؟",
    "breakLimit": {
      "text1": "تعيين حد زمني للفاصل لجميع الأعضاء أو الأعضاء المحددين",
      "editBreak": "تحرير الفاصل"
      },
      "manageIDText": "توقف عن المعاناة من أجل تذكر الأسماء وقم بإعداد معرفات مشروع فريدة لتحديد المشروع",
      "manageIdText1": "بمجرد الحفظ، لا يُسمح بإجراء تعديلات مستقبلية على هذا الإعداد.",
      "validation": "الرجاء إدخال رقم بين 0001 و 99999999",
      "Enable": "تمكين",
      "Disable": "تعطيل",
      "DEFAULT": "الافتراضي:",
      "statusName": "اسم حالة المهمة التي تجدر القيام بها",
      "editSTatus": "تحرير حالة المهمة التي تجدر القيام بها",
      "statusAlreadyAssigned": "تم تعيين حالة المهمة التي تجدر القيام بها هذه بالفعل لبعض المهام، يرجى تغيير حالة المهمة التي تجدر القيام بها لهذه المهام قبل تعطيل حالة المهمة التي تجدر القيام بها.",
      "enableDisable": "توقف عن النضال من أجل تذكر الأسماء وإعداد معرفات المهام الفريدة لتحديد هوية المهام.",
      "WeeklyOff": "إجازة أسبوعية",
      "allowMembers": "السماح للأعضاء باختيار أيام العمل الخاصة بهم",
      "settings": "الإعدادات",
      "editText1": "إذا لم تجد المنطقة الزمنية الدقيقة في نظامك، يرجى تحديد أقرب مدينة بنفس رمز GMT.",
      "allowmembers": "السماح للأعضاء باختيار المنطقة الزمنية",
      "timezonelist": "المناطق الزمنية المدرجة أعلاه بدون التوقيت الصيفي",
      "fileSize": "يجب أن يكون حجم الملف أقل من 2 ميجابايت",
      "changesAlert": "أنت على وشك تغيير منطقة زمنية منظمتك، يرجى مراعاة النقاط التالية:",
      "alert1": "سيؤدي ذلك إلى تغيير المنطقة الزمنية لجميع أعضاء منظمتك.",
      "alert2": "على تطبيقات التتبع (تطبيقات سطح المكتب والهواتف المحمولة)، سيتم تحديث تغيير المنطقة الزمنية الجديدة فقط بعد تسجيل الخروج وتسجيل الدخول مرة أخرى أو إعادة تحميل بيانات المنظمة.",
      "updateSetting": "هل أنت متأكد من رغبتك في المتابعة - إذا كانت الإجابة بنعم ، فاضغط على موافق واحفظ الإعداد؟",
      "noMembersPresent": "لا يوجد أعضاء حاليًا في هذه اللحظة!",
      "billRate": "سعر الفاتورة",
      "changeTimezone": "السماح للعضو بتغيير منطقة زمنية.",
      "selectCity": "إذا لم تجد المنطقة الزمنية الدقيقة في نظامك، يرجى تحديد أقرب مدينة بنفس رمز GMT.",
      "No": "لا",
      "Yes": "نعم",
      "Clear": "مسح",
      "Apply": "تطبيق",
      "diffTimezone": "منطقة زمنية مختلفة",
      "Filters": "مرشحات",
      "allowWeekOff": "السماح للعضو بتغيير إجازته الأسبوعية.",
      "selectOneDay": "يرجى تحديد على الأقل يوم واحد كيوم عمل",
      "pleaseSelectDay": "يرجى تحديد الأيام",
      "Upgrade": "ترقية",
      "timerApps": "التحكم في التطبيقات الخاصة بالمؤقت التي يمكن لفرد الفريق استخدامها.",
      "OK": "موافق",
      "enableMandatory": "مفعل وإلزامي",
      "enable": "تمكين ولكن ليس إلزامي",
      "Disabled": "معطل",
      "Unlimited": "غير محدود",
      "Fixed": "ثابت",
      "Start": "بداية",
      "time": "وقت",
      "End": "نهاية",
      "MEMBERS": "أعضاء",
      "trackingMode": "سيناريو التتبع",
      "NoOfDays": "عدد الأيام",
      "manageTodoStatus": "قم بإنشاء حالة ToDo مخصصة لإدارة المهام وتصنيفها بشكل أكثر كفاءة.",
      "confirmTxt": "الإعدادات التي تحاول تحديثها ستؤثر على جميع المستخدمين وبالتالي سنحتاج إلى إعادة تحميل الصفحة لجعلها فعالة على مستوى النظام. هل ترغب في المتابعة؟",
      "payRate": "سعر الأجر"
  },
  "editLimit": {
    "designation": "تعيين العضو",
    "hrs/wk": "ساعة/أسبوع",
    "hrs/day": "ساعة/يوم",
    "limitValidation": "يجب ألا يكون الحد اليومي أكبر من الحد الأسبوعي",
    "₹/hrs": "₹/ساعة"
  },
  "commonVar": {
    "OK": "موافق",
    "No": "لا",
    "Yes": "نعم",
    "cancel": "إلغاء",
    "Save": "حفظ",
    "searchmember": "البحث عن الأعضاء",
    "notAutorisedTxt": "ليس لديك حق الوصول لعرض هذه الصفحة.",
    "inConvinienceTxt": "نأسف على الإزعاج.",
    "showMore": "إظهار المزيد...",
    "showless": "أظهر أقل...",
    "trackerLogoutMsg":"يرجى إيقاف جهاز التتبع الخاص بك ثم يمكنك تسجيل الخروج",
    "trackerTimeError":"خطأ في تغيير التاريخ/الوقت",
    "trackerTimeErrorDes":"تم اكتشاف تغيير في تاريخ/وقت نظامك. سيتم إيقاف متتبع الويب الخاص بـ Workstatus نتيجةً لذلك. يُرجى تصحيح تاريخ/وقت نظامك حتى يتمكن متتبع الويب الخاص بـ Workstatus من المتابعة."
  },
  "membersTab": {
    "noMembers": "لا يوجد أعضاء في هذا المشروع!",
    "invite": "دعوة أعضاء جدد",
    "sureToRemove": "هل أنت متأكد من أنك تريد إزالة العضو؟",
    "remove": "بمجرد إزالة العضو من المشروع، لن يكون قادرًا على الوصول إلى بيانات منظمتك (مثل المشاريع وقائمة المهام وما إلى ذلك). هل أنت متأكد من أنك تريد إزالة العضو؟",
    "successMsg": "تم إرسال طلب دعوة العضو بنجاح.",
    "tooltipData": "في وضع التخفي، سيتم تشغيل التطبيق في الخلفية ولا يحتاج المستخدم إلى التدخل في التطبيق بأي شكل من الأشكال. لا يحتاجون إلى بدء/إيقاف التطبيق. ملاحظة: متاح فقط للنوافذ."
  },
  "featureLimit": {
    "proceed": "السماح للمؤقت بالمضي قدمًا بشكل طبيعي",
    "stopAlert": "إيقاف المؤقت وعرض تنبيه",
    "teamsSetting": "يعتمد هذا الإعداد على الفرق. يرجى إضافة الفريق أولاً لاستخدام هذا الإعداد. أنشئ فريقًا من",
    "Team": "الفريق",
    "PRODUCTIVE": "إنتاجي",
    "UNPRODUCTIVE": "غير إنتاجي",
    "addProductive": "إضافة إلى الإنتاجية",
    "addHere": "أضف هنا"
  },
  "jobsiteNotification": {
    "Yes": "نعم",
    "No": "لا"
    },
  "choosePlan": {
  "plan": "اختر الخطة المناسبة لفريقك",
  "planDependency": "اعتمادًا على احتياجاتك الفريدة، اختر الخطة التي تناسبك. تحقق من قائمة الميزات أدناه لمساعدتك في اتخاذ القرار الصحيح.",
  "Save": "حفظ",
  "Monthly": "شهرياً",
  "Annual": "سنوياً",
  "feature": "ميزات الخطة",
  "text1": "ابحث عن الاشتراك الذي يجعل",
  "text2": "الأكثر ملاءمة لك أو لفريقك.",
  "customPlan": "تحتاج إلى خطة مخصصة لمؤسستك",
  "customplanText": "لا تقلق! برنامجنا جاهز للتكيف وفقًا لاحتياجاتك الفريدة؛ تواصل معنا لتخصيص Workstatus لشركتك.",
  "requestCustom": "طلب عرض تقديمي مخصص",
  "upgrade": "ترقية، تخفيض أو إلغاء الخطة في أي وقت.",
  "stealthMode": "استخدم وضع الخفاء لتتبع غير المنقطع.",
  "smartDecision": "اتخذ قرارات تجارية ذكية بناءً على تقارير الذكاء الاصطناعي.",
  "regularization": "الامتثال لقانون اللائحة العامة لحماية البيانات (GDPR).",
  "increaseProductivity": "زيادة الإنتاجية والربحية والشفافية في مكان العمل."
  },
  "Addpolicy":  "إضافة سياسة جديدة.",
  "efficiencyPro": {
    "resetIcon": "إعادة تعيين الرمز",
    "changepicture": "تغيير الصورة",
    "changeIcon": "تغيير الرمز",
    "size": "(الحد الأقصى لحجم الرمز 50 كيلوبايت)",
    "Disable": "تعطيل",
    "Enable": "تمكين",
    "text": "لصق صورة، سحب وإسقاط الملفات أو",
    "click": "انقر هنا",
    "upload": "للتحميل",
    "fileSize": "الحد الأقصى للحجم: 50 كيلوبايت، الصيغة المدعومة - .PNG",
    "Save": "حفظ",
    "Attach": "إرفاق",
    "Cancel": "إلغاء",
    "sizelimit": "يجب أن يكون حجم الملف أقل من 50 كيلوبايت",
    "efficiency": "لفعالية المحترفة."
  },
  "searchField": {
    "todo": "البحث عن حالة المهام",
    "members": "البحث عن الأعضاء",
    "projects": "البحث في المشاريع",
    "selectOption": "حدد الخيارات",
    "searchDesignation": "تعيين البحث",
    "searchMilestone": "بحث عن مرحلة"

  },
  "editTodoStatus": {
    "addNewTodoGroup" : "إضافة مجموعة مهام جديدة",
    "edit": "تحرير حالة المهمة",
    "text1": "جعل وضع حالة المهام ممتعًا. قم بتخصيص لون بسيط لكل حالة.",
    "BI": "معلومات أساسية",
    "text2": "يجب ألا يتجاوز طول اسم العلامة 20 حرفًا.",
    "SelectColor": "اختيار اللون",
    "Status": "الحالة",
    "Save": "حفظ",
    "label": "اسم العلامة*",
    "editTodoGroup": "تحرير مجموعة المهام",
    "todoGroupErr": "يجب ألا يتجاوز طول اسم مجموعة المهام 100 حرفًا",
    "tooltip1": "سيكون هذا الفريق مرئيًا لكل من أعضاء المشروع ومستخدمي العميل.",
    "tooltip2": "سيكون هذا الفريق مرئيًا فقط لأعضاء المشروع.",
    "flag": "علامة*",
    "todoGroupName": "اسم مجموعة المهام*",
    "addTodogroup": "إضافة مجموعة مهام جديدة",
    "nodataTodoSelect": "اختر المشروع أولاً لتحميل الأعضاء المرتبطين به.",
    "noTodo": "ليس هناك ما يجب فعله هنا - برجاء اختيار مجموعة/مشروع Todo آخر."
  },
  "footer": {
    "policy": "سياسة الخصوصية",
    "service": "شروط الخدمة",
    "reportBug": "الإبلاغ عن خطأ",
    "suggestFeature": "اقتراح ميزة",
    "Copyright": "حقوق النشر",
    "rightReserved": "جميع الحقوق محفوظة"
  },
  "allDropdown": {
    "allMembers": "جميع الأعضاء",
    "allJobsites": "جميع مواقع العمل",
    "hoursLevel": "كل مستوى من الساعات",
    "AllTeams": "كل الفرق",
    "AllDepartments": "كل الأقسام",
    "Date": "تاريخ",
    "Member": "عضو",
    "allproject": "جميع المشاريع",
    "activePolicy": "فعال پالیسی",
    "TimeOffPolicies": "وقت کی چھٹیوں کی پالیسیاں",
    "Escalation": "تناسل",
    "'upgrade": "تحديث",
    "archivePolicy": "سياسة الأرشيف",
    "memberTimezoneDropdown": "الأعضاء الذين لديهم منطقة زمنية مختلفة"
  },
  "viewTimeOff": {
    "view": "عرض طلب إجازة",
    "viewIndividual": "عرض تفاصيل طلب الإجازة الفردية من هنا.",
    "GI": "معلومات عامة",
    "Back": "رجوع",
    "Member": "عضو",
    "policyName": "اسم السياسة",
    "startdate": "تاريخ البداية",
    "EndDate": "تاريخ الانتهاء",
    "Hours": "ساعات",
    "request": "تم الطلب في",
    "Status": "الحالة",
    "submitted": "تم الإرسال",
    "Userreason": "سبب المستخدم",
    "EditRequest": "تعديل الطلب"
  },
  "editTimeOff": {
    "edit": "تعديل الطلب",
    "text": "يمكن لموظفيك طلب إجازة؛ يمكنك عرض الطلب وقبوله أو رفضه من منصتنا الموحدة.",
    "BI": "معلومات أساسية",
    "currentbalance": "الرصيد الحالي",
    "totalTime": "إجمالي الوقت المطلوب",
    "remaining": "الرصيد المتبقي",
    "negativeBalance": "السياسة المحددة للوقت لا تسمح بالرصيد السلبي",
    "negativeLimit": "السياسة المحددة لا تسمح بالأرصدة السلبية أقل من 100",
    "lessHours": "الرجاء إدخال عدد الساعات المطلوبة أقل من",
    "hours": "ساعة"
  },
  "label": {
    "Member": "عضو",
    "Policy": "سياسة*",
    "timespan": "فترة زمنية*",
    "allday": "طوال اليوم",
    "request": "الوقت المطلوب في اليوم*",
    "reason": "السبب*",
    "time": "الوقت*"
  },
  "pageNotFound": {
    "text": "لقد بحثنا في كل مكان عن هذه الصفحة. هل أنت متأكد من أن عنوان URL للموقع صحيح؟ تواصل مع مالك الموقع أو ارجع إلى الصفحة الرئيسية.",
    "backHome": "العودة إلى الصفحة الرئيسية",
    "pageNotFound": "عفوًا... صفحة 404",
    "logoutContinue": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    "logoutSuccess": "لقد تم تسجيل خروجك بنجاح."
  },
  "achievement": {
    "activityGoal": "هدف النشاط",
    "Done": "تم"
  },
  "break": {
    "addBreak": "إضافة استراحة",
    "updateBreak": "تحديث الاستراحة",
    "Update": "تحديث",
    "Save": "حفظ",
    "Status": "الحالة",
    "name": "اسم الاستراحة"
  },
  "remainingText": {
    "selectDays": "يرجى تحديد الأيام"
  } ,
  "departmentSetting": {
    "textfeild": "تمكين القسم من إضافة تسلسل هرمي إضافي في منظمتك لتسهيل سير العمل الخاص بك",
    "setting": "إعدادات القسم",
    "Permissions": "الصلاحيات",
    "approve": "موافقة على جداول الوقت:",
    "text": "سيكون مدير القسم قادرًا على الموافقة على أو رفض الجدول الزمني الذي قام به أعضاء/فرق القسم.",
    "timeoff": "طلبات الإجازة:",
    "text1": "سيكون مدير القسم قادرًا على طلب إجازة لأعضاء/فرق القسم الخاصة به. كما سيكون قادرًا على الموافقة على طلبات الإجازة التي تقدمها أعضاء/فرق القسم.",
    "createSchedule": "إنشاء جداول:",
    "text3": "سيكون مدير القسم قادرًا على إنشاء جداول لأعضاء/فرق القسم.",
    "Enabled": "مُمكّن",
    "Disabled": "مُعطل",
    "text4": "بنقرة زر واحدة، قم بتمكين أو تعطيل إعدادات القسم."
  } ,
  "profileSetting": {
    "memberId": "قم العضو/الموظف",
    "Designation": "تعيين العضو",
    "Settings": "رؤية ملف العضو"
  },      
  "PaginationKeys":{
    "showing": "عرض",
    "to": "ل",
    "from": "من",
    "entries": "إدخالات",
    "of": "ل"
  },
  "placeholder": {
    "searchMilestoneList": "تلاش میل اسٹون لسٹ",
    "searchmember": "البحث عن عضو",
    "search": "البحث",
    "searchmembers": "البحث عن الأعضاء",
    "Searchproject": "البحث عن مشروع",
    "searchTeam": "البحث عن الفرق",
    "select": "تحديد",
    "selectClient": "اختيار العميل",
    "totalDays": "مجموع الأيام",
    "searchMilestone": "معلم البحث",
    "SelectTimeType": "اختر نوع الوقت",
    "SelectBreakType": "اختر نوع الاستراحة"
  },
  "freeforever": {
    "text1": "يرجى الرجوع إلى التفاصيل أدناه:",
    "text2": "لقد لاحظنا أنك تستخدم ميزات إضافية بالمقارنة بما هو متاح في",
    "Youve": "لديك",
    "users, only": "المستخدمين فقط",
    "allowedIn": "المستخدم مسموح به في",
    "remove": "يرجى إزالة",
    "text3": "أفرادًا من منظمتك. لا داعي للقلق - سيتم استرداد المبلغ المحسوب بالنسبة لك.",
    "Member(s):": "الأعضاء:",
    "Remove": "إزالة",
    "projects, only": "المشاريع فقط",
    "projectAllowed": "المشاريع مسموح بها في",
    "archieve": "يرجى الأرشفة",
    "orgProject": "المشروع من منظمتك.",
    "Project(s):": "المشروعات:",
    "Archive": "الأرشيف",
    "removedText": "بمجرد إزالته من المشروع، لن يكون العضو قادرًا على الوصول إلى بيانات منظمتك (أي المشاريع، والمهام، وما إلى ذلك). هل أنت متأكد أنك تريد إزالة العضو؟",
    "addon": "إضافة إضافات",
    "user": "المستخدم",
    "reviewDetails": "مراجعة تفاصيلك",
    "Plan Info:": "معلومات الخطة:",
    "billingPlan": "خطة الفوترة",
    "changePlan": "تغيير الخطة",
    "purchasedate": "تاريخ الشراء",
    "Personal Info:": "المعلومات الشخصية:",
    "Name": "الاسم",
    "Phone": "الهاتف",
    "Email": "البريد الإلكتروني",
    "companyName": "اسم الشركة",
    "details": "تفاصيل الدفع",
    "due": "المبلغ المستحق",
    "coupon": "(هل لديك قسيمة؟)",
    "User": "المستخدم",
    "validation": "الحد الأدنى 1 والحد الأقصى 500 مستخدم مسموح بهم",
    "Remove user": "إزالة المستخدم",
    "Discount": "الخصم",
    "PromoCode": "رمز الترويج",
    "Apply": "تطبيق",
    "Subtotal": "الإجمالي الفرعي",
    "totalDue": "المبلغ الإجمالي المستحق",
    "proceed": "المتابعة وإجراء الدفع",
    "text5": "لا تزال غير متأكد؟ اختبر جميع ميزاتنا مع",
    "text6": "يومًا تجريبيًا مجانيًا. ابدأ تجربتك الآن!",
    "start": "ابدأ تجربتي",
    "freeDays": "أيام تجريبية مجانية",
    "invalidCoupon": "رمز القسيمة غير صالح",
    "woohoo!": "واو!",
    "applied": "تم التطبيق",
    "You save ₹": "توفير ₹",
    "promocode": "باستخدام رمز العرض هذا",
    "LetProceed": "لنتابع",
    "removeMem": "إزالة الأعضاء",
    "text7": "يرجى إزالة الأعضاء من منظمتك. لا داعي للقلق - سيتم استرداد المبلغ المحسوب بالنسبة لك.",
    "THANKYOU": "شكرًا لك",
    "Continue": "متابعة",
    "Back": "العودة",
    "error": "خطأ في معالجة الدفع",
    "text8": "لقد تلقينا الخطأ أدناه أثناء محاولة إجراء الدفع:",
    "sessionExpired": "انتهت هذه الجلسة.",
    "text9": "يرجى حل المشكلة أعلاه وإعادة محاولة الدفع.",
    "InvoiceErr": "يُرجى تحديد خانة اختيار الفاتورة لإرسال بريد إلكتروني",
    "downloadErr": "يُرجى تحديد خانة اختيار الفاتورة للتنزيل",
    "yourInvoiceTxt": "فاتورتك"
  },
  "VerticalNav": {
    "downloadApps": "قم بتنزيل تطبيقاتنا",
    "chooseAppsTxt": "اختر التطبيق الذي يجعل تتبع الوقت أسهل بالنسبة لك. اعمل أينما كنت باستخدام الإصدارات للكمبيوتر المكتبي أو الهاتف المحمول.",
    "desktopApps": "تطبيقات سطح المكتب",
    "mobileApps": "تطبيقات الهاتف المحمول",
    "mainMenu": "القائمة الرئيسية",
    "gettingStartedTxt": "ابدأ مع Workstatus",
    "skipTxt": "تخطى هذه الخطوة",
    "addTeamTxt": "أضف أعضاء الفريق",
    "inviteText": "ادعو فريقك حتى يتمكنوا من بدء تتبع الوقت. يمكنك تحديد دورهم وتعيينهم لمشاريع، وحتى تحديد الحدود الأسبوعية وأسعار الفوترة وأسعار الأجر.",
    "trackTimeTxt": "تتبع وإضافة الوقت",
    "setup": "الإعداد",
    "application": "التطبيقات",
    "addTimeManually": "إضافة الوقت يدويًا"
  },
  "onboardingStatus": {
    "onboardingstatus": "حالة التوجيه",
    "name": "اسم العضو",
    "account": "تم إنشاء الحساب",
    "download": "تنزيل التطبيق",
    "trackedTime": "الوقت المتتبع",
    "reminder": "إرسال تذكير",
    "requestedOn": "طلب في",
    "invitedOn": "دعوة في",
    "createdOn": "تم إنشاء الحساب في",
    "downloadedOn": "تم تنزيل التطبيق في",
    "timetracked": "تم تتبع الوقت في"
  },
  "milestone":{
    "mileEnableDisable": " هنا يمكنك بسهولة تمكين/تعطيل معلم الطريق في مشروعك.",
    "enable": "تمكين",
    "disable": "تعطيل",
    "sureToUpdate": "هل أنت متأكد أنك تريد تحديث هذا الإعداد؟",
    "update": "تم تحديث الفاصل الزمني",
    "addMile": "إضافة معلم تحديد مرحلة جديدة",
    "mileDescription": "قم بتعيين معالمك وابدأ في التتبع: أضف معلومات أساسية وتفاصيل ثم اضغط حفظ.",
    "basicInfo": "معلومات أساسية",
    "milestoneName": "اسم المعلم",
    "letterValidation": "يجب أن تكون الحروف الثلاثة الأولى حروف أبجدية",
    "limitValidation": "يجب ألا يتجاوز اسم المعلم الحد البالغ 50 حرفًا",
    "projectName": "اسم المشروع",
    "startDate": "تاريخ البدء",
    "endDate": "تاريخ الانتهاء",
    "dateCheck": "يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء.",
    "flag": "علامة",
    "internal": "داخلي:",
    "internalDesc": "ستكون المعالم مرئية فقط لمستخدمي المشروع إذا تم وضع علامة داخلية عليها.",
    "external": "خارجي:",
    "externalDesc": "ستكون المعالم مرئية لمستخدمي المشروع والعملاء إذا تم وضع علامة خارجية عليها.",
    "budgetedHours": "الساعات الميزانية",
    "projectOwner": "مالك المشروع",    
    "milestoneCreated": "تم إنشاء المعلم بنجاح",    
    "milestoneDetails": "تفاصيل المعالم",
    "Milestones": "معالم",
    "ViewAllMilestones": "عرض جميع المعالم",
    "AddMilestone": "إضافة معلم"



  },
    "welcomeTour":{
    "welcomeText" : "مرحبًا بك في Workstatus!",
    "helpText": "نحن هنا لمساعدتك في إعداد حسابك.",
    "letsStart" : "لنبدأ"
  },
   "ProductivitySummary": {
    "productivity": "ملخص الإنتاجية",
    "duration": "المدة",
    "effectiveTime": "الوقت الفعّال",
    "productivityTime": "وقت الإنتاجية",
    "idleTime": "وقت الخمول",
    "manualTime": "وقت يدوي",
    "activityPercentage": "نسبة النشاط",
    "idlePercentage": "نسبة الخمول",
    "breakTime": "وقت الاستراحة"
  },
  "Milestone":{
      "editMilestone": "تحرير اللحظة",
      "individualMilestone": "عرض تفاصيل اللحظة الفردية هنا وتحرير التفاصيل بسرعة (إذا كان ذلك ضروريًا).",
      "details": "تفاصيل اللحظة",
      "backMilestone": "العودة إلى اللحظة",
      "Cancel": "إلغاء",
      "save": "حفظ اللحظة",
      "name": "اسم اللحظة",
      "validation": "يجب ألا يتجاوز اسم اللحظة الحد البالغ 100 حرفًا",
      "project": "اسم المشروع*",
      "Flag": "علامة",
      "visiblity": "ستكون اللحظات مرئية فقط لمستخدمي المشروع إذا تم وضع علامة عليها كداخلية.",
      "Internal": "داخلية:",
      "External": "خارجية:",
      "externalVisiblity": "ستكون اللحظات مرئية لكل من مستخدمي المشروع والعميل إذا تم وضع علامة عليها كخارجية.",
      "startDate": "تاريخ البداية",
      "endDate": "تاريخ الانتهاء",
      "dateValidation": "يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البداية.",
      "budget": "ساعات الميزانية",
      "budgtvalidation": "يجب أن تكون ساعات الميزانية أقل من 10",
      "additionalInfo": "معلومات إضافية",
      "owner": "مالك الإنجاز",
      "createdby": "تم الإنشاء بواسطة والوقت",
      "lastEdited": "آخر تعديل",
      "viewMilestone": "عرض اللحظة",
      "investedhours": "الساعات المستثمرة",
      "showOnlyCr": "إظهار السجل التجاري فقط",
      "numberLimit": "يتم قبول الأرقام فقط بين 0 و 100."
  },
  "TimeOff": {
    "monthlyPattern": "النمط الشهري",
    "leaveBalance": "رصيد الاجازات",
    "leaveData": "ترك البيانات",
    "weeklyPattern": "النمط الأسبوعي",
    "balance": "توازن",
    "consumed": "مستهلكة",
    "leaveHistory": "ترك التاريخ",
    "leaveType": "نوع الإجازة",
    "leaveDuration": "مدة الإجازة",
    "leaveNote": "ترك ملاحظة",
    "status": "حالة",
    "approverNote": "مذكرة الموافقة"
   },
   "comments": {
    "comment": "تعليقات",
    "sendTxt": "إرسال للجميع",
    "editComment": "تحرير التعليق",
    "deleteComment": "حذف التعليق",
    "quoteComment": "تعليق الاقتباس",
    "loadMore": "تحميل المزيد من التعليقات",
    "confirmComplete": "هل أنت متأكد أنك تريد حذف هذا التعليق؟",
    "bulkDelete":{
      "first":"هل انت متأكد من الحذف",
      "last":"لا يمكن استرجاع التعليقات المحذوفة"
    },
    "enterTagName": "أدخل اسم العلامة",
    "Internal": "داخلية",
    "External": "خارجية",
    "alertText": "حدث خطأ ما!"
  },
  "todoGroup": {
    "todogroup": "مجموعات المهام",
"Comments": "التعليقات",
    "todoGroups": "مجموعات المهام",
    "progress": "تقدم",
    "delete": "حذف",
    "sureDelete": "هل أنت متأكد أنك تريد حذف هذه المجموعة من المهام؟",
    "sureMarkAsComplete": "هل أنت متأكد أنك تريد تحديد هذه المجموعة كمكتملة؟",
    "sureMove": "هل أنت متأكد أنك تريد نقل هذه مجموعة المهام؟",
    "markAsComplete": "وضع علامة كمكتملة",
    "move": "نقل",
    "chooseMilestone": "اختر معلمًا",
    "chooseProject": "اختر مشروعًا",
    "Search": "بحث",
    "SelectProject": "اختر مشروعًا",
    "SelectMilestone": "اختر معلمًا",
    "Move": "نقل",
    "Cancel": "إلغاء",
    "Open": "فتح",
    "TodoGroup": "مجموعة المهام",
    "Todos": "مهام",
    "TodoCompleted": "المهام المكتملة",
    "NoDataFound": "لا توجد بيانات",
    "Completed": "تم الانتهاء",
    "newTodoGroup": "مجموعة مهام جديدة"
  },
  "SearchBarText": {
    "screenWiseHelp": "مساعدة تتعلق بالشاشة",
    "downloadApps": "تحميل التطبيقات",
    "welcomeToWorkstatus": "مرحبًا بك في Workstatus",
    "selectThemesMode": "اختر وضع الأوضاع",
    "changeLanguage": "تغيير اللغة",
    "quickLinks": "روابط سريعة"
  },
  "drawersText": {
    "noNotes": "لا توجد ملاحظات متاحة",
    "manualNotes": "ملاحظة/ملاحظات يدوية",
    "trackerNotes": "ملاحظة/ملاحظات المتعقب",
    "idleNotes": "ملاحظة/ملاحظات الخمول",
    "CategoryHeading":"فئات لوحة الرسائل",
    "CategoryText" : "قم بإعداد الفئات للوحة الرسائل هذه أدناه.",
    "addmore": "أضف مزيد من التفاصيل",
    "searchparenttodo": "البحث عن المهمة الرئيسية",
    "searchtodogroup": "البحث في مجموعة المهام",
    "enterEmailId": "أدخل البريد الإلكتروني",
    "enterMemberId": "أدخل هوية العضو",
    "selectRole": "اختر الدور",
    "memberId": "هوية العضو",
    "policy": "سياسة*",
    "ViewTimeOff": "عرض الإجازات",
    "requestedOn": "تم الطلب في",
    "EditTimeoff": "تحرير الإجازة",
    "EditTimeoffTxt": "يمكن لموظفيك طلب إجازة.",
    "memberName": "اسم العضو",
    "project": "المشروع",
    "invite": "دعوة",
    "manager": "المدير",
    "enterProjectName": "أدخل اسم المشروع",
    "client": "العميل",
    "selectClient": "اختر العميل",
    "description": "الوصف",
    "typeHere": "اكتب هنا",
    "date": "التاريخ",
    "startTime": "وقت البدء",
    "endTime": "وقت الانتهاء",
    "note": "ملاحظة",
    "addNewMilestone": "إضافة خطوة جديدة",
    "setYourMilestone": "قم بتعيين خطواتك وابدأ التتبع: أضف معلومات الأساسية والتفاصيل وانقر فوق حفظ.",
    "milestoneStoneName": "اسم الخطوة",
    "startDate": "تاريخ البدء",
    "endDate": "تاريخ الانتهاء",
    "flag": "علم",
    "milestoneOwner": "مالك الخطوة",
    "milestoneChangeRequest": "حدد إذا كنت ترغب في جعل هذه الخطوة طلب تغيير",
    "save": "حفظ",
    "newTodo": "إضافة مجموعة مهام جديدة",
    "markingTodo": "تحديد حالة المهمة أصبح ممتعًا. قم بتخصيص لون لكل حالة.",
    "projectName": "اسم المشروع",
    "todoGroupname": "اسم مجموعة المهام",
    "relatedMilestone": "خطوة مرتبطة",
    "none": "لا شيء",
    "internal": "داخلي",
    "external": "خارجي",
    "budgetedHours":"ساعات ميزنة",
    "AddTime": "إضافة وقت يدويًا",
    "AddTodo": "إضافة مهمة",
    "AddTodoGroup": "إضافة مجموعة مهام",
    "AddMilestone": "إضافة معلمة",
    "AddProject": "إضافة مشروع",
    "AddMember": "إضافة عضو جديد",
    "AddTeam": "إضافة فريق",
    "AddDepartment": "إضافة قسم",
    "AddClient": "إضافة عميل",
    "AddJobsite": "إضافة موقع العمل",
    "AddSchedule": "إضافة جدول زمني",
    "RequestTimeoff": "طلب إجازة",
    "projectCurr" : "عملة المشروع"
  },
  "drawersFieldPlaceholders": {
    "Manager": "اختر المدير",
    "Users": "اختر المستخدم",
    "Member": "اختر العضو",
    "projName": "اختر المشروع",
    "SelectTodoName": "اختر المهمة",
    "todoGroupName": "اختر مجموعة المهام",
    "parentTodoName": "اختر المهمة الأم",
    "todoName": "أدخل اسم المهمة",
    "status": "اختر الحالة",
    "Description": "اكتب هنا",
    "Tags": "اختر أو أدخل اسم الوسم",
    "TeamName": "أدخل اسم الفريق",
    "TeamMates": "اختر أفراد الفريق",
    "TeamLead": "اختر قائد الفريق",
    "clientEmail": "أدخل عنوان البريد الإلكتروني للعميل",
    "ClientProject": "اختر المشروع لهذا العميل",
    "Policy": "اختر السياسة",
    "Hours": "أدخل الساعات",
    "JobsiteName": "أدخل اسم موقع العمل",
    "JobsiteAddress": "أدخل العنوان",
    "Title": "أدخل العنوان",
    "DeptName": "أدخل اسم القسم",
    "DeptHead": "اختر رئيس القسم",
    "SelectTeam": "اختر الفريق",
    "milestoneName": "أدخل اسم الحدث",
    "SelectDate": "اختر التاريخ",
    "todoGrp": "أدخل اسم مجموعة المهام",
    "selectFlag": "تحديد العلم",
    "selectbudget": "تحديد الميزانية",
    "enterbudget": "ادخل الميزانية",
    "selectMilestoneOwner": "تحديد مالك العلامة البارزة"
  },
  "add":{
    "AddTo-do" : "إضافة مهمة",
    "AddTo-doGroup" : "إضافة مجموعة من المهام",
    "AddMilestone" : "إضافة معلم هام",
    "reqMilestoneName" : "اسم الخطوة الهامة*",
    "specialCharVal" : "الأحرف الخاصة غير مقبولة",
    "Internal": "داخلي",
"InternalText": "ستكون الخطوات الهامة مرئية فقط لمستخدمي المشروع إذا تم وضع علامة داخلية عليها.",
"External": "خارجي",
"ExternalText": "ستكون الخطوات الهامة مرئية لكل من مستخدمي المشروع والعملاء إذا تم وضع علامة خارجية عليها.",
"budgetVal" : "يجب أن تكون عدد ساعات التخطيط أقل من 10 أرقام",
"reqMilestoneOwner" : "مالك الخطوة الهامة",
"SearchProjectMember" : "البحث عن عضو في المشروع",
"milestoneChangeRequest" : "حدد إذا كنت ترغب في جعل هذه الخطوة الهامة كطلب تغيير",
"milestoneChangeRequestTooltip" : "يمكنك إنشاء خطوات هامة منفصلة لطلبات التغيير داخل المشروع لتتبع التفاصيل/التقدم المتعلقة بالتغييرات المحددة.",
"ProjectUpdatedSuccessfully" : "تم تحديث المشروع بنجاح.",
"updateStatus" : "هل أنت متأكد أنك تريد تحديث الحالة؟",
"StatisticsText" : "إحصائيات جميع التطبيقات المستخدمة",
"selectNone" : "عدم التحديد",
"selectAll" : "تحديد الكل",
"DataNotAvailable" : "البيانات غير متوفرة!",
"GridView" : "عرض الشبكة",
"TableView" : "عرض الجدول",
"Reject" : "رفض",
"searchTeammates" : "البحث عن زملاء الفريق",
"SearchTeamleads" : "البحث عن قادة الفريق",
"SearchDepartmentHead" : "البحث عن رئيس القسم",
"SelectProjectFirst" : "اختر مشروعًا أولا",
"AttendanceReports" : "تقارير الحضور"

  },
  "filterText": {
    "leaves" : "إجازات",
    "absent" : "غائب",
    "allLeaves": "جميع الإجازات",
    "leaveType" : "اختر نوع الإجازة",
    "shiftEntry" : "اختر وقت الوردية",
    "allShifts" : "جميع الورديات",
    "attendanceEntry": "اختر إدخال الحضور",
    "allEntry": "كل الإدخال"
    },
    "descLabel": {
      "projectDesc": "وصف المشروع",
      "todoDesc": "وصف المهمة"
  },
  "filter":{
    "Deleted": "تم الحذف",
    "Deactivated": "تم تعطيله",
    "deviceType": "نوع الجهاز",
    "Mac" : "ماك",
    "AllUsers": "جميع المستخدمين",
    "AllDevice": "جميع الأجهزة",
    "RoleType": "نوع الدور",
    "SelectRoleType": "اختر نوع الدور",
    "All": "الكل",
    "TrackingCategory": "فئة التتبع",
    "TrackingMode": "وضع التتبع",
    "SelectCategory" : "اختر الفئة",
    "SelectMode" : "اختر الوضع",
    "AllTodo" : "كل المهام",
    "Alphabetically": "ترتيب أبجدي",
    "LastVisited" : "زيارة أخيرة",
    "CurrentWeek" : "الأسبوع الحالي",
    "NextWeek" : "الأسبوع القادم",
    "Rejected" : "مرفوض",
    "Pending": "قيد الانتظار",
    "Leavepolicy" : "سياسة الإجازة",
    "Days": "أيام",
    "Yettostart" : "لم يبدأ بعد",
    "InProgress" : "قيد التنفيذ",
    "OnHold" : "في الانتظار",
    "Completed": "مكتملة",
    "Cancelled" : "ملغاة",
    "Archived": "مؤرشفة",
    "Selecttype": "اختر النوع",
"AllDuration" : "كل المدة",
"Select": "اختر",
"between": "بين",
"SecondGreaterThanFirstValue" : "يجب أن يكون القيمة الثانية أكبر من القيمة الأولى",
"Alltype" : "كل النوع"
  },
  "selectField":{
    "SameAsPayRate" :"نفس معدل الأجر",
    "SameAsPayRateDesc" :"حدد نفس الأجر الساعي لهذا المشروع",
    "ProjectMember": "عضو في المشروع",
  "projNameDesc": "أدخل اسم المشروع الذي ترغب في الاحتفاظ به. يجب ألا يحتوي اسم المشروع على أي أحرف خاصة فيه ويجب أن يكون اسم المشروع مكونًا من 3 أحرف على الأقل.",
  "managerdesc": "أضف عضوًا سيكون مدير هذا المشروع أو لمن ترغب في تعيينه كنقطة اتصال لهذا المشروع.",
  "projMemberDesc": "أدخل الأعضاء الذين سيعملون على هذا المشروع أو هذه المهمة. سيتم إضافة هؤلاء أعضاء المشروع في قائمة المهام التي ستقوم بإنشائها في هذا المشروع.",
  "projStatusDesc": "حدد حالة المشروع",
  "startDateDesc": "حدد تاريخ بدء المشروع.",
  "endDateDesc": "حدد تاريخ انتهاء هذا المشروع.",
  "projDesc": "أدخل تفاصيل هذا المشروع التي ترغب في عرضها للمديرين ومستخدمي المشروع. قد تحتوي هذه البيانات على هدف المشروع، والعملية، والأهداف المحققة أو أي معلومات أخرى قيمة / مطلوبة تتعلق بالمشروع.",
  "clientDesc": "يرجى إضافة العميل الذي تقوم بإنجاز هذا المشروع من أجله. لتحديد العميل من هنا، يجب عليك أولاً إضافة العميل.",
  "billableDesc": "يرجى تشغيل هذا الزر إذا كنت ترغب في جعل هذا المشروع مدفوع الرسوم. سيساعدك هذا في إصدار الفواتير للعملاء عن الساعات الفعلية التي تم فوتها.",
  "typeDesc": "حدد نوع الميزانية التي ترغب في تعيينها لهذا المشروع.",
  "hoursDesc": "حدد عدد الساعات التي ترغب في تعيينها كميزانية لهذا المشروع.",
  "costDesc": "قم بتعيين مبلغ التكلفة الذي تريد تعيينه كميزانية لهذا المشروع.",
  "projPerHrRate":"حدد الأجر الساعي لهذا المشروع.",
  "BudgetMode" : "حدد وضع الميزانية الذي ترغب في تحديده لهذا المشروع.",
  "CurrencyType" : "حدد نوع العملة للميزانية",
  "notifyAtDesc": "حدد النسبة المئوية المتبقية من الميزانية عندما ترغب في تلقي إشعار.",
  "todoProjName": "أدخل اسم المشروع الذي ترغب في إنشاء ToDos فيه",
  "todogroupDesc": "حدد مجموعة ToDo التي ترغب في ربط هذا ToDo/Task بها.",
  "parentTodoDesc": "حدد ToDo/Task تحته ترغب في إنشاء أي ToDo/Task فرعي",
  "todoNameDesc": "حدد الاسم الفريد للـ ToDo/Task الذي تقوم بإنشائه",
  "todoDesc": "أدخل تفاصيل هذا الـ ToDo/Task الذي ترغب في عرضها للأعضاء. قد تحتوي هذه البيانات على هدف الـ ToDo/Task، والعملية، والأهداف التي يمكن تحقيقها أو أي معلومات قيمة/مطلوبة أخرى تتعلق بالـ ToDo/Task.",
  "selectMemDesc": "أدخل الأعضاء الذين سيعملون على هذا Todo/Task. يمكن إضافة الأعضاء فقط الذين تمت إضافتهم في المشروع المحدد أعلاه في هذا ToDo",
  "todoRecurrence": "سيساعدك في تعيين ToDo كـ ToDo متكرر.",
  "statusDesc": "حدد حالة ToDo/Task وفقًا لتقدم ToDo. يمكن تغييره في أي وقت عن طريق تحرير ToDo",
  "priorityDesc": "حدد أولوية ToDo",
  "todoStartDate": "حدد تاريخ بدء ToDo/Task.",
  "todoEndDate": "حدد تاريخ انتهاء هذا ToDo/Task.",
  "todoTypeDesc": "حدد نوع ToDo الذي ترغب في إنشائه، Enhancement للـ ToDos الجديدة أو أي مهمة تقوم بتحديدها لأي عضو و Bugs للمشاكل التي تعترض في تنفيذ المهام من قبل الأعضاء",
  "todoBillable": "يرجى تشغيل هذا الزر إذا كنت ترغب في جعل هذا المشروع مدفوع الرسوم. سيساعدك هذا في إصدار الفواتير للعملاء عن الساعات الفعلية التي تم فوتها.",
  "severityDesc": "حدد خطورة العلل وفقًا لحدتها الحرجة. ستكون الأكثر حرجية محددة على أنها Shows Topper وستكون الأقل حرجية محددة على أنها Minor",
  "tagsDesc": "يمكنك إنشاء علامات مختلفة للـ ToDo التي ستساعد في تمييز ToDo هذه وستساعدك أيضًا في البحث عنها ويمكن استخدامها أيضًا أثناء التعليق على المشاريع والمراحل الرئيسية و ToDos.",
  "estHrDesc": "حدد عدد الساعات التي ترغب في تعيينها لهذا ToDo/Task للأعضاء",
  "investedHrsDesc": "هذه هي الساعات التي تُظهر كم من الوقت قضاه العضو في العمل على هذا المهمة. مصدر المهمة المستثمرة سيكون تطبيق سطح المكتب، تطبيق الجوال، أو إدخال يدوي من جداول الزمن.",
  "progressPerc": "قم بتعيين نسبة التقدم للمهمة"
  },
  "invite": {
    "InviteMembers": "يدعو",
    "investedHrsDesc": "هذه هي الساعات التي تُظهر كم من الوقت قضاه العضو في العمل على هذا المهمة. مصدر المهمة المستثمرة سيكون تطبيق سطح المكتب، تطبيق الجوال، أو إدخال يدوي من جداول الزمن.",
    "TrackingMode": "وضع التتبع",
    "SelectCategory" : "اختر الفئة",
    "SelectMode" : "اختر الوضع",
    "AllTodo" : "كل المهام",
    "Alphabetically": "ترتيب أبجدي",
    "LastVisited" : "زيارة أخيرة",
    "CurrentWeek" : "الأسبوع الحالي",
    "NextWeek" : "الأسبوع القادم",
    "Rejected" : "مرفوض",
    "Pending": "قيد الانتظار",
    "Leavepolicy" : "سياسة الإجازة",
    "Days": "أيام",
    "Yettostart" : "لم يبدأ بعد",
    "InProgress" : "قيد التنفيذ",
    "OnHold" : "في الانتظار",
    "Completed": "مكتملة",
    "Cancelled" : "ملغاة",
    "Archived": "مؤرشفة",
    "Selecttype": "اختر النوع",
"AllDuration" : "كل المدة",
"Select": "اختر",
"between": "بين",
"SecondGreaterThanFirstValue" : "يجب أن يكون القيمة الثانية أكبر من القيمة الأولى",
"Alltype" : "كل النوع"
  },
  "employeeDirectory":{
    "emplyoee" : "موظف",
    "selectedLocation" : "اختر الموقع",
    "searchLocation" : "البحث عن موقع",
    "allLocation" : "جميع المواقع",
    "selectDepartment" : "اختر القسم",
    "allDepartment" : "جميع الأقسام",
    "employeeDirectory": "دليل الموظفين",
    "searchEmployee" : "البحث عن الموظف",
    "punchedInAt: ":"لم يسجل الدخول بعد",
    "notLoggedInYet": "الدخول غير مسجل بعد",
    "jobTitle:" : "المسمى الوظيفي:",
    "department:" : "القسم:",
    "phoneNo:": "رقم الهاتف:",
    "location:" : "الموقع:"
},
"budget":{
  "TotalCost":"التكلفة الإجمالية",
  "TotalHours":"إجمالي الساعات",
  "BudgetedCost" : "التكلفة الميزانية",
  "InvestedCost" : "التكلفة المستثمرة",
  "BudgetedHours" : "الساعات الميزانية",
  "InvestedHours" : "الساعات المستثمرة",
  "Hours" : "ساعات",
  "Cost" : "تكلفة",
  "Selectbudget": "اختر الميزانية",
  "Enterbudget" : "أدخل الميزانية",
  "startDateSmaller" :"تاريخ البدء أصغر من تاريخ بدء المشروع",
  "startDateGreater" :"تاريخ البدء أكبر من تاريخ الانتهاء",
  "endDateGreater" : "تاريخ الانتهاء أكبر من تاريخ انتهاء المشروع",
  "endDateSmaller" : "تاريخ الانتهاء أصغر من تاريخ البدء",
  "projEndDateValid": "تاريخ الانتهاء أصغر من تاريخ بدء المشروع",
  "EstimatedCost" : "التكلفة المقدرة",
  "overinvested" : "مبالغ فيه"
},
"memberGraph":{
  "TableView" : "عرض الجدول",
  "GridView" : "عرض الشبكة",
  "GraphView" : "عرض الرسم البياني",
  "EmployeeDetails" : "تفاصيل الموظفين",
  "loadMore": "تحميل المزيد"
 },

"projectTimesheet":{
  "groupBy" : "تجميع حسب:",
  "date" : "التاريخ",
  "member" : "العضو",
  "Todo" : "المهام اليومية",
  "TaskDetail" : "تفاصيل المهمة",
  "HrsLog" : "سجل الساعات",
  "TimePeriod" : "الفترة الزمنية",
  "Member": "العضو",
  "Status" : "الحالة",
  "Date" : "التاريخ",
  "addManualTime": "إضافة وقت يدوي"
},
"officeLocation": {
  "manageLocation": "إدارة مواقع العمل",
  "locationName": "اسم الموقع",
  "addNewLocation": "إضافة موقع جديد",
  "enterLocation": "أدخل اسم الموقع",
  "saveLocation": "حفظ الموقع",
  "editLocation": "تحرير الموقع",
  "deleteLocation": "حذف الموقع",
  "noLocation": "لا توجد مواقع في الوقت الحالي!",
  "memberLocation" : "موقع العضو",
  "reminderTooltip": "تذكير ببدء المؤقت",
  "knowMore" : "يمكنك تعيين عناوين IP محددة كمواقع عمل. عندما يقوم الموظفون بتسجيل الدخول من هذه العناوين IP المحددة، سيكتشف النظام تلقائيًا ويعرض مواقع عمل الموظفين."

},
"messageBoardDisscussion": {
  "noMsg": "لا توجد رسائل حتى الآن",
  "noMsgText": "نشر الإعلانات، تقديم الأفكار، وجمع الملاحظات مع الحفاظ على المناقشات منظمة وموضوعية.",
  "newMsg": "رسالة جديدة",
  "pickCategory": "اختر فئة (اختياري)",
  "typeTitle": "اكتب عنوان...",
  "postMsg": "نشر الرسالة",
  "saveDraft": "حفظ كمسودة",
  "postThis": "متى ترغب في نشر هذا؟",
  "searchMsg": "بحث عن رسالة",
  "selectTime": "حدد الوقت",
  "scheduleSave": "الجدولة والحفظ",
  "editMessage": "تعديل الرسالة",
  "deleteMessage": "حذف الرسالة",
  "draft": "مسودة",
  "privacy": "الخصوصية",
  "allProjectMember": "جميع أعضاء المشروع",
  "SearchProjectMember": "بحث عن عضو في المشروع",
  "allClients": "جميع العملاء",
  "SearchProjectClient": "بحث عن عميل في المشروع",
  "editAddCategory": "تعديل/إضافة الفئات",
  "goBack": "عودة",
  "LastUpdated": "آخر تحديث"
},
"notificationSettings": {
  "ReceiveAllNotifications": "تلقي جميع الإشعارات",
  "ShowNotifications" : "عرض الإشعارات",
  "allNotification" : "جميع الإشعارات",
  "Notifications": "الإشعارات",
  "email": "البريد الإلكتروني"
  },
"graph": {
  "TotalTasks": "إجمالي المهام",
  "RecentFirst": "الأحدث أولاً",
  "RecentLast": "الأحدث آخراً",
  "ShareYourCommentHere": "شارك تعليقك هنا",
  "SearchComments": "ابحث في التعليقات",
  "CopyMilestoneLink": "نسخ رابط الإنجاز",
  "FullScreenview": "عرض شاشة كاملة",
  "DUEDATE": "تاريخ الاستحقاق",
  "ASSIGNEE(S)": "المُكلف(ون)",
  "CreatedDate": "تاريخ الإنشاء",
  "TaskID": "معرّف المهمة",
  "SelectMilestones": "اختر الإنجازات",
  "AllMilestones": "جميع الإنجازات",
  "NoMilestones": "لا توجد إنجازات",
  "ParentTasks": "المهام الرئيسية",
  "SearchParentTask": "ابحث عن مهمة رئيسية",
  "EnterKeyword": "أدخل الكلمة الرئيسية",
  "Searchtags": "ابحث عن العلامات",
  "SearchTasksLists": "ابحث في قوائم المهام",
  "Savefilterview": "حفظ عرض الفلترة",
  "ViewName": "اسم العرض",
  "Maximumlengthshouldbe35characters": "يجب أن يكون الحد الأقصى للطول 35 حرفًا",
  "deletefilter": "هل أنت متأكد أنك تريد حذف هذا الفلتر؟",
  "Updatefilterview": "تحديث عرض الفلترة",
  "maxiLimitfilters": "لقد وصلت إلى الحد الأقصى لحفظ الفلاتر. يمكنك حفظ ما يصل إلى 6 فلاتر فقط.",
  "deleteTask": "هل أنت متأكد أنك تريد حذف هذه المهمة؟",
  "CopyTaskLink": "نسخ رابط المهمة",
  "TodoID": "معرف المهمة",
  "ParentTodoID": "معرف المهمة الرئيسية",
  "NonBillable": "غير قابل للفوترة",
  "NonRecurring": "غير متكرر",
  "Enhancement": "تحسين",
  "Bug": "خلل",
  "LowPriority": "أولوية منخفضة",
  "MediumPriority": "أولوية متوسطة",
  "HighPriority": "أولوية عالية",
  "AddSubTask": "إضافة مهمة فرعية",
  "MoveTask": "نقل المهمة",
  "MoveTo-do": "نقل المهمة",
  "Title": "العنوان",
  "MessageType": "نوع الرسالة",
  "SelectMessageType": "اختر نوع الرسالة",
  "CategoryType": "نوع الفئة",
  "SelectCategory": "اختر الفئة",
  "Comment(s)": "تعليقات",
  "Locations": "المواقع",
  "OfficeRemote": "مكتب/عن بُعد",
  "origin": "الأصل",
  "UpdateFilter": "تحديث الفلتر",
  "DeleteFilter": "حذف الفلتر",
  "SaveFilters": "حفظ الفلاتر",
  "ClearFilters": "مسح الفلاتر",
  "TaskStatus": "حالة المهمة",
  "None" : "لا شيء",
  "ShowStopper" : "عائق رئيسي",
  "Critical" : "حرج",
  "Major": "رئيسي",
  "Minor": "ثانوي",
  "Posted": "تم النشر",
  "Scheduled": "مجدول",
  "Draft": "مسودة"
},
"tracker": {
  "title": "تتبع وقتك",
  "description": "اختر المشروع والمهمة لبدء التتبع",
  "searchPlaceholder":"يبحث...",
  "noData":"لا تتوافر بيانات",
  "noTaskData":"لم يتم تعيين مهمة",
  "noProjectSelected":"لم يتم تحديد أي مشروع",
  "workToday": "اليوم",
  "projectLabel":"مشروع",
  "projectPlaceholder":"حدد المشروع ",
  "taskLabel":"مهمة",
  "taskPlaceholder":"حدد المهمة",
  "downloadInfo": {
    "start":"لتسجيل لقطات الشاشة،",
    "download":"يرجى تنزيل",
    "last":"تطبيق سطح المكتب واستخدامه"
  }
},
"gantt": {
  "groupBy": "تجميع بواسطة",
  "groupByStatus": "الحالة",
  "groupByMember": "عضو",
  "groupByTaskGroup": "مجموعة المهام",
  "sortBy": "ترتيب حسب",
  "sortByAlfabetically": "أبجديًا",
  "sortByCreatedDate": "تاريخ الإنشاء",
  "sortByStartDate": "تاريخ البدء",
  "sortByDueDate": "تاريخ الاستحقاق",
  "sortByTaskId": "معرف المهمة",
  "timelineView": "عرض الجدول الزمني",
  "listingView": "عرض القائمة",
  "days": "أيام",
  "week": "أسبوع",
  "monthly": "شهريًا",
  "quarterly": "ربع سنوي"
},
"expense": {
  "moduleName":"المصاريف",
  "newExpense":"مصروف جديد",
  "listTitle":{
    "SNo":"رقم",
    "ExpenseCategory":"فئة المصاريف",
    "ProjectName":"اسم المشروع",
    "TaskName":"اسم المهمة",
    "TeamMember":"العضو",
    "DateOfExpense":"تاريخ المصروف",
    "Amount":"المبلغ",
    "ExpenseDescription":"وصف المصروف",
    "Attachments":"المرفقات",
    "Status":"الحالة",
    "Reason":"السبب"
  },
  "drawer":{
    "title":"إضافة مصروف",
    "titleDescription":"هنا، يمكنك إنشاء مصروف وتخصيصه للموارد المناسبة وبدء تتبعه.",
    "categoryLabel":"فئة المصاريف",
    "categoryPlaceholder":"اختر فئة المصاريف",
    "projectNameLabel":"اسم المشروع",
    "projectNamePlaceholder":"اختر مشروع",
    "taskLabel":"اسم المهمة",
    "taskPlaceholder":"اختر مهمة",
    "memberLabel":"اسم العضو",
    "memberPlaceholder":"اختر عضو",
    "expenseDateLabel":"تاريخ المصروف",
    "hoursWorkedLabel":"ساعات العمل",
    "amountLabel":"المبلغ",
    "descriptionLabel":"وصف المصروف",
    "descriptionPlaceholder":"أدخل الوصف",
    "attachFileText":"إرفاق ملف",
    "searchPlaceholder":"بحث..."
  }
}


}
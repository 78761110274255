import { defineStore } from 'pinia';

export const useMilestoneSettingStore = defineStore('milestoneSettingStatus', () => {

    const showMilestoneTab = ref<any>(0);
    const departmentStatus = ref<any>(0);

    return {
        showMilestoneTab,
        departmentStatus
    };
  });
  
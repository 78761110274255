<script lang="ts" setup>
import { useLayouts } from "@layouts";
import { config } from "@layouts/config";
import type { NavLink } from "@layouts/types";
import { getComputedNavLinkToProp, isNavLinkActive } from "@layouts/utils";
import { useThemeConfig } from "@core/composable/useThemeConfig";
import { useHelpdeskStore } from "@/stores/helpdesk";
import { useLoaderStore } from "@/stores/loader";

defineProps<{
  item: NavLink;
}>();

const { width: windowWidth } = useWindowSize();
const { isVerticalNavMini, dynamicI18nProps } = useLayouts();
const { isAppRtl } = useThemeConfig();
// let iconDark: NavLink;

const getImageUrl = (icon: any) => {
  // console.log("iconu", icon)
  let value= new URL(`../../assets/images/newtheme/${icon}.svg`, import.meta.url)
    .href;
    // console.log("valueu", value)
  return value;
};

const getImageUrlActive = (icon: any) => {
  // console.log("icona", icon)
  let value= new URL(`../../assets/images/newtheme/${icon}.svg`, import.meta.url)
    .href;
    // console.log("valuea", value)

    return value;

};



let flagVal = ref(1);
const toggleSvg = (flag: any) => {
  flagVal.value = flag;
};
const hideTitleAndBadge = isVerticalNavMini(windowWidth);
const isHelpdeskSidebarVisible = ref(false);
const helpdeskStatus = useHelpdeskStore();
const loader = useLoaderStore();

const openHelpdeskOrLog = (title: any) => {
  if (title == "Helpdesk") {
    helpdeskStatus.setHelpdeskNavStatus(true);
    // isHelpdeskSidebarVisible.value = true
  } else if (title == "Change log") {
    window.open("https://www.workstatus.io/change-log", "_blank");
  }
};
</script>

<template>
  <!-- <li
    v-if="!loader.darkTheme"
    class="nav-link my-1"
    :class="[
      {
        ' nav-link-active nav-link ': isNavLinkActive(item, $router)
      }
    ]"
  >
    <Component
      @click="openHelpdeskOrLog(item.title)"
      :is="item.to ? 'RouterLink' : 'a'"
      v-bind="getComputedNavLinkToProp(item)"
      class="mb-0 "
      :class="{
        'router-link-active router-link-exact-active pl-0 pr-4': isNavLinkActive(item,$router),
        'cursor-pointer':
          item.title == 'Helpdesk' || item.title == 'Change log',
      }"
      @mouseover="item.showIcon = getImageUrlActive(item.icon.activeIcon)"
      @mouseout="item.showIcon = getImageUrl(item.icon.icon)"
    >
      <span class="" :class="isAppRtl ? 'ml-3' : 'mr-3'"> 
        <img
          :src="
            isNavLinkActive(item, $router)
              ? getImageUrlActive(item.icon.activeIcon)
              : item.showIcon ||
                (item.showIcon
                  ? item.icon.icon + '.svg'
                  : getImageUrl(item.icon.icon))
          "
          style="height: 18px; width: 18px; border-radius: 0;"
          
        />
      </span>
      <TransitionGroup name="transition-slide-x">
        
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-show="!hideTitleAndBadge"
          key="title"
          class="nav-item-title inactive-font-weight"
          :class="[
            { 'active-font-weight': isNavLinkActive(item, $router) }
          ]"
          v-bind="dynamicI18nProps(item.title, 'span')"
        >
          {{ item.title }}
        </Component>

        
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-if="item.badgeContent"
          v-show="!hideTitleAndBadge"
          key="badge"
          class="nav-item-badge"
          :class="item.badgeClass"
          v-bind="dynamicI18nProps(item.badgeContent, 'span')"
        >
          {{ item.badgeContent }}
        </Component>
      </TransitionGroup>
    </Component>
  </li>
  <li
    v-else
    class="nav-link my-1"
    :class="[
      {
        ' nav-link-active nav-link':
          isNavLinkActive(item, $router),
          'dark_theme':loader.darkTheme
      }
    ]"
  >
  
    <Component
      @click="openHelpdeskOrLog(item.title)"
      :is="item.to ? 'RouterLink' : 'a'"
      v-bind="getComputedNavLinkToProp(item)"
      class="mb-0"
      :class="{
        'router-link-active router-link-exact-active pl-0 pr-4': isNavLinkActive(item,$router),
        'cursor-pointer':
          item.title == 'Helpdesk' || item.title == 'Change log',
         
      }"
      @mouseover="item.showIcon = item.dark_icon._activeIcon"
      @mouseout="item.showIcon = item.dark_icon._icon"
      :key="item.title"
    >
      <span :class="isAppRtl ? 'ml-3' : 'mr-3'" style="height: 18px;width: 18px;" >
        <img
          :src="
            isNavLinkActive(item, $router)
              ?item.dark_icon._activeIcon
              : item.showIcon ||
                (item.showIcon
                  ? item.icon._icon + '.svg'
                  : item.dark_icon._icon)
          "
          :key="item.title"
          style="height: 18px;width: 18px; border-radius: 0px;"
        />
      </span>
      <TransitionGroup name="transition-slide-x">
     
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-show="!hideTitleAndBadge"
          key="title"
          class="nav-item-title-dark inactive-font-weight"
          :class="[
            { 'active-font-weight': isNavLinkActive(item, $router) && loader.darkTheme },
            { 'font-color-dark': loader.darkTheme },
          ]"
          v-bind="dynamicI18nProps(item.title, 'span')"
        >
          {{ item.title }}
        </Component>

      
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-if="item.badgeContent"
          v-show="!hideTitleAndBadge"
          key="badge"
          class="nav-item-badge"
          :class="item.badgeClass"
          v-bind="dynamicI18nProps(item.badgeContent, 'span')"
        >
          {{ item.badgeContent }}
        </Component>
      </TransitionGroup>
    </Component>
  </li> -->


  <li
    v-if="!loader.darkTheme"
    class="nav-link sub-nav-link"
    style="margin-bottom: 3px; "
    :class="[
      {
        ' nav-link-active nav-link ': isNavLinkActive(item, $router),
      }
    ]"
  >
    <Component
      @click="openHelpdeskOrLog(item.title)"
      :is="item.to ? 'RouterLink' : 'a'"
      v-bind="getComputedNavLinkToProp(item)"
      
      class="mb-0 "
      style="height: 38px;"
      :style="{
        paddingLeft: !['Dashboard', 'Projects', 'Tasks', 'Settings']?.includes(item?.title) ? '32px' : ''
      }"
      :class="{
        'router-link-active router-link-exact-active': isNavLinkActive(item,$router),
        'cursor-pointer':
          item.title == 'Helpdesk' || item.title == 'Change log', 'pl-0 pr-4' : !isAppRtl && isNavLinkActive(item,$router), 'pr-0 pl-3' : isAppRtl && isNavLinkActive(item,$router) 
      }"
      @mouseover="item.showIcon = getImageUrlActive(item?.icon?.activeIcon)"
      @mouseout="item.showIcon = getImageUrl(item?.icon?.icon)"
    >
    <!-- <Component
        :is="config.app.iconRenderer || 'div'"
        v-bind="item.icon || config.verticalNav.defaultNavItemIconProps"
        class="nav-item-icon"
      /> -->
      <!-- {{ item?.icon?.icon }} -->
      <span class="pt-1" :class="isAppRtl ? 'icon-margin-left' : 'icon-margin-right'"> 
        <img
          :src="
            isNavLinkActive(item, $router)
              ? getImageUrlActive(item?.icon?.activeIcon)
              : item?.showIcon ||
                (item?.showIcon
                  ? item?.icon?.icon + '.svg'
                  : getImageUrl(item?.icon?.icon))
          "
          style="height: 18px; width: 18px; border-radius: 0;"
          
        />
      </span>
      <TransitionGroup name="transition-slide-x">
        
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-show="!hideTitleAndBadge"
          key="title"
          class="nav-item-title inactive-font-weight pt-1"
          :class="[
            { 'active-font-weight': isNavLinkActive(item, $router) }
          ]"
          v-bind="dynamicI18nProps(item.title, 'span')"
        >
          {{ item.title }}
        </Component>

        
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-if="item.badgeContent"
          v-show="!hideTitleAndBadge"
          key="badge"
          class="nav-item-badge"
          :class="item.badgeClass"
          v-bind="dynamicI18nProps(item.badgeContent, 'span')"
        >
          {{ item.badgeContent }}
        </Component>
      </TransitionGroup>
    </Component>
  </li>
  <li
    v-else
    class="nav-link"
    style="margin-bottom: 3px;"
    :class="[
      {
        ' nav-link-active nav-link':
          isNavLinkActive(item, $router),
          'dark_theme':loader.darkTheme
      }
    ]"
  >
  
    <Component
      @click="openHelpdeskOrLog(item.title)"
      :is="item.to ? 'RouterLink' : 'a'"
      v-bind="getComputedNavLinkToProp(item)"
      class="mb-0"
      style="height: 38px;"
      :style="{
        paddingLeft: !['Dashboard', 'Projects', 'Tasks', 'Settings']?.includes(item?.title) ? '32px' : ''
      }"
      :class="{
        'router-link-active router-link-exact-active ': isNavLinkActive(item,$router),
        'cursor-pointer':
          item.title == 'Helpdesk' || item.title == 'Change log', 'pl-0 pr-4' : !isAppRtl && isNavLinkActive(item,$router), 'pr-0 pl-3' : isAppRtl && isNavLinkActive(item,$router)
         
      }"
        @mouseover="item.showIcon = item?.dark_icon?._activeIcon"
      @mouseout="item.showIcon = item?.dark_icon?._icon"
      :key="item.title"
    >
    <!-- <Component
        :is="config.app.iconRenderer || 'div'"
        v-bind="item.icon || config.verticalNav.defaultNavItemIconProps"
        class="nav-item-icon"
      /> -->
      <span class="" :class="isAppRtl ? 'icon-margin-left' : 'icon-margin-right'" style="height: 18px; width: 18px;" >
        <img
          :src="
            isNavLinkActive(item, $router)
              ?item?.dark_icon?._activeIcon
              : item?.showIcon ||
                (item?.showIcon
                  ? item?.icon?._icon + '.svg'
                  : item?.dark_icon?._icon)
          "
          :key="item.title"
          style="height: 18px;width: 18px; border-radius: 0px;"
        />
      </span>
      <TransitionGroup name="transition-slide-x">
     
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-show="!hideTitleAndBadge"
          key="title"
          class="nav-item-title-dark inactive-font-weight "
          :class="[
            { 'active-font-weight': isNavLinkActive(item, $router) && loader.darkTheme },
            { 'font-color-dark': loader.darkTheme },
          ]"
          v-bind="dynamicI18nProps(item.title, 'span')"
        >
          {{ item.title }}
        </Component>

      
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-if="item.badgeContent"
          v-show="!hideTitleAndBadge"
          key="badge"
          class="nav-item-badge"
          :class="item.badgeClass"
          v-bind="dynamicI18nProps(item.badgeContent, 'span')"
        >
          {{ item.badgeContent }}
        </Component>
      </TransitionGroup>
    </Component>
  </li>
</template>

<style lang="scss">
.layout-vertical-nav {
 
  .nav-link
  {
    .router-link-active:not(.dark_theme){
      // padding-left:0!important;
  }
  a {
    display: flex;
    color: #d5d6ec!important;
    align-items: center;
    margin-left: 0;
    margin-right:0;
    padding-left:0;
  }
  a:hover span{
    color: #624de3!important;
    font-weight: 600 !important;
  }
}
  .nav-link :hover {
    background: #f3f1fe;
    color: #624de3!important;
    border-radius: 8px;
  }
  .nav-link-dark a {
    display: flex;
    color: #d5d6ec!important;
    align-items: center;
  }
  .nav-link-dark :hover {
    background: #f3f1fe;
    color: #624de3!important;
    border-radius: 8px;
  }
}
.nav-link-active {
  background: #f3f1fe;
  color: #624de3 !important;
  border-radius: 8px;
  margin: 0px 14px 1px 14px !important;
}
.active-font-weight {
  color: #624de3 !important;
  font-weight: 600 !important;
}

.inactive-font-weight {
  font-weight: 500;
}

.nav-item-title{
  padding-bottom: 3px;
}

.nav-link-active-dark {
  // background: linear-gradient(89.83deg, #3f42a8 0.11%, #4549d7 99.85%);
  background: #fff;
  color: #624de3;
  // border-right: 2.82px solid #75e8f0;
  border-radius: 8px;
  margin: 0px 14px 0px 14px;
}

.active-font-color-dark {
  color: #624de3 !important;
  font-weight: 600 !important;
}
.font-color-dark {
  color: #fff;
}
.center-align{
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-margin-left{
  margin-left: 8px;
}

.icon-margin-right{
  margin-right: 8px;
}
</style>

<script>
import { computed } from 'vue';
import { useSnackbarStore } from '@/stores/snackbarStore'; // Assuming the store file path
import snackbarSuccess from '@/assets/images/svg/snackbarSuccess.svg';
import snackbarError from '@/assets/images/svg/snackbarError.svg';
import snackbarInvalid from '@/assets/images/svg/snackbarInvalid.svg';
import activeDollar from "@/assets/images/svg/activedollar.svg";

export default {
  props: {
    message: {
      type: String,
      required: true
    },
    statusCode: {
      required:true
    },
    showSnackbar: {
      required: true
    }
  },
  setup(props) {
    const snackbarStore = useSnackbarStore(); // Accessing the Pinia store

    const show = computed(() => snackbarStore.show);
    const location = 'top end';
    const showSnackbarStatus = props.showSnackbar
    const closeSnackbar = () => {
      snackbarStore.hideSnackbar(); // Update the store state to hide the snackbar
    };

    const getSnackbarSuccessImage = () => {
      return new URL(`@/assets/images/svg/snackbarSuccess.svg`, import.meta.url)
        .href;
    };

    const getSnackbarErrorImage = () => {
      return new URL(`@/assets/images/svg/snackbarError.svg`, import.meta.url)
        .href;
    };

    const getSnackbarInvalidImage = () => {
      return new URL(`@/assets/images/svg/snackbarInvalid.svg`, import.meta.url)
        .href;
    };

    const getSnackbarCloseImage = () => {
      return new URL(`@/assets/images/svg/snackbarCloseIcon.svg`, import.meta.url)
        .href;
    };

    
    return {
      show,
      location,
      closeSnackbar,
      showSnackbarStatus,
      getSnackbarSuccessImage,
      getSnackbarErrorImage,
      getSnackbarInvalidImage,
      getSnackbarCloseImage
    };
  }
};

</script>

<template>
  <v-snackbar 
  color="#FFFFFF"
    v-model="showSnackbarStatus" :location="location"
    transition="scroll-x-reverse-transition"
    :class="statusCode == 200 ? 'projectsSnackbarSuccess' : 'projectsSnackbarError'"
    >    
    <div class=" d-flex align-center">
          <img :src="statusCode == '200' ? getSnackbarSuccessImage() : (statusCode == '400' ? getSnackbarErrorImage() : getSnackbarInvalidImage())" class="mr-3"/>
      <span class="snackbarText">{{ message }}</span>
      </div>
      <template v-slot:actions>
      <!-- <v-btn color="pink" text @click="closeSnackbar">
        Close
      </v-btn> -->
      <div @click="closeSnackbar" class="cursor-pointer closeIcon">
        <!-- <VIcon variant="outlined" icon="tabler-x" @click="showSnackbarStatus = false"/> -->
        <img :src="getSnackbarCloseImage()" />
      </div>
    </template>
  </v-snackbar>
</template>


<style scoped lang="scss">
  .closeIcon{
    margin-right: 2px;
    margin-bottom: 25px;
    margin-left: 20px;
  }
</style>

import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoaderStore } from '@/stores/loader';
import { userDetailStore } from './userdata';
import { addTodoGroupItem, fetchTaskAdvanceViewGroupData } from "@/indexedDBServices/task_advanceviewDB";
import moment from "moment";

// import { getAdditionalKeys } from '@/helper/dbObjectAdditionalKeys'

export const useTodosListStore = defineStore('todosListStore', () => {
   const checkIfCurrentLoggedInUserIsProjectManager = ref<any>(false)
   const selectedProjectForAddTodo = ref<any>("")
   const projectListDataForProjDetailHeader = ref<any>();
   const slelectedProjectDetForAddTodo = ref<any>()
   const setFilterToDefault = ref<boolean>(true)
   const projectCurrentStatus = ref<any>()
   const currentProject = ref<any>()
   const projectIsArchived = ref<any>()
   const selectedTodoStatus = ref<any>();
   const currentProjectUser = ref<any>()
   const showOperationDialog = ref<boolean>(false)
   const operationStatusCode = ref<any>()
   const operationSuccessMessage = ref<any>()
   const callDetailsViewSummary = ref<boolean>(false)
   const callTodoStatusSummary = ref<boolean>(false)
   const planDetailSet = ref<any>(0)
   const uniqueGroupIdForBulkOpt = ref<any>()
   const pmsLoader = ref<boolean>(false)
   const projectDetailLoader = ref<boolean>(false)
   const overviewLoader = ref<boolean>(false)
   const groupLoader = ref<boolean>(false)
   const todoDetailsBarLoader = ref<boolean>(false)
   const sortFilterKeyName = ref<any>()
   const setGroupParentChildSort = ref<any>()
   const projectIdForAddtodo = ref<any>()
   const taskIdStatusVal = ref<any>()
   const levelOneLoadMoreArray = ref<any>([])
   const loadMoreCurrentindex = ref<any>(15)
   const elementsPerLoad = ref<any>(15)
   const routeCurrentProjectId = ref<any>()
   const selectedIdsForBulkOpt = ref<any>([])
   const userData = userDetailStore();
   const org_id = userData.userDetails.org_id;
   const loader = useLoaderStore();
   const todoStausListStore = ref([]);
   const AwskeyData = ref();
   const tree = ref();
   const taskTimestamp = ref<any>();
   const updateMemberTaskCardDb = ref<boolean>(false);
   const updateMemberTaskListDb = ref<boolean>(false);
   const setGroupsForMilestone = ref<boolean>(false)
   const loadTreeViewFromAddTodoDrawer = ref<boolean>(false)
   const create_level_index = ref({
      // 'group_index': -1,
      'level1_index': -1,
      'level2_index': -1,
      'level3_index': -1,
      'level4_index': -1,
      'level5_index': -1
   })
   const level_index = ref({
         'group_index': -1,
         'level1_index': -1,
         'level2_index': -1,
         'level3_index': -1,
         'level4_index': -1,
         'level5_index': -1
   })
   
   const levelToggleStatus = ref<any>({
       'group':'opened',
       'level1':"closed",
       'level2':'closed',
       'level3':'closed',
       'level4':'closed'
   })
    const groups = ref([])
    const isCreateTodo = ref<boolean>(false)
    const currentOpenGroupId = ref<any>()
    const currentClickedTodoId = ref<any>()
    const level1TodosList = ref<any>([]);
    const level2TodosList = ref<any>([]);
    const level3TodosList = ref<any>([]);
    const level4TodosList = ref<any>([]);
    const level5TodosList = ref<any>([]);
   const estimatedHours = ref<any>();
   const investedHours = ref<any>();
    const getTree = computed(() => tree.value);
    const showOperationStatus = ref<boolean>(false)
    const operationMessage = ref<any>();
    const operationStatus = ref<any>()
    const showGroupLoaderOnClick = ref<boolean>(false)
    const showTodoLoaderOnClick = ref<boolean>(false)
    const bardata = ref<any>();
    const totalTodoCount = ref<any>();
    const displaybar = ref<boolean>(false);
    const pageNumber = ref<any>(1);
    const groupTimestamp = ref<any>()
    const apiGetGroupsList = async(projectId: any) => {
      let groupData = await fetchTaskAdvanceViewGroupData(parseInt(projectId))
      if(groupData){
         groups.value = groupData.data
         tree.value = groupData.data
         setGroupParentChildSort.value = groupData.sortBy[0]
         groupTimestamp.value = groupData.timestamp.object_updated_at
         groupLoader.value = false
         return;
      }
      setGroupsForMilestone.value = false
      groupLoader.value = true
      axios.post('/v1/todoweb/list', {
         project_id: projectId,
         view_name: 'todo_list_view'
     }).then(response => {
      // Insert Groups
      setGroupParentChildSort.value = response.data.response?.data[0]?.sort_data
      response.data.response.data = response.data.response.data.map((group: any) => ({
         ...group, 
         isGroup: true,
         uniqueGroupId: `${group.id}-${generateRandomString(5)}`
      }))
      groups.value = response.data.response.data
      tree.value = groups.value

      // store tree data in indexedDB
      const timestampData = getAdditionalKeys('/v1/todoweb/list', 'List all task lists related to project');
      groupTimestamp.value = timestampData.object_updated_at
      addTodoGroupItem(tree.value, setGroupParentChildSort.value, timestampData, parseInt(projectId))

      setGroupsForMilestone.value = true
      // loader.showLoader(false)
      groupLoader.value = false
     }).catch((err) => {
      groupLoader.value = false
     })
    }

   //todo complete two
   const apiGetGroupsListTwo = async(projectId: any) => {
      // loader.showLoader(true)
      // groupLoader.value = true
      axios.post('/v1/todoweb/list', {
         project_id: projectId,
         view_name: 'todo_list_view'
     }).then(response => {
      // Insert Groups
      setGroupParentChildSort.value = response.data.response?.data[0]?.sort_data
      response.data.response.data = response.data.response.data.map((group: any) => ({
         ...group, 
         isGroup: true,
         uniqueGroupId: `${group.id}-${generateRandomString(5)}`
      }))
      groups.value = response.data.response.data
      tree.value = groups.value
      // store tree data in indexedDB
      const timestampData = getAdditionalKeys('/v1/todoweb/list', 'List all task lists related to project');
      addTodoGroupItem(tree.value, setGroupParentChildSort.value, timestampData, parseInt(projectId))

      setGroupsForMilestone.value = true
      // loader.showLoader(false)
      groupLoader.value = false
      // apiGetTodosByGroupID(level_index.value.group_index, currentOpenGroupId.value)
     }).catch((err) => {
      groupLoader.value = false
     })
    }  
    const to = ref<any>();
    const total = ref<any>();
    const loadMoreLoader = ref<boolean>(false);
    const firstdata = ref<any>();
    const nextData = ref<any>();

    const apiGetTodosByGroupID = async(groupIndex: any, groupID: any) => {

      if(pageNumber.value == 1){
         levelOneLoadMoreArray.value = [];
         showGroupLoaderOnClick.value = true
         currentOpenGroupId.value = groupID;
         resetAllStates()
         level_index.value.group_index = groupIndex
      }
      
      // console.log("level_index.value.group_index....", level_index.value.group_index)
      // loadMoreCurrentindex.value = 15
      // elementsPerLoad.value = 15

      selectedIdsForBulkOpt.value = []
      // loader.showLoader(true)
      fetchInvestedEstimated();

      axios.post(`/v1/get/todo/bygroupid?page=${pageNumber.value}`, {
         data_count : 15,
         organization_id: org_id,
         group_id: currentOpenGroupId.value,
         project_id: [routeCurrentProjectId.value],
         sort_by: setGroupParentChildSort.value
      }).then((res) => {
         if(res.data.response.code == 200){
            to.value = res.data.response.data.to;
            total.value = res.data.response.data.total;
      //       estimatedHours.value = res.data.response.estimate_hrs;
      //       investedHours.value = res.data.response.invested_hours;
      //       res.data.response.bardata.todo_details = res.data.response.bardata?.todo_details.filter((todoStatus: any) => todoStatus.toggle_status == 1)
      //       // res.data.response.bardata.todo_details = res.data.response.bardata?.todo_details.filter((todo: any) => todo.count != 0)
      //   res.data.response.bardata.todo_details = res.data.response.bardata.todo_details.sort((a: any, b: any) => a.order_id - b.order_id)
      //       bardata.value =  res.data.response.bardata;
      //       totalTodoCount.value = bardata.value.total_todo;

         res.data.response.data.query_data.forEach((item, i) => {
            // item.estiamtedHours = convertToTotalHours(item.estiamtedHours);
            // item.invested_hours = convertToTotalHours(item.invested_hours);
            item.node_index = i
         });
         displaybar.value = true;

        const sortedTodoData = res.data.response.data.query_data.map((todo: any) =>
            // Check if the object has the key 'member'
            todo.hasOwnProperty('member')
                ? {
                    // Spread the existing properties
                    ...todo,
                    // Sort the 'member' array based on the 'pivot.role'
                    member: todo.member.sort((a: any, b: any) =>{
                     // Sort in descending order, placing role 3 at the start
                     a.role_id - b.role_id
                    }),
                }
                : todo
            );

            // level1TodosList.value = []
            // level1TodosList.value = res.data.response.data
            // levelOneLoadMoreArray.value = res.data.response.data
            levelOneLoadMoreArray.value = sortedTodoData

            if(pageNumber.value == 1){
            level1TodosList.value = levelOneLoadMoreArray.value.map((obj: any) => ({...obj, isOpen: false}));
            
            // level1TodosList.value = level1TodosList.value.map((obj: any) => ({...obj, isOpen: false}));
            tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value 
            firstdata.value = level1TodosList.value
            }
            

            if(pageNumber.value > 1){
               // console.log("level_index.value.group_index", level_index.value.group_index)
               nextData.value = levelOneLoadMoreArray.value.map((obj: any) => ({...obj, isOpen: false}));
               firstdata.value.push(...nextData.value)
               level1TodosList.value = firstdata.value
               tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value
            }
            // console.log("tree.value", tree.value)


            // fetchBarData()
         }
         else if(res.data.response.code == 204){
            // console.log("204>>")
            apiGetGroupsList(routeCurrentProjectId.value)
         
         }
         // loader.showLoader(false)
         fetchBarData();
         loadMoreLoader.value  = false
         showGroupLoaderOnClick.value= false
      }).catch((err) =>[
         // loader.showLoader(false)
         showGroupLoaderOnClick.value = false
      ])
    }

   //  loadMore
   const loadMore = () => {
      
      const endIndex = loadMoreCurrentindex.value + elementsPerLoad.value;
      const elementsToAdd = levelOneLoadMoreArray.value.slice(loadMoreCurrentindex.value, endIndex);
      level1TodosList.value.push(...elementsToAdd);
      loadMoreCurrentindex.value = endIndex;
   }

   // clean nodes
    const cleanNodes = (level:number) => {
      console.log("level_index.value.group_index", level_index.value.group_index)
      if(level==1){
         tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value.map(item => {
            const {nodes, ...rest} = item
            return {...rest}
         })
         level2TodosList.value = []
         level3TodosList.value = []
         level4TodosList.value = []
         level5TodosList.value = []
      }else if(level==2){
         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes'] = level2TodosList.value.map(item => {
            const {nodes, ...rest} = item
            return {...rest}
         })
         level3TodosList.value = []
         level4TodosList.value = []
         level5TodosList.value = []
      }else if(level==3){
         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes'] = level3TodosList.value.map(item => {
            const {nodes, ...rest} = item
            return {...rest}
         })
         level4TodosList.value = []
         level5TodosList.value = []
      }else if(level==4){
         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes'] = level4TodosList.value.map(item => {
            const {nodes, ...rest} = item
            return {...rest}
         })
         level5TodosList.value = []
      }
    }

    const toggleNodes = (level:number, childIndex: any) => {
console.log("tr111111")
      
      if(level==1 ){

         // console.log("tree.value[level_index.value.group_index]['nodes']", tree.value[level_index.value.group_index]['nodes'])
        
         //remove selected todoIds from level 2
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => !tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes'].some(obj => obj.id == id))

         //remove from level 3
         // selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => !tree.value[level_index.value.group_index]['nodes']
         // [level_index.value.level1_index]['nodes']
         // [level_index.value.level2_index]['nodes'].some(obj => obj.id == id))
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
          
            return !level2Nodes.some(obj => obj.id == id);
          });


          //remove from level 4
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
          
            return !level3Nodes.some(obj => obj.id == id);
          });
          

          //remove from level 5
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
            const level4Nodes = level3Nodes?.[level_index.value.level4_index]?.nodes || [];
          
            return !level4Nodes.some(obj => obj.id == id);
          });
          
         // console.log("selectedIdsForBulkOpt.value>>tg", selectedIdsForBulkOpt.value)

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes'] = []
         level2TodosList.value = []
         level3TodosList.value = []
         level4TodosList.value = []
         level5TodosList.value = []
      }else if(level==2 ){
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => !tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes'].some(obj => obj.id == id))

         // selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
         //    const groupNodes = tree.value[level_index.value.group_index]?.nodes;
         //    const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
         //    const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
          
         //    return !level2Nodes.some(obj => obj.id == id);
         //  });


          //remove from level 4
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
          
            return !level3Nodes.some(obj => obj.id == id);
          });
          
          //remove from level 5
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
            const level4Nodes = level3Nodes?.[level_index.value.level4_index]?.nodes || [];
          
            return !level4Nodes.some(obj => obj.id == id);
          });

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes'] = []
         level3TodosList.value = []
         level4TodosList.value = []
         level5TodosList.value = []
      }else if(level== 3 ){
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => !tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes'].some(obj => obj.id == id))

          //remove from level 4
         //  selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
         //    const groupNodes = tree.value[level_index.value.group_index]?.nodes;
         //    const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
         //    const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
         //    const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
          
         //    return !level3Nodes.some(obj => obj.id == id);
         //  });
          
          //remove from level 5
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
            const level4Nodes = level3Nodes?.[level_index.value.level4_index]?.nodes || [];
          
            return !level4Nodes.some(obj => obj.id == id);
          });

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes'] = []
         level4TodosList.value = []
         level5TodosList.value = []
      }else if(level==4 ){
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => !tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes']
         [level_index.value.level4_index]['nodes'].some(obj => obj.id == id))

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes']
         [level_index.value.level4_index]['nodes']
         = []
         level5TodosList.value = []
      }
    }

    const apiGetChildLevelTodos = (item:any, childIndex:any) => {
      console.log({
         // level:item.level,
         // toggle_status:levelToggleStatus.value,
         // level_index:level_index.value,
         // childIndex:childIndex,
         1:`${item.level} == 1`,
         2:`${levelToggleStatus.value.level1} == 'opened'`,
         3:`${level_index.value.level1_index} == ${childIndex}`
      })

      if(item.level == 1 && levelToggleStatus.value.level1 == 'opened' && level_index.value.level1_index == childIndex){
         tree.value[level_index.value.group_index]['nodes'].forEach((levelItem: any) => {
            if(levelItem.id == item.id){
               levelItem.isOpen = false
            } 
         })

         toggleNodes(item.level, childIndex)

         levelToggleStatus.value.level1 = 'closed'
         levelToggleStatus.value.level2 = 'closed'
         levelToggleStatus.value.level3 = 'closed'
         levelToggleStatus.value.level4 = 'closed'
         
         // console.log("tr2")
         return;
      }

      else if(item.level == 2 && levelToggleStatus.value.level2 == 'opened' && level_index.value.level2_index == childIndex){
         // cleanNodes(item.level)
         

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         .forEach((levelItem: any) => {
            if(levelItem.id == item.id){
               levelItem.isOpen = false
            } 
         })


         toggleNodes(item.level, childIndex)

         levelToggleStatus.value.level2 = 'closed'
         levelToggleStatus.value.level3 = 'closed'
         levelToggleStatus.value.level4 = 'closed'

         return;

      }

      else if(item.level == 3 && levelToggleStatus.value.level3 == 'opened' && level_index.value.level3_index == childIndex){
         // cleanNodes(item.level)

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         .forEach((levelItem: any) => {
            if(levelItem.id == item.id){
               levelItem.isOpen = false
            } 
         })

         toggleNodes(item.level, childIndex)

         levelToggleStatus.value.level3 = 'closed'
         levelToggleStatus.value.level4 = 'closed'

         return;

      }

      else if(item.level == 4 && levelToggleStatus.value.level4 == 'opened'&& level_index.value.level4_index == childIndex){
         // cleanNodes(item.level)

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes']
         .forEach((levelItem: any) => {
            if(levelItem.id == item.id){
               levelItem.isOpen = false
            } 
         })
         toggleNodes(item.level, childIndex)

         levelToggleStatus.value.level4 = 'closed'

         return;

      }

      // if in case any previous case breaks, remove this condition  #clear bulkOpt ids -> start 
      if(item.level == 1 && levelToggleStatus.value.level1 == 'opened'){
         // lev 2
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => 
            !tree.value?.[level_index.value.group_index]?.nodes?.[level_index.value.level1_index]?.nodes
              .some(obj => obj.id == id)
          );

         //lev 3
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
          
            return !level2Nodes.some(obj => obj.id == id);
          });


          //remove from level 4
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
          
            return !level3Nodes.some(obj => obj.id == id);
          });
          

          //remove from level 5
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
            const level4Nodes = level3Nodes?.[level_index.value.level4_index]?.nodes || [];
          
            return !level4Nodes.some(obj => obj.id == id);
          });
         // console.log("selectedIdsForBulkOpt.value", selectedIdsForBulkOpt.value)
     
      }
      else if(item.level == 2 && levelToggleStatus.value.level1 == 'opened'){
         //lev 3
         selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
          
            return !level2Nodes.some(obj => obj.id == id);
          });


          //remove from level 4
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
          
            return !level3Nodes.some(obj => obj.id == id);
          });
          

          //remove from level 5
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
            const level4Nodes = level3Nodes?.[level_index.value.level4_index]?.nodes || [];
          
            return !level4Nodes.some(obj => obj.id == id);
          });
         // console.log("selectedIdsForBulkOpt.value", selectedIdsForBulkOpt.value)
     
      }
      else if(item.level == 3 && levelToggleStatus.value.level1 == 'opened'){
          //remove from level 4
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
          
            return !level3Nodes.some(obj => obj.id == id);
          });
          

          //remove from level 5
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
            const level4Nodes = level3Nodes?.[level_index.value.level4_index]?.nodes || [];
            return !level4Nodes.some(obj => obj.id == id);
          });
         // console.log("selectedIdsForBulkOpt.value", selectedIdsForBulkOpt.value)
     
      }
      else if(item.level == 4 && levelToggleStatus.value.level1 == 'opened'){
          //remove from level 5
          selectedIdsForBulkOpt.value = selectedIdsForBulkOpt.value.filter(id => {
            const groupNodes = tree.value[level_index.value.group_index]?.nodes;
            const level1Nodes = groupNodes?.[level_index.value.level1_index]?.nodes || [];
            const level2Nodes = level1Nodes?.[level_index.value.level2_index]?.nodes || [];
            const level3Nodes = level2Nodes?.[level_index.value.level3_index]?.nodes || [];
            const level4Nodes = level3Nodes?.[level_index.value.level4_index]?.nodes || [];
          
            return !level4Nodes.some(obj => obj.id == id);
          });
         // console.log("selectedIdsForBulkOpt.value", selectedIdsForBulkOpt.value)
      }
      // #clear bulkOpt ids -> start
      // levelToggleStatus.value.
      // loader.showLoader(true)
      currentClickedTodoId.value = item.id
      showTodoLoaderOnClick.value = true
      axios.post('/v1/get/childtodo', {
         organization_id: org_id,
         todo_id: item.id,
         sort_by: setGroupParentChildSort.value
      }).then(res => {
         // if(level_index.value.level1_index == -1){
            res.data.response.data.forEach(item => {
               // item.estiamtedHours = convertToTotalHours(item.estiamtedHours);
           });


           res.data.response.data = res.data.response.data.map((todo: any) =>
        // Check if the object has the key 'member'
        todo.hasOwnProperty('member')
            ? {
                // Spread the existing properties
                ...todo,
                // Sort the 'member' array based on the 'pivot.role'
                member: todo.member.sort((a: any, b: any) =>
                    // Sort in descending order, placing role 3 at the start
                    a.role_id - b.role_id
                ),
            }
            : todo
        );

        
            if(item.level == 1){
            // show + - icon
               level1TodosList.value.forEach((levelItem: any) => {
             
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })

            levelToggleStatus.value.level1 = 'opened'
            level_index.value.level1_index = childIndex
            cleanNodes(item.level)
            
            // Insert data for level 2 todos state only
            level2TodosList.value = res.data.response.data
            
            for(let i = 0; i < level2TodosList.value.length; i++){
               level2TodosList.value[i].isOpen = false
               level2TodosList.value[i].node_index = i
            }

            // Insert data for level 1 Todos in the tree
            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes'] = level2TodosList.value


         }else if(item.level == 2){
            level2TodosList.value.forEach((levelItem: any) => {
             
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })


            levelToggleStatus.value.level2 = 'opened'      
            level_index.value.level2_index = childIndex
            cleanNodes(item.level)

             // Insert data for level 3 todos state only
             level3TodosList.value = res.data.response.data


             for(let i = 0; i < level3TodosList.value.length; i++){
               level3TodosList.value[i].isOpen = false
               level3TodosList.value[i].node_index = i
            }

            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes'] = level3TodosList.value


           

         }else if(item.level == 3){

            level3TodosList.value.forEach((levelItem: any) => {
             
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })

            levelToggleStatus.value.level3 = 'opened'
            level_index.value.level3_index = childIndex
            cleanNodes(item.level)

            // Insert data for level 4 todos state only
            level4TodosList.value = res.data.response.data

            for(let i = 0; i < level4TodosList.value.length; i++){
               level4TodosList.value[i].isOpen = false
               level4TodosList.value[i].node_index = i
            }

            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes']
            [level_index.value.level3_index]['nodes'] = level4TodosList.value

            
         }else if(item.level == 4){

            level4TodosList.value.forEach((levelItem: any) => {
             
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })

            levelToggleStatus.value.level4 = 'opened'
            level_index.value.level4_index = childIndex
            cleanNodes(item.level)

            // Insert data for level 5 todos state only
            level5TodosList.value = res.data.response.data

            for(let i = 0; i < level5TodosList.value.length; i++){
               level5TodosList.value[i].isOpen = false
               level5TodosList.value[i].node_index = i
            }

            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes']
            [level_index.value.level3_index]['nodes']
            [level_index.value.level4_index]['nodes'] = level5TodosList.value

            
         }

         // loader.showLoader(false)
         showTodoLoaderOnClick.value = false
     })
    }



    const getChildTodosOnParentComplete = (item:any, childIndex:any) => {
      console.log('getChildTodosOnParentComplete function called');
      let current_todo_level = item.level
      // showTodoLoaderOnClick.value = true
      axios.post('/v1/get/childtodo', {
         organization_id: org_id,
         todo_id: item.id,
         sort_by: setGroupParentChildSort.value
      }).then(res => {
            // if(level_index.value.level1_index == -1){
            res.data.response.data.forEach(item => {
               // item.estiamtedHours = convertToTotalHours(item.estiamtedHours);
            });


            res.data.response.data = res.data.response.data.map((todo: any) =>
               // Check if the object has the key 'member'
               todo.hasOwnProperty('member')
               ? {
                  // Spread the existing properties
                  ...todo,
                  // Sort the 'member' array based on the 'pivot.role'
                  member: todo.member.sort((a: any, b: any) =>
                     // Sort in descending order, placing role 3 at the start
                     a.role_id - b.role_id
                  ),
               }
               : todo
            );


            
            if(item.level == 1){
            // show + - icon
               // if(item.toggle_status == 1 && recurringData != null){
               //    level1TodosList.value.push(recurringData)
               // }
               level1TodosList.value.forEach((levelItem: any) => {
                  console.log('level 1 completed');
                  console.log(levelItem.id);
                  console.log(item.id);
                  console.log('childIndex',childIndex);
                  console.log('item.level',item.level);
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })

            levelToggleStatus.value.level1 = 'opened'
            level_index.value.level1_index = childIndex
            cleanNodes(item.level)
            
              // Update status and percentage of current Todo, which is being updated in this function using javascript filter function
            level1TodosList.value.forEach(element => {
               if(element.id == item.id){
                  element.complete = 1
                  element.todo_progress = "100"
               }
            });
            tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value


            // Insert data for level 2 todos state only
            level2TodosList.value = res.data.response.data
            
            for(let i = 0; i < level2TodosList.value.length; i++){
               level2TodosList.value[i].isOpen = false
            }

            // Insert data for level 1 Todos in the tree
            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes'] = level2TodosList.value


         }else if(item.level == 2){
            // if(item.toggle_status == 1 && recurringData != null){
            //    level2TodosList.value.push(recurringData)
            // }
            level2TodosList.value.forEach((levelItem: any) => {
               // console.log('level 2 completed');
               // console.log(levelItem.id);
               // console.log(item.id);
               // console.log('childIndex',childIndex);
               // console.log('item.level',item.level);
               // console.log("level_index.value.group_index ++++++++++++");
               // console.log(level_index.value.group_index);
               
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })


            levelToggleStatus.value.level2 = 'opened'      
            level_index.value.level2_index = childIndex
            cleanNodes(item.level)

            // Update status and percentage of current Todo, which is being updated in this function using javascript filter function
            level2TodosList.value.forEach(element => {
               if(element.id == item.id){
                  element.complete = 1
                  element.todo_progress = "100"
               }
            });
            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes'] = level2TodosList.value

             // Insert data for level 3 todos state only
             level3TodosList.value = res.data.response.data


             for(let i = 0; i < level3TodosList.value.length; i++){
               level3TodosList.value[i].isOpen = false
            }

            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes'] = level3TodosList.value


           

         }else if(item.level == 3){
            // if(item.toggle_status == 1 && recurringData != null){
            //    level3TodosList.value.push(recurringData)
            // }
            level3TodosList.value.forEach((levelItem: any) => {
             
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })

            levelToggleStatus.value.level3 = 'opened'
            level_index.value.level3_index = childIndex
            cleanNodes(item.level)

            // Update status and percentage of current Todo, which is being updated in this function using javascript filter function
            level3TodosList.value.forEach(element => {
               if(element.id == item.id){
                  element.complete = 1
                  element.todo_progress = "100"
               }
            });
            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes'] = level3TodosList.value

            // Insert data for level 4 todos state only
            level4TodosList.value = res.data.response.data

            for(let i = 0; i < level4TodosList.value.length; i++){
               level4TodosList.value[i].isOpen = false
            }

            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes']
            [level_index.value.level3_index]['nodes'] = level4TodosList.value

            
         }else if(item.level == 4){
            // if(item.toggle_status == 1 && recurringData != null){
            //    level4TodosList.value.push(recurringData)
            // }
            level4TodosList.value.forEach((levelItem: any) => {
             
               if(levelItem.id == item.id){
                  levelItem.isOpen = true
               } else{
                  levelItem.isOpen = false
               }
            })

            levelToggleStatus.value.level4 = 'opened'
            level_index.value.level4_index = childIndex
            cleanNodes(item.level)

            // Update status and percentage of current Todo, which is being updated in this function using javascript filter function
            level4TodosList.value.forEach(element => {
               if(element.id == item.id){
                  element.complete = 1
                  element.todo_progress = "100"
               }
            });
            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes']
            [level_index.value.level3_index]['nodes'] = level4TodosList.value

            // Insert data for level 5 todos state only
            level5TodosList.value = res.data.response.data

            for(let i = 0; i < level5TodosList.value.length; i++){
               level5TodosList.value[i].isOpen = false
            }

            tree.value[level_index.value.group_index]['nodes']
            [level_index.value.level1_index]['nodes']
            [level_index.value.level2_index]['nodes']
            [level_index.value.level3_index]['nodes']
            [level_index.value.level4_index]['nodes'] = level5TodosList.value

            
         }

         // loader.showLoader(false)
         // showTodoLoaderOnClick.value = false
         // setRecurringTodo(item, childIndex, recurringData)
     })
    }


    const setChildToggle = (item:any) => {
      if(item.level == 1){
            level1TodosList.value.forEach((levelItem: any) => {
            if(levelItem.id == item.id){
               levelItem.isOpen = true
            } else{
               levelItem.isOpen = false
            }
         })

         // levelToggleStatus.value.level1 = 'opened'
         // level_index.value.level1_index = childIndex
         // cleanNodes(item.level)
         tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value


      }else if(item.level == 2){
         level2TodosList.value.forEach((levelItem: any) => {
            if(levelItem.id == item.id){
               levelItem.isOpen = true
            } else{
               levelItem.isOpen = false
            }
         })


         // levelToggleStatus.value.level2 = 'opened'      
         // level_index.value.level2_index = childIndex
         // cleanNodes(item.level)
         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes'] = level2TodosList.value

        

      }else if(item.level == 3){
         level3TodosList.value.forEach((levelItem: any) => {
          
            if(levelItem.id == item.id){
               levelItem.isOpen = true
            } else{
               levelItem.isOpen = false
            }
         })

         // levelToggleStatus.value.level3 = 'opened'
         // level_index.value.level3_index = childIndex
         // cleanNodes(item.level)

         // Update status and percentage of current Todo, which is being updated in this function using javascript filter function
         
         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes'] = level3TodosList.value

      }else if(item.level == 4){
         level4TodosList.value.forEach((levelItem: any) => {
          
            if(levelItem.id == item.id){
               levelItem.isOpen = true
            } else{
               levelItem.isOpen = false
            }
         })

         // levelToggleStatus.value.level4 = 'opened'
         // level_index.value.level4_index = childIndex
         // cleanNodes(item.level)

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes'] = level4TodosList.value
      }
    }

    const setRecurringTodo = (item:any, childIndex:any, recurringData: any) => {
      console.log("item", item)
      // if(item.toggle_status == 1 && recurringData != null){
               //    level1TodosList.value.push(recurringData)
               // }
      
               if(item.level == 1){
                  // show + - icon
                  console.log("here in 1")
                     // adding index value into newly created todo 
                     recurringData.node_index = level1TodosList.value.length
                     
                     // moving child count of current todo into newly created todo 
                     recurringData.childcount = item.childcount
                     recurringData.isOpen = false
                     
                     // // removing + icon from the front of the recurring todo.
                     // item.childcount = 0
                     
                     // // closing current todo
                     // item.isOpen = false

                     // item.todo_progress = '100';
                     // item.complete = 1
                     const indexToUpdate = level1TodosList.value.findIndex(val => val.id == item.id);
                     if (indexToUpdate !== -1) {
                     // Update isOpen and isRequired properties
                        level1TodosList.value[indexToUpdate].isOpen = false;
                        level1TodosList.value[indexToUpdate].childcount = 0;
                        level1TodosList.value[indexToUpdate].complete = 1;
                        level1TodosList.value[indexToUpdate].todo_progress = '100';
                     }
                     if(item.toggle_status == 1 && recurringData != null){
                     // Pushing current todo data into new recurring todo
                        level1TodosList.value.push(recurringData)
                     }

                     tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value
                     level2TodosList.value = []
                     level3TodosList.value = []
                     level4TodosList.value = []
                     level5TodosList.value = []
                     // tree.value[level_index.value.group_index]['nodes']
                     // [level_index.value.level1_index]['nodes'] = []
                     // level2TodosList.value = []
                     // level3TodosList.value = []
                     // level4TodosList.value = []
                     // level5TodosList.value = []
               }else if(item.level == 2){
                  console.log("here in 2")

                     recurringData.node_index = level2TodosList.value.length
                     recurringData.childcount = item.childcount
                     recurringData.isOpen = false
                     // item.childcount = 0
                     // item.isOpen = false
                     // item.todo_progress = '100';
                     // item.complete = 1

                  const indexToUpdate = level2TodosList.value.findIndex(val => val.id == item.id);
                     if (indexToUpdate !== -1) {
                     // Update isOpen and isRequired properties
                        level2TodosList.value[indexToUpdate].isOpen = false;
                        level2TodosList.value[indexToUpdate].childcount = 0;
                        level2TodosList.value[indexToUpdate].complete = 1;
                        level2TodosList.value[indexToUpdate].todo_progress = '100';
                     }

                  if(item.toggle_status == 1 && recurringData != null){
                     level2TodosList.value.push(recurringData)
                  }
                 console.log("level_index.value.group_index", level_index.value.group_index, level_index.value.level1_index, level_index.value.level2_index)
   
                 tree.value[level_index.value.group_index]['nodes']
                 [level_index.value.level1_index]['nodes'] = level2TodosList.value
                 level3TodosList.value = []
                 level4TodosList.value = []
                 level5TodosList.value = []
                 
               //   tree.value[level_index.value.group_index]['nodes']
               //    [level_index.value.level1_index]['nodes']
               //    [level_index.value.level2_index]['nodes'] = []
               //    level3TodosList.value = []
               //    level4TodosList.value = []
               //    level5TodosList.value = []
               }else if(item.level == 3){
                  console.log("here in 3")

                  recurringData.node_index = level3TodosList.value.length
                  recurringData.childcount = item.childcount
                  recurringData.isOpen = false
                  // item.childcount = 0
                  // item.isOpen = false
                  // item.todo_progress = '100';
                  // item.complete = 1
                  const indexToUpdate = level3TodosList.value.findIndex(val => val.id == item.id);
                  if (indexToUpdate !== -1) {
                  // Update isOpen and isRequired properties
                     level3TodosList.value[indexToUpdate].isOpen = false;
                     level3TodosList.value[indexToUpdate].childcount = 0;
                     level3TodosList.value[indexToUpdate].complete = 1;
                     level3TodosList.value[indexToUpdate].todo_progress = '100';
                  }
                  if(item.toggle_status == 1 && recurringData != null){
                     level3TodosList.value.push(recurringData)
                  }

                  tree.value[level_index.value.group_index]['nodes']
                  [level_index.value.level1_index]['nodes']
                  [level_index.value.level2_index]['nodes'] = level3TodosList.value
                  level4TodosList.value = []
                  level5TodosList.value = []
                  // tree.value[level_index.value.group_index]['nodes']
                  // [level_index.value.level1_index]['nodes']
                  // [level_index.value.level2_index]['nodes']
                  // [level_index.value.level3_index]['nodes'] = []
                  // level4TodosList.value = []
                  // level5TodosList.value = []
                
               }else if(item.level == 4){
                  console.log("here in 4")

                  recurringData.node_index = level4TodosList.value.length
                  recurringData.childcount = item.childcount
                  recurringData.isOpen = false
                  // item.childcount = 0
                  // item.isOpen = false
                  // item.todo_progress = '100';
                  // item.complete = 1
                  const indexToUpdate = level4TodosList.value.findIndex(val => val.id == item.id);
                  if (indexToUpdate !== -1) {
                  // Update isOpen and isRequired properties
                     level4TodosList.value[indexToUpdate].isOpen = false;
                     level4TodosList.value[indexToUpdate].childcount = 0;
                     level4TodosList.value[indexToUpdate].complete = 1;
                     level4TodosList.value[indexToUpdate].todo_progress = '100';
                  }
                  if(item.toggle_status == 1 && recurringData != null){
                     level4TodosList.value.push(recurringData)
                  }

                  tree.value[level_index.value.group_index]['nodes']
                  [level_index.value.level1_index]['nodes']
                  [level_index.value.level2_index]['nodes']
                  [level_index.value.level3_index]['nodes'] = level4TodosList.value
                  level5TodosList.value = []
                  // tree.value[level_index.value.group_index]['nodes']
                  // [level_index.value.level1_index]['nodes']
                  // [level_index.value.level2_index]['nodes']
                  // [level_index.value.level3_index]['nodes']
                  // [level_index.value.level4_index]['nodes']
                  // = []
                  // level5TodosList.value = []
               }else if(item.level == 5){
                  console.log("here in 5")
                  item.todo_progress = '100';
                  item.complete = 1
                  recurringData.node_index = level5TodosList.value.length
                  // recurringData.childcount = item.childcount
                  // recurringData.isOpen = false
                  // item.childcount = 0
                  // item.isOpen = false
                  const indexToUpdate = level5TodosList.value.findIndex(val => val.id == item.id);
                  if (indexToUpdate !== -1) {
                  // Update isOpen and isRequired properties
                     // level5TodosList.value[indexToUpdate].isOpen = false;
                     // level5TodosList.value[indexToUpdate].childcount = 0;
                     level5TodosList.value[indexToUpdate].complete = 1;
                     level5TodosList.value[indexToUpdate].todo_progress = '100';
                  }
                  if(item.toggle_status == 1 && recurringData != null){
                     level5TodosList.value.push(recurringData)
                  }

                  tree.value[level_index.value.group_index]['nodes']
                  [level_index.value.level1_index]['nodes']
                  [level_index.value.level2_index]['nodes']
                  [level_index.value.level3_index]['nodes']
                  [level_index.value.level4_index]['nodes'] = level5TodosList.value
                  
               }
      
    }
    const generateRandomString = (length: any) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
  }
   //  watch(
   //    () => pmsLoader.value,
   //    (newStatus) => {
   //      if (newStatus) {
   //        // Display a message or perform an action when overviewLoader is true
   //        console.log('Overview Loader is true');
   //        loader.showLoader(true)
   //        // You can trigger a method to show a message here
   //      }else{
   //       loader.showLoader(false)
   //      }
   //    }
   //  );

   watch(
      [() => projectDetailLoader.value, () => overviewLoader.value, () => groupLoader.value, () => todoDetailsBarLoader.value],
      ([projectDetailStatus, overviewStatus, groupStatus, todoDetailBarStatus]) => {
        if (projectDetailStatus || overviewStatus || groupStatus || todoDetailBarStatus) {
          // Display a message or perform an action when either loader is true
          console.log('Project Detail Loader or Overview Loader is true');
          // You can trigger a method to show a message here
         //  loader.showLoader(true)
        }else{
         loader.showLoader(false)
        }
      }
    );


   /**
    * Convert time string to total hours.
    * @param {string} time - Time string in format "HH:mm" or "H:mm".
    * @returns {number} Total hours.
    */
   const convertToTotalHours = (time) => {
      // Check if time is null or empty
      if (!time || time === "0:00" || time === "0" || time === "00:00" || time === "00") {
         return 0; // Return 0 if time is null or zero
      }

      // Split the time string into hours and minutes
      const [hours, minutes] = time.split(":").map(Number);

      // Calculate total hours including fractional part for minutes
      const totalHours = hours + minutes / 60;

      // Return total hours with precision up to 2 decimal places
      return parseFloat(totalHours.toFixed(2));
   };

    const resetAllStates = () => {
      // selectedIdsForBulkOpt.value = []
      displaybar.value = false;
      level_index.value = {
         'group_index': -1,
         'level1_index': -1,
         'level2_index': -1,
         'level3_index': -1,
         'level4_index': -1,
         'level5_index': -1
      }
      create_level_index.value = {
         // 'group_index': -1,
         'level1_index': -1,
         'level2_index': -1,
         'level3_index': -1,
         'level4_index': -1,
         'level5_index': -1
      }
      tree.value = []
      tree.value = groups.value.map(item => {
         const { id, group_name, uniqueGroupId, milestone_status, milestone_name, todogrp_status, is_default, total_todo_count, estimate_hrs, invested_hours, sort_data} = item;
         return { id, group_name, isGroup: true, uniqueGroupId, milestone_status, milestone_name, todogrp_status, is_default, total_todo_count, estimate_hrs, invested_hours, sort_data};
      })
      // Reset groups array
      level1TodosList.value = []
      level2TodosList.value = []
      level3TodosList.value = []
      level4TodosList.value = []
      level5TodosList.value = []
    }
    const fetchBarData = () => {
      axios
      .post(
        "/v1/todobar/groupid",
        {
          project_id: [routeCurrentProjectId.value],
          group_id : currentOpenGroupId.value,
          organization_id: org_id,
        }).then((res) => {
        if (res.data.response.code == 200) {
         res.data.response.data.todo_details = res.data.response.data?.todo_details.filter((todoStatus: any) => todoStatus.toggle_status == 1)
         // res.data.response.data.todo_details = res.data.response.data?.todo_details.filter((todo: any) => todo.count != 0)
         res.data.response.data.todo_details = res.data.response.data.todo_details.sort((a: any, b: any) => a.order_id - b.order_id)
            bardata.value = res.data.response.data;
        }
      //   console.log("check hhh", tree.value[level_index.value.group_index]?.total_todo_count)

        //update total todo count > shown in front of group name (in case of recurring  or any case when todo count gets updated)
        groups.value = groups.value.map(group => {
         if (group.id == currentOpenGroupId.value) {
           return {
             ...group,
             total_todo_count: res.data.response.data.total_todo
           };
         }
         return group;
       });
        tree.value[level_index.value.group_index].total_todo_count = res.data.response.data.total_todo
      })
      .catch((err) => {

      });
    }

    const setUpdatedMembers = (item: any, updatedMembers: any) => {
      if(item.level == 1){
         // show + - icon
         console.log("here in 1")
            const indexToUpdate = level1TodosList.value.findIndex(val => val.id == item.id);
            if (indexToUpdate !== -1) {
               // push/add object here and update the level 1
               level1TodosList.value[indexToUpdate].member = updatedMembers;
            }

            tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value
            level2TodosList.value = []
            level3TodosList.value = []
            level4TodosList.value = []
            level5TodosList.value = []
      }else if(item.level == 2){
         console.log("here in 2")
         const indexToUpdate = level2TodosList.value.findIndex(val => val.id == item.id);
            if (indexToUpdate !== -1) {
               // push/add object here and update the level 2
               level2TodosList.value[indexToUpdate].member = updatedMembers;
            }

        tree.value[level_index.value.group_index]['nodes']
        [level_index.value.level1_index]['nodes'] = level2TodosList.value
        level3TodosList.value = []
        level4TodosList.value = []
        level5TodosList.value = []
      }else if(item.level == 3){
         console.log("here in 3")
         const indexToUpdate = level3TodosList.value.findIndex(val => val.id == item.id);
         if (indexToUpdate !== -1) {
            level3TodosList.value[indexToUpdate].member = updatedMembers;
         }
         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes'] = level3TodosList.value
         level4TodosList.value = []
         level5TodosList.value = []
       
      }else if(item.level == 4){
         console.log("here in 4")
         const indexToUpdate = level4TodosList.value.findIndex(val => val.id == item.id);
         if (indexToUpdate !== -1) {
            level4TodosList.value[indexToUpdate].member = updatedMembers;
         }

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes'] = level4TodosList.value
         level5TodosList.value = []
      }else if(item.level == 5){
         
         const indexToUpdate = level5TodosList.value.findIndex(val => val.id == item.id);
         if (indexToUpdate !== -1) {
            level5TodosList.value[indexToUpdate].member = updatedMembers;
         }

         tree.value[level_index.value.group_index]['nodes']
         [level_index.value.level1_index]['nodes']
         [level_index.value.level2_index]['nodes']
         [level_index.value.level3_index]['nodes']
         [level_index.value.level4_index]['nodes'] = level5TodosList.value
      }
    }

    const setNewToDoItem = (groupId: any,newData:any) => {
         const groupIndex = tree.value.findIndex(item => item.id == groupId);
         // console.log(newData,"newData")
         if(newData.level == 1){
            level1TodosList.value.unshift(newData)
           
            levelToggleStatus.value.group = 'opened'
            tree.value[groupIndex]['nodes'] = level1TodosList.value
         }
         if(newData.level == 2){
            
            level2TodosList.value.unshift(newData);
            levelToggleStatus.value.level1 = 'opened';
            level_index.value.level1_index = create_level_index.value.level1_index;
            // Parent Level update START 
            
            let parentNode = tree.value[groupIndex]['nodes'][create_level_index.value.level1_index];
            if(!parentNode?.childcount){
               parentNode.childcount = 1
               parentNode.is_parent = 1
               parentNode.isOpen = true
               parentNode.nodes = level2TodosList.value
               // cleanNodes(1) 
            }

            level1TodosList.value[create_level_index.value.level1_index] = parentNode;

            tree.value[groupIndex]['nodes']
            [create_level_index.value.level1_index] = parentNode;

            // Parent Level update END 

            tree.value[groupIndex]['nodes']
            [create_level_index.value.level1_index]['nodes'] = level2TodosList.value
               
            // console.log({next:tree.value[groupIndex]['nodes']
            // [level_index.value.level1_index]})
            
         }
         if(newData.level == 3){
            
            level3TodosList.value.unshift(newData);

            levelToggleStatus.value.level2 = 'opened'
            level_index.value.level2_index = create_level_index.value.level2_index;
            // Parent Level update START 
            
            let parentNode = tree.value[groupIndex]['nodes'][create_level_index.value.level1_index]['nodes'][create_level_index.value.level2_index];
            if(!parentNode?.childcount){
               parentNode.childcount = 1
               parentNode.is_parent = 1
               parentNode.isOpen = true
               parentNode.nodes = level3TodosList.value

            }
            level2TodosList.value[create_level_index.value.level2_index] = parentNode;

            tree.value[groupIndex]['nodes'][create_level_index.value.level1_index]['nodes'][create_level_index.value.level2_index] = parentNode;
            // Parent Level update END

            tree.value[groupIndex]['nodes']
            [create_level_index.value.level1_index]['nodes']
            [create_level_index.value.level2_index]['nodes'] = level3TodosList.value
         }
         if(newData.level == 4){
            
            level4TodosList.value.unshift(newData);

            levelToggleStatus.value.level3 = 'opened'
            level_index.value.level3_index = create_level_index.value.level3_index;
            // Parent Level update START 
            
            let parentNode = tree.value[groupIndex]['nodes'][create_level_index.value.level1_index]['nodes'][create_level_index.value.level2_index]['nodes']
            [create_level_index.value.level3_index];
            if(!parentNode?.childcount){
               parentNode.childcount = 1
               parentNode.is_parent = 1
               parentNode.isOpen = true

               parentNode.nodes = level4TodosList.value

            }

            level3TodosList.value[create_level_index.value.level3_index] = parentNode;

            tree.value[groupIndex]['nodes'][create_level_index.value.level1_index]['nodes'][create_level_index.value.level2_index]['nodes']
            [create_level_index.value.level3_index] = parentNode;
            // Parent Level update END

            tree.value[groupIndex]['nodes']
            [create_level_index.value.level1_index]['nodes']
            [create_level_index.value.level2_index]['nodes']
            [create_level_index.value.level3_index]['nodes'] = level4TodosList.value
         }
         if(newData.level == 5){
            
            level5TodosList.value.unshift(newData);

            levelToggleStatus.value.level4 = 'opened'
            level_index.value.level4_index = create_level_index.value.level4_index;
            // Parent Level update START 
            
            let parentNode = tree.value[groupIndex]['nodes'][create_level_index.value.level1_index]['nodes'][create_level_index.value.level2_index]['nodes']
            [create_level_index.value.level3_index]['nodes']
            [create_level_index.value.level4_index];
            if(!parentNode?.childcount){
               parentNode.childcount = 1
               parentNode.is_parent = 1
               parentNode.isOpen = true

               parentNode.nodes = level5TodosList.value

            }

            level4TodosList.value[create_level_index.value.level4_index] = parentNode;

            tree.value[groupIndex]['nodes'][create_level_index.value.level1_index]['nodes'][create_level_index.value.level2_index]['nodes']
            [create_level_index.value.level3_index]['nodes']
            [create_level_index.value.level4_index] = parentNode;
            // Parent Level update END

            tree.value[groupIndex]['nodes']
            [create_level_index.value.level1_index]['nodes']
            [create_level_index.value.level2_index]['nodes']
            [create_level_index.value.level3_index]['nodes']
            [create_level_index.value.level4_index]['nodes'] = level5TodosList.value
         }

         // tree.value[groupIndex].total_todo_count++;
         updateGroupTodoCount(groupId)
    }

    const updateGroupTodoCount = (groupId: any) => {
      const index = tree.value.findIndex(item => item.id == groupId)
      if (index != -1) {
         tree.value[index].total_todo_count++;
     }   

     groups.value = groups.value.map(group => {
      if (group.id == groupId) {
        return {
          ...group,
          total_todo_count: tree.value[index].total_todo_count
        };
      }
      return group;
    });
    }

    const updateCurrentGroupEtaInvested = (todo: any) => {
      // // Given ETA in "dd:hh:mm" format
      // let eta = tree.value[level_index.value.group_index].estimate_hrs;
      // let timeToRemove = todo.estiamtedHours;
      // // Split the ETA and time to remove into days, hours, and minutes
      // let [etaDays, etaHours, etaMinutes] = eta.split(':').map(Number);
      // let [removeDays, removeHours, removeMinutes] = timeToRemove.split(':').map(Number);

      // // Convert days to hours and add to hours
      // etaHours += etaDays * 24;
      // removeHours += removeDays * 24;

      // // Subtract hours and minutes
      // etaHours -= removeHours;
      // etaMinutes -= removeMinutes;

      // // Adjust negative minutes
      // while (etaMinutes < 0) {
      //    etaHours--;
      //    etaMinutes += 60;
      // }

      // // Construct the new ETA string
      // let newEta = `${etaHours}:${etaMinutes}`;

      // console.log(`New ETA: ${newEta} Hrs`);

      // Given ETA in "hh:mm" format
      let eta = tree.value[level_index.value.group_index].estimate_hrs;
      let timeToRemove = todo.estiamtedHours;

      // Split the ETA and time to remove into hours and minutes
      let [etaHours, etaMinutes] = eta?.split(':').map(Number);
      let [removeHours, removeMinutes] = timeToRemove.split(':').map(Number);

      // Subtract hours and minutes
      let newHours = etaHours - removeHours;
      let newMinutes = etaMinutes - removeMinutes;

      // Adjust negative values
      if (newHours < 0 || newMinutes < 0) {
         newHours = Math.max(0, newHours);
         newMinutes = Math.max(0, newMinutes);
      }

      // Construct the new ETA string
      let newEta = `${newHours}:${newMinutes < 10 ? '0' : ''}${newMinutes}`;

      console.log(`New ETA: ${newEta}`);


//       // Given ETA in "dd:hh:mm" format
//       let invested = tree.value[level_index.value.group_index].invested_hours;
//       let investedRemove = todo.invested_hours;
// console.log("todo.invested_hourstodo.invested_hours", todo.invested_hours)
//       // Split the ETA and time to remove into days, hours, and minutes
//       let [invDays, invHours, invMinutes] = invested.split(':').map(Number);
//       let [removeInvDays, removeInvHours, removeInvMinutes] = investedRemove.split(':').map(Number);

//       // Convert days to hours and add to hours
//       invHours += invDays * 24;
//       removeInvHours += removeInvDays * 24;

//       // Subtract hours and minutes
//       invHours -= removeInvHours;
//       invMinutes -= removeInvMinutes;

//       // Adjust negative minutes
//       while (invMinutes < 0) {
//          invHours--;
//          invMinutes += 60;
//       }

//       // Construct the new ETA string
//       let newInvested = `${invHours}:${invMinutes}`;

//       console.log(`New inv: ${newEta} Hrs`);

         let invested = tree.value[level_index.value.group_index].invested_hours;
         let investedRemove;
         if(todo.invested_hours == 0){
            investedRemove = '00:00'
         } else{
            investedRemove = todo.invested_hours
         }

         // Split the ETA and time to remove into hours and minutes
         let [invHours, invMinutes] = invested.split(':').map(Number);
         let [removeInvHours, removeInvMinutes] = investedRemove.split(':').map(Number);

         // Subtract hours and minutes
         let newInvHours = invHours - removeInvHours;
         let newInvMinutes = invMinutes - removeInvMinutes;

         // Adjust negative values
         if (newInvHours < 0 || newInvMinutes < 0) {
            newInvHours = Math.max(0, newInvHours);
            newInvMinutes = Math.max(0, newInvMinutes);
         }

         // Construct the new ETA string
         let newInvested = `${newInvHours}:${newInvMinutes < 10 ? '0' : ''}${newInvMinutes}`;

         console.log(`New invested: ${newInvested}`);

      // tree.value[level_index.value.group_index].estimate_hrs = newEta
      // tree.value[level_index.value.group_index].invested_hours = newInvested
      estimatedHours.value = newEta
      investedHours.value = newInvested

    }
    const setProjectUserDetails = (users:any)=>{
      currentProjectUser.value = users;
    }
    const getCurrentProjectId = computed(() => routeCurrentProjectId.value)

    //will open the group first and then show add todo field
    const apiGetTodosByGroupIDonQuickAddTodo = async(groupIndex: any, groupID: any) => {
      
      if(pageNumber.value == 1){
         levelOneLoadMoreArray.value = [];
         showGroupLoaderOnClick.value = true
         currentOpenGroupId.value = groupID;
         resetAllStates();
         level_index.value.group_index = groupIndex
      }
      loadMoreCurrentindex.value = 15
      elementsPerLoad.value = 15
      selectedIdsForBulkOpt.value = []
      // loader.showLoader(true)
      fetchInvestedEstimated();
      axios.post(`/v1/get/todo/bygroupid?page=${pageNumber.value}`, {
         data_count : 15,
         organization_id: org_id,
         group_id: currentOpenGroupId.value,
         project_id: [routeCurrentProjectId.value],
         sort_by: setGroupParentChildSort.value
      }).then((res) => {
         if(res.data.response.code == 200){
               to.value = res.data.response.data.to;
               total.value = res.data.response.data.total;
      //       estimatedHours.value = res.data.response.estimate_hrs;
      //       investedHours.value = res.data.response.invested_hours;
      //       res.data.response.bardata.todo_details = res.data.response.bardata?.todo_details.filter((todoStatus: any) => todoStatus.toggle_status == 1)
      //       // res.data.response.bardata.todo_details = res.data.response.bardata?.todo_details.filter((todo: any) => todo.count != 0)
      //   res.data.response.bardata.todo_details = res.data.response.bardata.todo_details.sort((a: any, b: any) => a.order_id - b.order_id)
      //       bardata.value =  res.data.response.bardata;
      //       totalTodoCount.value = bardata.value.total_todo;
         res.data.response.data.query_data.forEach((item, i) => {
            // item.estiamtedHours = convertToTotalHours(item.estiamtedHours);
            // item.invested_hours = convertToTotalHours(item.invested_hours);
            item.node_index = i
         });
         displaybar.value = true;

        const sortedTodoData = res.data.response.data.query_data.map((todo: any) =>
            // Check if the object has the key 'member'
            todo.hasOwnProperty('member')
                ? {
                    // Spread the existing properties
                    ...todo,
                    // Sort the 'member' array based on the 'pivot.role'
                    member: todo.member.sort((a: any, b: any) =>{
                     // Sort in descending order, placing role 3 at the start
                     a.role_id - b.role_id
                    }),
                }
                : todo
            );
            

            // level1TodosList.value = []
            // level1TodosList.value = res.data.response.data
            // levelOneLoadMoreArray.value = res.data.response.data
            levelOneLoadMoreArray.value = sortedTodoData

            if(pageNumber.value == 1){
               level1TodosList.value = levelOneLoadMoreArray.value.slice(0, 15)
               level1TodosList.value = level1TodosList.value.map((obj: any) => ({...obj, isOpen: false}));
               tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value  
               firstdata.value = level1TodosList.value
            }
            
            if(pageNumber.value > 1){
               nextData.value = levelOneLoadMoreArray.value.map((obj: any) => ({...obj, isOpen: false}));
               firstdata.value.push(...nextData.value)
               level1TodosList.value = firstdata.value
               tree.value[level_index.value.group_index]['nodes'] = level1TodosList.value 
            }
            fetchBarData()
            // isCreateTodo.value = true
         }
         else if(res.data.response.code == 204){
            // console.log("204>>")
            apiGetGroupsList(routeCurrentProjectId.value)
         
         }
         isCreateTodo.value = true
         
         // loader.showLoader(false)
         showGroupLoaderOnClick.value= false
      }).catch((err) =>[
         // loader.showLoader(false)
         showGroupLoaderOnClick.value = false
      ])
    }

    const fetchInvestedEstimated = () => {

      axios.post('/v1/get/invested/todogroup', {
         group_id: currentOpenGroupId.value,
         project_id: [routeCurrentProjectId.value],
      }).then((res) => {
         if(res.data.response.code == 200){
            estimatedHours.value = res.data.response.data.estimate_hrs;
            investedHours.value = res.data.response.data.invested_hours;
         }
      });
    }

    const getAdditionalKeys = ( apiEndpoint: any, objectContains: any) => {
      let formattedDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  
      let timeStamp = {
          object_org_id: userData.userDetails.org_id,
          object_user_id: userData.userDetails.id,
          object_created_at: formattedDate.toString(),
          object_updated_at: formattedDate.toString(),
          object_contains: objectContains,
          object_api: apiEndpoint
      }
      return timeStamp
  };
    return {
      tree,
      level_index,
      create_level_index,
      groups,
      level1TodosList,
      level2TodosList,
      level3TodosList,
      level4TodosList,
      level5TodosList,
      apiGetGroupsList,
      apiGetTodosByGroupID,
      getTree,
      apiGetChildLevelTodos,
      todoStausListStore,
      AwskeyData,
      showOperationStatus,
      operationMessage,
      operationStatus,
      currentOpenGroupId,
      selectedIdsForBulkOpt,
      showGroupLoaderOnClick,
      levelToggleStatus,
      routeCurrentProjectId,
      getCurrentProjectId,
      levelOneLoadMoreArray,
      bardata,
      totalTodoCount,
      displaybar,
      pageNumber,
      to,
      total,
      loadMore,
      currentClickedTodoId,
      showTodoLoaderOnClick,
      taskIdStatusVal,
      projectIdForAddtodo,
      loadMoreCurrentindex,
      elementsPerLoad,
      // pmsLoader
      projectDetailLoader,
      overviewLoader,
      groupLoader,
      todoDetailsBarLoader,
      planDetailSet,
      uniqueGroupIdForBulkOpt,
      showOperationDialog,
      operationStatusCode,
      operationSuccessMessage,
      callTodoStatusSummary,
      projectCurrentStatus,
      projectIsArchived,
      callDetailsViewSummary,
      currentProjectUser,
      setProjectUserDetails,
      selectedProjectForAddTodo,
      projectListDataForProjDetailHeader,
      checkIfCurrentLoggedInUserIsProjectManager,
      slelectedProjectDetForAddTodo,
      apiGetGroupsListTwo,
      getChildTodosOnParentComplete,
      setRecurringTodo,
      fetchBarData,
      setFilterToDefault,
      sortFilterKeyName,
      setGroupParentChildSort,
      setUpdatedMembers,
      updateGroupTodoCount,
      setGroupsForMilestone,
      updateCurrentGroupEtaInvested,
      estimatedHours,
      investedHours,
      taskTimestamp,
      updateMemberTaskListDb,
      updateMemberTaskCardDb,
      loadTreeViewFromAddTodoDrawer,
      isCreateTodo,
      toggleNodes,
      setNewToDoItem,
      apiGetTodosByGroupIDonQuickAddTodo,
      cleanNodes,
      setChildToggle,
      currentProject,
      loadMoreLoader,
      fetchInvestedEstimated,
      firstdata,
      nextData,
      selectedTodoStatus,
      groupTimestamp
    };
  });
  
import { defineStore } from "pinia";

export const useVerticalMenuStore = defineStore('verticalMenu',{
    state: () => ({
        verticalMenu: useLocalStorage('verticalMenu' ,[]),
        featuresList: useLocalStorage('featuresList', [])
    }),

    getters: {
        menuList(){
            return this.verticalMenu;
        },

        getFeaturesList(){
            return this.featuresList;
        }
    },

    actions: { 
        storeMenu(menuData: any) {
            this.verticalMenu = menuData
        },

        setFeaturesList(data: any) {
            this.featuresList = data
        }
    }
})
import { isEmpty, isEmptyArray, isNullOrUndefined } from './index'
import i18n from '../../../src/plugins/i18n/index'
const { t } = i18n.global;

// 👉 Required Validator
export const requiredValidator = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return t('validators.requiredValidator')

  return !!String(value).trim().length || t('validators.requiredValidator')
}

// 👉 Email Validator
export const emailValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value))
    return value.every(val => re.test(String(val))) || t('validators.emailValidator')

  return re.test(String(value)) || t('validators.emailValidator')
}

// 👉 Password Validator
export const passwordValidator = (password: string) => {
  // const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  // const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[!@#$%&*()-]).{8,}/
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[!@#$%&*()-])(?!.*[\'\"])(?!.*["']).{8,}/
  const validPassword = regExp.test(password)

  // console.log(validPassword || t('validators.passwordValidator'));
  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || t('validators.passwordValidator')
  )
}

// 👉 Confirm Password Validator
export const confirmedValidator = (value: string, target: string) =>

  value === target || t('validators.confirmedValidator')

// 👉 Between Validator
export const betweenValidator = (value: unknown, min: number, max: number) => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `${t('validators.betweenValidator.enterNumberbetween')} ${min} ${t('validators.betweenValidator.and')} ${max}`
}

// 👉 Integer Validator
export const integerValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  if (Array.isArray(value))
    return value.every(val => /^-?[0-9]+$/.test(String(val))) || 'Please enter correct phone number'

  return /^-?[0-9]+$/.test(String(value)) || 'Please enter correct phone number'
}

// 👉 Regex Validator
export const regexValidator = (value: unknown, regex: RegExp | string): string | boolean => {
  if (isEmpty(value))
    return true

  let regeX = regex
  if (typeof regeX === 'string')
    regeX = new RegExp(regeX)

  if (Array.isArray(value))
    return value.every(val => regexValidator(val, regeX))

  return regeX.test(String(value)) || t('validators.regexValidator')
}

// 👉 Alpha Validator
export const alphaValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

// 👉 URL Validator
export const urlValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(String(value)) || 'URL is invalid'
}

// 👉 Length Validator
export const lengthValidator = (value: unknown, length: number) => {
  if (isEmpty(value))
    return true

  return String(value).length === length || `The Min Character field must be at least ${length} characters`
}

// 👉 Alpha-dash Validator
export const alphaDashValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character are not valid'
}



// 👉 Required Validator for check box
export const requiredValidatorchk = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return false

  return !!String(value).trim().length || 'This field is required'
}


// 👉 Email Validator 2
export const emailValidatorMsg = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value))
    return value.every(val => re.test(String(val))) || 'Please enter a valid Email!'

  return re.test(String(value)) || 'Please enter a valid Email!'
}

export const emailValidatorMessage = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value))
    return value.every(val => re.test(String(val))) || 'Please enter a valid email'

  return re.test(String(value)) || 'Please enter a valid email'
}

export const requiredFieldValidator = (value: unknown) => {
  if(isNullOrUndefined(value) || isEmptyArray(value) || value === false){
    return 'Required field'
  }
  return !!String(value).trim().length || 'Required field'
}

export const passwordlengthValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return String(value).length < 100 || 'Only 100 characters are allowed';
}

export const NamerequiredValidator = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return t('validators.requiredValidator')

  return !!String(value).trim().length || 'Please enter name'
}

export const emailrequiredValidator = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return t('validators.requiredValidator')

  return !!String(value).trim().length || 'Please enter email'
}

export const phonerequiredValidator = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return t('validators.requiredValidator')

  return !!String(value).trim().length || 'Please enter phone number'
}

export const passwordrequiredValidator = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return t('validators.requiredValidator')

  return !!String(value).trim().length || 'Please enter password'
}

export const cpasswordrequiredValidator = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return t('validators.requiredValidator')

  return !!String(value).trim().length || 'Password confirmation is required'
}


export const negativeNumber = (value: any) => {
  const pattern = /-/;

  if ( pattern.test(value)) {
    return 'Invalid number';
  }else{
return ;
  }
};

<script setup lang="ts">
//pinia store
import { userDetailStore } from "@/stores/userdata";
import moment from 'moment';
import axios from "axios";

import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { requiredValidator } from '@validators'
import { useLoaderStore } from '@/stores/loader';
import { onBeforeRouteLeave } from 'vue-router';

import workstatuslogo from '@images/dialogwslogo.svg'
import doneIcon from '@images/icons/themeupdate/doneTodo.svg'
import addProjectFolderSvg from "@/assets/images/icons/themeupdate/addProjectFolder.svg";
import wstimepickerVue from "./wstimepicker.vue";
import successGif from "@/assets/images/pages/thankyousuccess.gif";
import warningGif from '@/assets/images/gif/swalwarningtheme.gif';
import loaderComponentDialogVue from '@/views/globalComponent/loaderComponentDialog.vue';
import dummyDP from '@/assets/images/svg/dummyDP.svg';

const userData = userDetailStore();
const loader = useLoaderStore();
const userName = userData.userDetails.name;
type Props = {
    status:{
        type:Boolean,
        required: true,
        default: false,
    }
}

let propsData = defineProps<Props>();
const emit = defineEmits(['closeAddTimesheet']);
const cancelSource = axios.CancelToken.source();

const searchMember = ref<string>('')
const searchProject = ref<string>('')
const searchTodo = ref<string>('')
const dialogCloseType = ref<any>()
const memberLabel = computed(() => t('timesheetModule.viewTimesheetSection.memNameLabel'));
const projectLabel = computed(() => t('timesheetModule.viewTimesheetSection.projNameLabel'));
const todoLabel = computed(() => t('timesheetModule.viewTimesheetSection.todoNameLabel'));
const showcal = ref<any>(false)
const showOverlay = ref<any>(false)
const selectedDate = ref<any>(moment().format('MM-DD-YYYY'))
const org_id = userData.userDetails.org_id;
const billable = ref<boolean>(false);
const reasonInputToggle = ref<boolean>(false);
const reasonInput = ref<string>();
const noteInputToggle = ref<boolean>(false);
const noteInput = ref<string>('');
const membersDropDownList = ref<any>();
const selectedMember = ref<any>();
const searchSelectedMemberId = ref<any>()
const projectsList = ref<any>();
const selectedProject = ref<any>();
const searchSelectedProjectId = ref<any>()
const todoList = ref<any>();
const selectedtodo = ref<any>();
const searchSelectedTodoId = ref<any>()
const menu = ref<boolean>(false);
const frommenu = ref<boolean>(false);
const timesheetAddMessage = ref<string>();
const showTimesheetSuccessModal = ref<boolean>(false);
const statusCode = ref<any>();
const showLoader = ref<boolean>(false)
const loadings = ref<boolean[]>([]);
loadings.value[0] = false
loadings.value[1] = false
loadings.value[2] = false
const timesheetLoaderFieldOne = ref<boolean>(false)
const timesheetLoaderFieldTwo = ref<boolean>(false)
const timesheetLoaderFieldThree = ref<boolean>(false);
const showNormalToggle = ref<boolean>(true)
const showSelectedDate = ref<any>(moment().format(userData.getDateFormat));

const areCredsValid = computed(() => {
  return requiredValidator(selectedMember.value) === true && 
         requiredValidator(selectedProject.value) === true &&
         requiredValidator(reasonInput.value) === true
})

const areCredsValidNoReason = computed(() => {
  return requiredValidator(selectedMember.value) === true && 
         requiredValidator(selectedProject.value) === true 
})
const defaultFromTime = ref(new Date());

const currentTimeMinutes = ref(moment(
    new Date(defaultFromTime.value)
).minutes());
let nextNum = ref(Math.ceil(currentTimeMinutes.value / 5) * 5);
const defaultToTime = ref(new Date());
const fromTime = ref(moment(new Date(defaultFromTime.value))
                    .add(Math.abs(currentTimeMinutes.value - nextNum.value), "minutes")
                    .subtract(15, "minutes")
                    .format("h:mm A"))
const toTime = ref(moment(new Date(defaultToTime.value))
                    .add(Math.abs(currentTimeMinutes.value - nextNum.value), "minutes")
                    .subtract(5, "minutes")
                    .format("h:mm A"))
const checkEndAndStartTime = computed(() => {
    const startTime = fromTime.value;
    const endTime = toTime.value;

    const startMoment = moment(startTime, 'h:mm A');
    const endMoment = moment(endTime, 'h:mm A');
    const isEndTimeGreater = endMoment.isAfter(startMoment);
    return isEndTimeGreater
})

const checkTimeFormatTo = computed(() => {
    let regex = new RegExp(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);

    if (regex.test(toTime.value) == true && toTime.value.length <= 8) {
        return true;
    }
    else {
        return false;
    }
})

const checkTimeFormatFrom = computed(() => {
    let regex = new RegExp(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);

    if (regex.test(fromTime.value) == true && fromTime.value.length <= 8) {
        return true;
    }
    else {
        return false;
    }
})
const closeCal = () => {
    showcal.value = false
    showOverlay.value = false
}

const closeCalOnModelVal = () => {
    selectedDate.value = moment(selectedDate.value).format('MM-DD-YYYY')
    showSelectedDate.value = moment(selectedDate.value, 'MM-DD-YYYY').format(userData.getDateFormat)
    showcal.value = false
    showOverlay.value = false

}

const closeAddTimesheetDetails = () => {
    let date = moment(selectedDate.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    console.log("🚀 ~ closeAddTimesheetDetails ~ date:", date)
    let fromtime = fromTime.value;
    let totime = toTime.value;
    let fromdatetime = moment(`${date} ${fromtime}`, 'YYYY-MM-DD h:mm A');
    let todatetime = moment(`${date} ${totime}`, 'YYYY-MM-DD h:mm A');
    console.log("🚀 ~ closeAddTimesheetDetails ~ fromdatetime:", fromdatetime)

    console.log("🚀 ~ closeAddTimesheetDetails ~ todatetime:", todatetime)
    const fromformattedDatetime = fromdatetime.format('YYYY-MM-DD HH:mm:ss');
    const toformattedDatetime = todatetime.format('YYYY-MM-DD HH:mm:ss');
    const payloadData =  {
        key: dialogCloseType.value,
        billable: billable.value,
        date: moment(selectedDate.value, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        deviceId: "ANG111222333",
        deviceType: "",
        from: fromformattedDatetime,
        ip_address: "",
        member_id: selectedMember.value,
        notes: {
            note: noteInput.value
        },
        organization_id: org_id,
        os_version: "",
        project_id: selectedProject.value,
        reason: reasonInput.value ?? "",
        source_type: 3,
        time_type: 4,
        to: toformattedDatetime,
        todo_id: selectedtodo.value ? selectedtodo.value : null,
        togglenotes: noteInputToggle.value,
        togglereason: reasonInputToggle.value
    }
    emit('closeAddTimesheet', payloadData);
}

const setMemberDets = () => {
    searchSelectedMemberId.value = selectedMember.value
}
const removeSelectedMember = (data:any)=>{
    selectedMembers.value = selectedMembers.value.filter(user => user != data.value)
}
const fetchTodoList = () => {
    setProjDets();
    timesheetLoaderFieldThree.value = true
    loadings.value[2] = true
    let org_id = userData.userDetails.org_id;
    axios.post('/v1/todos/list', {
        completed: false,
        memberIds: [selectedMember.value],
        organization_id: org_id,
        project_id: [selectedProject.value],
    },{cancelToken: cancelSource.token}).then((res) => {
        if(res.data.response.code == 200){
            todoList.value = res.data.response.data;
        }
        loadings.value[2] = false
        timesheetLoaderFieldThree.value = false
    }).catch((er)=> {
        timesheetLoaderFieldThree.value = false
        loadings.value[2] = false
    })
}

const filteredMemberList = computed(() => {
    if (searchMember.value) {
        selectedMember.value = null;
        return membersDropDownList.value.filter((member: any) =>
        member.name.toLowerCase().includes(searchMember.value?.toLowerCase())
        );
    }
    else{
        selectedMember.value = searchSelectedMemberId.value;
        return membersDropDownList.value;
    }
})

const filteredProjectList = computed(() => {
    if (searchProject.value) {
        selectedProject.value = null;
        return projectsList.value.filter((member: any) =>
        member.name.toLowerCase().includes(searchProject.value?.toLowerCase())
        );
    }
    else{
        selectedProject.value = searchSelectedProjectId.value;
        return projectsList.value;
    }
})

const filteredTodoList = computed(() => {
    if (searchTodo.value) {
        selectedtodo.value = null;
        return todoList.value.filter((member: any) =>
        member.name.toLowerCase().includes(searchTodo.value?.toLowerCase())
        );
    }
    else{
        selectedtodo.value = searchSelectedTodoId.value;
        return todoList.value;
    }
})

const setTodoDets = () => {
    searchSelectedTodoId.value = selectedtodo.value
}

const setProjDets = () => {
    searchSelectedProjectId.value = selectedProject.value
}

const fetchProjectsList = () => {
    setMemberDets();
    reasonInputToggle.value = false
    showNormalToggle.value = true
    timesheetLoaderFieldTwo.value = true
    loadings.value[1] = true
    let org_id = userData.userDetails.org_id;

    axios.post('/v1/filter/projectlist', {
        member_id: selectedMember.value,
        org_id: org_id
    },{cancelToken: cancelSource.token}).then((res) => {
        if(res.data.response.code == 200){
            projectsList.value = res.data.response.data;
            let selectedMemberModifyval = membersDropDownList.value.find((member: any) => member.user_id == selectedMember.value)
            if(selectedMemberModifyval.modify_value){
                showNormalToggle.value = false
                reasonInputToggle.value = true
            }else{
                showNormalToggle.value = true
            }
        }
        loadings.value[1] = false
        timesheetLoaderFieldTwo.value = false
    }).catch((err) => {
        loadings.value[1] = false
        timesheetLoaderFieldTwo.value = false
    })
}


//function to fetch member list when user is not org manager or owner in the organisation
const fetchMembersDropdownList = () => {
    loadings.value[0] = true
    timesheetLoaderFieldOne.value = true
    let org_id = userData.userDetails.org_id;
    // axios.get(`/v1/activity/members/dropdown?org_id=${org_id}&timeoff=1`,{cancelToken: cancelSource.token}).then((res) => {
    axios.post(`/v3/timesheets/viewTimesheets/memberList?page_type=addTimesheet`,{
        user_id: userData.userDetails.id
    },{cancelToken: cancelSource.token}).then((res) => {
        if (res.data.response.code == 200) {
            membersDropDownList.value = res.data.response.data.memberList.data.filter(
                (item: any) => (item.user_status != 2 && item.user_status != 3)
            );
            membersDropDownList.value.forEach((element:any) => {
                if(element.member_id){
                    element.name = element.name + " (" + element.member_id + ")";
                }
            });
        } 
        timesheetLoaderFieldOne.value = false
        loadings.value[0] = false
        
    }).catch((err) => {
        timesheetLoaderFieldOne.value = false
        loadings.value[0] = false
    });
};

const getChangedTime = (event: any, fromToType: string) => {
    console.log("event", event);
    fromToType === 'toTime' ? toTime.value = event: fromTime.value = event
}


// //function used to fetch member dropdown when user is owner or org manager function not in use kept for reference
// const fetchRequireReasonList = () => {
//     loadings.value[0] = true
//     timesheetLoaderFieldOne.value = true
//     axios.post('/v1/setting/featureTimesheet/requestReason/userList', {
//         organization_id: userData.userDetails.org_id
//     },{cancelToken: cancelSource.token}).then((res) => {
//         if(res.data.response.code == 200){
//             membersDropDownList.value = res.data.response.data.filter(
//                 (item: any) => (item.user_status != 2 && item.user_status != 3)
//             );
//             membersDropDownList.value.forEach((element:any) => {
//                 if(element.member_id){
//                     element.name = element.name + " (" + element.member_id + ")";
//                 }
//             });
//         }
//         timesheetLoaderFieldOne.value = false
//         loadings.value[0] = false
//     }).catch((err) => {
//         timesheetLoaderFieldOne.value = false
//         loadings.value[0] = false
//     });
// }

//function used on the basis of user role in the organization to fetch member dropdown list
onMounted(() => {
    fetchMembersDropdownList();
})

onBeforeRouteLeave((to, from) => {
    cancelSource.cancel('Request canceled by navigation');
})
</script>


<template>
    <loaderComponentDialogVue v-if="showLoader"></loaderComponentDialogVue>
    <div>
        <VDialog
            v-model="propsData.status"
            fullscreen
            :scrim="false"
            persistent
        >
            <VCard class="reports-vselect-label">
                <VRow class="h-100 w-100 m-0 p-0">
                    <VCol cols="12" sm="12" xs="12"  md="4" lg="4" class="m-0 p-0 bgcolor" >
                        <div class="pa-10">
                            <img :src="workstatuslogo" style="height: 50px; width: 175px;"/>
                            <div class="mt-8 d-flex">
                                <img :src="addProjectFolderSvg" :width="38"/>
                                <h2 class="title-style ml-2">{{ t('timesheetModule.viewTimesheetSection.addTimesheet') }}</h2>
                            </div>
                            <p class="mt-4 dialogsub ml-2">{{ t('timesheetModule.viewTimesheetSection.addTimeDesc') }}</p>
                            <div class="d-flex align-center pt-2">
                                <img :src="doneIcon" class="me-4" v-if="areCredsValid"/>
                                <span class="whitecirclewithnm me-4" v-else>1</span>
                                <div class="basicinformation">{{ t('timesheetModule.viewTimesheetSection.genInfo') }}</div>
                            </div>

                        </div>
                    </VCol>

                    <VCol cols="12" sm="12" xs="12" md="6" lg="6" class="m-0 p-0">
                        <div class="pa-10">
                            <VBtn
                                variant="outlined"
                                icon="tabler-chevron-left"
                                @click="dialogCloseType = 'back' ;closeAddTimesheetDetails()"
                            />
                            <span class="ml-2">
                                {{ t('timesheetModule.approveTimesheetSection.Back') }}
                            </span>
                        </div>
                    <div class="px-14">
                            <VSelect
                                :menu-props="{maxHeight: 300, maxWidth: 400}"
                                :label="memberLabel"
                                variant="underlined"
                                :items="filteredMemberList"
                                v-model="selectedMember"
                                item-title="name"
                                item-value="id"
                                @update:model-value="fetchProjectsList"
                                :rules="[requiredValidator]"
                                :loading="loadings[0]"
                            >

                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center">
                                <v-avatar style="width: 20px; height: 20px;" class="mr-2">
                                    <img style="width: 20px; height: 20px;" :src="item.raw.image ? item.raw.image : dummyDP" />
                                </v-avatar>
                                <span>{{ item.title }}</span>
                                </div>
                            </template>
                                <template v-slot:no-data v-if="timesheetLoaderFieldOne" >
                                    <div style="padding-top: 20px; padding-bottom: 20px">
                                        <v-progress-circular
                                            indeterminate
                                            :size="25"
                                            style="left: 50%"
                                        />
                                    </div>
                                </template>
                                <template v-slot:no-data v-if="membersDropDownList?.length == 0">
                                    <VListItem>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ t('schedule.noResult') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </VListItem>
                                </template>

                                <template v-slot:prepend-item v-if="membersDropDownList?.length">
                                    <VTextField
                                        v-model="searchMember"
                                        bg-color="#f6f8fb"
                                        variant=""
                                        prepend-inner-icon="mdi-magnify"
                                        :placeholder="t('placeholder.searchmember')"
                                    />
                                </template>
                            </VSelect>

                            <div class="mt-9">
                                <VSelect
                                    :menu-props="{maxHeight: 300, maxWidth: 400}"
                                    :label="projectLabel"
                                    variant="underlined"
                                    :items="filteredProjectList"
                                    v-model="selectedProject"
                                    item-title="name"
                                    item-value="id"
                                    @update:model-value="fetchTodoList"
                                    :rules="[requiredValidator]"
                                    :loading="loadings[1]"
                                >
                                    <template v-slot:no-data v-if="timesheetLoaderFieldTwo" >
                                        <div style="padding-top: 20px; padding-bottom: 20px">
                                            <v-progress-circular
                                            indeterminate
                                            :size="25"
                                            style="left: 50%"
                                            ></v-progress-circular>
                                        </div>
                                    </template>
                                    <template v-slot:no-data v-if="projectsList?.length == 0">
                                        <VListItem>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ t('schedule.noResult') }}</v-list-item-title>
                                            </v-list-item-content>
                                        </VListItem>
                                    </template>
                                    <template v-slot:prepend-item v-if="projectsList?.length">
                                        <VTextField
                                            v-model="searchProject"
                                            bg-color="#f6f8fb"
                                            variant=""
                                            prepend-inner-icon="mdi-magnify"
                                            :placeholder="t('placeholder.Searchproject')"
                                        />
                                    </template>
                                </VSelect>
                            </div>
                            
                            <div class="mt-9">
                                <VSelect
                                    :menu-props="{maxHeight: 300, maxWidth: 400}"
                                    v-model="selectedtodo"
                                    :label="todoLabel"
                                    variant="underlined"
                                    :items="filteredTodoList"
                                    item-title="name"
                                    item-value="id"
                                    @update:model-value="setTodoDets"
                                    :loading="loadings[2]"
                                >
                                    <template v-slot:no-data v-if="timesheetLoaderFieldThree" >
                                        <div style="padding-top: 20px; padding-bottom: 20px">
                                            <v-progress-circular
                                            indeterminate
                                            :size="25"
                                            style="left: 50%"
                                            ></v-progress-circular>
                                        </div>
                                    </template>
                                    <template v-slot:no-data v-if="todoList?.length == 0">
                                        <VListItem>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ t('schedule.noResult') }}</v-list-item-title>
                                            </v-list-item-content>
                                        </VListItem>
                                    </template>
                                    <template v-slot:prepend-item v-if="todoList?.length">
                                        <VTextField
                                            variant=""
                                            bg-color="#f6f8fb"
                                            prepend-inner-icon="mdi-magnify"
                                            :placeholder="t('reports.searchTodo')"
                                            v-model="searchTodo"
                                        />
                                    </template>
                                </VSelect>
                            </div>
                            

                            <VRow class="mt-9">
                                <VCol sm="4" md="4" lg="4">
                                    <div class="div-box">
                                        <div class="div-font cursor-pointer-adtimesheet">
                                            <div>
                                                <VTextField 
                                                    variant="underlined"
                                                    v-model="showSelectedDate"
                                                    readonly
                                                    class="addtimesheet-iconset input-style"
                                                >
                                                    <template v-slot:append-inner>
                                                        <v-icon
                                                            icon="mdi-calendar-week-outline"
                                                            @click="showcal=true;showOverlay=true"
                                                        />
                                                    </template>
                                                </VTextField>
                                            </div>
                                        </div>
                                    </div>
                                </VCol>
                                <span v-if="showOverlay"
                                    id="overlay"
                                    @click="closeCal"    
                                >
                                </span>
                                <span 
                                :class="
                                    showcal
                                            ? 'datepicker-align'
                                            : 'hide-v-cal'
                                    ">
                                    <!-- <VDatePicker
                                        v-if="showcal" 
                                        is-required
                                        v-model="selectedDate" 
                                        mode="date"
                                        :min-date="userData.userDetails.user_joined_date"
                                        :max-date='new Date()'
                                        @update:modelValue="closeCalOnModelVal()"    
                                    /> -->
                                    <VDatePicker
                                        v-if="showcal" 
                                        is-required
                                        v-model="selectedDate" 
                                        mode="date"
                                        :max-date='new Date()'
                                        @update:modelValue="closeCalOnModelVal()"    
                                    />
                                </span>
                                <VCol sm="4" md="4" lg="4">
                                    <div class="div-box">
                                        <div class="div-font">
                                            <div>
                                                <v-menu
                                                    v-model="frommenu"
                                                    :close-on-content-click="false"
                                                    location="top"
                                                >
                                                    <template v-slot:activator="{props}">
                                                        <VTextField class="addtimesheet-iconset input-style"
                                                        
                                                            v-bind="props"
                                                            style="text-transform:uppercase;"
                                                            variant="underlined"
                                                            v-model="fromTime"
                                                            @input="fromTime = fromTime.toUpperCase()"
                                                        >
                                                            <template v-slot:append-inner >
                                                                <v-icon
                                                                    v-bind="props"
                                                                    icon="tabler-clock-hour-3"
                                                                    @click="fromTime = fromTime.toUpperCase()"
                                                                />
                                                            </template>
                                                        </VTextField>
                                                        <span style="color: red; font-size: 10px;" v-if="!checkTimeFormatFrom">
                                                            {{ t('timesheetModule.approveTimesheetSection.timeValidation') }}
                                                        </span>
                                                    </template>

                                                    <v-card min-width="300">
                                                        <VCardText class="pa-0">
                                                            <wstimepickerVue
                                                            @changeTimesheetTime="getChangedTime($event, 'fromTime')"
                                                            :time="fromTime"
                                                            ></wstimepickerVue>
                                                        </VCardText>
                                                    </v-card>
                                                </v-menu>
                                            </div>
                                        </div>
                                    </div>
                                </VCol>
                                <VCol sm="4" md="4" lg="4">
                                    <div class="div-box">
                                        <div class="div-font">
                                            <div>
                                                <v-menu
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    location="top"
                                                >
                                                    <template v-slot:activator="{props}">
                                                        <VTextField class="addtimesheet-iconset input-style"
                                                            v-bind="props"
                                                            style="text-transform:uppercase"
                                                            variant="underlined"
                                                            v-model="toTime"
                                                            @input="toTime = toTime.toUpperCase()"
                                                            
                                                        >
                                                            <template v-slot:append-inner >
                                                                <v-icon 
                                                                    v-bind="props"
                                                                    icon="tabler-clock-hour-3"
                                                                    @click="toTime = toTime.toUpperCase()"
                                                                />
                                                            </template>
                                                        </VTextField>
                                                        <span style="color: red; font-size: 10px;" v-if="!checkEndAndStartTime">
                                                            {{ t('timesheetModule.approveTimesheetSection.startEndTimeValidation') }}
                                                        </span><br>
                                                        <span style="color: red; font-size: 10px;" v-if="!checkTimeFormatTo">
                                                            {{ t('timesheetModule.approveTimesheetSection.timeFormat') }}
                                                        </span>
                                                    </template>

                                                    <v-card min-width="300">
                                                        <VCardText class="pa-0">
                                                            <wstimepickerVue
                                                            @changeTimesheetTime="getChangedTime($event, 'toTime')"
                                                            :time="toTime"
                                                            ></wstimepickerVue>
                                                        </VCardText>
                                                    </v-card>
                                                </v-menu>
                                            </div>
                                        </div>
                                    </div>
                                </VCol>
                            </VRow>

                            <div class="d-flex mt-4">
                                <span class="mt-2">{{ t('timesheetModule.viewTimesheetSection.billable') }}</span>
                                <VSwitch
                                    class="ml-4"
                                    v-model="billable"
                                    :true-value="true"
                                    :false-value="false"
                                />
                            </div>

                            
                            <span v-if="showNormalToggle">
                                <div class="d-flex mt-4">
                                    <span class="mt-2">{{ t('timesheetModule.viewTimesheetSection.reason') }}</span>
                                    <VSwitch
                                        class="ml-3"
                                        v-model="reasonInputToggle"
                                        :true-value="true"
                                        :false-value="false"
                                    />
                                </div>

                                <div class="mt-4" v-if="reasonInputToggle">
                                    <VTextField
                                        :label="t('timesheetModule.viewTimesheetSection.whyareyouadding')"
                                        variant="underlined"
                                        v-model="reasonInput"
                                        :rules="[requiredValidator]"
                                    >
                                    </VTextField>
                                </div>
                            </span>

                            <span v-else>
                                <div class="d-flex mt-4">
                                    <span class="mt-2">{{ t('timesheetModule.viewTimesheetSection.reason') }}</span>
                                    <VSwitch
                                        class="ml-3"
                                        :readonly="reasonInputToggle"
                                        v-model="reasonInputToggle"
                                        :true-value="true"
                                        :false-value="false"
                                    />
                                </div>

                                <div class="mt-4" v-if="reasonInputToggle">
                                    <!-- label="Why are you adding?" -->
                                    <VTextField
                                        :label="t('timesheetModule.viewTimesheetSection.whyareyouadding')"
                                        variant="underlined"
                                        v-model="reasonInput"
                                        :rules="[requiredValidator]"
                                    >
                                    </VTextField>
                                </div>
                            </span>
                            <div class="d-flex mt-4">
                                <span class="mt-2">{{ t('timesheetModule.viewTimesheetSection.noteSwitch') }}</span>
                                <VSwitch
                                    class="ml-7"
                                    v-model="noteInputToggle"
                                    :true-value="true"
                                    :false-value="false"
                                />
                            </div>

                            <div class="mt-4" v-if="noteInputToggle">
                                <!-- label="What were you working on?" -->
                                <VTextField
                                    :label="t('timesheetModule.viewTimesheetSection.workingOn')"
                                
                                    variant="underlined"
                                    v-model="noteInput"
                                >
                                </VTextField>
                            </div>
                            <div class="float-right">
                                <VBtn 
                                v-if="showNormalToggle"
                                    :disabled="!areCredsValidNoReason"
                                    :color="!areCredsValidNoReason ? 'secondary' : 'primary'"
                                    class="mt-4"
                                    rounded="pill"
                                    height="38"
                                    width="120"
                                    :class="!areCredsValidNoReason ? '' : 'btn-class custom-btn-primary'"
                                    @click="dialogCloseType = 'save' ;closeAddTimesheetDetails()">
                                    {{ t('timesheetModule.viewTimesheetSection.save') }}
                                </VBtn>
                                
                                <VBtn v-else
                                    :disabled="!areCredsValid"
                                    :color="!areCredsValid ? 'secondary' : 'primary'"
                                    class="mt-4 "
                                    width="120"
                                    height="38"
                                    :class="!areCredsValid ? '' : 'btn-class custom-btn-primary'"
                                    rounded="pill"
                                    @click="dialogCloseType = 'save' ;closeAddTimesheetDetails()">
                                    {{ t('timesheetModule.viewTimesheetSection.save') }}
                                </VBtn>
                            </div>
                    </div>
                        
                    </VCol>
                </VRow>
            </VCard>
        </VDialog>

        <VDialog v-model="showTimesheetSuccessModal" width="530">
            <VCard class="rounded-lg">
                <div class="d-flex justify-end">
                <v-btn icon color="black" variant="plain" @click="closeAddTimesheetDetails(); showTimesheetSuccessModal = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </div>
                <div class="text-center px-5">
                <img
                    :width="100"
                    aspect-ratio="1/1"
                    class="ml-auto gif-warning"
                    :src="statusCode == '200' ? successGif : warningGif"
                />
                </div>
                <div class="text-center px-5 ma-6 message-style">
                {{ timesheetAddMessage }}
                </div>
                <div class="mt-6 mb-8 px-5 d-flex justify-center">
                <VBtn rounded="pill" class="ml-3 w-25 btn-class" @click="closeAddTimesheetDetails(); showTimesheetSuccessModal = false">
                    {{ t('department.ok')}}
                </VBtn>
                </div>
            </VCard>
        </VDialog>
    </div>
</template>

<style lang="scss" scoped>
#overlay{
    position:absolute;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    background-color: transparent;
    z-index: 1
}
// .show-v-cal{
//     z-index: 11;
//     position: absolute;
//     // top: 100.5px;
//     // right: 27px;
//     top: 293.5px;
//     right: 599px;
// }

// .hide-v-cal{
//     /* z-index: 1000000000000;
//     position: absolute; */
//     visibility: hidden;
// }
.datepicker-align{
    z-index: 11;
    position: absolute;
    top: 379px
}
.div-box{
    box-shadow: none!important;
    padding-left: 0!important;
    margin-right: 0!important;
    padding-right: 0!important;
}
.div-font{
    font-size: 14px;
    margin: 0;
    padding: 2px;
}
.h2-style{
    margin: 0 10px 10px 0%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #333;
}

.gif-warning {
  background-color: #f4f2ff !important;
  border-color: #f4f2ff !important;
  border-radius: 50%;
  height: 100px !important;
  width: 100px !important;
}

.message-style {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #00234b;
  margin: 0 0 10px !important;
}

.whitecirclewithnm{
    padding: 12px;
    border-radius: 49%;
    width: 25px;
    height: 25px;
    justify-content: center;
    border: 0.5px solid #ffffff;
    /* font-family: Open Sans; */
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #fff;
}
.custom-btn-primary{
    background-color: #624de3!important;
    line-height: 20px;
    font-size: 15px;
    font-weight: 500!important;
    height: 38px!important;
    color: #fff!important;
}
</style>
import axios from 'axios';
const cancelSource = axios.CancelToken.source();

export const referEarnServices =  () => {
    const sendEmail = async (payload: any) => {
       return axios.post('/v1/send/referral/invite', payload, {cancelToken: cancelSource.token})
    }
    const InviteeDataList = async () => {
        return axios.get('/v1/get/myInvitees', {cancelToken: cancelSource.token})
    }
    const RefferalsDataList = async () => {
        return axios.get('/v1/get/myreferrals/details', {cancelToken: cancelSource.token})
    }
    const RefferalsTotalList = async () => {
        return axios.get('/v1/get/mywallet/details', {cancelToken: cancelSource.token})
    }
    const payoutRequestList = async () => {
        return axios.get('/v1/generate/payout', {cancelToken: cancelSource.token})
    }
    const referLink = async () => {
        return axios.get('/v1/share/referral/link', {cancelToken: cancelSource.token})
    }
   
    return {
        sendEmail,
        InviteeDataList,
        RefferalsDataList,
        RefferalsTotalList,
        payoutRequestList,
        referLink
    };
}
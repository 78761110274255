<script lang="ts" setup>
import { useLayouts } from '@layouts'
import { config } from '@layouts/config'
import { can } from '@layouts/plugins/casl'
import type { NavSectionTitle } from '@layouts/types'
import { useLoaderStore } from "@/stores/loader";

defineProps<{
  item: NavSectionTitle
}>()

const { isVerticalNavMini, dynamicI18nProps } = useLayouts()
const { width: windowWidth } = useWindowSize()
const shallRenderIcon = isVerticalNavMini(windowWidth)
const loader = useLoaderStore();
</script>

<template>
  <!-- <li
    v-if="can(item.action, item.subject)"
    class="nav-section-title"
  > -->
  <li
    :class="loader.isNavCollapsed ? 'collapsed-nav' :'nav-section-title'"
    v-if="item.heading !== 'Dashboard'"
  >
    <div class="title-wrapper" :style="loader.isNavCollapsed ? 'display:none': ''">
      <!-- <Transition
        name="vertical-nav-section-title"
        mode="out-in"
      > -->
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <Component  v-if="item.heading !== 'Dashboard'"
          :is="loader.isNavCollapsed ? config.app.iconRenderer : config.app.enableI18n ? 'i18n-t' : 'span'"
          :key="shallRenderIcon"
          :class="[loader.isNavCollapsed ? 'placeholder-icon' : 'title-text', loader.darkTheme == false ? 'title-heading-font' : 'title-heading-font-dark' ]"
          v-bind="{ ...config.icons.sectionTitlePlaceholder, ...dynamicI18nProps(item.heading, 'span') }"
          v-text="!shallRenderIcon ? item.heading : null"
        />
        <!-- eslint-enable vue/no-v-text-v-html-on-component -->
      <!-- </Transition> -->
    </div>
  </li>
</template>
<style scoped>
.title-heading-font{
  font-weight: 500;
  font-size: 12px!important;
  line-height: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #525f7f!important;
  font-variation-settings: "slnt" 0;
}

.title-heading-font-dark{
  font-weight: 500;
  font-size: 12px!important;
  line-height: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #F4F4F4!important;
  font-variation-settings: "slnt" 0;
}


</style>
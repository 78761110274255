import { openDB } from '@/stores/local/indexedDB'
let userData = useLocalStorage('userData', {})
const DB_NAME = `workstatus_${userData.value.org_id}`;
// Define store collection name
let STORE_NAME = `milestone_list_table_${userData.value.id}`;
let db: IDBDatabase | null = null;

function ensureSerializable(obj: any): any {
return JSON.parse(JSON.stringify(obj));
}
  
function updateUserData(){
  userData = useLocalStorage('userData', {});
  STORE_NAME = `milestone_list_table_${userData.value.id}`;
}

//insert items in table/store
export function addItem(item: any): Promise<void> {
  updateUserData()
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB();
        const transaction = db.transaction([STORE_NAME], "readwrite");
        const store = transaction.objectStore(STORE_NAME);
  
        // Ensure the item is serializable
        const serializableItem = ensureSerializable(item);
  
        const request = store.put(serializableItem);
        request.onsuccess = () => resolve();
        request.onerror = (event: Event) =>
          reject((event.target as IDBRequest).error);
      } catch (error) {
        reject(error);
      }
    });
  }

//get milestoneList 
export function getMilestoneItems(projectId: any): Promise<void> {
  updateUserData()
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDB();
            const transaction = db.transaction([STORE_NAME], "readonly");
            const store = transaction.objectStore(STORE_NAME);
          
            const request = store.get(projectId);
            request.onsuccess = () => {
                const items = request.result
                resolve(items);
              };
        }
        catch (error) {
            reject(error)
        }
    })
}


//insert items in table/store
export function addTimestamp(item: any): Promise<void> {
  updateUserData()
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB();
        const transaction = db.transaction([STORE_NAME], "readwrite");
        const store = transaction.objectStore(STORE_NAME);
  
        // Ensure the item is serializable
        const serializableItem = ensureSerializable(item);
  
        const request = store.put(serializableItem);
        request.onsuccess = () => resolve();
        request.onerror = (event: Event) =>
          reject((event.target as IDBRequest).error);
      } catch (error) {
        reject(error);
      }
    });
  }

  //update item in table/store
  export function updateTimestamp(item: any): Promise<void> {
    updateUserData()
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB();
        const transaction = db.transaction([STORE_NAME], "readwrite");
        const store = transaction.objectStore(STORE_NAME);
  
  
        // Ensure the item is serializable
        const serializableItem = ensureSerializable(item);
  
        const request = store.put(serializableItem);
        request.onsuccess = () => resolve();
        request.onerror = (event: Event) =>
          reject((event.target as IDBRequest).error);
      } catch (error) {
        reject(error);
      }
    });
  }
  

//fetch pagination and timestamp
// export function getTimestamp(): Promise<any[]>  {
//     return new Promise( async (resolve, reject) => {
//         const db = await openDB();
//         const transaction = db.transaction([STORE_NAME], "readonly");
//         const store = transaction.objectStore(STORE_NAME);
      
//       const request = store.getAll();
//       request.onsuccess = () => {
//         const items = request.result
//         resolve(items);
//       };
//       request.onerror = (event: Event) =>
//         reject((event.target as IDBRequest).error);
//     });
//   };
  
  export function getMilestoneTimestamp(): Promise<any[]>  {
    updateUserData()
    return new Promise( async (resolve, reject) => {
        const db = await openDB();
        const transaction = db.transaction([STORE_NAME], "readonly");
        const store = transaction.objectStore(STORE_NAME);
      
      const request = store.get('timestamp');
      request.onsuccess = () => {
        const items = request.result
        resolve(items);
      };
      request.onerror = (event: Event) =>
        reject((event.target as IDBRequest).error);
    });
  };
//clear the store data
export function clearMilestoneStore(): Promise<void> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
      const request = store.clear();

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };

      transaction.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    } catch (error) {
      reject(error);
    }
  });
}

//delete milestone
export async function deleteMilestoneItems(projectId: any, milestoneId: any): Promise<void> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      const request = store.get(parseInt(projectId));
      request.onsuccess = () => {
        const project = request.result;
        if (project && project.milestone) {
          // Filter out the milestone with the matching milestoneId
          project.milestone = project.milestone.filter((milestone: any) => milestone.id != milestoneId);

          // Update the store with the modified project object
          const updateRequest = store.put(project);
          updateRequest.onsuccess = () => {
            resolve();
          };
          updateRequest.onerror = (event) => {
            reject(new Error('Failed to update the store'));
          };
        } else {
          reject(new Error('Project or milestones not found'));
        }
      };
      request.onerror = (event) => {
        reject(new Error('Failed to retrieve project'));
      };
    } catch (error) {
      reject(error);
    }
  });
}

//complete milestone
export async function completeMilestoneItems(projectId: any, milestoneId: any): Promise<void> {
  updateUserData()
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
// console.log("asdasasd23", projectId, milestoneId)
      const request = store.get(parseInt(projectId));
      request.onsuccess = () => {
        const project = request.result;
        // console.log('project', project)
        if (project && project.milestone) {
          // Find and update the milestone with the matching milestoneId
          const milestoneIndex = project.milestone.findIndex((milestone: any) => milestone.id == milestoneId);
          
          if (milestoneIndex !== -1) {
            project.milestone[milestoneIndex].milestone_status = 1;
            // Update the store with the modified project object
            
            const updateRequest = store.put(project);
            updateRequest.onsuccess = () => {
              resolve();
            };
            updateRequest.onerror = (event) => {
              reject(new Error('Failed to update the store'));
            };
          } else {
            reject(new Error('Milestone not found'));
          }
        } else {
          reject(new Error('Project or milestones not found'));
        }
      };
      request.onerror = (event) => {
        reject(new Error('Failed to retrieve project'));
      };
    } catch (error) {
      reject(error);
    }
  });
}

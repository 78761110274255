// gtmPlugin.ts
import { App } from 'vue';

// export default {
//   install(app: App) {
//     app.config.globalProperties.$addGtmScript = () => {
//       const script = document.createElement('script');
//       script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//         })(window,document,'script','dataLayer','GTM-NPPMGZH');`;
//       document.head.appendChild(script);
//     };
//   }
// };


export default {
    install(app: App) {
      app.config.globalProperties.$addGtmScript = () => {
        // Add the <noscript> tag right after the opening <body> tag
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NPPMGZH"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          
        // Check if <body> is already present
        if (document.body) {
          document.body.insertBefore(noscript, document.body.firstChild);
        } else {
          // If <body> is not yet available, wait for it
          window.addEventListener('load', () => {
            document.body.insertBefore(noscript, document.body.firstChild);
          });
        }
  
        // Add the <script> tag to the <head>
        const script = document.createElement('script');
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NPPMGZH');`;
        document.head.appendChild(script);
      };
    }
  };

import { defineStore } from 'pinia'
import { reactive, watch } from 'vue'

export const userDetailStore = defineStore('userData', {
    state: () => ({
        userData: useLocalStorage('userData', {}),
        org_data: useLocalStorage('orgData', {}),
        userRolesPermissions: useLocalStorage('userRoles', {}),
        userOrgRole: useLocalStorage('userRole', {}),
        lastSelectedOrg: useLocalStorage('lastSelectedOrganisation', {}),
        userSetupSteps: useLocalStorage('setupSteps', {}),
        activeTab: '0',
        jobSiteSelectedTab: '0',
        AchievementSelectedTab: '0',
        orgName: useLocalStorage('orgName' ,''),
        dateFormat: useLocalStorage('orgDateFormat' ,'YYYY-MM-DD'),
        literalDateFormat: useLocalStorage('literalOrgDateFormat' ,'MMM, D, YYYY'),
        literalDateFormatDay: useLocalStorage('literalDateFormatDay', 'ddd, MMM D, YYYY'),
        literalDateFormatHma: useLocalStorage('literalDateFormatHma', 'MMM DD, YYYY | hh:mm a'),
        literalDateFormatWithoutYear : useLocalStorage('literalDateFormatWithoutYear', 'MMM D'),
        literalNewDateFormat : useLocalStorage('literalNewDateFormat' ,'MMM D, YYYY'),
        literalDateSync: useLocalStorage('literalDateFormatSync', 'MMM DD, YYYY | hh:mm'),
        userIP:'0.0.0.0'
    }),

    getters: {
        userDetails() {
            return this.userData
        },
        orgId() {
            return this.userData.org_id
        },
        orgDetails() {
            return this.org_data;
        },
        userPermissions() {
            return this.userRolesPermissions;
        },
        userRole() {
            return this.userOrgRole;
        },
        lastSelOrganisation() {
            return this.lastSelectedOrg;
        },
        userSteps() {
            return this.userSetupSteps;
        },
        getOrgName() {
            return this.orgName;
        },
        getDateFormat(){
            return this.dateFormat;
        },
        getLiteralDateFormat(){
            return this.literalDateFormat;
        },
        getLiteralDateFormatDay(){
            return this.literalDateFormatDay;
        },
        getLiteralDateFormatWithoutYear(){
            return this.literalDateFormatWithoutYear;
        },
        getLiteralNewDateFormat(){
            return this.literalNewDateFormat;
        },
        getUserIP(){
            return this.userIP;
        },
        getLiteralDateFormatHma(){
            return this.literalDateFormatHma
        },
        getSyncDate(){
            return this.literalDateSync
        }
    },

    actions: {
        storeUserData(userData: any) {
            this.userData = userData
        },
        orgData(orgId: any) {
            this.userData.org_id = orgId
        },
        setUserPermissions(userPermissions: any) {
            this.userRolesPermissions = userPermissions
        },
        setOrganization(payload: any) {
            this.org_data = payload
        },
        setUserOrgRole(payload: any) {
            this.userOrgRole = payload
        },
        setUserSetupSteps(payload: any) {
            this.userSetupSteps = payload;
        },
        setOrgName(orgName:any){
            this.orgName = orgName;
        },
        setOrgDateFormat(format:string){
            this.dateFormat = format;
            if(format == 'YYYY-MM-DD'){
                this.literalDateFormat = 'YYYY, MMM D'
                this.literalDateFormatDay = 'ddd, YYYY, MMM, D'
                this.literalDateFormatHma = 'YYYY MM, DD | hh:mm a'
            }else if(format == 'MM-DD-YYYY'){
                this.literalDateFormat = 'MMM D, YYYY'
                this.literalDateFormatDay = 'ddd, MMM, D, YYYY'
                this.literalDateFormatHma = 'MMM DD, YYYY | hh:mm a'

            }else{
                this.literalDateFormat = 'DD MMM, YYYY'
                this.literalDateFormatDay = 'ddd, DD MMM, YYYY'
                this.literalDateFormatHma = 'DD MMM, YYYY | hh:mm a'

            }
        },
        setOrgDateFormatWithoutYear(format:string){
            if(format == 'YYYY-MM-DD'){
                this.literalDateFormatWithoutYear = 'MMM D'
            }else if(format == 'MM-DD-YYYY'){
                this.literalDateFormatWithoutYear = 'MMM D'
            }else{
                this.literalDateFormatWithoutYear = 'DD MMM'
            }
        },
        setNewOrgDateFormat(format:string){
            this.dateFormat = format;
            if(format == 'YYYY-MM-DD'){
                this.literalNewDateFormat = 'YYYY, MMM D'
            }else if(format == 'MM-DD-YYYY'){
                this.literalNewDateFormat = 'MMM D, YYYY'
            }else{
                this.literalNewDateFormat = 'DD MMM, YYYY'
            }
        },
        setSyncDateFormat(format:string){
            this.dateFormat = format;
            if(format == 'YYYY-MM-DD'){
                this.literalDateSync = 'YYYY, MMM D | hh:mm'
            }else if(format == 'MM-DD-YYYY'){
                this.literalDateSync = 'MMM DD, YYYY | hh:mm'
            }else{
                this.literalDateSync = 'DD MMM, YYYY| hh:mm'
            }
        },
        setIPAddress(ip:any){
            this.userIP = ip;
        }
    }
})
<script setup lang="ts">
    type Props = {
        time:{
            toTime: any,   
            required: true
        }
    }

    const { t } = useI18n();

    let propsData = defineProps<Props>();
    // console.log("propsData.time", propsData.time)
    
    const emit = defineEmits(['changeTimesheetTime']);
    const changeTimesheetTimeFn = () => {
        emit('changeTimesheetTime', )
    }
    const hourList = ref<any>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const minuteList = ref<any>(['00', '05', 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]);
    let slectedAmPm = ref<any>();
    let dummySelectedMin = ref<any>();
    let selectedHour = ref<number>();
    let selectedMin = ref<number>();

        let value = propsData.time;
        let [hour,minuteampm] = value.split(':');
        selectedHour.value = parseInt(hour);
        selectedMin.value = parseInt(minuteampm.split(" ")[0]);
        slectedAmPm.value = minuteampm.split(" ")[1];
        dummySelectedMin.value = parseInt(minuteampm.split(" ")[0]);
    const setHour = (hour: number) => {
        selectedHour.value = hour;
        emit('changeTimesheetTime', 
        `${valueIntoTwoValues(selectedHour.value)}:${valueIntoTwoValues(selectedMin.value)} ${slectedAmPm.value}`
        )
    }

    const setMinute = (min: number) => {
        // console.log("minminmin", min)
        selectedMin.value = min;
        dummySelectedMin.value = min;
        emit('changeTimesheetTime', 
        `${valueIntoTwoValues(selectedHour.value)}:${valueIntoTwoValues(selectedMin.value)} ${slectedAmPm.value}`
        )
    }

    const setSelectedAM = (data: any) => {
        slectedAmPm.value = data;
        emit('changeTimesheetTime', 
        `${valueIntoTwoValues(selectedHour.value)}:${valueIntoTwoValues(selectedMin.value)} ${slectedAmPm.value}`
        )
    }
    function addZeroToSingleDigit(number: any) {
        if (number >= 0 && number < 10) {
            return "0" + number;
        } 
    }
    const isSelectedMinutes = () => {
      let minuteval;
      if(dummySelectedMin.value == 0 || dummySelectedMin.value == 5){
        minuteval = addZeroToSingleDigit(dummySelectedMin.value)
      }else[
      minuteval = dummySelectedMin.value
      ]
      if(minuteList.value.includes(minuteval)){
        
        return dummySelectedMin.value;
      }
    }

    const valueIntoTwoValues = (data: any) => {
      if(data>=0 && data.toString().length===1){
        let time='0'+data;
        return time;
      }else{
        return data;
      }
    }
    watchEffect(() => {
        // if(propsData.time){

        // }
        
        // console.log("propsData.time", value, propsData.time, typeof(propsData.time), propsData.time.length)
        // console.log("propsData.time", value )
        value = propsData.time;
        [hour,minuteampm] = value.split(':');
        selectedHour.value = parseInt(hour);
        selectedMin.value = parseInt(minuteampm.split(" ")[0]);
        // console.log("selectedmin", Math.ceil(selectedMin.value / 5) * 5)
        slectedAmPm.value = minuteampm.split(" ")[1];
        
            dummySelectedMin.value = Math.ceil(selectedMin.value / 5) * 5;
        
        
        
    })
</script>

<template>
    <div class="ws_timePicker">
    <div class="timeMomentPicker">
        <h4>
            {{ t('wsTimePicker.Hours') }}
        </h4>
        <section>
            <a
                v-for="(hour, i) in hourList" 
                :key='i'
                @click="setHour(hour)"
                :class="{ selected: hour === selectedHour }"
                >{{ hour }}</a
            >
        </section>
        <h4>
            {{ t('wsTimePicker.Minutes') }}
        </h4>
        <section>
            <a
                v-for="(min, i) in minuteList"
                :key="i" 
                @click="setMinute(min)"
                :class="{ selected: min==isSelectedMinutes()}"
                >{{ min }}</a
            >
        </section>
        <section>
            <a 
            :class="{ selected: slectedAmPm === 'AM' }"
            @click="setSelectedAM('AM')"
            >AM</a>
            <a
            :class="{ selected: slectedAmPm === 'PM' }"
            @click="setSelectedAM('PM')"
            >PM</a>
        </section>
        <!-- /ko -->
    </div>
</div>

</template>


<style lang="scss" scoped>
.ws_timePicker {
    width: 377px;
    min-height: 208px;
    background-color: white;
    border-radius: 4px !important;
    .timeMomentPicker {
        padding: 5px;
        h4 {
            font-size: 14px;
            font-weight: bold;
            padding: 0;
            margin: 0 0 0 5px;
        }
        section {
            padding: 3px 0 10px 0;
            text-align: center;
            margin-bottom: 10px;
            border-bottom: 1px dotted #d8d8d8;
            &:last-of-type {
                margin-bottom: 0;
                border-bottom: 0;
            }
            a {
                display: inline-block;
                width: 26px;
                height: 26px;
                text-align: center;
                color: #727272;
                border-radius: 50%;
                text-decoration: none;
                font-size: 12px;
                line-height: 26px;
                font-weight: bold;
                cursor: pointer;
                text-decoration: none !important;
                &.selected {
                    background: #624de3;
                    color: #ffff;
                }
            }
        }
    }
}

</style>

























<template>
    <v-navigation-drawer v-if="drawerstore.addMilestoneDrawer"  v-model="drawerstore.addMilestoneDrawer" temporary :location="isAppRtl ? 'left' : 'right'"
      class="w-50" :style="isAppRtl ? 'border-right: 8px solid #695EEE' : 'border-left: 8px solid #695EEE'">
      <img @click="drawerstore.addMilestoneDrawer = false;" style="position:absolute;  cursor:pointer;"
        :style="isAppRtl ? 'margin-right:-45px' : 'margin-left:-45px'" class="mt-16"
        src="@/assets/images/svg/closeDrawer.svg" />
      <AddMilestone @closeMilestoneDrawer="drawerstore.closeMilestoneDrawer" v-if="drawerstore.showMilestoneComponent" />
    </v-navigation-drawer>
</template>
<script setup lang="ts">
import { useDrawerStore } from "@/stores/drawer.ts";
import AddMilestone from '@/views/drawers/add-milestone.vue'
const drawerstore = useDrawerStore();
</script>
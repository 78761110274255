import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn } from './utils'
import routes from '~pages'
import { canNavigate } from '@layouts/plugins/casl'
import { forEach } from 'lodash'
export const lastRoute = ref();
const redirectIfLoggedIn = (to:any, componentName:any) => {
const userData = JSON.parse(localStorage.getItem('userData'));
const featuresList = JSON.parse(localStorage.getItem('featuresList'));
  // if support team member is currently logged in with their account and hits the below route, then they will beredirected to the below route and will be asked to log out first
  if(to.matched[0].path == '/auth/loginFromSuperadmin/:token'){
    return { name: 'auth-loginFromSuperadmin-token' };
  }
  else if (userData && userData !== null && Object.keys(userData).length) {
    return { name: 'dashboard-me' };
  }
  return { name: componentName, query: to.query };
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: to => redirectIfLoggedIn(to, 'auth-login'),
    },
    {
      path: '/auth/login',
      redirect: to => redirectIfLoggedIn(to, 'auth-login'),
    },
    {
      path: '/pages/user-profile',
      redirect: () => ({ name: 'pages-user-profile-tab', params: { tab: 'profile' } }),
    },
    {
      path: '/pages/account-settings',
      redirect: () => ({ name: 'pages-account-settings-tab', params: { tab: 'account' } }),
    },
    {
      path: '/auth/register',
      redirect: to => redirectIfLoggedIn(to, 'auth-register'),
    },
    {
      path: '/auth/forgot-password',
      redirect: to => redirectIfLoggedIn(to, 'auth-forgot-password'),
    },
    {
      path: '/auth/register/:token',
      redirect: to => redirectIfLoggedIn(to, 'auth-register-token'),
    },
    {
      path: '/auth/account-verification/:token',
      redirect: to => redirectIfLoggedIn(to, 'auth-account-verification-token'),
    },
    {
      path: '/auth/reset-password/:id',
      redirect: to => redirectIfLoggedIn(to, 'auth-reset-password-id'),
    },
    {
      path: '/auth/mail-confirm',
      redirect: to => redirectIfLoggedIn(to, 'auth-mail-confirm'),
    },
    {
      path: '/auth/email-verify/:token',
      redirect: to => redirectIfLoggedIn(to, 'auth-email-verify-token'),
    },
    // {
    //   path: '/auth/member-verify/:token',
    //   redirect: to => redirectIfLoggedIn(to, 'auth-member-verify-token'),
    // },
    {
      path: '/auth/login_with_app',
      redirect: to => redirectIfLoggedIn(to, 'auth-login_with_app'),
    },
    {
      path: '/auth/thankyou',
      redirect: to => redirectIfLoggedIn(to, 'auth-thankyou'),
    },
    {
      path: '/auth/account-verification/:token',
      redirect: to => redirectIfLoggedIn(to, 'auth-account-verification-token'),
    },
    {
      path: '/auth/stealth-invitation/:id',
      redirect: to => redirectIfLoggedIn(to, 'auth-stealth-invitation-id'),
    },
    {
      path: '/auth/loginFromSuperadmin/:token',
      redirect: to => redirectIfLoggedIn(to, 'auth-loginFromSuperadmin-token'),
    },
    {
      path: '/thankyou',
      redirect: to => redirectIfLoggedIn(to, 'thankyou'),
    },
    {
      path: '/invite/WS/:token',
      redirect: to => redirectIfLoggedIn(to, 'invite-WS-token'),
    },
    {
      path: '/auth/member-verification/:token',
      redirect: to => redirectIfLoggedIn(to, 'auth-member-verification-token'),
    },
    ...setupLayouts(routes),
  ],
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach((to, from, next) => {
//  restricted routes for different roles starts
const user_restricted_routes =  ['/settings/manage-notification','/settings/projectid','/settings/todostatus','/settings/todoid','/settings/project-milestone','/settings/finance-setting','/settings/organization/edit','/settings/organization/set-rates','/settings/organization/member-timezone','/settings/organization/weekly-off','/settings/organization/work-location','/settings/screenshot-timer','/settings/idlenotes','/settings/define-break','/settings/break-limit','/settings/tracking-mode','/settings/department','/settings/memberid','/settings/member-designation','/settings/member-visibility','/settings/modify-timesheet','/settings/timesheet-approval','/settings/require-reason','/settings/face-detection','/settings/weekly-timesheet','/settings/hide-activity','/settings/ss-notification','/settings/track-appurl','/settings/screenshot-blur','/settings/screenshot-frequency','/settings/screenshot-delete','/settings/shift-alerts','/settings/minimum-shifthours','/settings/timeoff-policies','/settings/timeoff-escalation','/settings/restrict-timer','/settings/efficiency-pro','/settings/productivity-champ','/settings/time-hero','/settings/daily-summary','/settings/weekly-summary']

const user_role = JSON.parse(localStorage.getItem('userRole'));
const user_type = JSON.parse(localStorage.getItem('userType'));

// if (to.path.includes('/settings') && user_role?.role_id != 5 && user_role?.role_id != 2) {
//   // This check will allow Owner and Manager to access any settings page.
//   router.push('/unauthorized')
// }

 if(user_role?.role_id == 1 && !(user_type?.is_department_head == 1 || user_type?.is_pm == 1 || user_type?.is_team_lead == 1) ){
   if(to.path === '/dashboard/all') router.push('/unauthorized');
 }
 if(user_role?.role_id == 1){
   user_restricted_routes.forEach((routePath)=>{
     if(to.path === routePath){ router.push('/unauthorized') }
   })
 }
//  restricted routes for different roles starts ends
 

  // Store the last accessed route
  lastRoute.value = from;
  
  const userDataSteps = JSON.parse(localStorage.getItem('userData'));
  // Check if onboarding_steps exists in userData
  const hasOnboardingSteps = userDataSteps && userDataSteps.hasOwnProperty('onboarding_steps');

  const restrictedPaths = ['/dashboard/premiumPlan', '/dashboard/change-plan'];
  // Check if 'marketingToFreeTrial' exists and is set to 'true'
  const marketingToFreeTrial = localStorage.getItem('marketingToFreeTrial') === 'true';

  // Check if the target path is '/dashboard/organisation/welcome'
  const targetPath = '/dashboard/organisation/welcome';
  
  if (restrictedPaths.includes(from.path) && to.path === targetPath) {
    if (!hasOnboardingSteps && !marketingToFreeTrial) {
      next(false); // Prevents navigation to the target path
    }
  }
  if(
    (from.fullPath == '/dashboard/organisation/welcome?page=account_setup' ||
    from.fullPath == '/dashboard/organisation/welcome?page=business_setup' ||
    from.fullPath == '/dashboard/organisation/welcome?page=team_onboarding') &&
    to.fullPath == from.fullPath
  ){
    
    window.history.replaceState(null, '', '/dashboard/organisation/welcome');
     next(false)
  }
  // console.log('lastRoute.value', lastRoute.value)
  // console.log('to.fullPath', to.fullPath)
  // console.log('from.fullPath', from.fullPath)
  const isLoggedIn = isUserLoggedIn();

  // Check if user is not logged in and trying to access protected routes
  if (
    to.name !== 'auth-login' &&
    to.name !== 'auth-forgot-password' &&
    to.name !== 'auth-mail-confirm' &&
    to.name !== 'auth-reset-password-id' &&
    to.name !== 'auth-register' &&
    to.name !== 'auth-thankyou' &&
    to.name !== 'thankyou' &&
    to.name !== 'auth-account-verification-token' &&
    to.name !== 'auth-register-token' &&
    to.name !== 'auth-stealth-invitation-id' &&
    to.name !== 'auth-email-verify-token' &&
    to.name !== 'auth-login_with_app' &&
    to.name !== 'auth-loginFromSuperadmin-token' &&
    to.name !== 'invite-WS-token' &&
    to.name !== 'auth-member-verification-token' &&
    (localStorage.getItem('userData') == null || localStorage.getItem('userData') == '{}') &&
    localStorage.getItem('accessToken') == null
  ) {
    // Redirect to login if not logged in
    if (to.name != 'auth-member-verify-token') {
      next({ name: 'auth-login' });
    } else {
      // Handle auth-member-verify-token route separately
      // Check if conditions are met to redirect to member verify
      if (
        localStorage.getItem('userData') !== null &&
        localStorage.getItem('userData') !== '{}' &&
        localStorage.getItem('accessToken') !== null
      ) {
        // Redirect to member verify route with token
        return next({ path: `/auth/member-verify/:token` });
      } else {
        // Otherwise, continue to prevent infinite loop
        return next();
      }
    }
  } else if (to.name == 'auth-member-verify-token' &&
             (localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== '{}') &&
             localStorage.getItem('accessToken') !== null) {
              lastRoute.value.name = 'auth-member-verify-token'
    // If user is logged in and trying to access auth-member-verify-token, redirect to '/members'
    next({ path: '/members' });
  } else {
    // User is logged in or accessing public routes, proceed
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userRole = JSON.parse(localStorage.getItem('userRole'));

    let featuresList = localStorage.getItem('featuresList');
    if (featuresList) {
      featuresList = JSON.parse(featuresList);
    } else {
      featuresList = []; // or any default value you want to use
    }


    // //dashboard all when step != 2
    // if(to.path == '/dashboard/all' && userData.onboarding_steps != 2){
    //   next({ path: `/dashboard/organisation/welcome` });
    // }

    // Handle specific routes or conditions (example: dashboard, pms)
    if (to.path === '/dashboard/analytics' || to.path === '/dashboard/me') {
      const userId = userData.id;

      if (userData.org_id == undefined || userData.onboarding_steps != 2) {
        next({ path: `/dashboard/organisation/welcome` });
      } else {

        if(featuresList.some(feature => feature?.name == "attendance_tracking" && feature.status == 1) && 
        featuresList.every(feature => feature.name == "attendance_tracking" || feature.status == 0)
        ){
            next({ path: `/dashboard/attendance` });
          }else{
            if (userRole.role_id === 5 || userRole.role_id === 2) {
              next({ path: `/dashboard/all` });
            } else {
              next({ path: `/member/dashboard/${userId}` });
            }
          }

      }
    } else if (to.path === '/dashboard/projects') {
      if (featuresList.some(feature => feature?.name === "project_management" && feature.status === 1) || userRole.role_id == 6) {
        next();
      } else {
        next({ path: `/dashboard/projects/view/1001/overview` });
      }
    }
    // Proceed to the next route
    next();
  }
});

// router.onError((error, to) => {
//   if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
//     window.location = to.fullPath
//   }
// })

export default router
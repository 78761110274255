
<script setup lang="ts">
import { useHelpdeskStore } from "@/stores/helpdesk"
import { userDetailStore } from '@/stores/userdata';
import { useI18n } from 'vue-i18n';

import closeSvg from '@images/icons/themeupdate/close.svg'
import chatWithUsSvg from "@/assets/images/svg/chatwithus.svg"
import orSvg from "@/assets/images/svg/orpart.svg";
import supportSvg from "@/assets/images/newtheme/support.svg";

import { useThemeConfig } from '@core/composable/useThemeConfig'
const { isAppRtl } = useThemeConfig()

const { t } = useI18n();
const helpdeskStatus = useHelpdeskStore();
const userStore = userDetailStore();


// console.log("helpdeskStatus.getHelpdeskNavStatus", helpdeskStatus.getHelpdeskNavStatus)
// interface Emit {
//     (e: 'update:isDrawerOpen', value: boolean): void
//     // (e: 'submit', value: SubmitData): void
// }

// interface Props {
//     isDrawerOpen: boolean
// }

// const props = defineProps<Props>()
// const emit  = defineEmits<Emit>()
const visitHelpCenter = () => {
    window.open('https://support.workstatus.io/en/', "_blank");
}

// Crisp imported
import Crisp from '@layouts/crisp';
function openCrisp() {
    Crisp.chat.open();
    const user ={
        userId: userStore.userDetails.id,
        userName: userStore.userDetails.name,
        orgId: userStore.userDetails.org_id,
        userEmail: userStore.userDetails.email,
        orgName: userStore.getOrgName,
        planName: userStore.lastSelOrganisation.subscription_details.name
        
    };
    Crisp.session.setData(user);

}
</script>

<template>
    <div>
    <VNavigationDrawer
        v-model="helpdeskStatus.helpdeskNavStatus"
        temporary
        :location="isAppRtl ? 'left' : 'right'"
        :width="533"
        class="z-index-nav"
        :class="helpdeskStatus.helpdeskNavStatus ? 'showHelp' : 'hideHelp'"
        :style="isAppRtl ? 'left: 0' : 'right: 0'"
    >
    
    <div class="main_support_comp">
        <div class="header_with_close_icon" >
            <div class="flex">
                <h2>{{ t('helpDesk.supportHelp') }}</h2>
                <p class="orgId-text ">{{ t('helpDesk.orgId') }} {{ userStore.userDetails.org_id }} <span class="share-text">({{ t('helpDesk.orgText') }})</span></p>
            </div>
            <div>
                <img :src="closeSvg" alt="" @click="helpdeskStatus.setHelpdeskNavStatus(false)" class="cursor-pointer"/>
            </div>
        </div>
        

    <div style="margin: 20px 35px;">

   
    <div>
        <span 
            style="font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #546181;
            ">{{ t('helpDesk.supportHrs') }}
        </span>
        
        <p 
            style="font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #00234B;
            margin-top: 10px;
            ">{{ t('helpDesk.dayTime') }}
        </p>
    </div>
    <p style="margin-top: 30px; font-weight: 500; font-size: 17px; color: #00234B;">{{ t('helpDesk.successManager') }}</p>
    

    <VRow>
        <VCol cols="12"
                md="6">
            <div style="display: flex;">
                <div style="" :style="isAppRtl ? 'margin-left: 10px;' : 'margin-right: 10px;'">
                    <div class="circle">P</div>
                </div>
                <div>
                    <p class="__support_name">Prachi Kala</p>
                    <a
                        href="mailto:prachi@workstatus.io"
                        class="_support_email"
                    >
                        prachi@workstatus.io
                    </a>
                    <p v-if="isAppRtl" class="__support_number">918595620285+</p>
                    <p v-else class="__support_number">+918595620285</p>
                </div>
            </div>
        </VCol>
    </VRow>
    <p style="margin-top: 30px; font-weight: 500; font-size: 17px; color: #00234B;">{{ t('helpDesk.escalation') }}</p>
        <div style="display: flex;">
            <div style="" :style="isAppRtl ? 'margin-left: 10px;' : 'margin-right: 10px;'">
                <div class="circle">S</div>
            </div>
            <div style="" :style="isAppRtl ? 'margin-left: 35px;' : 'margin-right: 35px;'">
                <p class="__support_name">Shashikant Chauhan</p>
                <a
                    class="_support_email"
                    href="mailto:shashikant@workstatus.io"
                >
                shashikant@workstatus.io
                </a>
                <p v-if="isAppRtl" class="__support_number">918287323907+</p>
                <p v-else class="__support_number">+918287323907</p>
            </div>
        </div>

        <div style="margin-top: 60px;">
            <div class="d-flex justify-center"> 
                <VBtn @click="helpdeskStatus.setHelpdeskNavStatus(false);helpdeskStatus.setOpenChatToggle(true); openCrisp();">
                    <img :src="chatWithUsSvg" :class="isAppRtl ? 'ml-1' : 'mr-1'" class=""/>
                    {{ t('helpDesk.chatWus') }}
                </VBtn>
                <img :src="orSvg" />
                <VBtn
                    height=""
                    variant="outlined"
                    @click="visitHelpCenter()"
                >
                    <img :src="supportSvg" :class="isAppRtl ? 'ml-1' : 'mr-1'" class="pt-1"/>
                    {{ t('helpDesk.visitHelpCenter') }}
                </VBtn>
            </div>  
        </div>
    </div>

</div>


    </VNavigationDrawer>
    </div>
</template>

<style lang="scss" scoped>
    // @import "src/@fuse/scss/fuse";
.main_support_comp {
    .__support_name{
        font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 160%;
                            color: #00234b;
                            margin-bottom: 5px; 
    }
    ._support_email{
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;

        margin-bottom: 1em;
        color: #624de3;
    }
    .__support_number{
        font-weight: 400;
                            font-size: 14px;
                            line-height: 120%;
                            color: #525f7f;
                            margin-top: 10px;
                            margin-bottom: 0;
    }
    .header_with_close_icon {
        background: #f7faff;
        border-radius: 0px;
        display: flex;
        justify-content: space-between;
        padding: 25px 22px 15px 22px;

        h2 {
            // font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #00234b;
        }
        .close_icon {
            color: #525f7f;
            cursor: pointer;
        }
    }
    .mark_all_read {
        display: flex;
        justify-content: space-between;
        padding: 15px 22px;
    }
    .mal_text {
        // font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #525f7f;
        padding-right: 6px;
        cursor: pointer;
    }
    .clear_all {
        text-decoration-line: underline;
        color: #624de3;
        // font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .ul_notification_content_main {
        margin: 0;
        // padding: 0;
        padding: 0 0 56px 0;
        .notification_content {
            padding: 12px;
            display: flex;
            .title_message_time {
                display: flex;
                flex-direction: column;
                .title {
                    // font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #00234b;
                    padding-bottom: 8px;
                }
                .message {
                    // font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #525f7f;
                }
                .date_time {
                    // font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #525f7f;
                    padding-top: 8px;
                    opacity: 0.6;
                }
            }
        }
    }
    .new_unread_notification {
        background: #f2f0ff !important;
        box-shadow: 0px 1px 0px #e4e8ee;
    }
    .last_div_view_all_notification {
        position: fixed;
        bottom: 0;
        background: #ffffff;
        box-shadow: 0px -5px 4px rgba(5, 0, 64, 0.15);
        width: 425px;
        padding: 14px 25px;
        text-align: right;
        // @include media-breakpoint("lt-md") {
        //     width: 75%;
        // }
        a {
            text-decoration-line: underline;
            color: #624de3;
            // font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
        }
    }
    .circle {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        padding: 7px;
        background: #EEE7FF;
        // border: 3px solid #000;
        color: #624DE3;
        text-align: center;
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
      }
    .orgId-text{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        color: #00234b;
        margin-top: 5px;
        margin-bottom: 0;
    }
    .share-text{
        color: #546181;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }
}
.showHelp{
    z-index: 9999!important; 
}
.hideHelp{
    display:none !important;

}
</style>
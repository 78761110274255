// this composable is for add drawers visiblitity based on features selection during account creation.
import { ref } from 'vue';
const unionArray = ref<any>([]);
let argumentArray: any[] = [];

function unionArrays(...arrays: any) {
  let combinedArray = [].concat(...arrays);
  let uniqueSet = new Set(combinedArray);
  return Array.from(uniqueSet);
}
function selectDrawers(val: any) {
  console.log(val, "featurelist");

  argumentArray = []; 

  if (val[0]?.status) argumentArray.push([0, 1, 2, 3, 4, 5, 6, 7,8]);
  if (val[1]?.status) argumentArray.push([0, 1, 2, 3, 4, 5, 6, 7, 8]);
  if (val[2]?.status) argumentArray.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11]);
  if (val[3]?.status) argumentArray.push([1, 2, 3, 4, 5, 6, 7, 8, 10, 11]);
  if (val[4]?.status) argumentArray.push([1, 2, 3, 4, 5, 6, 7, 8, 9]);

//   console.log(argumentArray, "argumentArray");

  unionArray.value = unionArrays(...argumentArray);
  console.log(unionArray.value, "drawer composable");
}

export function useDrawer() {
  return { unionArray, selectDrawers };
}

  
import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoaderStore } from '@/stores/loader';
import { userDetailStore } from './userdata';

//plan check 7 days
// import { documentationObj } from "@types/sidebar";
// import { useVerticalMenuStore } from "@/stores/verticalmenu";
//plan check 7 days end

export const useRolelistStore = defineStore('roleListStatus', () => {
    const roleListIsSet = ref<any>(0)
    const planData = ref<any>()
    const currentPlan = ref<any>();
    const userData = userDetailStore();

    //  plan check 7 days
    // const veritcalMenu = useVerticalMenuStore();
    //plan check 7 days end
    const timesheetpermission=ref<any>();
    const userRole = ref<any>();
    const userSteps = ref(useLocalStorage('setupSteps' ,{}));
    const welcomeScreen = ref<any>();
    const payment = ref<any>();
    const isDepartmentHead = ref();
    const orgCreationDate = ref<any>();
    const isTeamLead = ref<any>()
    const fetchOrgPlanDetails = () => {
        axios.post('v1/user/organization/role/list',{
            organization_id: userData.userDetails.org_id
        }).then((res: any)=>{
            if(res.data.response.code == 200) {
                // loader.showLoader(false);
                localStorage.setItem(
                    "currentOrgPlanName",
                    res.data.response.data.plan_name
                  );
                orgCreationDate.value = res.data.response.org_creation_date;  
                planData.value = res.data.response.data.expiry_alert;
                currentPlan.value = res.data.response.data;
                roleListIsSet.value = 1;
                userRole.value = res.data.response.data.role.id;
                userSteps.value = res.data.response.steps;
                welcomeScreen.value = res.data.response.welcomescreen;
                localStorage.setItem('setupSteps', JSON.stringify(res.data.response.steps));
                userData.setUserSetupSteps(res.data.response.steps);
                isTeamLead.value = res.data.response.data.is_team_lead
                console.log(res.data.response.data.modify_value,"res.data.response.data.modify_value")
                timesheetpermission.value = res.data.response.data.modify_value;
                isDepartmentHead.value=  res.data.response.data.is_department_head;
                //plan check 7 days
                // let sidemenu = res.data.response.data.role.menu;
                // const objectExists = sidemenu.find(
                //   (obj) => obj.title === documentationObj.title
                // );
                // if (!objectExists) {
                    // documentationObj.children = documentationObj.children.filter(child => {
                    //     if (child.title == "Employees") {
                    //         return (res.data.response?.data.is_department_head == 1 || [2, 3, 5].includes(res.data.response?.data.role_id));
                    //     }
                    //     return true;
                    // });
                //   sidemenu.push(documentationObj);
                // }
                // localStorage.setItem("verticalMenu", JSON.stringify(sidemenu));
                // veritcalMenu.storeMenu(sidemenu);
                //plan check 7 days end
            }
        }).catch((err: any) => {
            
        })
    }
    
    return {
        planData,
        currentPlan,
        roleListIsSet,
        userRole,
        userSteps,
        welcomeScreen,
        orgCreationDate,
        fetchOrgPlanDetails,
        isTeamLead,
        isDepartmentHead,
        timesheetpermission
    };
  });
  
<template>
    <!-- <loaderComponentDialogVue
      v-if="loader.globalLoader"
    ></loaderComponentDialogVue> -->
    <div class="px-8 py-6" :class="loader.darkTheme?'darkBackground':'lightBackground'">
         <h1 class="heading" :class="loader.darkTheme?'darkHeading':''">{{ t('drawersText.addNewMilestone') }}</h1>
                <p class="subHeading mt-2" :class="loader.darkTheme?'darkContent':''">
                    {{ t('drawersText.setYourMilestone') }}
                </p>
            </div>
            <div class="ma-6">
                    <v-row>
                        <v-col cols="12" :lg="!isRouteNameInList ? 6 : 12">
                  <label class="labelText">{{ t('drawersText.milestoneStoneName') }}*</label>
                  <VTextField
                    v-model="milestoneName"
                    variant="outlined"
                    class="TextField_style"
                    :placeholder="t('drawersFieldPlaceholders.milestoneName')"
                    :rules="[
                      requiredFieldValidator,
                      requiredValidator,
                      requiredValidatorchk,
                    ]"
                  />
                  <span
                    v-if="checkMilestoneNameLength"
                    style="font-size: 12px; color: red"
                  >
                    Milestone name must not exceed limit of 250 characters.
                  </span>
                  <!-- <span v-if="checkMilestoneName"
                    style="font-size: 12px; color: red"
                  >
                    Special character are not accepted
                  </span> -->
                        </v-col>
                        <v-col cols="12" lg="6" v-if="!isRouteNameInList"> 
                            <label class="labelText">{{t('todo.viewTodo.projectName')}}</label>
                <v-autocomplete
                  v-click-outside="clearSearch"
                  :menu-props="{ maxWidth: 300, maxHeight: 300 }"
                  class="TextContent ws_vue_multip_chips_element"
                  v-model="selectProject"
                  :items="filteredProjects"
                  :placeholder="t('drawersFieldPlaceholders.projName')"
                  @update:model-value="fetchProjectOwnerList(); selectedProjectData();"
                  item-title="name"
                  item-value="id"
                  :loading="loadings[0]"
                  variant="outlined"
                > 
                <template v-slot:no-data v-if="fieldLoader[0]">
                                        <div style="padding-top: 20px; padding-bottom: 20px">
                                            <v-progress-circular indeterminate :size="25"
                                                style="left: 50%"></v-progress-circular>
                                        </div>
                                    </template>
                  <template v-slot:prepend-item v-if="!fieldLoader[0]">
                    <VTextField
                      autofocus
                      placeholder="Search project"
                      class="px-2 mt-2"
                      v-model="searchProject"
                    >
                    </VTextField>
                  </template>
                </v-autocomplete></v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <VCol cols="12" lg="3" style="position:relative;">
                    <label class="labelText">{{ t('drawersText.startDate') }}</label>
                    <VTextField
                    class="startTimeField"
                      variant="outlined"
                      readonly
                      @click="
                        showcal = true;
                        showOverlay = true;
                      "
                    >
                      <span
                        @click="
                          showcal = true;
                          showOverlay = true;
                        "
                        class="TextContent pl-2"
                        >{{
                          showSelectedStartDate
                        }}</span
                      >
                      <template #append-inner>
                        <img v-if="showSelectedStartDate"
                          :src="calendarSvg"
                          @click="
                            showcal = true;
                            showOverlay = true;
                          "
                          style="margin-top: 2px; margin-right: 3px"
                        />
                        <v-icon style="margin-top: 0px;
                                    margin-right: 0px;" class="cursor-pointer" v-if="showSelectedStartDate" @click="showSelectedStartDate = ''">mdi-close</v-icon>
                                            <v-icon v-else
                                                        icon="mdi-calendar-week-outline"
                                                        @click="showcal=true;showOverlay=true"
                                                    />
                      </template>
                    </VTextField>
                    <span  style="position:absolute; width:140px; font-size: 10px; color: red"
                      v-if="milestoneStartDateChecks">
                      {{ warningStartDate }}
                    </span>
                    <span v-if="showOverlay" id="overlay" @click="closeCal">
                    </span>
                    <span :class="showcal ? 'show-v-cala' : 'hide-v-cal'">
                      <VDatePicker
                        is-required
                        v-if="showcal"
                        v-model="selectedStartDate"
                        mode="date"
                        @update:modelValue="closeCalOnModelVal();"
                        />
                        <!-- :min-date="moment(selectedStartDate).format('DD-MM-YYY')"
                        :max-date="moment(selectedEndDate).format('DD-MM-YYYY')" -->
                    </span>
                    
                  </VCol>
                  <VCol cols="12" lg="3" style="position:relative;">
                    <label class="labelText">{{ t('drawersText.endDate') }}</label>
                    <VTextField
                    class="startTimeField"
                      variant="outlined"
                      readonly
                      @click="
                        showcalB = true;
                        showOverlayB = true;
                      "
                    >
                      <span
                        @click="
                          showcalB = true;
                          showOverlayB = true;
                        "
                        class="TextContent pl-2"
                        >{{
                          showSelectedEndDate
                        }}</span
                      >
                      <template #append-inner>
                        <img v-if="showSelectedEndDate"
                          :src="calendarSvg"
                          @click="
                            showcalB = true;
                            showOverlayB = true;
                          "
                          style="margin-top: 2px; margin-right: 3px"
                        />
                        <v-icon style="margin-top: 0px;
                                    margin-right: 0px;" class="cursor-pointer" v-if="showSelectedEndDate" @click="showSelectedEndDate = ''">mdi-close</v-icon>
                                            <v-icon v-else
                                                        icon="mdi-calendar-week-outline"
                                                        @click="showcalB=true;showOverlayB=true"
                                                    />
                      </template>
                    </VTextField>
                    <span
                      v-if="milestoneEndDateChecks"
                      style="position:absolute; width:140px; font-size: 10px; color: red"
                      class="error-text"
                    > 
                    {{ warningEndtDate }}
                    </span>
                    <span v-if="showOverlayB" id="overlayb" @click="closeCalB">
                    </span>
                    <span :class="showcalB ? 'show-v-calb' : 'hide-v-calb'">
                      <VDatePicker
                        is-required
                        v-if="showcalB"
                        v-model="selectedEndDate"
                        mode="date"
                        @update:modelValue="closeCalOnModelValB()"
                      />
                    </span>
                  </VCol> 
                  <VCol cols="12" lg="6">
                    <div>
                    <v-tooltip activator="parent" location="top" max-width="231px">
                      <template v-slot:activator="{ props }">
                        <label class="labelText">
                          {{ t('drawersText.flag') }}*
                          <VIcon
                            style="cursor: pointer"
                            v-bind="props"
                            class="tooltipIcon"
                          >
                            mdi-information-variant-circle-outline
                          </VIcon>
                        </label>
                      </template>
                      <span class="tooltipText">
                        <b class="tooltipText">Internal:</b> Milestones will be
                        visible only to project users if marked as internal.
                      </span>
                        <br />
                      <span class="tooltipText"><b class="tooltipText">External:</b> Milestones will be
                        visible to both project and client users if marked as
                        external.
                      </span>
                    </v-tooltip>
                    </div>
                    <VSelect
                      :items="selectFlag"
                      item-title="name"
                      item-value="id"
                      v-model="flag"
                      variant="outlined"
                      class="TextContent ws_vue_multip_chips_element"
                    >
                    </VSelect>
                  </VCol>
                  <!-- <VCol cols="12" lg="3">
                    <label class="labelText">{{ t('drawersText.budgetedHours') }}</label>
                    <VTextField
                      :placeholder="t('drawersText.budgetedHours')"
                      v-model="numberInput"
                      :min="0"
                      type="number"
                      variant="outlined"
                      :max="999999999"
                      v-on:keyup="handleBudgetHours"
                      class="proj_OwnerLabel TextField_style"                      
                    ></VTextField>
                    <span style="color:red" v-show="isGreaterThan10">Budgeted hours should be less than 10 digits</span>
                  </VCol> -->
                    </v-row>
                    <v-row :class="(milestoneEndDateChecks || milestoneStartDateChecks) ? 'mt-7': 'mt-2'">
                       <VCol cols="12" lg="3">
                    <label class="labelText">{{ t('budget.Selectbudget') }}</label>
                    <VSelect variant="outlined" class="TextContent add_edit_todo_seletion" :items="budgetTypeDropdown"
                    :disabled="!finance_setting"  v-model="selectedBudgetType" :loading="loadings[1]"
                        >
                    </VSelect>
                    <!-- <span style="color:red" v-show="isGreaterThan10">Budgeted hours should be less than 10 digits</span> -->
                  </VCol>
                   <VCol cols="12" lg="3">
                    <label class="labelText">{{ t('budget.Enterbudget') }}</label>
                    <VTextField v-if="selectedBudgetType == ''"
                      :placeholder="t('budget.Enterbudget')"
                      v-model="budgetInHours"
                      type="number"
                      variant="outlined"
                      :min="0"
                      :max="99999999"
                      
                      class="proj_OwnerLabel TextField_style"                      
                    ></VTextField>
                    <VTextField v-if="selectedBudgetType == 'Total Hours'"
                      :placeholder="t('budget.Enterbudget')"
                      v-model="budgetInHours"
                      type="number"
                      variant="outlined"
                      :min="0"
                      :max="99999999"
                      onkeydown="javascript: return event.keyCode == 69 || event.keyCode == 189 || event.keyCode == 190 || event.keyCode == 110 || event.keyCode == 109 ? false : true"
                      class="proj_OwnerLabel TextField_style"                      
                    ></VTextField>
                    <VTextField v-if="selectedBudgetType == 'Total Cost'"
                      :placeholder="t('budget.Enterbudget')"
                      v-model="budgetInCost"
                      type="number"
                      :min="0"
                      :max="99999999"
                      onkeydown="javascript: return event.keyCode == 69 || event.keyCode == 189 || event.keyCode == 190 || event.keyCode == 110 || event.keyCode == 109 ? false : true"
                      variant="outlined"
                      class="proj_OwnerLabel TextField_style"     
                                       
                    ></VTextField>
                    <span style="color:red; font-size:12px;" v-if="budgetInHoursComp">{{ budgetInHoursComp }}</span>
                    <span style="color:red; font-size:12px;" v-if="budgetInCostComp">{{ budgetInCostComp }}</span>
                  </VCol>
                        <VCol cols="12" lg="6">
                    <label class="labelText">{{ t('drawersText.milestoneOwner') }}</label>
                    <VSelect
                      v-click-outside="clearOwner"
                      v-model="selectOwner"
                      :items="filteredOptions"
                      item-value="id"
                      item-title="name"
                      :menu-props="{
                        maxHeight: '200px',
                        closeOnContentClick: false,
                      }"
                      variant="outlined"
                      class="ws_vue_multip_chips_element proj_OwnerLabel"
                      :placeholder="t('drawersText.milestoneOwner')"
                    >
                      <template v-slot:prepend-item>
                        <VTextField
                          v-model="customSearch"
                          ref="ownerRef"
                          class="searchField"
                          density="compact"
                          prepend-inner-icon="mdi-magnify"
                          bg-color="#f6f8fb"
                          variant="solo"
                          placeholder="Search project member"
                        />
                      </template>
                      <template #item="{ item }">
                        <v-list-item class="ownerList pl-4">
                          <v-list-item-title
                            class="ownerContent"
                            @click="ownerSelect(item)"
                          >
                            <v-avatar
                              class="avatar"
                              v-if="item.image"
                              :image="item.image ? item.image : backupProfile"
                            >
                              <!-- :image="owners[item.value - 1].image" -->
                            </v-avatar>
                            <v-avatar
                              class="avatar"
                              v-else
                            >
                            <div class="avatar_circle" 
                                  :style="{
                                  backgroundColor: avatarColors[getColorIndex(item?.title)].bgColor,
                                  color: avatarColors[getColorIndex(item?.title)].fgColor
                                  }">
                                  <span class="avatar_initial">{{ getFirstInitial(item?.title) }}</span>
                              </div>
                            </v-avatar>
                            {{ item.title }}
                            <!-- {{ item.title }} {{ owners[item.value - 1].memberId }} -->
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template #selection="{ item }">
                        <v-chip
                          v-if="selectOwner"
                          closable
                          class="owner-chip"
                          @click="closeOwner(item)"
                        >
                          <template #prepend>
                            <v-avatar
                              class="avatar1"
                              v-if="item.image"
                              :image="item.image ? item.image : backupProfile"
                            >
                            </v-avatar>
                            <v-avatar
                              class="avatar"
                              v-else
                            >
                            <div class="avatar_circle" 
                            style="width:24px; height:21px;"
                                  :style="{
                                  backgroundColor: avatarColors[getColorIndex(item?.title)].bgColor,
                                  color: avatarColors[getColorIndex(item?.title)].fgColor
                                  }">
                                  <span class="avatar_initial">{{ getFirstInitial(item?.title) }}</span>
                              </div>
                            </v-avatar>
                          </template>
                          {{ item.title }}
                        </v-chip>
                        <!-- :image="owners[item.value - 1].image" -->
                      </template>
                    </VSelect>
                  </VCol>
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="12">   
                          <div class="d-flex align-center">
                <VCheckbox
                  class="check"
                  v-model="isChecked"
                ></VCheckbox>
                <label class="TextContent">
{{ t('drawersText.milestoneChangeRequest') }}
                </label>
                  <div>
                    <VIcon
                      style="cursor: pointer"
                      v-bind="props"
                      class="tooltipIcon ml-2"
                    >
                      mdi-information-variant-circle-outline
                    </VIcon>
                    <v-tooltip activator="parent" location="right" max-width="254px" class="icon-tooltip" >
                      <span class="tooltipText">
                        You can create separate milestones for Change Requests within project to track the specific CR related progress/details.
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
                    </v-row>
                    <v-row style="position: absolute; bottom: 0; width: 100%;">
                        <v-col>
                            <VBtn
                            :loading="btnLoadings"
                  @click="addNewMilestone(); btnLoad();"
                  :disabled="
                    !basic ||
                    checkStartDate || milestoneStartDateChecks ||
                    milestoneEndDateChecks ||
                    
                    checkMilestoneNameLength || budgetInHoursComp || budgetInCostComp
                  "
                  min-width="120" 
                  height="38"
                  class="clrDisable ms-btn mb-4"
                  rounded="pill"
                >
                  {{ t('drawersText.save') }}
                </VBtn>
                        </v-col>
                    </v-row>
            </div>
    
    <VDialog v-model="showDialog" width="500">
      <VCard style="border-radius: 24px">
        <div class="d-flex justify-end">
          <v-btn icon color="black" variant="plain">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-center px-5">
          <img
            v-if="addMilestoneResponse = 200"
            :width="100"
            aspect-ratio="1/1"
            class="ml-auto gif-warning"
            :src="successGif"
          />
          <img
            v-if="addMilestoneResponse != 200"
            :width="100"
            aspect-ratio="1/1"
            class="ml-auto gif-warning"
            :src="warningGif"
          />
        </div>
        <div class="text-center px-5 ma-6 message-style">
          {{ milestonecreatedMsg }}
        </div>
        <div class="mb-8 px-5 d-flex justify-center">
          <VBtn
            rounded="pill"
            class="ml-3 w-25 btn-class"
            @click="
              goBackToProjectPage();
              closeOnSubmit();
              showDialog = false;
            "
            height="44"
          >
            OK
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </template>
  
<script setup lang="ts">
import { useLoaderStore } from "@/stores/loader";
import {useTodosListStore} from '@/stores/todosListTree'
import { useSnackbarStore } from '@/stores/snackbarStore'
import workstatuslogo from "@/assets/images/dialogwslogo.svg";
import addMilestone from "@images/icons/themeupdate/addMilestone.png";
import calendarSvg from "@/assets/images/svg/date_picker_img.svg";
import successGif from "@/assets/images/pages/thankyousuccess.gif";
import loaderComponentDialogVue from "@/views/globalComponent/loaderComponentDialog.vue";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import {watch} from 'vue';
import axios from "axios";
import { userDetailStore } from "@/stores/userdata";
import backupProfile from "@/assets/images/newtheme/ws_default_profile_placehoder.svg";
import { requiredFieldValidator, requiredValidator, requiredValidatorchk } from "@validators";
import { useI18n } from 'vue-i18n';
import warningGif from "@/assets/images/gif/swalwarningtheme.gif";
import { useDrawerStore } from "@/stores/drawer.ts"
import { clearMilestoneStore} from '@/indexedDBServices/milestonelistDB';

// helper file
import { avatarColors, getFirstInitial, getFirstTwoInitials, getColorIndex } from "@/helper/avatarLetter";

  //declarations
const drawerstore = useDrawerStore(); 
const snackbarStore = useSnackbarStore();



const router = useRouter();
const route = useRoute();
const todoListStore = useTodosListStore()
const projectOwnerList = ref<any>();
const showDialog = ref<boolean>(false);
const isChecked = ref<boolean>(false);
const numberInput = ref("");
const milestoneName = ref<any>("");
const ownerRef = ref();
const customSearch = ref<any>("");
const searchProject = ref<any>("");
const selectProject = ref<any>();
const loader = useLoaderStore();
const showLoader = ref<any>(false);
const projectList = ref<any>();
const milestonecreatedMsg = ref<any>();
const { t } = useI18n();
const checkboxVal = ref<any>();
const flag = ref<any>(0);
const selectedBudgetType = ref<any>('')
const budgetTypeDropdown = ref<any>([]);
const budgetInHours = ref<any>();
const budgetInCost = ref<any>();
const selectFlag = [
  { id: 0, name: "Internal" },
  { id: 1, name: "External" },
];
const showcal = ref<any>(false);
const showOverlay = ref<any>(false);
const showcalB = ref<any>(false);
const showOverlayB = ref<any>(false);
const selectedStartDate = ref<any>("");
const showSelectedStartDate=ref<any>('');
const selectedEndDate = ref<any>("");
const showSelectedEndDate=ref<any>('');
const userData = userDetailStore();
const selectOwner = ref<any>();
const isGreaterThan10 = ref<any>();
const cancelSource = axios.CancelToken.source();
const oldVal = ref<any>(0); 
const addMilestoneResponse = ref<any>();   
const projectFieldIsReadonly = ref<boolean>(false)
const loadings = ref<boolean[]>([]);
loadings.value[0] = false
loadings.value[1] = false
// loadings.value[2] = false
const fieldLoader=ref<boolean[]>([]);
fieldLoader.value[0] = false;
// loader for save button 
const btnLoadings = ref<any>(false);
const btnLoad = () => {
  btnLoadings.value = true;

};
const emit = defineEmits(['closeMilestoneDrawer'])
const closeOnSubmit= () => {
    emit('closeMilestoneDrawer');
}
  
onBeforeRouteLeave((to, from) => {
  cancelSource.cancel("Request canceled by navigation");
});

const budgetInHoursComp = computed(() => {
  if(budgetInHours.value){
      const inputHours = String(budgetInHours.value)?.trim();
      
        if (inputHours?.length > 8) {
        return 'Maximum 8 digits are accepted.';
      }
    }
    });
    const budgetInCostComp = computed(() => {
      if(budgetInCost.value){
      const inputCost = String(budgetInCost.value)?.trim();
      
        if (inputCost?.length > 8) {
        return 'Maximum 8 digits are accepted.';
      }
    }
    });
onMounted(() => {
  console.log("roitw", route.params.id)
  fetchProjectOwnerList()
  // getFinanceSetting();
  fetchprojectList();
  fetchBudgetTypelist();

});

onBeforeUnmount(() => {
    todoListStore.selectedProjectForAddTodo = ""
})

const routeNames = [
  'dashboard-projects-view-id-bulk-task',
  'dashboard-projects-view-id-import-task',
  'dashboard-projects-view-id-message-board',
  'dashboard-projects-view-id-timesheet',
  'dashboard-projects-view-id-todos',
  'dashboard-projects-view-id-milestones',
  'dashboard-projects-view-id-overview'
];

const isRouteNameInList = computed(() => routeNames.includes(route.name));

const fetchprojectList = () => {
    // loader.setGlobalLoader(true);
    loadings.value[0]=true;
    fieldLoader.value[0]=true;
    axios.post("/v1/filter/projectlist",
        {
          org_id: userData.userDetails.org_id,
          member_id: userData.userDetails.id
          // keyword: "",
          // billable: "",
          // project_status: "",
          // over_budget: "",
          // user_id: [],
          // client_id: [],
          // data_count: 10,
        },{ cancelToken: cancelSource.token }
      ).then((res) => {
        if (res.data.response.code == 200) {
        const filteredProjData = res.data.response.data.filter(item => item.archieved !== 1 && ![3, 4, 5].includes(item.project_status));
          projectList.value = filteredProjData
          selectedProjectForMilestoneChecks.value = projectList.value.find(project => project.id == selectProject.value);
          if(selectedProjectForMilestoneChecks.value?.budget_type == "cost" && finance_setting.value){
          selectedBudgetType.value = "Total Cost"
          }
          else{
          selectedBudgetType.value = "Total Hours"
          }
        }

        if(route.params.id){
          selectProject.value = parseInt(route.params.id)
          projectFieldIsReadonly.value = true
          selectedProjectForMilestoneChecks.value = projectList.value.find(project => project.id == selectProject.value);
          if(selectedProjectForMilestoneChecks.value?.budget_type == "cost" && finance_setting.value){
          selectedBudgetType.value = "Total Cost"
          }
          else{
          selectedBudgetType.value = "Total Hours"
          }
        }
        getFinanceSetting();
        loader.setGlobalLoader(false);
        loadings.value[0]=false;
    fieldLoader.value[0]=false;
      });
};
const warningStartDate = ref<any>();
  const warningEndtDate = ref<any>();
const warningEndDate = ref<any>();
const selectedProjectForMilestoneChecks = ref<any>();
const selectedProjectData = () => {
  selectedProjectForMilestoneChecks.value = projectList.value.find(project => project.id == selectProject.value);
  if(selectedProjectForMilestoneChecks.value?.budget_type == "cost" && finance_setting.value){
  selectedBudgetType.value = "Total Cost"
  }
  if(selectedProjectForMilestoneChecks.value?.budget_type == "hours"){
  selectedBudgetType.value = "Total Hours"
  }
  getFinanceSetting()
}
const milestoneStartDateChecks = computed(() => {
  if (selectedStartDate.value == "" || showSelectedStartDate.value == "")
    return false;
  let formattedStartDate = moment(selectedStartDate.value).format("YYYY-MM-DD");
  let formattedEndtDate = moment(selectedEndDate.value).format("YYYY-MM-DD");
  let startdate = moment(formattedStartDate, "YYYY-MM-DD");
  let enddate = moment(formattedEndtDate, "YYYY-MM-DD");
  let projectStartDate = moment(selectedProjectForMilestoneChecks.value?.start_date, "YYYY-MM-DD");
  let projectEndDate = moment(selectedProjectForMilestoneChecks.value?.end_date, "YYYY-MM-DD");
  if (startdate.isBefore(projectStartDate)) {
    warningStartDate.value = t('budget.startDateSmaller');
    return true;
  }
  else if(startdate.isAfter(enddate)){
    warningStartDate.value = t('budget.startDateGreater');
    return true;
  }
  else if(startdate.isAfter(projectEndDate)){
    warningStartDate.value = "Start date is greater than project end date";
    return true;
  }
  else if (startdate.isSame(enddate)) {
    return false;
  }
  else {
    return false;
  }

});

const milestoneEndDateChecks = computed(() => {
  if ( selectedEndDate.value == "" || showSelectedEndDate.value == "")
    return false;
  let formattedStartDate = moment(selectedStartDate.value).format("YYYY-MM-DD");
  let formattedEndtDate = moment(selectedEndDate.value).format("YYYY-MM-DD");
  let startdate = moment(formattedStartDate, "YYYY-MM-DD");
  let enddate = moment(formattedEndtDate, "YYYY-MM-DD");
  let projectStartDate = moment(selectedProjectForMilestoneChecks.value?.start_date, "YYYY-MM-DD");
  let projectEndDate = moment(selectedProjectForMilestoneChecks.value?.end_date, "YYYY-MM-DD");

  if (enddate.isAfter(projectEndDate)) {
    warningEndtDate.value = t('budget.endDateGreater');
    return true;
  }
  else if (enddate.isBefore(startdate)) {
    warningEndtDate.value = t('budget.endDateSmaller');
    return true;
  }
  else if (enddate.isBefore(projectStartDate)) {
    warningEndtDate.value = " End date is smaller than Project start date";
    return true;
  }
  else if (startdate.isSame(enddate)) {
    return false;
  }
  else {
    return false;
  }
});
const fetchProjectOwnerList = () => {
    // loader.setGlobalLoader(true);
  
    axios.post("/v1/todos/list/membersdropdown",
        {
          org_id: userData.userDetails.org_id,
          project_id: selectProject.value ? [selectProject.value] : [parseInt(route.params.id)],
          active_user: 1
        },{ cancelToken: cancelSource.token }
    ).then((res) => {
        if (res.data.response.code == 200) {
          projectOwnerList.value = res.data.response.data;
          projectOwnerList.value.forEach((element) => {
            if (element.member_id) {
              element.name = element.name + " (" + element.member_id + ")";
            }
          });
        }
        // getFinanceSetting();
        loader.setGlobalLoader(false);
      });
};
const addNewMilestone = () => {
    // loader.setGlobalLoader(true);
    if (isChecked.value) {
      checkboxVal.value = "1";
    } else {
      checkboxVal.value = "0";
    }
    if(selectedBudgetType.value == "Total Cost" ){
      budgetInHours.value = 0
    }
    else{
      budgetInCost.value = 0
    }
    axios.post("/v1/add/projectmilestone",
        {
          name: milestoneName.value,
          project_id: selectProject.value,
          start_date: selectedStartDate.value ? moment(selectedStartDate.value, userData.getDateFormat).format('YYYY-MM-DD') : '',
          end_date: selectedEndDate.value ?  moment(selectedEndDate.value, userData.getDateFormat).format('YYYY-MM-DD') : '',
          budgted_hours: budgetInHours.value ? budgetInHours.value : 0,
          budget_type : selectedBudgetType.value == "Total Cost" ? 'cost' : 'hours',
          budget_cost : budgetInCost.value ? budgetInCost.value : 0,
          flag: flag.value,
          project_owner: selectOwner.value?.raw?.userID,
          cr: checkboxVal.value,
        },{ cancelToken: cancelSource.token }
      ).then((res) => {
        if (res.data.response.code == 200) {
          if(projectFieldIsReadonly.value){
            let milestoneObj = {
              id: res.data.response.data.id,
              milestone_name: res.data.response.data.milestone,
              flag: res.data.response.data.flag
            }
            drawerstore.createdMilestoneData = milestoneObj
            
          }
          clearMilestoneStore();
          drawerstore.loadMilestonesListFromTodoDrawer = true;
          snackbarStore.setSnackbar(res.data.response.message, res.data.response.code);
          goBackToProjectPage();
          closeOnSubmit();
          
        }
        snackbarStore.setSnackbar(res.data.response.message, res.data.response.code);
        addMilestoneResponse.value = res.data.response.code;
        // showDialog.value = true;
        milestonecreatedMsg.value = res.data.response.message;
        loader.setGlobalLoader(false);
        btnLoadings.value=false;
        goBackToProjectPage();
        closeOnSubmit();
      });
};
const ownerSelect = (item: any) => {
  selectOwner.value = item;
};
const closeOwner = (item: any) => {
  selectOwner.value = "";
};
const handleBudgetHours = () => {
  if(budgetInHours.value.length > 8){
    isGreaterThan10.value = true;
    budgetInHours.value = oldVal.value;
  } else {
    isGreaterThan10.value = false;
    oldVal.value = budgetInHours.value;
  }
}
const handleBudgetCost = () => {
  if(budgetInCost.value.length > 8){
    isGreaterThan10.value = true;
    budgetInCost.value = oldVal.value;
  } else {
    isGreaterThan10.value = false;
    oldVal.value = budgetInCost.value;
  }
}
const finance_setting = ref<any>();
const getFinanceSetting=()=>{
    axios.post('v1/checkUserFinanceSettings',{
        user_id : userData.userDetails.id,
        project_id: selectProject.value ? selectProject.value : [parseInt(todoListStore.selectedProjectForAddTodo)],
    },{cancelToken: cancelSource.token}).then((res)=>{
        if(res.data.response.code == 200){
          finance_setting.value = res.data.response.data.financeSettings;
        }
    })
}
const fetchBudgetTypelist= ()=>{
 loadings.value[1]=true;
    axios.get('v1/project/budget/types', { cancelToken: cancelSource.token }).then((res)=>{
        if(res.data.response.code == 200){
            const data = res.data.response.data;
            for (let key in data) {
              budgetTypeDropdown.value.push(data[key]);
}          
    // if(selectedBudgetType.value == ''){  
    //     selectedBudgetType.value = budgetTypeDropdown.value[0];
    //   }
        }
         loadings.value[1]=false;
    })
}
const filteredOptions = computed(() => {
  const _search = customSearch.value.toLowerCase();
  if (!_search) return projectOwnerList.value;
  return projectOwnerList.value?.filter((item) => {
    const text = item.name?.toLowerCase();
    return text.indexOf(_search) > -1;
  });
});
const filteredProjects = computed(() => {
  const searchp = searchProject.value.toLowerCase();
  if (!searchp) return projectList.value;
  return projectList.value.filter((item) => {
    const text = item.name.toLowerCase();
    return text.indexOf(searchp) > -1;
  });
});

const clearSearch = () => {
  searchProject.value = "";
};
const clearOwner = () => {
  customSearch.value = "";
};
const closeCal = () => {
  showcal.value = false;
  showOverlay.value = false;
};
const closeCalOnModelVal = () => {
  showcal.value = false;
  showOverlay.value = false;
  showSelectedStartDate.value=moment(selectedStartDate.value).format(
    userData.getDateFormat
  )
  // selectedStartDate.value = moment(selectedStartDate.value).format(
  //   userData.getDateFormat
  // );
};
const closeCalOnModelValB = () => {
  showcalB.value = false;
  showOverlayB.value = false;
  showSelectedEndDate.value = moment(selectedEndDate.value).format(userData.getDateFormat);
  // selectedEndDate.value = moment(selectedEndDate.value).format(userData.getDateFormat);
};
const closeCalB = () => {
  showcalB.value = false;
  showOverlayB.value = false;
};
// const checkMilestoneName = computed(() => {
//   if (milestoneName.value && milestoneName.value.length) {
    
//     let checkVal = /^[a-zA-Z0-9\s]+$/.test(milestoneName.value);
//     if (checkVal) {
//       return false;
//     } else {
//       return true;
//     }
//   }
//   return false;
// });
const checkMilestoneName = computed(() => {
  if (milestoneName.value && milestoneName.value.length) {      
    let checkVal = /^[a-zA-Z0-9\s\-|&>]+$/.test(milestoneName.value);
    if (checkVal) {
      return false;
    } else {
      return true;
    }
  }
  return false;
});
const checkMilestoneNameLength = computed(() => {
  if (milestoneName.value && milestoneName.value.length > 100) {
    return true;
  }
  return false;
});
const basic = computed(() => {
  return (
    requiredFieldValidator(milestoneName.value) === true &&
    milestoneLengthError.value === false &&
    requiredFieldValidator(selectProject.value) === true &&
    requiredFieldValidator(flag.value) === true
  );
});
const milestoneLengthError = computed(() => {
  if (milestoneName.value.length > 250) {
    return true;
  } else {
    return false;
  }
});
const checkStartDate = computed(() => {
  if (selectedStartDate.value == "" || selectedEndDate.value == "")
    return false;
  let startdate = moment(selectedStartDate.value, userData.getDateFormat);
  let endDate = moment(selectedEndDate.value, userData.getDateFormat);

  if (startdate.isSame(endDate)) {
    return false;
  }
  if (startdate.isBefore(endDate)) {
    return false;
  } else {
    return true;
  }
});
const checkEndDate = computed(() => {
  if (selectedStartDate.value == "" || selectedEndDate.value == "")
    return false;
  let startdate = moment(selectedStartDate.value, userData.getDateFormat);
  let endDate = moment(selectedEndDate.value, userData.getDateFormat);
  if (startdate.isSame(endDate)) {
    return false;
  }
  if (endDate.isAfter(startdate)) {
    return false;
  } else {
    return true;
  }
});
const displayDialog = () => {
  showDialog.value = true;
};
const back = () => {
  router.back();
};

const goBackToProjectPage = () =>{
//   router.push(`/dashboard/projects/view/${selectProject.value}/milestones`);
}
</script>
  
<style scoped lang="scss">
  .bgcolor {
    background: linear-gradient(91deg, #4535a7 0.86%, #503ec5 110.1%);
  }
  .title-style {
    font-style: normal;
    font-weight: 560;
    font-size: 25px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #fff !important;
  }
  .dialogsub {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #c8c1f2 !important;
  }
  .whitecirclewithn {
    padding: 12px;
    border-radius: 49%;
    width: 25px;
    height: 25px;
    justify-content: center;
    border: 0.5px solid #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .milestoneTab {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .dialog-back-button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #624de3;
    margin-left: 10px;
  }
  .tooltipIcon {
    width: 13px;
    height: 13px;
    margin-bottom: 2px;
  }
  .tooltipText {
    color: #fff;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
  }
  .ms-btn {
    width: 144px;
    height: 44px;
    background-color: #624de3;
    border-radius: 100px;
    font-weight: 500;
    color: #ffff;
  }
  .searchField {
    position: sticky !important;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: 50px !important;
  }
  .ownerList {
    border-bottom: 1px solid #cfd3da;
  }
  .ownerList:hover {
    background: #f4f7fc;
  }
  .ownerContent {
    cursor: pointer;
  }
  .avatar {
    width: 28px;
    height: 28px;
    margin-right: 7px;
  }
  .avatar1 {
    border: 1px solid white;
    width: 22px;
    height: 22px;
  }
  .owner-chip {
    color: #624de3;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #overlay {
    // position: absolute;
    // top: 0px;
    // left: 0px;
    // bottom: 0px;
    // right: 0px;
    // background-color: transparent;
    // z-index: 5;
    
    position: absolute;
    top: -202px;
    left: 0px;
    right: 0px;
    /* bottom: 0px; */
    width: 48vw;
    height: 98vh;
    background-color: transparent;
    z-index: 5;
  }
  .hide-v-cal {
    visibility: hidden;
  }
  #overlayb {
    // position: absolute;
    // top: 0px;
    // left: 0px;
    // bottom: 0px;
    // right: 0px;
    // background-color: transparent;
    // z-index: 5;

    position: absolute;
    top: -202px;
    left: -225px;
    right: 0px;
    /* bottom: 0px; */
    width: 48vw;
    height: 98vh;
    background-color: transparent;
    z-index: 5;
  }
  .hide-v-calb {
    visibility: hidden;
  }
  .label {
    color: var(--font-3, #546181);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .check {
    .v-label {
      color: var(--font-2-WS, #525f7f);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-wrap: wrap;
    }
  }
  .disabled-btn-style {
    background: #666;
  }
  .clrDisable:disabled {
    background-color: #ccc8c8;
    cursor: not-allowed;
    border-radius: 100px;
  }
  .gif-warning {
    background-color: #f4f2ff !important;
    border-color: #f4f2ff !important;
    border-radius: 50%;
    height: 100px !important;
    width: 100px !important;
  }
  .message-style {
    color: #00234b;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 12px;
  }

  .show-v-cala {
    z-index: 6;
    position: absolute;
  }
  .show-v-calb {
    z-index: 6;
    position: absolute;
  }

  /* -------------------- */
.heading {
    color: #00234B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subHeading {
    color: #617798;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.TextContent {
    color: #00234B;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: normal; */
}

.labelText {
    color: #617798;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.switchLabel {
    color: var(--font-2-WS, #525F7F);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* css for Dark Mode */
.darkBackground{
    background: #594EDF;
}
.lightBackground {
    background:#F3F4F8;
}
.darkHeading{
color: #FFFFFF;
}
.darkContent{
   color: #C8C1F2;
}
</style>
  
  <route lang="yaml">
    meta:
        layout: blank
</route>
  